<template>
    <div class="my-4 relative">
      <div class="grid lg:grid-cols-2 grid-cols-1 lg:px-16 sm:px-4 gap-4">
        <AnalyticsTile title="TOTAL SPENT THIS YEAR" :stat="'£' + yearly.spent" icon="/img/icons/pound-circle-48x48.png"/>
        <AnalyticsTile title="GIGS BOOKED THIS YEAR" :stat="yearly.booked_gigs" icon="/img/icons/group-circle-48x48.png"/>
      </div>
      <div class="grid lg:px-16 sm:px-4 gap-4 mt-10">
        <BasicAnalyticChart :user-venues="userVenues" :spending="chartData.spending" @fetchAnalytics="fetchAnalytics"/>
      </div>
  </div>
</template>

<script type="text/javascript">


import { apiComputed, apiMethods } from '@/state/helpers.js'
import store from "@/state/store";
import AnalyticsTile from "./Components/analytics-tile.vue";
import BasicAnalyticChart from './Components/basic-analytic-chart.vue';
import NProgress from "nprogress";
import Echo from 'laravel-echo';

export default {
  name: 'Analytics',
  data: () => {
    return {
      yearly: {
        spent: 0.00,
        booked_gigs: 0
      },
      chartData: {},
      userVenues: [],
      filter_data: {},
    }
  },
  components: {
    AnalyticsTile,
    BasicAnalyticChart
  },
  mounted() {
    setTimeout(() => {
        // do route stuff
        store.dispatch('venue/getVenuesList').then(response => {
          this.userVenues = response;
        });
        this.setupPusher();
    }, 1500);
  },
  computed: {
    ...apiComputed
  }, 
  methods: { 
    ...apiMethods,
    fetchAnalytics(filter_data){
      NProgress.start();
      this.chartData.spending = null;
      this.filter_data = filter_data;
      store.dispatch('api/getBasicAnalytics', filter_data).then(async (response) => {
        await this.setAnalyticsData(response);
      }).then(() => {
        NProgress.done();
      });
    },
    setAnalyticsData(response_or_event){
      this.yearly.spent = 0;
      this.yearly.booked_gigs = 0;
      this.chartData = [{}];
      if(response_or_event) {
        this.yearly.spent = response_or_event.data.tiles.total_spent;
        this.yearly.booked_gigs = response_or_event.data.tiles.gigs_booked;
        this.chartData = response_or_event.data.charts;
      }
    },
    setupPusher(){
      window.Pusher = require('pusher-js');
      var authToken = this.$auth.getTokenSilently();
      authToken.then((authToken) => {
        window.Echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: false, // set to true if you are using HTTPS
          authEndpoint: process.env.VUE_APP_BROADCASTING_AUTH_URL,
          auth: {
            headers: { Authorization: 'Bearer ' + authToken }
          }
        });
      }).then(() => {
        setTimeout(() => {
          this.setupAnalyticsListener();
        }, 1000);
      });
    },
    setupAnalyticsListener(){
      let state_user_id = this.$store.state.user.userData.id;
      let $self = this;
      window.Echo.leave("update-analytics." + state_user_id);
      window.Echo.private("update-analytics." + state_user_id).listen(
          '.update-analytics',
          async (event_data) => {
            console.log('analytics', event_data.data);
            await $self.fetchAnalytics($self.filter_data);
            // This is not used because of date filters
            // await $self.setAnalyticsData(event_data);
          }
      );
    },
  }
};

</script>