<template>
    
        <div class="mt-5 flex-1 flex flex-col lg:overflow-auto overflow-y-scroll"> 
            <nav id="sidebar" class="flex-1 px-2 justify-between v space-y-1 flex flex-col">
              <div v-if="navLoaded">
                  <nav-artist v-if="showArtistNav" :disableNav="disableNav" />
                  <nav-event v-if="showVenueNav"  />
              </div>   
            </nav>
               
        </div>   
</template>

<script>
import NavArtist from './artist/NavArtist.vue';
import NavEvent from './event/NavEvent.vue';
import { apiMethods, apiComputed } from '@/state/helpers'
export default {
    name: "NavContainer",
    components: { NavEvent, NavArtist }, 
    data()
    {
        return { 
          disableNav: false,
          navLoaded: false
        }
    },
    computed:{
        ...apiComputed,
        showArtistNav(){
            return this.$route.path && this.$route.path.indexOf('/artist') >= 0 && this.$route.path.indexOf("/artist-directory") < 0;
        },
        showVenueNav(){ 
            return this.$route.path && this.$route.path.indexOf('/artist') < 0 && this.$route.path != "/" && this.$route.path != "/auth/callback" || this.$route.path.indexOf("/artist-directory") >= 0;
        }
    },
    created(){
        this.navLoaded = true;
    },
    methods:
    {
        ...apiMethods        
    }
}
</script>