<template>
	<div class="flex flex-row flex-wrap gap-4 mt-2">
		<span
			class="bg-gp_pink-default-500 text-white text-xs sm:text-sm px-2 sm:px-4 py-0.5 rounded-xl sm:rounded-2xl"
			v-for="tag in venueTags" :key="tag.id"
		>
			{{ tag.name }}
		</span>
	</div>
</template>

<script>
export default {
	props:{
		venueTags:{
			type:Array,
			required: true,
		}
	},
}
</script>