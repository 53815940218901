<template>
	<div class="relative z-10">
		<div class="fixed z-10 inset-x-0 bottom-20">
			<div class="flex items-end sm:items-center justify-center min-h-full shadow ring-1 ring-black ring-opacity-5 mx-3 mb-12 sm:mx-16 sm:mb-2 rounded sm:rounded-lg p-2 text-center transition-all" :class="classes">
				<slot name="default"></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		show:{
			type:Boolean,
			default:false
		}
	},
	computed:{
		classes() {
			return !this.show ? 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' : 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100'
		}
	}
}
</script>