<template>
	<TabsWrapper :tabs="tabs" 
				:gig="this.gig"
				class="overflow-x-auto overflow-y-hidden">


	</TabsWrapper>
</template>

<script>
import TabsWrapper from '../ui/tabs/TabsWrapper.vue'; 

export default {
    name: "GigTabs",
		components: {
			TabsWrapper, 
		},
    props: 
    {
			currentRoute:{
				type:String,
				required: true,
			},
			gigId:{
				type:String,
			}
    },
		data(){
			return {
				tabs:[],
				offerCount: null
			}
		},
		computed:{
			gig(){
				return this.$store.getters['gig/currentGig']
			},
			userPermissions(){
				return this.$store.getters['user/rolesPermissionsSlugs']
			},
      bookingsIncludingArtistCancellations() {
        const c = this.gig.requestedCancellationBookings ? this.gig.requestedCancellationBookings : []
        return [...this.gig.bookings, ...c]
      }
		},
		watch: {
			gig() { 
				this.setOfferCounter();
			}
		}, 
		mounted(){
				this.tabs = this.setTabsData(this.gigId, this.currentRoute)
				this.setOfferCounter();
		},
		methods:{
			setOfferCounter(){
				if (this.$route.name === 'GigChooseArtists') {
					if (this.gig.bids_count) {
						this.offerCount = this.gig.bids_count ? this.gig.bids_count : 0
					} else {
						this.offerCount = 0;
					}
				} else { 
					if (this.gig.bid_count) {
						this.offerCount = this.gig.bid_count ? this.gig.bid_count : 0
					} else {
						this.offerCount = 0;
					}
				}

				this.tabs = this.setTabsData(this.gigId, this.currentRoute)
			},
			setTabsData(gigId, currentRoute){
				const tabs = [{
						title:"Details", 
						route:this.gig ? `/gigs/${gigId}` : '/gigs/add', 
						current:currentRoute === 'index' || currentRoute === 'details'
				}]
				if(this.gig){
					tabs.push({ 
						title:"Artist instructions", 
						route:`/gigs/${gigId}/instructions`, 
						current:currentRoute === 'instructions'
					});

					if(this.gig.payment_terms){
						tabs.push({ 
						title:"Choose artists", 
						route:`/gigs/${gigId}/choose-artists`, 
						current:currentRoute === 'choose-artists'
						});
					
						tabs.push({ 
							title:"Repeat gig", 
							route:`/gigs/${gigId}/repeat`, 
							current:currentRoute === 'repeat'
						});

						tabs.push({ 
						title:"Gig template", 
						route:`/gigs/${gigId}/gig-template`, 
						current:currentRoute === 'gig-template'
						});

						tabs.push({ 
							title:"Notes", 
							route:`/gigs/${gigId}/notes`, 
							current:currentRoute === 'notes',
						}); 
					}
					if(this.gig.published){
						// TODO - do not use bookings_count here, instead go on status? 
						tabs.push({ 
							title:"Offers", 
							route:`/gigs/${gigId}/offers`, 
							current:currentRoute === 'offers',
							notifications:this.offerCount
						});
					}

					if(this.bookingsIncludingArtistCancellations){
						if(this.bookingsIncludingArtistCancellations.length){
							tabs.push({
								title:"Bookings",
								route:`/gigs/${gigId}/bookings`,
								current:currentRoute === 'bookings',
								notifications:this.bookingsIncludingArtistCancellations ? this.bookingsIncludingArtistCancellations.length : 0
							});
							if(this.gig.is_past){
								tabs.push({
								title:"Invoices",
								route:`/gigs/${gigId}/invoices`,
								current:currentRoute === 'invoices',
								});
							}
						}
					}

					if (this.userPermissions.includes('billing-invoices') || this.userPermissions.includes('full-access') || this.isVenueOwner) {
						tabs.push({
							title: "Manage Venue Subscription",
							route: `/venues/${this.gig.venue.id}/manage-subscription`,
							current: currentRoute === 'manage-subscription'
						});
					}
					
				}
				return tabs
			},
			onMessagesSelect(){
				this.$router.push('/messages/gig/' + this.gigId); 
			}
		},
}
</script>

<style lang="postcss" scoped>
	a,
	a.router-link-active{
    @apply selection:border-transparent text-gray-500  hover:text-gray-700  hover:border-gray-200 whitespace-nowrap flex py-4 px-1 font-medium text-sm;
	}
	.router-link-active{
    @apply bg-transparent;
	}
	a.router-link-exact-active{
		@apply border-indigo-500 text-indigo-600 hover:border-indigo-500 hover:text-indigo-600 border-b-2 bg-transparent;
	}
	
</style>