<template>
	<div class="details-view">
		<h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">You've been invited to GigPig</h1> 

		<template v-if="isPageLoading">
			<div class="p-8 flex justify-center items-start">
				<PageSpinner 
					tailwind-diameter="8"
					tailwind-border="4"
					tailwind-color="pink-500"
				/>
			</div>
		</template>
		<template v-else-if="pageError">
			<div class="flex">
				<p class="text-md my-4">{{ pageError }}</p>
			</div>
		</template>
		<!-- Invite Check (Invite exists)-->
		<template v-else-if="!invite">
			<div class="flex">
				<p class="text-md my-4">We are sorry, but the invite could not be loaded</p>
			</div>
		</template>
		<!-- Invite has not been accepted -->
		<template v-else-if="invite.accepted_at">
			<div class="flex">
				<p class="text-md my-4">We are sorry, but this invititation to {{ invite.role.name }} has already been accepted</p>
			</div>
		</template>

		<template v-else>
			
			<div class="flex flex-col">
				<p class="text-md my-4">You've been invited as {{ invite.role.name }} to {{ invite.related.name }}.</p>
				<p class="text-md my-4">Please accept this invite to continue.</p>
			</div>
		

			<div v-if="successMsg" class="flex w-full justify-center bg-green-50 rounded">
				<p class="text-center text-md my-4 text-green-600">{{ successMsg }}</p>
			</div>

			<div v-if="errorMsg" class="flex w-full justify-center bg-red-50 rounded">
				<p class="text-center text-md my-4 text-red-600">{{ errorMsg }}</p>
			</div>

			<div class="flex flex-col sm:flex-row w-full justify-between bg-gray-50">

				<div class="px-2 pt-3 pb-0 sm:pb-3 sm:px-3 flex flex-col sm:flex sm:flex-row-reverse space-x-4">
					<GPButton @click.native="onReject" :disabled="submitDisabled" :loading="isLoading && apiCall === 'reject'" type="button" color="indigo">{{ rejectButtonLabel }}</GPButton>
				</div>

				<div class="px-2 py-3 sm:px-3 flex space-x-0 sm:space-x-4 space-y-2 sm:space-y-0 flex-col sm:flex-row" >
					<GPButton @click.native="onAccept" :disabled="submitDisabled" :loading="isLoading && apiCall === 'accept'" type="button">{{ acceptButtonLabel }}</GPButton>
				</div>
				
			</div>
		</template>
		

	</div>
	
</template>

<script>

import PageSpinner from '../../components/ui/spinner/PageSpinner.vue';
import GPButton from '../../components/ui/buttons/Button.vue'
import client from '../../utils/client';

export default {
	components: {
		PageSpinner,
		GPButton,
	},
	props:['venueId', 'inviteId'],
	data(){
		return {
			isPageLoading:false,

			isLoading:false,
			form:{
				token:null
			},
			queryToken:null,
			apiCall:null,
			successMsg:null,
			errorMsg:null,
			pageError:null,

			invite:null,
		}
	},
	computed:{
		tokenValid() {
      return this.form.token === "" || !this.form.token ? false : true;
    },
		submitDisabled() {
      return this.form.token === "" || !this.form.token || !this.venueId || !this.inviteId || this.isLoading || this.successMsg || this.errorMsg ? true : false;
    },
		acceptButtonLabel() {
      return this.isLoading && this.apiCall === "accept" ? 'Accepting invitation' : 'Accept invitation';
    },
		rejectButtonLabel() {
      return this.isLoading && this.apiCall === "reject" ? 'Rejecting invitation' : 'Reject invitation';
    },
	},
	methods:{
		onAccept(){
			this.apiCall = "accept"
			this.isLoading = true;
			this.resetMessages();
			const payload = { token: this.form.token }
			client
				.post(`invites/${this.inviteId}/acceptances`, payload)
				.then(() => {
					// reload roles
					this.$store.dispatch('user/getUsersRoles', true)
					.then(() => {
						this.successMsg = "Invitation acceptance was successful"
						this.isLoading = false;
					}, err => {
						console.log(err)
					});
				})
				.catch((err) => {
					if(err.response && err.response.status === 403){
						this.errorMsg = "Sorry, the invitation acceptance failed. Either the invite has expired or you do not have access."
					}else{
						this.errorMsg = "Sorry, the invitation acceptance failed"
					}
					this.isLoading = false;
				})
		},
		onReject(){
			this.apiCall = "reject"
			this.isLoading = true;
			this.resetMessages();
			const payload = { token: this.form.token }
			client
				.post(`invites/${this.inviteId}/rejections`, payload)
				.then(() => {
					this.successMsg = "Invitation rejection was successful"
					this.isLoading = false;
				})
				.catch((err) => {
					if(err.response && err.response.status === 403){
						this.errorMsg = "Sorry, the invitation rejection failed. Either the invite has expired or you do not have access."
					}else{
						this.errorMsg = "Sorry, the invitation rejection failed"
					}
					this.isLoading = false;
				})
		},
		resetMessages(){
			this.successMsg = null;
			this.errorMsg = null;
			this.pageError = null;
		}
	},
	mounted(){
		this.isPageLoading = true;
		this.queryToken = this.$route.query.token || null
		this.form.token = this.queryToken

		client
			.get(`venues/${this.venueId}/invites/${this.inviteId}?token=${this.queryToken}`)
			.then((response) => {
				console.log("Load invite")
				console.log(response.data.data)
				this.invite = response.data.data
				this.isPageLoading = false;
			})
			.catch((err) => {
				if(err.response && err.response.status === 403){
					this.pageError = "Sorry, we could not load the invitation. Either the invite has expired or you do not have access."
				}else{
					console.log(err)
					this.pageError = "Sorry, we could not load the invitation."
				}
				this.isPageLoading = false;
			})

	}
}
</script>