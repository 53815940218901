<template>
    <span class="badge " :class="gig.status.class">
        {{gig.status.name}}
    </span>
</template>

<script>
export default {
    name: "GigStatus",
    props:{
        gig: Object
    }
}
</script>