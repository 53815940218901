<template>

	<ModalWrapper ref="editVenueBillingsModal" :is-open="isOpen">
		<template #header>
			<div class="text-xl"> 
				Profile Billing Details
			</div>
			<p class="mt-2 text-sm">
				This information will be shown on your invoices from artists.
			</p>
		</template>

		<template #body>

			<form class="mb-24 form mt-0 gap-y-4 input-group" ref="form" novalidate> 
				<div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5 col-span-full">

					<div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <span>Billing address</span>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label
                for="venue-address-line1"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Email address
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input 
                  name="venue-billing-email"
                  id="venue-billing-email"
                  v-model="form.billing_email"
                  type="email"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  required
                />
                <span v-show="!form.billing_email" class="mt-8 text-xs text-red-500">Please complete the email field.</span>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label for="venue-address-company-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Company name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-company-name"
                  id="venue-address-company-name"
                  v-model="form.billing_address.company_name"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
                <span v-show="!form.billing_address.company_name" class="mt-8 text-xs text-red-500">Please complete the company name field.</span>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label
                for="venue-address-line1"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Line 1
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-line1"
                  id="venue-address-line1"
                  v-model="form.billing_address.address_line_1"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  required
                />
                <span v-show="!form.billing_address.address_line_1" class="mt-8 text-xs text-red-500">Please complete this field.</span>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
              <label
                for="venue-address-line2"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Line 2 (Optional)
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-line2"
                  id="venue-address-line2"
                  v-model="form.billing_address.address_line_2"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
              <label
                for="venue-address-town"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Town/City
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-town"
                  id="venue-address-town"
                  v-model="form.billing_address.town"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  required
                />
                <span v-show="!form.billing_address.town" class="mt-8 text-xs text-red-500">Please complete the town field.</span>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
              <label
                for="venue-address-county"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                County
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-county"
                  id="venue-address-county"
                  v-model="form.billing_address.county"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
                <span v-show="!form.billing_address.county" class="mt-8 text-xs text-red-500">Please complete the county field.</span>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
              <label
                for="venue-address-postcode"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Postcode
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-address-postcode"
                  id="venue-address-postcode"
                  v-model="form.billing_address.post_code"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  required
                />
                <span v-show="!form.billing_address.post_code" class="mt-8 text-xs text-red-500">Please complete the post code field.</span>
              </div>
            </div>
            <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
            >
              <label
                  for="countries"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Currency
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2 pt-0">
                <TRichSelect
                    id="currencies"
                    name="currencies"
                    v-model="form.currency.id"
                    :options="currencies"
                    @change="pickCurrency"
                    value-attribute="id"
                    text-attribute="display_name"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
                <span v-show="!form.currency.id" class="mt-8 text-xs text-red-500">Please complete the currency field.</span>
              </div>
            </div>
          </div>
			
				</div>
			</form>

		</template>

		<template #footer>

			<ModalFooterWrapper>

				<template #left>
					<GPButton @click.native="onClose" :disabled="isFormLoading" color="gray">Cancel</GPButton>
				</template>

				<template #right>
					<GPButton @click.native="onSave" :disabled="formDisabled" :loading="isFormLoading">{{ submitButtonLabel }}</GPButton>
				</template>

			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>

</template>
<script>

import ModalWrapper from '@/components/ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '@/components/ui/modal/ModalFooterWrapper.vue'
import {TRichSelect} from "vue-tailwind/dist/components";
import GPButton from '@/components/ui/buttons/Button.vue'

export default {
  components: {
		ModalWrapper,
		ModalFooterWrapper,
    TRichSelect,
    GPButton
  },
  props: {
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
    venue: {
      type: Object,
      required: true,
    },
    currenciesProp: {
      type: Array,
      required: false
    },
    currentCurrency: null
  },
	emits:["closed", "updated"],
  data() {
    return {
      form: {
        billing_address: {
          address_line_1: '',
          town: '',
          county: '',
          post_code: ''
        },
        billing_email: '',
        currency: {}
      },
    };
  },
  computed: {
    currencies() {
      return this.currenciesProp
    },
		hasData(){
			if(this.venue.id){
				return true;
			}
			return false;
		},
		formDisabled() {
      return !this.form.billing_address.address_line_1 || !this.form.billing_address.town || !this.form.billing_address.county  || !this.form.billing_address.post_code  || !this.form.billing_email ? true : false
    },
		isFormLoading(){
			return false
		},
		submitButtonLabel(){
			return this.isFormLoading ? 'Applying' : 'Apply'
		}
  },
  mounted() {
    // this.form.billing_address = { ...this.venue.billing_address }
    const address = { ...this.venue.billing_address }

    this.form.billing_email = this.venue.billing_email;
    this.form.billing_address = {
      company_name: address.company_name,
			address_line_1: address.address_line_1,
			address_line_2: address.address_line_2,
			town: address.town,
			county: address.county,
			post_code: address.post_code,
		};

    this.form.currency = Object.assign({}, this.currentCurrency)
  },
  methods: {
    onClose(){
			this.$emit('closed')
		},
		onSave(){
      
      if(this.form.billing_address.address_line_1 == "" || this.form.billing_address.town == "" || 
          this.form.billing_address.county == "" || this.form.billing_address.post_code == "")
      {
        return;
      } else {
        this.$emit('updated', this.form);
      }
		},
    pickCurrency(value) {
      this.form.currency = this.currencies.find(c => c.id === value)
    }
  },
};
</script>
