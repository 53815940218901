<template>
    <div>         


        <InlineNotification Heading="" class="ml-4 bg_dj">
            <template slot="body">
				<DictionaryContent :hookId="15" dynamicUrl="artist/awaiting-venue-response">
					<h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">Gigs you've let the venue know you're available for</h3>
					<p>Nearly there. These are the gigs you’ve said you’re available for, so just sit tight, and we’ll let you know if the venue’s booked you for it.</p>           
				</DictionaryContent>
            </template> 
        </InlineNotification> 

		<div v-show="this.filteredBidsCopy" class="xl:flex xl:space-x-32 pb-4 pt-12 ml-4">
			<div class="w-80">
				<TRichSelect 
				required 
				id="profile-selection" 
				name="profile-selection" class="trichselect"                    
				:options="artistProfiles"  
				value-attribute="name" 
				text-attribute="name" 
				placeholder="Filter by artist profile"
				v-model="selectedProfile"
				/>
			</div>

			<div class="items-center md:flex md:space-x-8 sm:mt-4">
				<span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider">Filter by date:</span>
				<div class="col-span-3 lg:col-span-1">
					<input ref="start" placecholder="Start date" type="date" v-model="dateFilterStart" class="datepick h-full rounded-lg border-gray-300"/>
				</div>
				<span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider">to</span>
				<div class="col-span-3 lg:col-span-1">
					<input ref="end" type="date" v-model="dateFilterEnd" class="datepick h-full rounded-lg border-gray-300"/>
				</div>
			</div>
		</div>

		<div v-show="this.filteredBidsCopy" class="grid grid-flow-col items-center gap-4 black-placeholder  ml-4 mb-9 w-64 h-[42px]">
            <label for="excludesBooked">Exclude booked gigs</label>
            <t-checkbox id="excludesBooked" v-model="excludeBooked"/>
        </div>

        <OfferByDate ref="offerByDate"
					noItemsText="You're not awaiting any response from venues at the moment." :unfilteredBids="filteredBids"
				>

        </OfferByDate>
		
    </div>
</template>


<script type="text/javascript">
import NProgress from 'nprogress'
import OfferByDate from '../../../components/gigs/OfferByDate.vue'
 import { TRichSelect } from 'vue-tailwind/dist/components';
import InlineNotification from '../../../components/notifications/inline.vue';
import { TCheckbox } from 'vue-tailwind/dist/components' 
import { apiComputed } from '@/state/helpers.js';
import DictionaryContent from '../../../components/dictionary/DictionaryContent.vue';


export default {
  name: 'ArtistBidIndex',
  components:
	{
		InlineNotification,
		OfferByDate,
		TRichSelect,
		TCheckbox,
		DictionaryContent
	},
	data(){
		return{ 
			filteredBids:null,
			filteredBidsCopy: null,
			artistProfiles: [],
			selectedProfile: null,
			dateFilterStart: null,
			dateFilterEnd: null,
			excludeBooked: true,
			bookedFilter: 'CONFIRMED',
			profileSelected: 'All artist profiles',
			date_filter_start: '2023-01-01T00:00:00.000000Z',
			date_filter_end: '3000-01-01T00:00:00.000000Z',
			isLoading:false,

		}
	}, 
	watch:{
		selectedProfile(profile){
			this.profileSelected = profile;
			this.artistFilters();
		},
		dateFilterStart(val) {
			this.date_filter_start = !val ? '2023-01-01T00:00:00.000000Z' : val + 'T00:00:00.000000Z';
			this.artistFilters();
		},
		dateFilterEnd(val) {
			this.date_filter_end = !val ? '3000-01-01T00:00:00.000000Z' : val + 'T00:00:00.000000Z';
			this.artistFilters();
		},
		excludeBooked(val) {
			if (val) {
				this.bookedFilter = 'CONFIRMED';
				this.artistFilters();
			} else {
				this.bookedFilter = null;
				this.artistFilters();
			}
		}
	},
	computed: {
		...apiComputed
	}, 
	mounted(){
		this.isLoading = true;

		NProgress.start();
		this.loadArtists();

		this.$store.dispatch('artistBids/getArtistRespondedOffers')
			.then((resp) => {
				this.filteredBids = resp;
				this.filteredBidsCopy = this.filteredBids;
				this.artistFilters();
			}).finally(() => {
			this.isLoading = false;
			NProgress.done();
		}); 

	},
	methods:{
		loadArtists(){
			this.$store
				.dispatch('api/getArtists')
				.then(() => {
					if (this.artists && this.artists.length > 0){            
						this.artists.forEach(artist => {
							let artistObj = {
								name: artist.name
							};
							this.artistProfiles.push(artistObj);
						});
						this.artistProfiles = [{name: "All artist profiles", id: "all"}, ...this.artistProfiles];
					}
				})
		},
		artistFilters(){
			if(this.profileSelected !== "All artist profiles") {
				this.filteredBids = this.filteredBidsCopy
				.filter((r) => r.artist.name == this.profileSelected && r.gig.start >= this.date_filter_start && r.gig.end <= this.date_filter_end && r.gig.status !== this.bookedFilter);

			} else {
				this.filteredBids = this.filteredBidsCopy
				.filter((r) => r.gig.start >= this.date_filter_start && r.gig.end <= this.date_filter_end && r.gig.status !== this.bookedFilter)
			}  	
		},

	}
};
</script>