import client from "../../utils/client"

export const state = {
  products: null,
}

export const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products
  }
}

export const getters = {
  all: state => {
    return state.products;
  },
}

export const actions = {
  getProducts({ getters, commit }) {
    return new Promise((resolve) => {
      client
        .get('products')
        .then((response) => {
          commit('SET_PRODUCTS', response.data.data);
          resolve(getters.all)
        })
        .catch(err => {
          console.log(err)
        });
    })
  },
} 