<template>
    <Callback  />
</template>

<script>
import Callback from '../../components/modal/AuthCallback.vue'
export default {
    setup() {
        
 
           
        },
        components:{
            Callback
        }
}
</script>