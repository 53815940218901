<template>
<div>
    
    <Header Heading="Your Gigs" hidden />

    <InlineNotification Heading="Adding a new gig">
        <template slot="body">
          <p>Click add a new gig.  Click the offer gig to artists button to start finding availability. </p>
        </template>
        <template slot="cta">
                <a @click="managePayments()">Manage gig tokens</a>
            <router-link to="/gigs/add" class="gp_cta">Add new Gig</router-link>
        </template>
    </InlineNotification>
    

    <SubscriptionManagement v-if="isShowSubscription" v-on:close="isShowSubscription = false;" />


      <div class="ml-4 flex flex-col mt-4 " v-if="gigs && gigs.length > 0">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200 text-left">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Artists & Venues
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Gig
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(gig, index) in convertedRows()" :key="index" :row="gig">
                      <td class="flex flex-col text-left mx-2 p-6">
                          <strong>{{gig.venue.name}}</strong>
                          {{  fullDate(gig.start) }}
                      </td> 
                      <td class="p-6">
                          <span>{{gig.name}}</span>
                      </td>
                      <td class="p-6">
                        <!-- https://tailwindui.com/components/application-ui/elements/dropdowns#component-1f10562f6266c9b3ef15ff792edd508e -->
                        <router-link :to="'gigs/' + gig.id">Edit</router-link >
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    <div v-if="debug && false">
      {{ convertedRows() }}
    </div>
</div>
</template>

<script type="text/javascript">


import { apiComputed, apiMethods } from '@/state/helpers.js'
import fullDate from  '@/utils/format-date-full.js' 
import Header from '../../components/listing/header.vue'
import InlineNotification from '../../components/notifications/inline.vue'
import SubscriptionManagement from '../../components/payment/SubscriptionManagement.vue'
export default {
  components:
  { 
    Header,
    InlineNotification,
    SubscriptionManagement
  },
  data() { 
      return {
        isShowSubscription: false
      }
  },
  computed: {
    ...apiComputed
  },
  methods: {
    ...apiMethods,
    fullDate,
    managePayments(){
      this.isShowSubscription = true;
    },
    convertedRows() {
      
      let rows = []
      if (this.gigs != null){
        this.gigs.forEach(gig => {
              rows.push(gig);
        });
      }

      return rows;
      //return rows
    }
  }
};

</script>