import client from "../../utils/client";
import { getStatusFromType } from "../models/gigStatus";

export const state = {
    gig: null,
    gigStatus: null,
};

export const mutations = {
    SET_GIG(state, gig) {
        state.gig = gig;
    },
    SET_GIG_STATUS(state, gigStatus) {
        state.gigStatus = gigStatus;
    },
    REMOVE_GIG_TIER(state, tierId) {
        state.gig.gig_tiers.splice(tierId, 1);
    },
    ADD_GIG_TIER(state, tier) {
        state.gig.gig_tiers.push(tier);
    },
};

export const getters = {
    currentGig: (state) => {
        return state.gig;
    },
    currentGigStatus: (state) => {
        return state.gigStatus;
    },
    isCurrentGigCancelled: (state) => {
        return !state.gigStatus ? false : state.gigStatus.type === "CANCELLED";
    },
    isCurrentGigPending: (state) => {
        return !state.gigStatus ? false : state.gigStatus.type === "PENDING";
    },
    isCurrentGigBooked: (state) => {
        return !state.gigStatus ? false : state.gigStatus.type === "CONFIRMED";
    },
    doesCurrentGigNeedBooking: (state) => {
        if (!state.gigStatus) {
            return false;
        }
        switch (state.gigStatus.type) {
            case "PENDING":
            case "NEEDS_RESPONSE":
            case "NO_ARTISTS":
            case "UNPUBLISHED":
            case "UNCAUGHT_STATUS":
                return true;
            default:
                return false;
        }
    },
};

export const actions = {
    removeGigTier({ commit }, gigId) {
        commit("REMOVE_GIG_TIER", gigId);
    },
    addGigTier({ commit }, tier) {
        commit("ADD_GIG_TIER", tier);
    },
    storeGig({ commit }, gig) {
        commit("SET_GIG", gig);
        commit("SET_GIG_STATUS", getStatusFromType(gig.status));
    },
    getCurrentGig({ getters, dispatch }, gigId) {
        return new Promise((resolve, reject) => {
            dispatch("getGigDetails", gigId)
                .then(() => {
                    resolve(getters.currentGig);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getGigDetails({ getters, dispatch }, gigId) {
        //commit,
        return new Promise((resolve, reject) => {
            client
                .get(
                    `/gig-details/${gigId}?include=gigTiers,bookings,venue,artistTags,customTags`
                )
                .then((response) => {
                    dispatch("storeGig", response.data.data);
                    resolve(getters.currentGig);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getGigSummary({ getters, dispatch }, gigId) {
        //commit,
        return new Promise((resolve) => {
            client
                .get(`/gig-summary/${gigId}?include=bookings,venue`)
                .then((response) => {
                    dispatch("storeGig", response.data.data);
                    resolve(getters.currentGig);
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    },

    async getGigsCalendarByRange($, range) {
        return new Promise((resolve) => {
            let rangeQuery = "";
            if (!isNaN(range.venue_id) && range.venue_id != null) {
                rangeQuery = `&filter[venue_id]=${range.venue_id}`;
            }

            let paramsObj = {
                statuses: range.statuses,
                custom_tags: range.tags,
            };

            const queryParams = new URLSearchParams(paramsObj);

            paramsObj.statuses.forEach((status) => {
                queryParams.append("statuses[]", status);
            });

			client
				.get(`/calendar/venues?filter[starts_between]=${range.from},${range.to}&include=venue,bookings,customTags,cancellationReason` + rangeQuery + `&${queryParams}`)
				.then(response => {
					resolve(response.data.data)
				})
				.catch(err => {
					console.log(err)
				});
		});
	},

    getGigsByRange($, range) {
        return new Promise((resolve) => {
            let rangeQuery = "";
            if (!isNaN(range.venue_id) && range.venue_id != null) {
                rangeQuery = `&filter[venue_id]=${range.venue_id}`;
            }

            client
                .get(
                    `/gigs?filter[starts_between]=${range.from},${range.to}&include=venue,bookings` +
                        rangeQuery
                )
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    },
};
