<template>  
    <div v-if="isOpen" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
               
            </div>
    
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    
            <transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                           <div>
        <div class="mx-auto flex flex-row justify-between items-center ">
          
          <h3 class=" font-bold leading-6text-gray-900" id="modal-title">
           Do you want to fast book {{addedArtist.artist.name}}?  
          </h3> 
        </div>
        <div class="text-left sm:mt-5">
          <div class="text-sm"> 
            <p>Fee: {{currencySymbol}}{{addedArtist.fee}}</p>
            <p class="my-6">
							Confirming will book {{addedArtist.artist.name}} onto this Gig, and the booking is complete
            </p>
            <div class="my-6 font-bold">
            <p class="my-6">
                {{addedArtist.artist.name}} will be sent a notification.
            </p>
        </div>
            <div class="flex justify-between">
                <a class="gp_cta bg-gp_navy-default-500" @click="closeModal">Cancel</a>
                
                <a class="gp_cta" @click="fastBook">Confirm</a>
            </div>
          </div>
        </div>
      </div>
                </div>
            </transition>
        </div>
    </div>
</template>
 
<script>
export default {
    name: "FastBook",
    props: {
      currencySymbol: {
        required: false,
        type: String,
        default: '£'
      }
    },
    data()
    {
        return {
            addedArtist: {},
            isOpen: false
    }
    },
    methods: {
        closeModal() {
            this.isOpen = false;
        },
        fastBook()
        {
            this.$emit('fastBook', this.addedArtist);
            this.closeModal();
        },
        show($addedArtist){
            console.log($addedArtist);
            this.addedArtist = $addedArtist;
            this.isOpen = true;
        }

    }
}
</script>

