<template>
	
	<ModalWrapper 
		ref="deleteRoleModal" 
		:is-open="isOpen"
	>
		<template #header>
			<div class="text-xl">{{ modalTitle }}</div>
		</template>

		<template #body>
			<p class="mt-2 text-sm">
				Are you sure you want to delete the invitation for {{ invite.invitee.email }} inviting them to join {{ roleLabel }}
			</p>
		</template>

		<template #footer>

			<ModalFooterWrapper>
				<template #left>
					<Button @click.native="onClose" :disabled="isLoading" color="gray">Close</Button>
				</template>
				<template #right>

					<Button @click.native="onDeleted" :disabled="isLoading" :loading="isLoading">{{ submitButtonLabel }}</Button>

				</template>
			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>
	
</template>


<script>
import ModalWrapper from '../../ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '../../ui/modal/ModalFooterWrapper.vue'
import Button from '../../ui/buttons/Button.vue'



import client from '../../../utils/client';

export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		Button,
	},
	props:{
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
		invite:{
			type:Object,
			required: true
		},
		venueId:{
			type:String,
			required: true,
		}
	},
	emits:["closed", "deleted"],
	data(){
		return {
			isLoading:false,
		}
	},
	computed:{
		submitButtonLabel() {
      return this.isLoading ? 'Deleting invite' : 'Delete';
    },
		modalTitle() {
      return `Delete invite for ${ this.invite.invitee.email }?`
    },
		roleLabel() {
      return this.invite.role ? this.invite.role.name : '-';
    },
	},
	methods:{
		onClose(){
			this.$emit("closed");
		},
		onDeleted(){
			this.isLoading = true;
			client
				.delete(`/venues/${this.venueId}/invites/${this.invite.id}`)
				.then(() => {
					this.$store
						.dispatch('venueInvites/deleteVenueInvite', {
							venueId:this.venueId, 
							invite:this.invite
						})
						.then((response) => {
							this.isLoading = false;
							this.$emit("deleted", response);
						}, err => {
							console.log(err)
						});
				})
				.catch((err) => {
					console.log(err)
					this.isLoading = false;
				})
		}
	},
}
</script>