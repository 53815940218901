<template>
	<PageDetails>
		<h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">Edit Venue {{ isLoading ? '-' : '-' }} {{ venueName }}</h1> 
      <div class="lg:max-w-5xl">

				<VenueTabs
            :venue-id="venueId"
            :venue-user-id="user.id"
            current-route="manage-teams"
        >
					<template v-slot:right-cta v-if="roles">
						<button class="gp_cta" @click="onNewTeamMember">{{ userHasVenueFullAccessPerms ? 'Add' : 'Invite'}} a new team member</button>
					</template>
				</VenueTabs>


				<template v-if="isLoading">
					<div class="p-8 flex justify-center items-start">
						<PageSpinner 
							tailwind-diameter="8"
							tailwind-border="4"
							tailwind-color="pink-500"
						/>
					</div>
				</template>
				<template v-else-if="invites && !hasData">
					<NoDataMsgWrapper>
						There are no invites for this venue.
						<p v-if="!roles.length" class="mt-1">You need to create roles before you add invites</p>
					</NoDataMsgWrapper>
					<!-- < -->
				</template>
				<template v-else>
					<TableWrapper>
						<template #header-rows>
							<tr>
								<th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6">Team member</th>
								<th scope="col" class="hidden px-3 py-3.5 lg:table-cell">Role</th>
								<th scope="col" class="hidden px-3 py-3.5 sm:table-cell">Status</th>

								<th scope="col" class="relative w-8 px-3 py-3.5 "><!-- pl-3 pr-4 sm:pr-6 -->
									<span class="sr-only">Edit</span>
								</th>
								<th scope="col" class="relative w-8 py-3.5 pl-3 pr-4 sm:pr-6">
									<span class="sr-only">Delete</span>
								</th>

							</tr>
						</template>
						<template #body-rows>

							<tr v-for="member in members" :key="member.id">

								<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
									{{ getMemberInfo(member) }}
									<dl class="font-normal">
										<dd class="mt-1 truncate text-gray-700">
                      <!-- {{ getVenueMemberCreatedOn(getVenueMemberInviteAcceptedOn(member), 'joined') }} -->
										</dd>
									</dl>
									<dl class="font-normal lg:hidden">
										<dt class="sr-only">Role</dt>
										<dd class="mt-1 truncate text-gray-700">
											<ul class="list-none">
												<li v-for="role in member.roles" :key="role.id" class="">
													{{ role.name }}
												</li>
											</ul>
										</dd>
										<dt class="sr-only sm:hidden">Status</dt>
										<dd class="mt-1 truncate text-gray-500 sm:hidden">
											<span 
												class="badge capitalize"
												:class="statusClasses('accepted')"
											>{{ statusLabel('accepted') }}</span>
										</dd>
									</dl>
								</td>

								<td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
									{{ getMembersRoles(member) }}

									<span class="relative inline-flex flex-col items-center group" v-if="member.roles.length > 1">
										<span 
											class="text-sm font-medium underline underline-offset-2 cursor-pointer"
										>+{{ member.roles.length - 1 }} more</span>
										<div class="absolute bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
											<span class="relative z-10 p-4 bg-black rounded-sm shadow-lg w-48">
												<ul class="list-none">
													<li v-for="role in member.roles" :key="role.id" class="text-white ">{{ role.name }}</li>
												</ul>
											</span>
											<div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
										</div>
									</span>

								</td>

								<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
									<span 
										class="badge capitalize"
										:class="statusClasses('accepted')"
									>{{ statusLabel('accepted') }}</span>
								</td>

								<td class="w-8 px-3 py-4 text-right text-sm font-medium sm:pr-6">
									<a 
										@click.prevent="onEditMember(member.id)" 
										class="text-indigo-600 hover:text-indigo-900"
									>
										<span class="sm:hidden"><PencilAltIcon /></span>
										<span class="hidden sm:block">
											Edit<span class="sr-only">, {{ getMemberInfo(member) }}</span>
										</span>
									</a>
								</td>

								<td class="w-8 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
									<a 
										@click.prevent="onDeleteMember(member.id)"
										class="text-gp_pink-default-500 hover:text-gp_pink-default-700"
									>
										<span class="sm:hidden"><TrashIcon /></span>
										<span class="hidden sm:block">
											Remove<span class="sr-only">, {{ getMemberInfo(member) }}</span>
										</span>
									</a>
								</td>


							</tr>

							<tr v-for="invite in openInvites" :key="invite.id">
								<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
									{{ invite.invitee.email }}
									<dl class="font-normal">
										<dd class="mt-1 truncate text-gray-700">
											{{ getVenueMemberCreatedOn(invite.created_at, invite.accepted_at) }}
										</dd>
									</dl>
									<dl class="font-normal lg:hidden">
										<dt class="sr-only">Role</dt>
										<dd class="mt-1 truncate text-gray-700">
											{{ roleLabel(invite.role) }}
										</dd>
										<dt class="sr-only sm:hidden">Status</dt>
										<dd class="mt-1 truncate text-gray-500 sm:hidden">
											<span
												class="badge capitalize"
												:class="statusClasses(invite.accepted_at)"
											>{{ statusLabel(invite.accepted_at) }}</span>
										</dd>
									</dl>
								</td>

								<td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ roleLabel(invite.role) }}</td>
								<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
									<span 
										class="badge capitalize"
										:class="statusClasses(invite.accepted_at, invite.rejected_at)"
									>{{ statusLabel(invite.accepted_at, invite.rejected_at) }}</span>
								</td>
								<td class="w-8 px-3 py-4 text-right text-sm font-medium sm:pr-6">
									<a
										@click.prevent="() => null"
										:class="!invite.accepted_at ? 'text-indigo-200 hover:text-indigo-200 cursor-not-allowed' : 'text-indigo-600 hover:text-indigo-900' "
										:disabled="!invite.accepted_at"
									>
										<span class="sm:hidden"><PencilAltIcon /></span>
										<span class="hidden sm:block">
											Edit<span class="sr-only">, {{ invite.invitee.email }}</span>
										</span>
									</a>
								</td>

								<td class="w-8 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
									<a 
										@click.prevent="onDeleteInvite(invite.id)"
										:class="invite.accepted_at ? 'text-pink-200 hover:text-pink-200 cursor-not-allowed' : 'text-gp_pink-default-500 hover:text-gp_pink-default-700' "
										:disabled="invite.accepted_at"
									>
										<span class="sm:hidden"><TrashIcon /></span>
										<span class="hidden sm:block">
											Delete<span class="sr-only">, {{ invite.invitee.email }}</span>
										</span>
									</a>
								</td>

							</tr>

						</template>
					</TableWrapper>
				</template>
				
			</div>

			<!-- Modals -->
			<template v-if="roles">

				<InviteMembersModal 
					identity="inviteMembersModal"
					:is-open="inviteMembersModalIsOpen" 
					:venueId="venueId"
					:roles="roles"
					:user-has-full-venue-perms="userHasVenueFullAccessPerms"
					@closed="onModalClosed"
					@invited="onInvitesAdded"
					@added="onMemberAdded"
				></InviteMembersModal>

				<template v-if="selectedMember">
					<EditMemberModal 
						identity="editMemberModal"
						:is-open="editMemberModalIsOpen" 
						:member="selectedMember"
						:venueId="venueId"
						:roles="roles"
						@closed="onModalClosed"
						@updated="onMemberUpdated"
					></EditMemberModal>
				</template>
				<template v-if="selectedMember">
					<DeleteMemberModal 
						identity="deleteMemberModal"
						:is-open="deleteMemberModalIsOpen" 
						:member="selectedMember"
						:venueId="venueId"
						:roles="roles"
						@closed="onModalClosed"
						@delete="onMemberDeleted"
					></DeleteMemberModal>
				</template>
				<template v-if="selectedInvite">
					<DeleteInviteModal 
						identity="deleteInviteModal"
						:is-open="deleteInviteModalIsOpen" 
						:invite="selectedInvite"
						:venueId="venueId"
						@closed="onModalClosed"
						@deleted="onInviteDeleted"
					></DeleteInviteModal>
				</template>

			</template>
			
		<NotificationSmall ref="notification" />
	</PageDetails>
	
</template>

<script>
import PageDetails from '@/components/ui/wrappers/PageDetails.vue'
import TableWrapper from '../../components/ui/table/TableWrapper.vue';
import PageSpinner from '../../components/ui/spinner/PageSpinner.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';


import VenueTabs from '../../components/venues/VenueTabs.vue';

import InviteMembersModal from '../../components/modal/venues/InviteMembers'
import EditMemberModal from '../../components/modal/venues/EditMember'
import DeleteMemberModal from '../../components/modal/venues/DeleteMember'
import DeleteInviteModal from '../../components/modal/venues/DeleteInvite'
import NotificationSmall from '../../components/notifications/small.vue';

import { TrashIcon } from "@vue-hero-icons/outline"
import { PencilAltIcon } from "@vue-hero-icons/outline"

import { DateTime } from "luxon";


export default {
	components: {
		PageDetails,
		TableWrapper,
		PageSpinner,
		NoDataMsgWrapper,
		VenueTabs,
		InviteMembersModal,
		EditMemberModal,
		DeleteMemberModal,
		DeleteInviteModal,
		NotificationSmall,
		PencilAltIcon,
		TrashIcon,
	},
	props:['venueId'],
	data(){
		return {
			venueName:null,
      venueUserId: null,
			isLoading:false,
			inviteMembersModalIsOpen:false,
			editMemberModalIsOpen:false,
			deleteInviteModalIsOpen:false,
			deleteMemberModalIsOpen: false,
			selectedInvite:null,
			selectedMember:null,
			roles:this.$store.getters['venueRoles/venueRoles'](this.venueId),
			invites:this.$store.getters['venueInvites/venueInvites'](this.venueId),
			members:this.$store.getters['venueMembers/venueMembers'](this.venueId),
		}
	},
	computed:{
    user() {
      return this.$store.getters['user/userData']
    },
		userHasVenueFullAccessPerms() {
			let userRoles = this.$store.getters['user/roles']
			return userRoles.filter(role => {
				return role.venue.id == this.venueId && role.permissions.filter(perm => {
					return perm.slug === 'full-access'
				}).length > 0 || this.user.id === this.venueUserId
			}).length > 0
		},
		hasData(){
			if(this.invites){
				return this.invites.length > 0;
			}
			return false;
		},
		openInvites(){
			return this.invites ? [ 
				...this.invites.filter(i => !i.accepted_at)
			] : [];
		},
		getVenueMemberCreatedOn(){
			// Fixed with Luxon
      return (createdAt, acceptedAt) => `${ acceptedAt ? 'Joined' : 'Invited'} ${DateTime.fromISO(createdAt).toFormat('cccc, LLLL d, yyyy HH:mm')}`;
		},
    getVenueMemberInviteAcceptedOn(){
      return (member) => this.invites.findLast(i => (i.invitee.id === member.id && i.accepted_at != null)).accepted_at;
    },
		statusLabel() {
      return (acceptedAt, rejected_at = null) => `${ acceptedAt ? 'Active' : rejected_at ? 'Rejected' : 'Awaiting'}`
    },
		statusClasses() {
      return (acceptedAt, rejected_at = null) => `${ acceptedAt ? 'gig-paid' : rejected_at ?  'gig-needs-response': 'gig-not-signed-off'}`
    },
		roleLabel() {
      return (role) => `${ role ? role.name : '-'}`
    },
		getMemberInfo(){
			return (member) => {
				if(member){
					const display = 
						member.name || member.name !== "" ? member.name
						: member.email || member.email !== "" ? member.email 
						: "";
					return display === "" ? `No details for member ${member.id}` : display;
				}else{
					return "Member not found!"
				}
			}
		},
		getMembersRoles(){
			return (member) => member.roles ? `${member.roles[0].name}${member.roles.length > 1 ? ', ' : ''}` : "-";
		},
	},
	methods:{
		// open modals
		onNewTeamMember(){
			this.inviteMembersModalIsOpen = true;
		},
		onEditMember(memberId){
			const member = this.members.find(member => member.id === memberId);
			if(member){
				this.selectedMember = member;
				this.editMemberModalIsOpen = true;
			}
		},
		onDeleteMember(memberId){
			const member = this.members.find(member => member.id === memberId);
			if(member){
				this.selectedMember = member;
				this.deleteMemberModalIsOpen = true;
			}
		},
		onDeleteInvite(inviteId){
			const invite = this.invites.find(invite => invite.id === inviteId);
			if(!invite.accepted_at){
				this.selectedInvite = invite;
				this.deleteInviteModalIsOpen = true;
			}
		},
		// close modals 
		onModalClosed(){
			this.inviteMembersModalIsOpen = false;
			this.editMemberModalIsOpen = false;
			this.deleteMemberModalIsOpen = false;
			this.deleteInviteModalIsOpen = false;
		},
		// CRUD methods
		onInvitesAdded(invites){
			this.onModalClosed();
			// 
			this.$store.dispatch('venueInvites/getVenueInvites', this.venueId).then(() => {
				this.invites = this.$store.getters['venueInvites/venueInvites'](this.venueId);
				this.$refs.notification.createNotification(`The invite${invites.length === 1 ? ' has' : 's have'} been added.`, false);
			}, err => {
				console.log(err)
			});
			
		},
		onMemberAdded(){
			this.onModalClosed();
			this.$store.dispatch('venueMembers/requestVenueMembers', this.venueId).then(() => {
				this.members = this.$store.getters['venueMembers/venueMembers'](this.venueId);
				this.$refs.notification.createNotification(`The member/s have been added.`, false);
			}, err => {
				console.log(err)
			})
			
		},
		onMemberUpdated(){
			this.onModalClosed();
			this.$store.dispatch('venueMembers/requestVenueMembers', this.venueId).then(() => {
				this.members = this.$store.getters['venueMembers/venueMembers'](this.venueId);
				this.$refs.notification.createNotification(`The member has been updated.`, false);
			}, err => {
				console.log(err)
			})
			
		},
		onMemberDeleted(){
			this.onModalClosed();
			this.$store.dispatch('venueMembers/requestVenueMembers', this.venueId).then(() => {
				this.members = this.$store.getters['venueMembers/venueMembers'](this.venueId);
				this.$refs.notification.createNotification(`The member has been removed.`, false);
			}, err => {
				console.log(err)
			})
			
		},
		onInviteDeleted(invite){
			this.onModalClosed();
			// 
			this.$store.dispatch('venueInvites/getVenueInvites', this.venueId).then(() => {
				this.invites = this.$store.getters['venueInvites/venueInvites'](this.venueId);
				this.$refs.notification.createNotification(`The invite for ${invite.invitee.email} has been deleted.`, false);
			}, err => {
				console.log(err)
			});
		}
		
	},
	mounted(){
		this.isLoading = true;
		Promise.all([
			// Venue
			this.$store.dispatch('venue/getCurrentVenue', this.venueId)
			.then((response) => {
				this.venueName = response.name
        this.venueUserId = response.user.id
			}),
			// Roles
			this.$store.dispatch('venueRoles/getVenueRoles', this.venueId).then((response) => {
				this.roles = response
			}, err => {
				console.log(err)
			}),
			// Invites
			this.$store.dispatch('venueInvites/getVenueInvites', this.venueId).then((response) => {
				this.invites = response
			}, err => {
				console.log(err)
			}),
			// Members
			this.$store.dispatch('venueMembers/getVenueMembers', this.venueId).then((response) => {
				this.members = response
			}, err => {
				console.log(err)
			})
		]).finally(() => {
			this.isLoading = false;
		});
	}
}
</script>