<template>
<div>

    <div class="max-w-4xl ml-4">
 
    
      <InlineNotification Heading="Invoices">
        <template slot="body">

          <DictionaryContent :hookId="9" :dynamicUrl="'artist/invoices'">
            <p>Find all your invoices here. View or download them anytime.</p>
          </DictionaryContent>
 
        </template>
    </InlineNotification>

     <Accordian heading="Filter" class="lg:mx-0 mx-4">
       <template #contents>
          <div class=" my-4 grid grid-flow-row grid-cols-2 pt-0 mt-2 gap-4 pr-0 form ">                            
              <div class="hidden">
                <label>
                  Status
                </label>
                <TSelect v-model="invoiceStatus"  class="trichselect" :options="[{ value: 'all', text: 'All'}, { value: 'paid', text: 'Paid'}, { value: 'unpaid', text: 'Unpaid'}]"></TSelect>
              </div>
              <div>
                <label>Gig date From</label>
                <TDatePicker v-model="raisedFrom"></TDatePicker>
              </div> 
              <div>                
                <label>Gig date To</label>
                <TDatePicker  v-model="raisedTo"></TDatePicker>
              </div>
              <InputText name="invoicenumber" v-model="invoiceNumber" ref="invoicenumber" class="mt-0" type="number"  prefix="GP-" label="Invoice number" placeholder="00001" />
      
              <div class="col-span-2 justify-start text-left">                
                <a class="inline-block cta bg-indigo-500 mr-4" @click="filter()">Filter</a>
              </div>
          </div>
       </template>
     </Accordian>
      <div class="flex justify-end">
      </div>
      
    <div class="lg:mx-0 mx-4 flex flex-col lg:flex-row">
        <a class="lg:mr-4 inline-block cta bg-indigo-500 mt-4" @click="downloadInvoices(true)">Download selected invoices ({{selectedInvoices.length}})</a>
        
        <a class="inline-block cta bg-gp_pink-default-500 mt-4" @click="downloadInvoices(false)">Download all invoices</a>
    </div>
      <div class=" flex flex-col mt-4 " v-if="results && results.length > 0">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="max-w-5xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200 text-left">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="relative px-6 py-3"> 
                      <input type="checkbox" @change="selectAll($event.target.checked)" />
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Venue
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Gig
                    </th> 
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr class="relative" v-for="(bid, index) in results" :key="index" :row="bid">
                      <td class="p-6">                        
                        <input ref="check" v-bind:id="bid.id"  type="checkbox" @change="toggleOffer($event.target.checked, bid)" />
                      </td>
                      <td class="p-6"> 

                        <router-link :to="'/artist/bookings/' + bid.id" class="block">View booking</router-link > 
                        <a @click="downloadInvoice(bid)" class="block text-gp_pink-default-500">Download</a> 
                        <span class="badge gig-confirmed" v-if="bid.signed_off_at">Signed off</span>
                        <span class="badge gig-pending"  v-else>Awaiting signoff from venue</span>
                        
                        
                      </td>
                      <td class=" text-left mx-2 p-6 justify-center h-full items-center">
                          <span class="block">#GP_{{ bid.invoice_number }}</span>
                          <span class="block">{{bid.venue.name}}</span>
                          <span>£{{bid.fee}} {{ bid.vat_number ? ' +VAT' : '' }}</span>
                      </td> 
                      <td class="p-6"> 
                          <span class="block">{{bid.artist.name}}</span>
                          <span class="block">{{bid.gig.name}}</span>
                          <span class="block">{{  fullDate(bid.gig.start) }}</span>
                      </td> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="mt-4">There are no invoices to display, please refine your filters.</p>
      </div>

    <div v-if="debug && false">
      {{ getResults() }}
    </div> 
    
    <InvoiceGeneration class="absolute top-[9999px] left-[9999px]" ref="invoice" :invoiceItems="invoiceItems" />
 
  </div>
</div>
</template>

<style type="text/css" scoped> 
      table 
      {
        table-layout:auto; 
        width:1000px;
      }
      
      table td {
        min-width:min-content;
      }
</style>

<script type="text/javascript">
import Accordian from '../../../components/accordian/Accordian.vue'
import { apiComputed, apiMethods } from '@/state/helpers.js'
import fullDate from  '@/utils/format-date-full.js'  
import timeAgo from  '@/utils/format-date-timeago.js'  
import InlineNotification from '../../../components/notifications/inline.vue'; 
import {  TSelect } from 'vue-tailwind/dist/components';
import InputText from '../../../components/form/InputText.vue';
import TDatePicker from 'vue-tailwind/dist/t-datepicker'; 
import InvoiceGeneration from '../../../components/invoice/wrapper.vue'; 
import DictionaryContent from '../../../components/dictionary/DictionaryContent.vue'; 
import NProgress from 'nprogress';

export default { 
    name: "ArtistInvoices",
    components:
    {
        InvoiceGeneration,
        InlineNotification, 
        InputText,
        TDatePicker,
        TSelect,
        Accordian,
        DictionaryContent
    },
  data() { 
    return {
       selectedVenue: null,
       invoiceNumber: '',
       invoiceStatus: 'all',
       raisedFrom: null,
       raisedTo: null,
       selectedInvoices: [],
       invoiceItems: [],
       results: [],
       invoiceBids: []
    }
  },
  computed: {
    ...apiComputed,
  },
  mounted(){    
    NProgress.start();
    this.$store.dispatch("api/getArtistInvoices").then((response) => {

      
      this.invoiceBids = response.data.data;

    }).finally(() => {

      this.getResults();
      NProgress.done();
    }); 
  },
  methods: {
    selectAll($checked){
        this.$refs.check.forEach((c) => {
          c.checked = $checked;

          let $bid = this.results.find(bid => bid.id == c.id);

          this.toggleOffer($checked, $bid);
        });
    },
    toggleOffer: function(isOn, $bid){ 

      if (isOn)
      {
        this.selectedInvoices.push($bid);
      }
      else 
      {
        let removeIndex = this.selectedInvoices.indexOf($bid);
        if (removeIndex !== -1){
          this.selectedInvoices.splice(removeIndex, 1);
        }
      }
    }, 
    downloadInvoice($bid){
      
      this.invoiceItems = [$bid]; 
      this.$refs.invoice.generate();
    },
    downloadInvoices($only_selected){
      
      if ($only_selected)
      {        
        this.invoiceItems = this.selectedInvoices; 
      }
      else{
        this.invoiceItems = this.getResults(); 
      }

      if (this.invoiceItems.length > 0)
        this.$refs.invoice.generate();
    },
    filter(){
      this.getResults(); 
    },
    ...apiMethods,
    fullDate, 
    timeAgo,
    getResults() {
         
      if (this.invoiceBids != null){
           
        let $results = this.invoiceBids;

        let start = new Date('2001-01-01');
        let end = new Date('2050-01-01');


        if (this.raisedFrom){

            start = new Date(this.raisedFrom);

            $results = $results
            .filter(g => 
              new Date(g.gig.start) > start 
            );

        }

        if (this.raisedTo){

            end = new Date(this.raisedTo);

            $results = $results
            .filter(g => 
              new Date(g.gig.start) < end 
            );

        }


        if (this.invoiceNumber){
          
          let invoiceNumber = this.invoiceNumber
            $results = $results
            .filter(g => 
              g.invoice.invoice_number == invoiceNumber 
            );
        }

        $results = $results.sort(function(a, b) 
        { 
          return new Date(b.gig.start) -  new Date(a.gig.start) 
        });

        this.results = $results;

        return $results; 

      }
 
      //return rows
    }
  }
};

</script>