<template>
    <div>
        <div class="mt-6 sm:mt-5 col-span-full">
            <p class="mt-1 max-w-2xl text-md font-bold text-gray-900">Add your minimum fees</p>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              These are the minimum fees you will accept to perform a gig.
            </p>
        </div>
        <hr class="h-px my-5 border-0 bg-gray-100">

        <div v-for="(date, index) in dailyFees" :key="date.day" class="flex space-x-12 items-center mt-4">
            <div class="w-44 md:w-24">
                <p class="mt-1 max-w-2xl text-sm font-bold text-gray-900">{{ date.day }}</p>
            </div>
            <div class="relative w-64">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <span class="text-gp_pink-default-400 bg-gray-100/50 sm:text-md font-bold bg-none rounded-md py-1 px-2">£</span>
                </div>
                <input
                    :value="dailyFees[index].fee"
                    @input="updateDailyRate(index, $event.target.value)"
                    type="number" 
                    class="bg-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 p-2.5  dark:bg-none dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Add fee"
                >
            </div>
        </div> 

        <hr class="mt-5 h-px border-0 bg-gray-100">

        <div class="mt-6 sm:mt-5 col-span-full flex space-x-3">
            <CalendarIcon class="text-gp_pink-default-400"/>
            <p class="mt-1 max-w-2xl text-sm font-bold text-gray-900">Add special rate</p>
        </div>

        <div class="mt-2 grid grid-cols-1 md:grid-cols-2 gap-x-9">
            <div v-show="holidays.length > 0" class="w-full">
                <TRichSelect 
                            id="typeSelection" 
                            ref="typeSelection" 
                            name="type-selection" 
                            class="trichselect w-full text-sm" 
                            :options="holidays" 
                            v-model="specialSelected"
                            placeholder="Choose special event"
                            text-attribute="name"                             
                            ></TRichSelect>
            </div>
            <div v-show="specialSelected" class="w-full mt-3">
                <div class="relative full">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span class="text-gp_pink-default-400 bg-gray-100/50 sm:text-md font-bold bg-none rounded-md py-1 px-2">£</span>
                    </div>
                    <input
                        v-model="specialFee"
                        type="number"
                        class="bg-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 p-2.5  dark:bg-none dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder="Add fee"
                    >
                    </div>
            </div>
        </div>

        <div v-show="holidays.length > 0">
            <hr class="h-px border-0 bg-gray-100">
            <div @click="submitSpecialRate()" :class="buttonStyling">
                <div class="flex justify-center items-center space-x-2">
                    <PlusCircleIcon />
                    <p class="text-sm">Add Special Rate</p>
                </div>
            </div> 
        </div>

        <div v-for="(date, index) in specialFees" :key="date.day">
            <div v-show="date.fee !== null" class="flex space-x-12 items-center mt-4">
                <div class="w-44 md:w-28">
                    <p class="mt-1 max-w-2xl text-sm font-bold text-gray-900">{{ date.name }}</p>
                </div>
                <div class="relative w-64">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span class="text-gp_pink-default-400 bg-gray-100/50 sm:text-md font-bold bg-none rounded-md py-1 px-2">£</span>
                    </div>
                    <input
                        :value="specialFees[index].fee"
                        @input="updateSpecialRate(index, $event.target.value)"
                        type="number" 
                        class="bg-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 p-2.5  dark:bg-none dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder="Add fee"
                    >
                </div>
            </div>
        </div> 
        <NotificationSmall ref="notification" />
    </div>
</template>

<script>
import { CalendarIcon, PlusCircleIcon } from '@vue-hero-icons/outline'
import { TRichSelect } from 'vue-tailwind/dist/components'
import NotificationSmall from '../../components/notifications/small.vue';

export default {
    name:"InputMinFees",
    components:{
        CalendarIcon, 
        PlusCircleIcon,
        TRichSelect,
        NotificationSmall
    },
    mounted(){
        this.$emit('updatedDailyFees', this.dailyFees);
    },
    computed:{
        buttonStyling()
        {
            return !this.specialFee ? 'mt-2 w-full p-3 bg-none text-gp_pink-default-500 outline-line text-center rounded-lg hover:cursor-not-allowed' : 'mt-2 w-full p-3 bg-none hover:bg-gp_pink-default-500 text-gp_pink-default-500 hover:text-white outline-line text-center rounded-lg hover:cursor-pointer'
        }
    },
    methods: {
        submitSpecialRate()
        {
            if (this.specialFee && this.specialSelected) {
                let index = this.specialFees.findIndex((f) => { return f.id === this.specialSelected });
                this.specialFees[index].fee = this.specialFee;
                let spliceIndex = this.holidays.findIndex((f) => { return f.id === this.specialSelected });
                this.holidays.splice(spliceIndex, 1);
                this.specialSelected = null;
                this.specialFee = null;
                this.$emit('updatedSpecialFees', this.specialFees.filter((f) => { return f.fee !== null }));
            } else {
                this.$refs.notification.createNotification('Please choose an event and input a fee', true);
            }
        },
        updateDailyRate(index, val)
        {
            this.dailyFees[index].fee = val;
            this.$emit('updatedDailyFees', this.dailyFees);
        },
        updateSpecialRate(index, val)
        {
            if (val === '') {
                val = '0.00';
            }

            this.specialFees[index].fee = val;
            this.$emit('updatedSpecialFees', this.specialFees.filter((f) => { return f.fee !== null }));
        },
        updateSpecials($specials)
        {
            let artistSpecialFees = $specials;

            artistSpecialFees.forEach((e) => {
                let target = this.specialFees.findIndex((f) => { return f.id === e.id });
                this.specialFees[target].fee = e.fee;
                let spliceIndex = this.holidays.findIndex((f) => { return f.id === e.id });
                this.holidays.splice(spliceIndex, 1);
            })

        }
    },
    data(){
        return {
            dailyFees: [
                {
                    'day': 'Monday',
                    'fee': null 
                },
                {
                    'day': 'Tuesday',
                    'fee': null 
                },
                {
                    'day': 'Wednesday',
                    'fee': null 
                },
                {
                    'day': 'Thursday',
                    'fee': null 
                },
                {
                    'day': 'Friday',
                    'fee': null 
                },
                {
                    'day': 'Saturday',
                    'fee': null
                },
                {
                    'day': 'Sunday',
                    'fee': null
                },
            ],
            specialFees: [],
            holidays: [],
            specialSelected: '',
            specialFee: null
        }
    },
}

</script>