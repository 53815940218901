<template>
<div>
         
    <div class=" text-left flex flex-col lg:flex-row lg:min-h-100vh">

        
        <div class="min-w-100% lg:min-w-30% lg:max-w-30%">
                <div class="bg-white ">
        <div class="lg:mx-auto">
          <div class="pb-6 md:flex md:items-start  ">
            <div class="flex-1 min-w-0">
              <!-- Profile --> 
                  <div class="px-3 flex flex-col   space-y-4 w-full">
                    <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                      Your Messages
                    </h1>

                    <p>
                    Questions about your gig? Drop the venue a message directly. You can keep track of your message history too.
                </p>
                <p>Pick a venue to chat</p>

                            <TRichSelect class="w-full" v-model="venueID" @change="refreshBidList" required id="venue-selection" ref="venuepicker" name="venue-selection"                      
                                :options="this.venues"
                                value-attribute="id" 
                                text-attribute="name" />

                  </div>  
            </div> 
          </div>
        </div>
      </div>
            <ul   role="list" class="divide-y divide-gray-200" v-if="!gig || !gig.bids">
                 <li  class=" relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                       <div class="flex justify-between space-x-3">
                       <div class="min-w-0 flex-1">

                <p class="line-clamp-2 text-center text-sm text-gray-600 min-w-300px">
                </p>
                       </div>
                       </div>
                 </li>
            </ul>
            <ul   role="list" class="divide-y divide-gray-200" v-if="artistbids">  
            <li @click="selectBid(bid)" v-for="(bid, index) in venuebids" v-bind:key="index" class="  relative bg-white hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div  v-bind:class="selectedBid && selectedBid.id == bid.id ? '  border-indigo-500 bg-gray-100 ' : 'border-white'" class="border-l-4 py-5 px-4" >
                <div class="flex justify-between space-x-3">
                    <div class="min-w-0 flex-1">
                        <a href="#" class="block focus:outline-none">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <p class="text-sm   text-gray-900 truncate">{{bid.artist.name}}</p>
                            <p class="text-sm text-gray-500 truncate">{{bid.gig.venue.name}}, {{bid.gig.name}}</p>
                            <p class="text-sm text-gray-500 truncate">{{format(bid.gig.start)   }}</p>
                        </a>
                    </div>
               
                    <div class="flex flex-row gap-2 align-top items-start" v-if="bid && bid.messages && bid.messages.length > 0">
                        <span class="text-sm bg-gp_pink-default-100  rounded text-gray-500 p-1" v-if="isRecentMessageRead(bid.messages)">Unread</span>
                        <time :datetime="bid.created_at" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500" v-if="getRecentMessage(bid.messages).message">
                              {{ formatTimeago(getRecentMessage(bid.messages).message.created_at)}} ago
                        </time>
                    </div>
                </div>
                <div class="mt-1">
                <p class="line-clamp-2 text-sm text-gray-600" v-if="bid && bid.messages">
                   {{getRecentMessage(bid.messages).snippet}}
                </p>
                </div>
                </div>
            </li>
           


            <!-- More messages... -->
            </ul>
        </div>
        <div   v-if="selectedBid"  v-click-outside="closeMenu"  class="w-full 3xl:max-w-50% relative">

     <transition :duration="1000" appear enter-active-class="animate__animated animate__fadeInRight" leave-active-class="animate__animated animate__fadeOutRight">
            

<div v-if="isMessagePanelOpen" class="absolute top-0 right-0 w-8/12 p-4 h-full bg-gray-200 shadow-2xl flex flex-col gap-4">
    <p>Replying to {{selectedBid.artist.name}}</p>
    <div class="text-gray-500 text-sm">
    <p>{{format(selectedBid.gig.start)}}</p>
    <p>{{selectedBid.gig.name}}</p>
    </div>
<p>
    {{getRecentMessage(selectedBid.messages).snippet}}
</p>
    <textarea v-model="messageBody" rows="10" class="w-full shadow-inner" ref="reply-message" placeholder="Type your message here..." />

    <button @click="(reply(selectedBid))" class="bg-gp_pink-default-500 text-white p-4 rounded hover:bg-indigo-500 flex flex-row justify-center items-center gap-4">
        <img src="@/assets/images/icons/icon-mail.svg" alt="mail icon"/> Send Message
    </button>
</div>

     </transition>
<div  class="p-5 w-full border-b bg-gray-100 border-gray-200 sm:flex sm:items-center sm:justify-between" >
  <div class="text-gray-400 text-sm">
    <h3 class="text-lg leading-6 font-medium text-gray-900" v-if="selectedBid.gig.venue">
        {{selectedBid.gig.venue.name}}
    </h3>
    <p>{{format(selectedBid.gig.start)}}</p>
    <p>{{selectedBid.gig.name}}</p>
  </div>
  <div class="mt-3 flex sm:mt-0 sm:ml-4">
    <router-link :to="selectedBid.accepted ? '/artist/bookings/' + selectedBid.id : '/artist/offers/' + selectedBid.id" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      View Booking Details
    </router-link >
    <button type="button" 
                    v-on:click="toggleMessagePanel" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gp_pink-default-500  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      Reply
    </button>
  </div>
</div>

<div class="p-5 w-full border-b  border-gray-200 sm:flex sm:items-center sm:justify-start gap-12 shadow">
  <div>
      <img class="rounded-full  w-10 h-10" :src="selectedBid.gig.venue.icon != null ? appConfig.apiUrl + selectedBid.gig.venue.icon : require('@/assets/images/placeholder.svg')" alt="profile picture" />
  </div>
  <div class="flex flex-row justify-between w-full ">
        <div class="text-gray-400 text-sm ">
            <h4 class="text-base leading-6 font-medium text-gray-900">
           {{selectedBid.gig.venue.name}}
            </h4>
            <p v-if="selectedBid.gig.venue.user"> {{selectedBid.gig.venue.user.name}}</p>
        </div>
        <div>
            <p>£{{selectedBid.fee}}</p> 
        </div>
  </div>
</div>
<div @click="closeMenu" class="bg-gray-200 p-12 max-h-80vh  min-h-80vh overflow-y-scroll flex flex-col ">

    <div v-if="selectedBid.messages.length == 0">
        No messages from this venue yet
    </div>

    <div v-for="(message, index) in selectedBid.messages.slice().reverse()" v-bind:key="index">
    <div class="bg-white p-4 mb-4 flex flex-col gap-4 ml-12" v-if="!messageFromMe(message)">
        <div class="w-full text-right">
            <h2 v-if="message.user"><strong>{{message.user.name}} said</strong></h2>
            <h3 class="text-gray-400">{{format(message.created_at)}}</h3>
        </div>
        <p>
            {{message.message}}
        </p>
    </div>
    <div v-else class="bg-white p-4 mb-4 flex flex-col gap-4">
        <h2><strong>You said</strong></h2>
        <h3 class="text-gray-400">{{format(message.created_at)}}</h3>
        <p> 
            {{message.message}}
        </p>
    </div>
    </div>
    

</div>
        </div>
        <div v-else class="p-5 w-full border-b bg-gray-100 border-gray-200 sm:flex sm:items-center sm:justify-between" >
            <div class="text-gray-400 text-sm  h-full"> 

            </div> 
</div>
    </div>
</div>
</template>

<script>
import { apiMethods, apiComputed } from '@/state/helpers';  
import { TRichSelect } from 'vue-tailwind/dist/components';
import format from  '@/utils/format-date-default.js';
import formatTimeago from  '@/utils/format-date-timeago.js';
import appConfig from '@/app.config';


export default {
    name: "MessageList",
       data: () =>
         ({
        isMessagePanelOpen: false ,
        messageBody: '', 
        venueID: null,
        gigID: null,
        gig: null,
        selectedBid: null, 
        messages: [],
        loadGig: null,
        artistbidsByVenue: [],
        appConfig: appConfig,
        venues: null
    }),
    components:  {
        TRichSelect
    }, 
    mounted: function(){
        let $self = this; 
        this.artistbids = this.$store.state.api.artistbids;

        let $allVenues = this.artistbids.map(m => m.gig.venue);
        let $distinctVenues = [...new Map($allVenues.map(item =>[item['id'], item])).values()];

        this.venues = $distinctVenues;
 

        this.uuid = this.$store.state.api.uuid;

        let channel = null;
        if (this.$echo)
        {
            channel = this.$echo.channel(this.uuid);
        }

            this.$refs.venuepicker.selectedOption = {
                text: 'Pick a venue to begin',
                value: ''
            }; 

        if (this.$route.params.venueID)
        {
            this.gigID = this.$route.params.gigID;
            this.$refs.venuepicker.selectedOption = this.gigID;
            this.getGig(this.gigID).then((resp) => {
                this.loadGig = resp; 
                this.gig = resp;
                $self.$refs.gigpicker.selectedOption = {
                    text: resp.name,
                    value: resp.id.toString()
                };
                $self.$refs.gigpicker.selectedOption.text = resp.name;
                this.refreshBidList();
             });
        }
        else if (this.$route.params.bidID)
        {
            this.remountBid(this.$route.params.bidID);
        }
        else{
            
            this.remountBid();
        }


        if (channel)
        {
            //prevent stacking multiple subscriptions to same channel
            channel.unsubscribe();
            channel.subscribe();
            
        //TODO: Move this to global scope
        channel.listen('NotifyUser', function(data) {



            $self.getGig($self.gig.id).then((resp) => {
                console.log("refresh", resp);                
                $self.gig = resp;
                $self.selectBid(resp.bids.find((bid) => bid.id == $self.selectedBid.id));
                //$self.selectBid($self.selectedBid);
            });


            $self.$notify({ 
                title: data.notification.title,
                text: data.notification.body
            });



              let notification = {
        title: data.notification.title,
        options: {
            body: data.notification.body
        },
        events: {
            onerror: function () {
                console.log('Custom error event was called');
            },
            onclick: function () {
                console.log('Custom click event was called');
            },
            onclose: function () {
                console.log('Custom close event was called');
            },
            onshow: function () {
                console.log('Custom show event was called');
            }
        }
        } 

            $self.$notification.requestPermission() .then(console.log);
            $self.$notification.show(notification.title, notification.options, notification.events);

        });
 
        }

    
    },
    computed:{
        ...apiComputed,
        venuebids() {
            return this.artistbidsByVenue;
        }
    },
    methods: { 
        format,
        formatTimeago,
        ...apiMethods,
        remountBid($bidid){

            let $bid = null;
            this.getGigBids().then((resp) => {
                this.bids = resp;
                console.log("bid",resp);

                $bid = this.bids.find((bid) => bid.id == $bidid); 
                this.selectBid($bid);
            }).then(() => {
                
                this.gig = this.selectedBid.gig;
                this.selectedBid.gig = this.gig;
                this.gigID = this.selectedBid.gig_id; 
            });
        },
        refreshBidList(){
            let $self = this;
            
            let bidsForThisVenue = this.artistbids.filter(
                    (item) =>item.gig.venue.id == $self.venueID
                 );

                 console.log("bids for venue", bidsForThisVenue);

            const $fallbackDate = new Date(2001, 1, 1);

            this.artistbidsByVenue = bidsForThisVenue.sort(function(a, b) {

                     let aCreatedAt = a.messages.length > 0 ? new Date(a.messages[0].created_at) : $fallbackDate;
                     let bCreatedAt = b.messages.length > 0 ? new Date(b.messages[0].created_at) : $fallbackDate;

                        let comp = aCreatedAt < bCreatedAt;

                     console.log( {comp, aCreatedAt, bCreatedAt});
                     return aCreatedAt > bCreatedAt;
                 }); 

                 console.log("sorted venues", this.artistbidsByVenue)

        },
        messageFromMe($message)
        {
            console.log("thisuuid",);
            return $message.user.uuid == this.uuid;
        },
        selectBid($bid){
            let $self = this;
            this.selectedBid = $bid;
            this.messages = $bid.messages;
            let $recentMessage =this.getRecentMessage($bid.messages); 
            console.log($recentMessage);
            if ($recentMessage.message)
            { 
                this.markAsRead({id: $recentMessage.message.id}).then(() => { 
    
                    $self.getGigs().then(() => { 
                        this.refreshBidList();
                    });               
                });
            }
        },
        isRecentMessageRead($messages){
            
            let $message = this.getRecentMessage($messages).message;
            
            if ($message)
            {
                return !$message.is_read;
            }
            else{
                return true;
            }
        },
        getRecentMessage($messages){

            if ($messages.length > 0)
            {
                let message = $messages.at(-1);
            
                if (message)
                {
                    return  {
                        message: message,
                        snippet: message.message.substring(0, 30)
                    };
                } 
            }

             return {
                message: null,
                snippet: "No messages.",
             };
        },
        toggleMessagePanel() { 
            this.isMessagePanelOpen = !this.isMessagePanelOpen;
        },
        reply($bid){
            
            let $payload = {
                message: this.messageBody,
                bid: $bid /* bid */
            }

            let self = this; 
            this.$notify("Posting...");
            //TODO: could be more performant here by switching out updated bid, or message thread
            this.sendMessage($payload).then(() => {
                    self.getArtistBids().then(() => {    
                    self.remountBid($bid.id);
                     

                }).finally(() => {
                    
                    this.$notify("Posted");
                    this.toggleMessagePanel();
                });
            });

        },
        closeMenu() {
            if (this.isMessagePanelOpen)
                this.isMessagePanelOpen = false;
        }
    }
}
</script>