// import Calendar from '../views/Calendar/Index.vue';
import VenueCalendar from '../views/Calendar/VenueCalendar.vue';
//import ArtistCalendar from '../views/ArtistProfile/Calendar/Index.vue';
import ArtistCalendar from '../views/ArtistProfile/Calendar/ArtistCalendar.vue';
export default (store) => [
	{
    path: "/calendar",
    name: "Calendar",
    meta: { authRequired: true },
    beforeEnter: (to, from, next) => {
			// make sure userData is present


			
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasViewCalendarPermission']){
						// do route stuff
						store.dispatch('user/getUsersData').then(() => {
							if (store.getters['user/hasAllUserDataLoaded']) {
								next(); 
							}
						});
					}else{
						next(false);
					}
				})
    },
    component: VenueCalendar
  },
  {
    path: "/calendar/:venueid",
    name: "CalendarByVenue",
    meta: { authRequired: true },
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasViewCalendarPermission']){
						// do route stuff
						next()
					}else{
						next(false);
					}
				})
    },
    component: VenueCalendar
  },
  {
    path: "/artist/calendar",	
    name: "ArtistCalendar",
    meta: { authRequired: true },
    beforeEnter: (to, from, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasViewCalendarPermission']){
						// do route stuff
						next();
					}else{
						next(false);
					}
				})
      
    },
    component: ArtistCalendar
  },
]
