<template>  
    <div v-if="isOpen" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
		<div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
			<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
			<transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">     
                <div class="inline-block align-bottom bg-white rounded-sm p-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-1">                      
                    <!-- Header -->
                    <div class="mt-4 px-6">

                        <div class="text-xl">Are you sure you want to remove the following artist profile?</div>  
                        <p class="mt-2 text-base text-red-500">
                            Confirming will remove the following artist profile from GigPig indefinitely.
                        </p>   
                    </div>
                    <!-- Body -->
                    <div class="overflow-auto max-h-72 my-4 px-6">
                        <div class="grid grid-flow-col gap-4 justify-start items-start my-6">
                            <img v-if="selectedArtist.icon" :src="selectedArtist.icon" class="w-28 h-28 object-cover object-center" /> 
                            <div class="text-left">
                                <div class="text-sm"> 
                                    <div class="font-bold">
                                        <p v-if="selectedArtist.name" class="my-1">
                                            Name:   {{ selectedArtist.name }}
                                        </p>
                                        <p v-if="selectedArtist.tier" class="my-1">
                                            Type:   {{ selectedArtist.tier }}
                                        </p>
                                    </div>
                                    <p class="my-6 text-red-500">
                                        Note: The artist profile cannot be removed if outstanding gigs remain. Please ensure you have no further gigs booked in order to proceed with this request.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Footer -->
                    <footer class="flex flex-col sm:flex-row w-full justify-between bg-gray-50">
                        <div class="px-2 pt-3 pb-0 sm:pb-3 sm:px-3 flex flex-col sm:flex sm:flex-row-reverse space-x-4">
                            <Button @click.native="closeModal" :disabled="btnDisabled" color="gray">Close</Button>
                        </div>
                        <div class="px-2 py-3 sm:px-3 flex space-x-0 sm:space-x-4 space-y-2 sm:space-y-0 flex-col sm:flex-row">
                            <Button
                                    @click.native="deleteProfile()"
                                    :disabled="btnDisabled"
                                    :loading="btnDisabled"
                                    color="pink"
                                    >Confirm</Button>
                        </div>
                    </footer>
				</div>
            </transition>
		</div>
	</div>
</template>
 
<script>
import Button from '../../components/ui/buttons/Button.vue'
import { apiComputed, apiMethods } from '@/state/helpers.js'

export default {
    name: "ArtistRemove", 
    components: {
        Button,
    },
    data()
    {
        return {
            selectedArtist: {},
            isOpen: false,
            btn: false,
            isArtistDirectory: false,
        }
    },
    computed:{
        ...apiComputed,
        btnDisabled(){
            return this.btn;
        },
    },
    methods: {
        ...apiMethods,
        closeModal() {
            this.isOpen = false;
        },
        deleteProfile()
        {
            this.btn = true;

            this.deleteArtistProfile(this.selectedArtist.id)
            .then(() => {
                this.$notify("Artist profile successfully deleted");
                this.btn = false;
                if(this.isArtistDirectory){
                    this.$emit('refresh');
                } else {
                    this.$router.push('/artist/profiles');
                    this.isArtistDirectory = false;
                }
                this.$emit('refresh');
                this.isOpen = false;
            })
            .catch(() => {
                this.btn = false;
                this.$notify("Sorry, you can't delete this profile because you have gigs booked. Please contact the GigPig team.");
            });
        },
        show($selectedArtist, $isArtistDirectory){
            this.isArtistDirectory = $isArtistDirectory;
            this.selectedArtist = $selectedArtist;
            this.isOpen = true;
        }

    }
}
</script>
