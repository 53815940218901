<template>
  <div  class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
    <div class="flex flex-1 justify-center sm:hidden">
      <button @click="prevPage()" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</button>
      <button @click="nextPage()" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</button>
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          <span class="font-medium">{{ paginate_from }}</span>
          to
          <span class="font-medium">{{ paginate_to }}</span>
          of
          <span class="font-medium">{{ paginate_total}}</span>
          results
        </p>
      </div>
      <div>
        <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button @click="$emit('changePage', 1)" type="button" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Previous</span>
            <ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>

          <button @click="$emit('changePage', paginate_currPage - 1)" type="button" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <span v-show="paginate_currPage > 1" class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
          <button type="button" aria-current="page" class="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{{ paginate_currPage }}</button>
          <button v-show="paginate_currPage < paginate_lastPage - 3" @click="$emit('changePage', paginate_currPage + 1)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ paginate_currPage + 1 }}</button>
          <button v-show="paginate_currPage < paginate_lastPage - 4" @click="$emit('changePage', paginate_currPage + 2)" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">{{ paginate_currPage + 2 }}</button>
          <span v-show="paginate_currPage <= paginate_lastPage - 6" class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
          <button v-show="paginate_currPage < paginate_lastPage - 2" @click="$emit('changePage', paginate_lastPage - 2)" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">{{ paginate_lastPage - 2}}</button>
          <button v-show="paginate_currPage < paginate_lastPage - 1" @click="$emit('changePage', paginate_lastPage - 1)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ paginate_lastPage - 1 }}</button>
          <button v-show="paginate_currPage !== paginate_lastPage" @click="$emit('changePage', paginate_lastPage)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ paginate_lastPage }}</button>
          <button @click="nextPage()" type="button" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>

          <button @click="$emit('changePage', paginate_lastPage)" type="button" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Next</span>
            <ChevronDoubleRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from "@vue-hero-icons/outline";

export default {
  name: 'Pagination',
  components: {ChevronDoubleRightIcon, ChevronRightIcon, ChevronLeftIcon, ChevronDoubleLeftIcon},
  props: {
    paginate_from: null,
    paginate_to: null,
    paginate_total: { type: Number},
    paginate_currPage: { type: Number, default: 1},
    paginate_lastPage: null,
  },
  emits: ['changePage'],
  methods: {
    prevPage(){
      if (this.paginate_currPage !== 1) {
        this.$emit('changePage', this.paginate_currPage - 1)
      }
    },
    nextPage(){
      if (this.paginate_currPage !== this.paginate_lastPage){
        this.$emit('changePage', this.paginate_currPage + 1)
      }
    },
  }

}
</script>