<template>
    <div :class="showNotificationClass" class="invisible inline_notification justify-between flex-wrap md:flex transform transition-all bg-gray-50 sm:rounded-lg max-w-4xl mx-0 mt-4  gap-4 grid-flow-row lg:grid-flow-col items-center px-4 py-5 sm:p-6">
        
        <div>  
            <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{Heading}}
            </h3>
            <div class="my-2 max-w-xl text-sm text-gray-500 grid grid-flow-row gap-4">
                <slot name="body" />
            </div> 
        </div> 
         <slot   name="cta"/>

        <div class="text-white h-full flex self-start items-start justify-end text-sm dismiss" v-if="dismissEnabled">
            <button @click="setDismissed" class="flex gap-4 items-center group">
                Dismiss
                <XCircleIcon class="group-hover:rotate-90 transform transition-all"/>            
            </button>
        </div>
    </div>
</template>

<script>
import { XCircleIcon } from '@vue-hero-icons/outline'
const DISMISS_KEY = "DISMISS";
export default {
    name: "inline-notification",
    components: {
        XCircleIcon
    },
    computed: {
        showNotificationClass()
        {
            return this.isDismissed ? "dismissed" : "!visible";
        },
        dismissKeyValue(){
            return this.DismissId ? DISMISS_KEY + '_' + this.DismissId : null;
        },
        isDismissedStorage(){
            return window.localStorage.getItem(this.dismissKeyValue) != null;
        },
    }, 
    mounted(){
        this.dismissEnabled = this.DismissId != undefined;
        this.isDismissed = this.isDismissedStorage;

    },
    data(){
        return {
            isDismissed: false,
            dismissEnabled: false
        }
    },
    methods: {
        setDismissed(){
            window.localStorage.setItem(this.dismissKeyValue, true); 
            this.isDismissed = true;
        }
    },
    props:{
        HtmlBody: String,
        Heading: String,
        DismissId: String
    }
}
</script>