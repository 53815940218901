<template>  
    <div transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen lg:pt-4 lg:px-4 lg:pb-20 text-center sm:block sm:p-0">
    
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
               
            </div>
    
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    
            <transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                           <div>
        <div class="mx-auto flex flex-row justify-between items-center ">
          
          <h3 class=" font-bold leading-6text-gray-900" id="modal-title">
            Note
          </h3> 
        </div>
        <div class="text-left sm:mt-5">
          <div class="text-sm">
             <form  class="form mb-36 lg:mb-24">
                <div class="sm:col-span-6 grid grid-flow-row grid-cols-2"  v-show="false">
                    <h3 class="text-xl">
                        Visible to all
                    </h3>
                    <div class="justify-self-end">
                        <TToggle v-model="gigNote.visible_to_all" ref="visibletoall"  /> 
                    </div>
                </div>
                <div class="sm:col-span-6" v-show="false">
                    <label>Assigned to</label>
                    <TSelect required ref="assignedto" v-model="gigNote.assignee_id" :options="[ 
                                    {
                                        value:0 , text: 'Me'
                                    }
                                ]">

                    </TSelect>
                </div>
                <div class="sm:col-span-6">
                    <InputTextArea v-on:input="gigNote.body = $event" v-bind:value="gigNote.body"  label="" placeholder="Note contents" name="copy-template" required   rows="6" />
                </div>
                <div class="sm:col-span-6 grid grid-flow-row grid-cols-2" v-show="false">
                    <label for="sendReminder">
                        <h3 class="text-xl">Send reminder</h3>
                    </label>
                    <div class="justify-self-end">
                    <TToggle name="sendReminder" ref="sendReminder" v-model="gigNote.send_reminder" /> 
                    </div>
                </div>
                <div class="sm:col-span-6 " v-if="this.show_reminder">

                    <label class="col-span-3">Reminder date</label>
                    <TDatePicker  ref="reminderTime" required dateFormat="Y/m/d H:i" userFormat="D d/m/Y H:i"  
                    initialTime  v-model="gigNote.reminder_datetime" :timepicker="true" />

                </div>



             </form>
                <div class="action-bar justify-apart">  
                    <a @click="closeModal" class="gp_cta w-full bg-gray-500">Close</a>
                    <div class="space-x-5 w-full flex">
                        <a @click="deleteNote()" v-if="note" class="gp_cta bg-indigo-500 min-w-120px">Delete Note</a>              
                        <button @click="saveNote()" class="gp_cta w-full bg-gp_pink-default-500  min-w-120px" >Save Note</button> 
                    </div>               
                </div> 
          </div>
        </div>
      </div>
                </div>
            </transition>
        </div>
    </div>
</template>
 
<script>
import InputTextArea from '../../components/form/InputTextArea.vue';
import TDatePicker from 'vue-tailwind/dist/t-datepicker'; 
import { apiMethods, apiComputed } from '@/state/helpers.js'
import {
  TSelect, TToggle
} from 'vue-tailwind/dist/components';
export default {
    name: "CreateNote",
    components:{
        TSelect,
        TDatePicker,
        TToggle,
        InputTextArea
    },
    props:{
        note: Object,
        gig: Object
    }, 
    computed: {
        ...apiComputed, 
        show_reminder(){
            return  this.gigNote.send_reminder === 1 || this.gigNote.send_reminder === true;
        }
    }, 
    data(){
        return{
            gigNote: {
                gig_id: this.gig.id,
                visible_to_all: true,
                body: '',
                assignee_id: null, 
                reminder_datetime: new Date(),
                send_reminder: false
            }
        }
    },
    mounted: function(){
        if (this.note)
        { 
            this.gigNote = JSON.parse(JSON.stringify(this.note));
            this.$refs.assignedto.localValue = this.gigNote.assignee_id;
            this.gigNote.send_reminder == 1 ? this.gigNote.send_reminder = true : false;
            this.gigNote.visible_to_all == 1 ? this.gigNote.visible_to_all = true : false;
        }
    },
    methods: {
        ...apiMethods,
        closeModal() {
            this.$emit('closeModal',
                false
            )
        },
        saveNote()
        { 
            console.log("in modal gigNote", this.gigNote);
            this.$emit('saveNote', this.gigNote);
        },
        deleteNote()
        {
            this.$emit('deleteNote', this.gigNote);
        }

    }
}
</script>

