<template>
  <TableWrapper>
    <template #header-rows>
      <tr>
        <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6">Date</th>
        <th scope="col" class="px-0 py-3.5 lg:table-cell">Gig</th>
        <th scope="col" class="hidden px-3 py-3.5 lg:table-cell text-center"></th>

        <th scope="col" class="relative w-32 px-3 py-3.5 ">
          <span class="sr-only">Actions</span>
        </th>
      </tr>
    </template>
    <template #body-rows>
      <tr v-for="usage in usages" :key="usage.id" :style="`background: ${usage.type === 'INVOICE' ? '#FCFCFC' : '#fff'}`">
        <td class="py-3 pl-8 grid grid-flow-row">
          <p v-if="usage.type === 'INVOICE'" class="text-gp_pink-default-500">{{ usage.label }}</p>
          <span class="text-xs hidden">
          {{ format(usage.date, 'do MMMM yyyy') }}
          </span>
          <span v-if="usage.type === 'INVOICE'" class="text-gray-500 text-sm">            
            {{ format(usage.date, 'do MMMM yyyy') }}
          </span>
        </td>
        <td class="py-3 lg:table-cell">

          <div class="grid grid-flow-row" v-if="usage.type === 'USAGE'">
            <span>{{ usage.gig.name }}</span>
            <span class="text-gray-500 text-sm">{{ formatDate(usage.gig.start) }}</span>
          </div>
        </td>
        <td class="py-3 hidden lg:table-cell">
          <span v-if="usage.type === 'INVOICE'" class="text-left text-sm text-indigo-500">
            <p>Reference: #{{ usage.invoice.external_id }}</p>
            <p>{{ formatAmount(usage.invoice.amount) }}</p>
          </span>

        </td>
        <td class="py-3">
          <a 
            v-if="usage.type === 'INVOICE'"
            :href="usage.invoice.invoice_url"
            class="text-indigo-500"
            target="_blank"
          >
            View invoice
          </a>

          <button 
            v-if="usage.type === 'USAGE'"
            class="text-gp_pink-default-500"
            @click="goToGig(usage.gig.id)"
          >
            View gig
          </button>
        </td>
      </tr>
    </template>
  </TableWrapper>
</template>

<script>
import TableWrapper from '../ui/table/TableWrapper.vue'
import format from 'date-fns/format'
const { DateTime } = require("luxon");

export default {
  components: {
    TableWrapper
  },
  methods: {
    goToGig(gigId) {
      this.$router.push(`/gigs/${gigId}`)

      this.$store
    },
    formatDate(date){
      return DateTime.fromISO(date).toFormat('DDDD HH:mm')
    },
    formatAmount(amount) {
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      });

      return formatter.format(amount / 100)
    },
    format,
    DateTime
  }, 
  props: {
    usages: {
      type: Array
    }
  },
}
</script>

<style>

</style>