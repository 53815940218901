<template>

	<ModalWrapper ref="editVenueContactModal" :is-open="isOpen">
		<template #header>
			<div class="text-xl"> 
				Profile Contact Details
			</div>
			<p class="mt-2 text-sm">
				This information will be shown on your venue profile page to potential artists.
			</p>
		</template>

		<template #body>

			<form class="mb-24 form mt-0 gap-y-4 input-group" ref="form" novalidate> 
				<div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5 col-span-full">
           
					<div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
						<div>
							<div>
								<h3 class="text-lg leading-6 font-medium text-gray-900">
									
								</h3>
								<p class="mt-1 max-w-2xl text-sm text-gray-500">
									
								</p>
							</div>

							<div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
								<div
									class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
								>
									<span>Venue address</span>
								</div>
								<div
									class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start"
								>
									<label
										for="venue-address-line1"
										class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
									>
										Line 1
									</label>
									<div class="mt-1 sm:mt-0 sm:col-span-2">
										<input
											type="text"
											name="venue-address-line1"
											id="venue-address-line1"
											v-model="form.address.address_line_1"
											class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
											required
										/>
										<span v-show="!form.address.address_line_1" class="mt-8 text-xs text-red-500">Please complete this field.</span>
									</div>
								</div>
								<div
									class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
								>
									<label
										for="venue-address-line2"
										class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
									>
										Line 2 (Optional)
									</label>
									<div class="mt-1 sm:mt-0 sm:col-span-2">
										<input
											type="text"
											name="venue-address-line2"
											id="venue-address-line2"
											v-model="form.address.address_line_2"
											class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
										/>
									</div>
								</div>
								<div
									class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
								>
									<label
										for="venue-address-town"
										class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
									>
										Town/City
									</label>
									<div class="mt-1 sm:mt-0 sm:col-span-2">
										<input
											type="text"
											name="venue-address-town"
											id="venue-address-town"
											v-model="form.address.town"
											class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
											required
										/>
										<span v-show="!form.address.town" class="mt-8 text-xs text-red-500">Please complete the town/city field.</span>
									</div>
								</div>
								<div
									class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
								>
									<label
										for="venue-address-county"
										class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
									>
										County
									</label>
									<div class="mt-1 sm:mt-0 sm:col-span-2">
										<input
											type="text"
											name="venue-address-county"
											id="venue-address-county"
											v-model="form.address.county"
											class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
										/>
										<span v-show="!form.address.county" class="mt-8 text-xs text-red-500">Please complete the county field.</span>
									</div>
								</div>
								<div
										class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
								>
									<label
											for="countries"
											class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
									>
										Country
									</label>
									<div class="mt-1 sm:mt-0 sm:col-span-2 pt-0">
										<TRichSelect
												id="countries"
												ref="countriespicker"
												name="countries"
												v-model="form.address.country_id"
												@change="pickCountry"
												:options="countries"
												value-attribute="id"
												text-attribute="name"
												class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
										/>
										<span v-show="!form.address.country_id" class="mt-8 text-xs text-red-500">Please select the country.</span>
									</div>
								</div>
								<div
									class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
								>
									<label
										for="venue-address-postcode"
										class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
									>
										Postcode
									</label>
									<div class="mt-1 sm:mt-0 sm:col-span-2">
										<input
											type="text"
											name="venue-address-postcode"
											id="venue-address-postcode"
											v-model="form.address.post_code"
											class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
											required
										/>
										<span v-show="!form.address.post_code" class="mt-8 text-xs text-red-500">Please complete the postcode field.</span>
									</div>
								</div>

								<div
									class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
								>
									<label
										for="email"
										class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
									>
										Email
									</label>
									<div class="mt-1 sm:mt-0 sm:col-span-2">
										<input
											id="email"
											name="email"
											type="email"
											v-model="form.email"
											class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
											required
										/>
										<p class="mt-2 text-sm text-gray-500">
											The primary venue email address.
										</p>
										<span v-show="!form.email" class="mt-8 text-xs text-red-500">Please complete the email field.</span>
									</div>
								</div>
								<div
									class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
								>
									<label
										for="tel"
										class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
									>
										Telephone
									</label>
									<div class="mt-1 sm:mt-0 sm:col-span-2">
										<input
											type="tel"
											id="tel"
											name="tel"
											v-model="form.telephone"
											class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
										
											required
										/>
										<p class="mt-2 text-sm text-gray-500">
											The primary venue telephone number.
										</p>
										<span v-show="!form.telephone" class="mt-8 text-xs text-red-500">Please complete the telephone field.</span>
									</div>
								</div>
								<div
									class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
								>
									<label
										for="website"
										class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
									>
										Website
									</label>
									<div class="mt-1 sm:mt-0 sm:col-span-2">
										<input
											type="url"
											id="website"
											name="website"
											class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
										
											v-model="form.website"
											required
										/>
										<p class="mt-2 text-sm text-gray-500">
											The primary venue website.
										</p>
										<span v-show="!form.website" class="mt-8 text-xs text-red-500">Please complete the website field.</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>

		</template>

		<template #footer>

			<ModalFooterWrapper>

				<template #left>
					<Button @click.native="onClose" :disabled="isFormLoading" color="gray">Cancel</Button>
				</template>

				<template #right>
					<Button @click.native="onSave" :disabled="formDisabled" :loading="isFormLoading">{{ submitButtonLabel }}</Button>
				</template>

			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>

</template>
<script>
import ModalWrapper from '@/components/ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '@/components/ui/modal/ModalFooterWrapper.vue'
import Button from '@/components/ui/buttons/Button.vue'
import {TRichSelect} from "vue-tailwind/dist/components";

export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		Button,
		TRichSelect
  },
  props: {
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
    venue: {
      type: Object,
      required: true,
    },
		countriesProp: {
			type: Array,
			required: false
		},
  },
	emits:["closed", "updated"],
  data() {
    return {
      form: {
        address: {},
        email: '',
        telephone: '',
        website: '',
      },
    }
  },
  computed: {
		countries() {
			return this.countriesProp
		},
		hasData(){
			if(this.venue.id){
				return true;
			}
			return false;
		},
		formDisabled() {
			return !this.form.email || !this.form.telephone || !this.form.website || !this.form.address.address_line_1 || !this.form.address.county || !this.form.address.town || !this.form.address.post_code ? true : false
		},
		isFormLoading(){
			return false
		},
		submitButtonLabel(){
			return this.isFormLoading ? 'Applying' : 'Apply'
		}
  },
  mounted() {
		const address = { ...this.venue.address }
    this.form.address = {
			address_line_1: address.address_line_1,
			address_line_2: address.address_line_2,
			town: address.town,
			county: address.county,
			post_code: address.post_code,
			country_id: address.country_id
		};
    this.form.email = this.venue.email;
    this.form.telephone = this.venue.telephone;
    this.form.website = this.venue.website;
  },
  methods: {
		pickCountry(value){
			this.form.address.country_id = value
		},
		onClose(){
			this.$emit('closed')
		},
		onSave(){
			
			if(this.form.address.address_line_1 == "" || this.form.address.town == "" || this.form.address.county == "" || 
				this.form.address.post_code == "" || this.form.email == "" || this.form.telephone == "" || this.form.website == "")
			{
				return;
			} else {
				this.$emit('updated', this.form);
			}
		}
  }
}
</script>
