<template>
	<PageDetails>
		<h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">Edit Venue {{ isLoading ? '-' : '-' }} {{ venueName }}</h1> 
      <div class="lg:max-w-5xl">

				<VenueTabs :venue-id="venueId" current-route="manage-roles">
					<template #right-cta v-if="permissions && !isLoading">
						<button @click="onCreateNewRole" class="gp_cta">Create a new role</button>
					</template>
				</VenueTabs>

				<template v-if="isLoading">
					<div class="p-8 flex justify-center items-start">
						<PageSpinner 
							tailwind-diameter="8"
							tailwind-border="4"
							tailwind-color="pink-500"
						/>
					</div>
				</template>
				<template v-else-if="!isLoading && !hasData">
					<NoDataMsgWrapper>There are no roles for this venue</NoDataMsgWrapper>
				</template>
				<template v-else>
					<TableWrapper>
						<template #header-rows>
							<tr>
								<th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6">Role</th>
								<th scope="col" class="hidden px-3 py-3.5 lg:table-cell">Access</th>
								<th scope="col" class="hidden px-3 py-3.5 sm:table-cell">Status</th>
								<th scope="col" class="relative w-8 px-3 py-3.5 ">
									<span class="sr-only">Edit</span>
								</th>
								<th scope="col" class="relative w-8 py-3.5 pl-3 pr-4 sm:pr-6">
									<span class="sr-only">Delete</span>
								</th>
							</tr>
						</template>
						<template #body-rows v-if="roles">
							<tr v-for="role in roles" :key="role.id">
								<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
									{{ role.name }}
									<dl class="font-normal lg:hidden">
										<dt class="sr-only">Access</dt>
										<dd class="mt-1 truncate text-gray-700">
											<ul class="list-none">
													<li v-for="permission in role.permissions" :key="permission.id" class="">
														{{ permission.name }}
													</li>
											</ul>
										</dd>
										<dt class="sr-only sm:hidden">Status</dt>
										<dd class="mt-1 truncate text-gray-500 sm:hidden">
											<span class="badge gig-paid">{{ getStatus(role) }}</span>
										</dd>
									</dl>
								</td>
								<td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
									{{ getPermissions(role) }}
									<span class="relative inline-flex flex-col items-center group" v-if="role.permissions.length > 1">
										<span 
											class="text-sm font-medium underline underline-offset-2 cursor-pointer"
										>+{{ role.permissions.length - 1 }} more</span>
										<div class="absolute bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
											<span class="relative z-50 p-4 bg-black rounded-sm shadow-lg w-48">
												<ul class="list-none">
													<li v-for="permission in role.permissions" :key="permission.id" class="text-white ">{{ permission.name }}</li>
												</ul>
											</span>
											<div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
										</div>
									</span>

								</td>
								<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
									<span class="badge gig-paid">{{ getStatus(role) }}</span>
								</td>
				
								<td class="w-8 px-3 py-4 text-right text-sm font-medium sm:pr-6"><!-- pl-3 pr-4 -->
									<a 
										@click.prevent="onEditRole(role.id)" 
										class="text-indigo-600 hover:text-indigo-900"
									>
										<span class="sm:hidden"><PencilAltIcon /></span>
										<span class="hidden sm:block">
											Edit<span class="sr-only">, {{ role.name }}</span>
										</span>
									</a>
								</td>

								<td class="w-8 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
									<a 
										@click.prevent="onDeleteRole(role.id)" class="text-gp_pink-default-500 hover:text-gp_pink-default-700 "
									>
										<span class="sm:hidden"><TrashIcon /></span>
										<span class="hidden sm:block">
											Delete<span class="sr-only">, {{ role.name }}</span>
										</span>
									</a>
								</td>

							</tr>
						</template>
					</TableWrapper>
				</template>
				
			</div>

			<!-- Modals -->
			<template v-if="permissions">
				<CreateRoleModal 
					identity="createRoleModal"
					:is-open="createRoleModalIsOpen" 
					:venueId="venueId"
					:permissions="permissions"
					@closed="onModalClosed"
					@saved="onRoleAdded"
				></CreateRoleModal>

				<template v-if="selectedRole">
					<EditRoleModal 
						identity="editRoleModal"
						:is-open="editRoleModalIsOpen" 
						:role="selectedRole"
						:permissions="permissions"
						:venueId="venueId"
						@closed="onModalClosed"
						@updated="onRoleUpdated"
						@delete="onDeleteRole"
					></EditRoleModal>
					<DeleteRoleModal 
						identity="deleteRoleModal"
						:is-open="deleteRoleModalIsOpen" 
						:role="selectedRole"
						:roles="roles"
						:venueId="venueId"
						@closed="onModalClosed"
						@deleted="onRoleDeleted"
					></DeleteRoleModal>
				</template>

			</template>
			
			
			
			
		<NotificationSmall ref="notification" />
	</PageDetails>

</template>

<script>
import PageDetails from '@/components/ui/wrappers/PageDetails.vue'
import TableWrapper from '../../components/ui/table/TableWrapper.vue';
import PageSpinner from '../../components/ui/spinner/PageSpinner.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';

import VenueTabs from '../../components/venues/VenueTabs.vue';
import CreateRoleModal from '../../components/modal/venues/CreateRole'
import EditRoleModal from '../../components/modal/venues/EditRole'
import DeleteRoleModal from '../../components/modal/venues/DeleteRole'
import NotificationSmall from '../../components/notifications/small.vue';

import { TrashIcon } from "@vue-hero-icons/outline"
import { PencilAltIcon } from "@vue-hero-icons/outline"


export default {
	components: {
		PageDetails,
		TableWrapper,
		PageSpinner,
		NoDataMsgWrapper,
		VenueTabs,
		CreateRoleModal,
		EditRoleModal,
		DeleteRoleModal,
		NotificationSmall,
		PencilAltIcon,
		TrashIcon,
	},
	props:['venueId'],
	data(){
		return {
			venueName:null,
			isLoading:false,
			createRoleModalIsOpen:false,
			editRoleModalIsOpen:false,
			deleteRoleModalIsOpen:false,
			selectedRole:null,
			permissions:this.$store.getters['permissions/all'],
			roles:this.$store.getters['venueRoles/venueRoles'](this.venueId),
		}
	},
	computed:{
		hasData(){
			if(this.roles){
				if(this.roles.length){
					return true;
				}
			}
			return false;
		},
		getStatus(){
			return (role) => !role.users ? '-' : `${role.users.length} member${role.users.length !== 1 ? 's' : ''}`;
		},
		getPermissions(){
			return (role) => role.permissions && role.permissions.length > 0 ? `${role.permissions[0].name}${role.permissions.length > 1 ? ', ' : ''}` : "-";
		},
	},
	methods:{
		// open modals
		onCreateNewRole(){
			this.createRoleModalIsOpen = true;
		},
		onEditRole(roleId){
			this.selectedRole = this.roles.find(role => role.id === roleId);
			this.editRoleModalIsOpen = true;
		},
		onDeleteRole(roleId){
			this.selectedRole = this.roles.find(role => role.id === roleId);
			this.deleteRoleModalIsOpen = true;
		},
		// close modals 
		onModalClosed(){
			this.createRoleModalIsOpen = false;
			this.editRoleModalIsOpen = false;
			this.deleteRoleModalIsOpen = false;
		},
		// CRUD methods
		onRoleAdded(role){
			this.onModalClosed();
			// 
			this.$store.dispatch('venueRoles/requestVenueRoles', this.venueId)
			.then(() => {
				this.roles = this.$store.getters['venueRoles/venueRoles'](this.venueId);
				this.$refs.notification.createNotification(`The role ${ role.name } has been added.`, false);
			}, err => {
				console.log(err)
			})
		},
		onRoleUpdated(role){
			this.onModalClosed();
			// 
			this.$store.dispatch('venueRoles/requestVenueRoles', this.venueId)
			.then(() => {
				this.roles = this.$store.getters['venueRoles/venueRoles'](this.venueId);
				this.$refs.notification.createNotification(`The role ${ role.name } has been updated.`, false);
			}, err => {
				console.log(err)
			})
		},
		onRoleDeleted(role){
			this.onModalClosed();
			this.selectedRole = false;
			if(role.users && role.users.length > 0){
				// Roles
				this.$store.dispatch('venueRoles/requestVenueRoles', this.venueId)
				.then(() => {
					this.roles = this.$store.getters['venueRoles/venueRoles'](this.venueId)
				}, err => {
					console.log(err)
				})
			}else{
				this.roles = this.$store.getters['venueRoles/venueRoles'](this.venueId);
			}
			this.$refs.notification.createNotification(`The role ${ role.name } has been deleted.`, false);
		},
		
	},
	mounted(){
		this.isLoading = true;

		Promise.all([
			
			// Venue
			this.$store.dispatch('venue/getCurrentVenue', this.venueId)
			.then((response) => {
				this.venueName = response.name
			}),
			// Permissions
			this.$store.dispatch('permissions/getRolePermissions')
			.then((response) => {
				this.permissions = response
			}, err => {
				console.log(err)
			}),
			// Roles
			this.$store.dispatch('venueRoles/getVenueRoles', this.venueId)
			.then((response) => {
				this.roles = response
			}, err => {
				console.log(err)
			}),
			// Invites
			// this.$store.dispatch('venueInvites/getVenueInvites', this.venueId).then((response) => {
			// 	this.invites = response
			// }, err => {
			// 	console.log(err)
			// }),
			// Members
			// this.$store.dispatch('venueMembers/getVenueMembers', this.venueId).then((response) => {
			// 	this.members = response
			// }, err => {
			// 	console.log(err)
			// })
		]).finally(() => {
			this.isLoading = false;
		});
		
	}
}
</script>
