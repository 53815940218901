/* eslint-disable no-empty-pattern */
import client from "../../utils/client"

export const state = {
  artistBids: null,
}

export const mutations = {
  SET_ARTIST_BIDS(state, artistBids) {
    state.artistBids = artistBids
  },
}

export const getters = {
	artistBids: state => {
    return state.artistBids;
  },
}

export const actions = {
	storeArtistBids({ commit }, artistBids){
		commit('SET_ARTIST_BIDS', artistBids);
	},
  getArtistBids({ getters, dispatch }, refresh = false) {
		return new Promise((resolve) => {
			const artistBids = getters.artistBids
			if(artistBids && !refresh){
				resolve(getters.artistBids)
			}else{
				dispatch('loadArtistBids')
				.then(() => {
					resolve(getters.artistBids);
				}).catch(err => {
					console.log(err)
				});
			}
		})
  },
	loadArtistBids({ dispatch }, params){
		return new Promise((resolve) => {

			let url = `/artist/bids`

			const argParams = params !== undefined ?
			new URLSearchParams(params).toString() : null

			if (argParams !== null) {
				// const queryParams = new URLSearchParams(argParams);
				url = `${url}?${argParams}`
			}

			console.log('url params', url)
			client
					.get(url)
					.then((response) => {
						dispatch('storeArtistBids', response.data.data)
						resolve(response.data.data)
					})
					.catch(err => {
						console.log(err)
					});
		})
	},
	
    getFutureBookedOffers({ }) {

		return new Promise((resolve) => {
           client
            .get(`/artist/future-booked-offers`)
            .then(response => {
				resolve(response.data.data);
            })
            .catch(err => {
                console.log(err)
            })
		});
    }, 
    getArtistRespondedOffers({}){
        
		
		return new Promise((resolve) => {
			client.get('/artist/responded-offers')
			.then(response => {
				resolve(response.data.data);
			})
			.catch(err => {
				console.log(err)
			})
		});
    },
    getNewArtistOffers({}){
		
		return new Promise((resolve) => {
			client.get('/artist/new-offers')
				.then(response => {
					resolve(response.data.data);
				})
				.catch(err => {
					console.log(err)
				})
		});
    },
} 