<template>

  <div  transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <GigPig
          v-if="brand.name === 'gigpig'"
          :venue_only="venue_only"
          :artist_only="artist_only"
          :alignment="alignment"
      />

      <BrandedLogin
          v-else
          :venue_only="venue_only"
          :artist_only="artist_only"
          :alignment="alignment"
          :logo-url="brand.logo_url"
          :css-file="brand.css_file"
          :name="brand.name"
      />
    </div>
  </div>

</template>

<style scoped>

.external .router-link-active {

    @apply bg-transparent;

}
.fadeIn-transition {
  transition: all .3s ease;
  opacity:0;
}
.expand-enter, .expand-leave {
  opacity: 1;
}
</style>

<script>
import GigPig from "@/components/modal/logins/GigPig.vue";
import branding from '@/global/branding'
import BrandedLogin from "@/components/modal/logins/BrandedLogin.vue";

export default {
    name: "ModalDefault",
  data() {
      return {
        allow_venues: true
      }
  },
    props:{
      venue_only: Boolean,
      artist_only:Boolean,
    },
  components: {
    BrandedLogin,
    GigPig,
  },
  mounted(){
    if (window.location.search.includes('?ref=')){
      const urlParams = new URLSearchParams(window.location.search);
      const value = urlParams.get('ref');
      localStorage.ref = value;
    }
    
  },
  computed:
  {
    alignment: function(){
      return this.venue_only || this.artist_only ? 'justify-center' : 'justify-center"';
    },
    brand: function() {
        return branding.getBrand()
    },
  }
}
</script>
