<template>
    <div>         

        <InlineNotification Heading="New available gigs" class="ml-4" DismissId="BidsNew">
            <template slot="body">
              <p>New gigs? Take your pick.</p>
              <p>Click ‘view’ on each box for all the details.<br/>Hit the ‘thumbs up’ button if you're available for the gig.
              </p>
              <p>You’ll then find those gigs in the ‘Availability given’ tab.</p> 
              <p>If the gig’s not for you, hit the ‘thumbs down’ button instead. </p> 
              <p class="text-red-500 uppercase">Remember - The gig is only booked when you get a booking confirmation.  All confirmed bookings will be in the 'booked gigs' tab (and your calendar too).</p>            
            </template> 
        </InlineNotification> 

        <InlineNotification class="p-0 m-0 mb-5 ml-4" DismissId="BidsTip">
            <template slot="body">
              <h3 class="text-lg leading-6 font-medium text-gray-900 flex flex-row">
                <SpeakerphoneIcon class="mr-2" color="pink"/> 
                
                <DictionaryContent :hookId="16" dynamicUrl="artist/offers">
                  GigPig Tip
                </DictionaryContent>
              </h3>
              <p class="text-justify">Artists with a fully completed profile yield a higher gig offer rate. Be sure to continually update your artist profile(s) with your best content and that which most represents yourself as an artist.</p>
              <p class="text-justify">If you feel your artist profile(s) could be improved, navigate to your artist profiles view using the component below and select edit to edit your profile.</p>
              <router-link :to="'/artist/profiles'" class="flex items-center mt-1 bg-transparent w-48"><ArrowCircleLeftIcon class="mr-2" color="pink"/><span class="text-white">My artist profiles</span></router-link>
            </template>
        </InlineNotification>

        <div v-show="showBids" class="max-w-6xl xl:flex xl:space-x-32 pb-4 pt-12 ml-4">
          <div class="w-80">
            <TRichSelect 
              required 
              id="profile-selection" 
              name="profile-selection" class="trichselect"                    
              :options="artistProfiles"  
              value-attribute="name" 
              text-attribute="name" 
              placeholder="Filter by artist profile"
              v-model="selectedProfile"
            />
          </div>

          <div class="items-center md:flex md:space-x-8 sm:mt-4">
            <span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider">Filter by date:</span>
            <div class="col-span-3 lg:col-span-1">
              <input ref="start" placecholder="Start date" type="date" v-model="dateFilterStart" class="datepick h-full rounded-lg border-gray-300"/>
            </div>
            <span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider">to</span>
            <div class="col-span-3 lg:col-span-1">
              <input ref="end" type="date" v-model="dateFilterEnd" class="datepick h-full rounded-lg border-gray-300"/>
            </div>
          </div>
        </div>
      
        <div v-show="showBids" class="max-w-6xl xl:ml-96 ml-4">
          <div class="items-baseline md:flex md:space-x-8 sm:mt-4">
              <span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider xl:ml-16">Filter by gig fee:</span>
              <div class="relative w-40">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span class="text-gray-500 sm:text-sm bg-gray-100 rounded-md py-1 px-2">£</span>
                </div>
                <input
                  v-model="priceFilterFrom" 
                  type="text"  
                  class="bg-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 p-2.5  dark:bg-none dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                  placeholder="0.00">
              </div>
              <span class="marker: text-xs font-medium text-gray-400 uppercase tracking-wider">to</span>
              <div class="relative mb-6 w-40">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span class="text-gray-500 sm:text-sm bg-gray-100 rounded-md py-1 px-2">£</span>
                </div>
                <input
                  v-model="priceFilterTo" 
                  type="text"  
                  class="bg-none border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-12 p-2.5  dark:bg-none dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                  :placeholder="highestBidOffer">
              </div>
          </div>
        </div>

        <OfferByDate noItemsText="No new gig offers just yet." v-on:reloadData="reloadData" ref="offers" :showSelects="true"  :unfilteredBids="currentBids"></OfferByDate>
 
    </div>
</template>


<script type="text/javascript">

import { 
} from 'vue-tailwind/dist/components'
import { TRichSelect } from 'vue-tailwind/dist/components';
import OfferByDate from '../../../components/gigs/OfferByDate.vue';
import InlineNotification from '../../../components/notifications/inline.vue';
import { apiComputed } from '@/state/helpers.js';
import { ArrowCircleLeftIcon, SpeakerphoneIcon } from "@vue-hero-icons/outline";
import NProgress from 'nprogress';
import DictionaryContent from '../../../components/dictionary/DictionaryContent.vue';

export default {
  name: 'ArtistBidIndex',
  components:
  {
    InlineNotification,
    OfferByDate,
    ArrowCircleLeftIcon, SpeakerphoneIcon,
    TRichSelect,
    DictionaryContent
},
  data() { 
      return {
        currentBids: null,
        currentBidsCopy: null,
        artistProfiles: [],
        selectedProfile: null,
        dateFilterStart: null,
        dateFilterEnd: null,
        priceFilterTo: null,
        priceFilterFrom: null,
        highestOffer: null,
        profileSelected: 'All artist profiles',
        date_filter_start: '2023-01-01T00:00:00.000000Z',
        date_filter_end: '3000-01-01T00:00:00.000000Z',
        price_filter_from: 0,
        price_filter_to: 100000000,
      }
  },
  computed: {
    ...apiComputed,
    highestBidOffer(){
      return this.highestOffer;
    },
    showBids() {
        return this.currentBidsCopy !== null && this.currentBidsCopy.length
    }
  },  
  watch: {
    selectedProfile(profile){
      this.profileSelected = profile;
      this.artistFilters();
    },
    dateFilterStart(val) {
      this.date_filter_start = !val ? '2023-01-01T00:00:00.000000Z' : val + 'T00:00:00.000000Z';
      this.artistFilters();
    },
    dateFilterEnd(val) {
      this.date_filter_end = !val ? '3000-01-01T00:00:00.000000Z' : val + 'T00:00:00.000000Z';
      this.artistFilters();
    },
    priceFilterFrom(val){
      this.price_filter_from = !val ? 0 : parseFloat(val);
      this.artistFilters();
    },
    priceFilterTo(val){
      this.price_filter_to = !val ? this.highestOffer : parseFloat(val);
      this.artistFilters();
    }
  },
  mounted(){
    this.loadArtists();
    this.loadData();
  },
  methods: { 
    filter($items, index){
      console.log("?", {$items, "index" : index});
      this.filteredBids = $items;
      this.selectedIndex = index;
      this.$refs.offers.items = $items; 
    },
    reloadData(){
      this.loadData();
    },
    loadData(){

      console.time('bids load');

      NProgress.start();

      this.$store.dispatch('artistBids/getNewArtistOffers')
        .then((resp) => {
          this.currentBids = resp;
          this.currentBidsCopy = this.currentBids;

          let fees = [];

          this.currentBids.map((b) =>{
            fees.push(b.fee);
          })

          this.highestOffer = Math.max.apply(Math, fees) + '.00';
        })
        .finally(() => {
          NProgress.done();
        }); 
    },
    loadArtists(){
        this.$store
          .dispatch('api/getArtists')
          .then(() => {
              if (this.artists && this.artists.length > 0){            
                  this.artists.forEach(artist => {
                      let artistObj = {
                          name: artist.name
                      };
                      this.artistProfiles.push(artistObj);
                  });
                  this.artistProfiles = [{name: "All artist profiles", id: "all"}, ...this.artistProfiles];
              }
          })
    },
    artistFilters(){
      if(this.profileSelected !== "All artist profiles") {
        this.currentBids = this.currentBidsCopy
          .filter((r) => r.artist.name == this.profileSelected && r.gig.start >= this.date_filter_start 
                                          && r.gig.end <= this.date_filter_end && r.fee >= this.price_filter_from
                                          && r.fee <= this.price_filter_to);
      } else {
        this.currentBids = this.currentBidsCopy
          .filter((r) => r.gig.start >= this.date_filter_start && r.gig.end <= this.date_filter_end && r.fee >= this.price_filter_from
                                          && r.fee <= this.price_filter_to);
      }  
         
    },
  }
};

</script>