import { render, staticRenderFns } from "./VenueTabs.vue?vue&type=template&id=4c6e217b"
import script from "./VenueTabs.vue?vue&type=script&lang=js"
export * from "./VenueTabs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports