<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">       
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>  
            <transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">        
                <div v-click-outside="toggleModal" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-4 w-full ">
                            <h3 class="text-xl font-bold leading-6 text-gray-900 justify-between flex" id="modal-title">
                                Adding to your favourites lists
                                <span class="text-gp_pink-default-500 text-base">Adding {{Artist.name}}</span>
                            </h3> 
                            <InlineNotification class="mx-0 p-0 important py-0">
                                <template slot="body">
                                    <p>You can create as many favourite lists as you like.  These lists help you find the artists you love quicker, for those gigs you host often.</p>
                                    <p class="block mt-4">Each list can then be used in your gig templates and gigs. You can also manage your lists via 
                                        <router-link to="/favourites" class="text-gp_pink-default-500">
                                        "My favourites"</router-link>
                                        in the main menu.</p>
                                </template> 
                            </InlineNotification> 
                            <div>
                                <label>Add to an existing list</label>
                                <TRichSelect
                                    :value="compFaveslistsIds"
                                    :close-on-select="false"
                                    multiple ref="favelist"
                                    :options="compFaveslists"
                                    textAttribute="name"
                                    valueAttribute="id"
                                    @change="favSelected"
                                    placeholder="Select a list"  class="w-full" >

                                    <template
                                        slot="option"
                                        slot-scope="{ index, isHighlighted, isSelected, className, option }"
                                        >
                                        <div :class="className">
                                            <div class="text-gray-800">
                                                {{ option.raw.name }} &nbsp;<span v-if="option.raw.is_shared"><strong>(shared)</strong></span>
                                            </div>
                                        </div>
                                    </template>
                                </TRichSelect> 
                            </div>
                            <div>
                                <label>Or create a new list and add this artist</label>
                                <TInput ref="favename" v-model="favename" placeholder="Name your new list" class="w-full" />
                            </div>
                            <div v-if="privateArtistVenues.length">
                                <p class="font-bold text-md mb-2">Private Artist Invitations</p>
                                <table class="w-full border-spacing-y-10 border-separate">
                                    <thead>
                                        <tr>
                                            <th>Venue</th>
                                            <th>Invited</th>
                                            <th>Artist Response</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            class="border-spacing-y-10"
                                            v-for="venue in privateArtistVenues"
                                            :key="venue.id"
                                        >
                                            <td>{{ venue.name }}</td>
                                            <td>
                                                <div class="grid max-w-sm">
                                                    <TCheckbox
                                                        :key="venue.id"
                                                        v-model="venueIds"
                                                        :value="venue.id"
                                                        :wrapped="true"
                                                        :classes="{label: 'text-sm inline', input: 'mr-2 text-pink-500 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-pink-500 focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed'}"
                                                    ></TCheckbox>
                                                </div>
                                            </td>
                                            <td class="content-center">
                                                <span
                                                    class="align-middle rounded-full inline-block px-2 py-1 text-xs font-semibold"
                                                    :class="statusStyles(venue.status)"
                                                >
                                                    {{ venue.status ? capitaliseString(venue.status) : 'Not invited'}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div class="text-left sm:mt-5">
                            <div class="text-sm">
                                <form  class="form mb-36 lg:mb-24">               
                                    <div class="action-bar px-6 w-full justify-between">  
                                        <GPButton @click.native="toggleModal()" 
                                                color="indigo">Close</GPButton> 
                                        <GPButton @click.native="save()"
                                                :disabled="btnDisabled || SaveButtonDisabled"
                                                :loading="btnDisabled"
                                                color="pink"><SaveIcon class="mr-2"/>Save & add artist</GPButton>  
                                    </div> 
                                </form> 
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script type="text/javascript"> 
    import { TRichSelect, TInput, TCheckbox } from 'vue-tailwind/dist/components';
    // import { TRichSelect, TInput } from 'vue-tailwind/dist/components';
    import InlineNotification from '../notifications/inline.vue';
    import { apiMethods, apiComputed } from '@/state/helpers'; 
    import GPButton from '../ui/buttons/Button.vue';
    import { SaveIcon } from "@vue-hero-icons/outline";
    import capitalise from '../../utils/capitalise';
    // import Vue from "vue";

    export default {
        name: "FaveManagement",
        components:{
            TRichSelect,
            TInput,
            TCheckbox,
            InlineNotification,
            GPButton,
            SaveIcon
        },
        props:{
            Artist: Object,
            ShowModal: Boolean,
            privateArtistVenuesProp: {type: Array, default: Array},
            privateInvites: {type: Array, default: Array},
            privateInviteIds: {type: Array, default: Array},
        },
        data(){
            return {
                labelTest: 'A Label',
                btn: false,
                modal_open: false,
                favename: '',
                favelist: null, 
                ddList: null,
                compFaveslists: null,
                venueIds: this.privateInviteIds,
                SaveButtonDisabled: true
            }
        },
        emits: ['privateVenueAdded'],
        watch: {
            privateInviteIds() {
                this.venueIds = this.privateInviteIds
            },
            venueIds($event) {
                this.$emit('privateVenueAdded', $event)
            },
            favename(value) {
                this.SaveButtonDisabled = value === '' && this.compFaveslistsIds.length === 0;
            },
        },
        computed: {
            ...apiComputed,
            btnDisabled(){
                return this.btn;
            },
            privateArtistVenues() {
                let venues = []
                this.privateArtistVenuesProp.forEach(venue => {
                    let newVenue = Object.create(venue)
                    newVenue.status = null
                    venue.private_artists.forEach(privateArtist => {
                        if (privateArtist.id === this.Artist.id) {
                            newVenue.status = privateArtist.pivot.status
                        }
                    })
                    venues.push(newVenue)
                })
                return venues
            },
            compFaveslistsIds() {
              return this.compFaveslists
                .filter(list => list.artists.some(artist => artist.id === this.Artist.id))
                .map(list => list.id);
            },
        },
        mounted(){
            this.getFavesLists().then((resp) =>{
                this.compFaveslists = resp;
            });
        },
        methods:{
            ...apiMethods,
            favSelected() {
              this.SaveButtonDisabled = this.favename === '' && this.compFaveslistsIds.length === 0;
            },
            toggleModal(){
                this.modal_open = !this.modal_open; 
            },
            save()
            {  
                this.btn = true;
                let $fave = this.favename;
                let $artist_id = this.Artist.id; 
                        
                let payload = 
                        {
                            name: $fave, 
                            artists: [{ id:$artist_id }],
                            allow_brand_visibility: false
                        };



                if (this.favename != ''){
                    this.createFavesList(payload).then((resp) => {            
                        this.compFaveslistsIds.push(resp.id)
                    }).finally(() => {

                        this.createFave({
                            private_venue_artists: {artistId: this.Artist.id, venueIds: this.venueIds},
                            faveLists : this.compFaveslistsIds,
                            artist_id : $artist_id,

                        })
                        .then(() => {
                            this.$notify({ 
                                title: 'List created',
                                text: this.Artist.name + ' added to list'
                            });

                            this.getFavesLists().then((resp) =>{
                                this.compFaveslists = resp;
                            });
                        })
                        .finally(() => {
                            this.$emit('forcePageUpdate');
                            this.btn = false;
                            this.favename = '';
                            this.toggleModal();
                        });

                    })

                } else {

                    let $payload = {
                        faveLists : this.compFaveslistsIds,
                        artist_id : $artist_id,
                        private_venue_artists: {artistId: this.Artist.id, venueIds: this.venueIds}
                    }

                    this.createFave($payload)
                        .then(() => {
                            this.$notify({ 
                                title: 'Lists updated',
                                text: this.Artist.name + ' added to lists'
                            });
                            
                            this.getFavesLists().then((resp) =>{
                                this.compFaveslists = resp;
                            });
                        })
                        .catch((err) => {
                            this.$notify(err.response.data.message);
                        })
                        .finally(() => {
                            this.btn = false;
                            this.$emit('forcePageUpdate');
                            this.toggleModal();
                        });
                 
                }

            },
            statusStyles(status) {
                let styles = ''
                switch (status) {
                    case 'pending':
                        styles = 'bg-blue-400 text-white'
                        break
                    case 'accepted':
                        styles = 'bg-green-600 text-white'
                        break
                    case 'declined':
                        styles = 'bg-red-600 text-white'
                        break
                    default:
                        styles = 'bg-yellow-300'

                }
                return styles
            },
            capitaliseString(string) {
                return capitalise(string)
            }
        }
    }

</script>