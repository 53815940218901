<template>
  <div>
    <draggable v-model="orderedImages" @end="imagesReordered">
      <transition-group class="grid gap-6 grid-cols-4">
        <div class="relative" v-for="image in orderedImages" :key="image.id">
          <div
            v-if="image.uploading && image.uploading === true"
            class="absolute flex justify-center items-center w-full h-full"
          >
            <PageSpinner />
          </div>
          <button
            @click="deleteImage(image)"
            type="button"
            class="gallery-delete bg-gray-500 bg-white absolute p-1 rounded-full"
          >
            <TrashIcon class="h-6 text-white" />
            <span class="sr-only">Delete</span>
          </button>
          <img
            class="rounded object-cover cursor-move h-32 w-32 min-w-full min-h-full"
            :src="image.url ? image.url : image.preview"
            draggable="false"
          />
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script type="text/javascript">
import draggable from "vuedraggable";
import { TrashIcon } from "@vue-hero-icons/outline";
import PageSpinner from "../ui/spinner/PageSpinner.vue";

export default {
  components: {
    draggable,
    TrashIcon,
    PageSpinner,
  },
  computed: {
    orderedImages: {
      get: function () {
        return this.images;
      },
      set: function (newImages) {
        this.$emit("onGalleryChange", newImages);
      },
    },
  },
  props: {
    images: Array,
  },
  methods: {
    imagesReordered() {
      this.$emit("onGalleryChange", this.orderedImages);
    },
    deleteImage(image) {
      this.$emit("onGalleryImageDeleted", image);
    },
  },
};
</script>

<style scoped>
.gallery-delete {
  right: -0.5rem;
  top: -0.5rem;
}
</style>
