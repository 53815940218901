import { mapActions } from 'vuex'
 
export const state = {
    isMobileMenuOpen: false,
}

export const getters = {}

export const mutations = {
    TOGGLE_MOBILE_MENU(state, toggleMobileMenuSetting) {
        state.isMobileMenuOpen = toggleMobileMenuSetting
    },
}

export const actions = {
    setToggleMobileMenu({ commit }, toggleMobileMenuSetting ) {
        commit('TOGGLE_MOBILE_MENU', toggleMobileMenuSetting);
    },
}


export const layoutMethods = mapActions('layout', [
    'setToggleMobileMenu' 
]);