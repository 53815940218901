<template>
	<div>
		<div v-if="fetchedContent" v-html="decodeHTML(fetchedContent.content)"></div>
		<div v-if="!fetchedContent && fallback == true">
			<slot></slot>
		</div>
	</div>
</template>
  
<script>
export default {
	props: {
		hookId: {
			type: Number,
			default: 1, // Set the default value to 1
		},
		dynamicUrl: {
			type: String,
			required: true
		},
		fallback: {
			type: Boolean,
			default: true,
		}
	},
	computed: {
		fetchedContent() {

			if (!this.$store.state.dictionaryArray) {
				return false;
			}

			return this.$store.state.dictionaryArray.find(
				(item) => item.url === this.dynamicUrl && item.hook_id === this.hookId
			)
		}
	},
	methods: {
		decodeHTML(html) {
			const txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;
		},
	},
};
</script>