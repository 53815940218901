<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">

                    <!-- Country -->
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center">
                            <h3 class="block font-bold text-gray-900" id="modal-title">Artist Ratings</h3>
                            <p class="text-sm">
                                Would you like to rate or leave any feedback for the artists you've signed-off? To rate an artist click the 'Rate' button next to their
                                info. Alternatively, clicking 'Finish' will automatically give each artist a five star rating.
                            </p>
                        </div>

                        <div class="relative flex py-5 items-center">
                            <div class="flex-grow border-t border-gray-400"></div>
                            <div class="flex-grow border-t border-gray-400"></div>
                        </div>

                        <div class="overflow-auto max-h-72">
                            <div v-for="(bid, index) in toRate"  :key="'bid' + index" class="grid grid-cols-1 md:grid-cols-2 mt-4 bg-gray-100/30 p-4 pr-12 rounded-xl shadow">
                                <div class="grid grid-flow-col gap-4 justify-start items-start">
                                    <div>
                                        <span class="font-bold">{{bid.gig.venue.name}}</span>
                                        <span class="block">{{bid.gig.name}}</span>
                                        <span class="block">{{  fullDate(bid.gig.start) }}</span>
                                    </div>
                                </div>

                                <div class="flex mt-6 md:mt-0">
                                    <div>
                                        <img v-if="bid.artist.image && bid.artist.image.url !== null" :src="bid.artist.image.url" class="w-20 h-20 object-cover object-center rounded-full border-2 border-gp_pink-default-400" /> 
                                        <img v-else :src="require('@/assets/images/placeholder.svg')" class="w-20 h-20 object-cover object-center rounded-full" /> 
                                    </div>
                                    <div class="ml-8">
                                        <p class="text-sm font-semibold text-[#0D1C43]">{{ bid.artist.name }}</p>
                                        <!-- <StarRating class="mt-2" :rating="bid.artist.rating"/> -->
                                        <GPButton @click.native="rateArtist(bid, index)" 
												:color="'indigo'"
												size="sm"
                                                class="mt-2"
											>Rate Artist</GPButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
                                                class="invisible"
											>Close</GPButton>
                                    <GPButton   @click.native="skipAllRatings()" 
												:color="'pink'"
                                                :disabled="isProcessing"
                                                :loading="isProcessing"
												size="md"
											>Finish</GPButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <ArtistRatingModal ref="ratingmodal" @reload="spliceArtist()"/>
        <NotificationSmall ref="notification" />
    </div>
</template>

<script>
import GPButton from '../../components/ui/buttons/Button.vue'
import fullDate from  '@/utils/format-date-full.js'  
import ArtistRatingModal from '../../components/modal/ArtistRating.vue';
import { apiMethods } from '@/state/helpers'; 
import NotificationSmall from '../../components/notifications/small.vue';

export default
{
    name: "ArtistMultiRating", 
    components:{
        GPButton, 
        ArtistRatingModal,
        NotificationSmall
    },
    data(){
        return {
            modal_open: false,
            isProcessing: false
        }
    },
    props: ['toRate'],
    mounted() {
        
    },
    methods: {
        fullDate,
        ...apiMethods,
        toggleModal() {
            this.modal_open = !this.modal_open;
        },
        rateArtist(bid, index) {
            this.selectedIndex = index;
            this.$refs.ratingmodal.artist = bid.artist;
            this.$refs.ratingmodal.bidId = bid.id;
            this.$refs.ratingmodal.gigId = bid.gig.id;
            this.$refs.ratingmodal.toggleModal();
        },
        spliceArtist() {
            if (this.toRate.length == 1) {
                this.$router.push('/invoices')
            } else {
                this.toRate.splice(this.selectedIndex, 1);
            }
        },
        skipAllRatings() {
            
            this.isProcessing = true;
            let payload = [];

            payload.push(...this.toRate.map (e => ({
                artistId: e.artist.id,
                gigId: e.gig.id,
                bidId: e.id,
            })));

            this.skipAllArtistRatings(payload);
            this.$notify({text: 'Ratings submitted. Thank you for your feedback.',  duration: 5000});
            this.$router.push('/invoices')
        }
    }
}

</script>