<template>
    <div class="mt-1 input-group" v-bind:class="classes">
        <div class="input-content">   
            <label v-bind:for="name" class="block text-sm font-medium text-gray-700">
                {{label}}
            </label>    
            <textarea v-bind="$attrs"
            v-bind:id="name"
            v-on:input="handler"
            v-bind:value="value"
            v-bind:data-pristine-pattern-message="hint"
            v-bind:data-pristine-required-message="error" />
            <div class="input-error">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "input-textarea",
    inheritAttrs: false,
    props: {
			name: {
				type: String,
				required: true
			}, 
			label: {
				type: String,
				required: false
			},
			value: {
				type: String,
				required: false
			},
			classes: {
				type: String,
				required: false,
			},
			error: {
				type: String,
				required: false,
			},
			hint: {
				type: String,
				required: false,
			},
		},
        methods: {
        handler(event) {
            this.$emit('input',
                event.target.value
            )
        }
    }
}
</script>