<template>

    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">

                     <!-- Country -->
                    <div v-if="show_modal == 0">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center">
                            <h3 class="block font-bold text-gray-900" id="modal-title">Add location</h3> 
                            <p>Pick the country where your are located</p>

                            <div class="grid w-full gap-4"> 
                                <t-rich-select
                                    :close-on-select="false" ref="countries" v-model="setCountry"
                                    :options="sortedItem" text-attribute="name" value-attribute="id" 
                                    placeholder="Countries"  class="w-full"
                                ></t-rich-select> 
                            </div>
                        </div>
                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    <GPButton   @click.native="show_modal = 1;" 
												:color="'pink'"
												:disabled="!selections.country" 
												size="md"
											>Next</GPButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Region -->
                    <div  v-if="show_modal == 1">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center">
                            <h3 class="block font-bold text-gray-900" id="modal-title"> Add location - {{ countryChoice }}</h3> 
                            <p>Pick where in this country are located</p>
                            <div class="grid w-full gap-4"> 
                                <t-rich-select
                                    :close-on-select="false" ref="regions" v-model="setRegion"
                                    :options="sortedRegions" text-attribute="name" value-attribute="id" 
                                    placeholder="Regions"  class="w-full"
                                ></t-rich-select> 
                            </div>
                        </div>
                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    <GPButton 
												@click.native="show_modal = hasAreas" 
												:color="'pink'"
												:disabled="!selections.region" 
												size="md"
											>Next</GPButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Areas -->
                    <div v-if="show_modal == 2">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center ">
                        
                        <h3 class="block font-bold text-gray-900" id="modal-title"> 
                            Add location - {{ countryChoice }}
                        </h3> 
                        <p>Pick the area where you are located</p>
                        <div class="grid w-full gap-4"> 
                                <t-rich-select
                                    :close-on-select="false" ref="city" v-model="setArea"
                                    :options="this.areas" text-attribute="name" value-attribute="id" 
                                    placeholder="Areas"  class="w-full"
                                ></t-rich-select> 
                            </div>
                        </div>
                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    <div class="space-x-8">
                                         <GPButton
												@click.native="show_modal = 1;" 
												:color="'indigo'"
												size="md"
											>Back</GPButton>
                                         <GPButton 
												@click.native="show_modal = hasPlace" 
                                                :disabled="!selections.area" 
												:color="'pink'"
												size="md"
											>Next</GPButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Places -->
                    <div v-if="show_modal == 3">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center ">
                        
                        <h3 class="block font-bold text-gray-900" id="modal-title"> 
                            Add location - {{ countryChoice }} - Place
                        </h3> 
                        <p>Pick the specific location where you are based</p>
                        <div class="grid w-full gap-4"> 
                                <t-rich-select
                                    :close-on-select="false" ref="further" v-model="selections.place"
                                    :options="this.places" text-attribute="name" value-attribute="id" 
                                    placeholder="Places"  class="w-full"
                                ></t-rich-select> 
                            </div>
                        </div>
                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    <div class="space-x-8">
                                        <GPButton 
												@click.native="show_modal = 2;" 
												:color="'indigo'"
												size="md"
											>Back</GPButton>
                                         <GPButton 
												@click.native="show_modal = 4;" 
                                                :disabled="!selections.place" 
												:color="'pink'"
												size="md"
											>Confirm</GPButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Finish -->
                    <div v-if="show_modal == 4">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center ">
                            <h3 class="block font-bold text-gray-900" id="modal-title"> 
                                Double-check and confirm the venue location
                            </h3> 
                            <p>If you are happy with your choice, click 'Save' to continue.</p>
                        </div>
                        <div class="grid grid-cols-2 space-x-auto text-left my-8 gap-x-16 gap-y-8">
                            <div v-show="selections.country">
                                <p class="font-bold mb-2">Country</p>
                                <p class="">{{ countryChoice }}</p>
                            </div>

                            <div v-show="selections.region">
                                <p class="font-bold mb-2">Region</p>
                                <p class="">{{ regionChoice }}</p>
                            </div>

                            <div v-show="selections.area">
                                <p class="font-bold mb-2">Area</p>
                                <p class="">{{ areaChoice }}</p>
                            </div>

                            <div v-show="selections.place">
                                <p class="font-bold mb-2">Place</p>
                                <p class="">{{ placeChoice }}</p>
                            </div>
                        </div>
                    
                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    <div class="space-x-8">
                                        <GPButton 
												@click.native="show_modal = toPage" 
												:color="'indigo'"
												size="md"
											>Back</GPButton>
                                         <GPButton 
												@click.native="save()" 
												:color="'pink'"
												size="md"
											>Save</GPButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>        
        </div>
    </div>
</template>

<script>

import { apiComputed, apiMethods } from '@/state/helpers.js'
import { TRichSelect } from 'vue-tailwind/dist/components';
import GPButton from '../../components/ui/buttons/Button.vue'

    export default
    {
        name: "VenueLocation", 
        components:{
         TRichSelect,
         GPButton
        },
        data(){
            return {
                item: [],
                regionList: [],
                areas: [],
                places: [],
                show_modal: 1,
                modal_open: false, 
                setCountry: null,
                setRegion: null,
                setArea: null,
                selections:{
                    country: null,
                    region: null,
                    area: null,
                    place: null,
                },
            }
        },
        watch: {
            setCountry(val){
                this.selections.country = val;
                this.setRegion = null;
                this.setArea = null; 
                this.selections.place = null;
                if (val !== null) {
                    this.regionList = this.item.find((f) => f.id === val).locations;
                }
            },
            setRegion(val){
                this.selections.region = val;
                this.selections.area = null;
                if (val !== null) {
                    this.areas = this.regionList.find((f) => f.id == val).all_children;
                }
            },
            setArea(val){
                this.selections.area = val;
                this.selections.place = null; 
                if (val !== null) {
                    this.places = this.areas[this.areas.findIndex((f) => f.id == val )].all_children;
                }
            },
        },
        computed:{
            ...apiComputed, 
            sortedItem(){
                return this.item;
            },
            sortedRegions(){
                return this.regionList.slice().sort(function(a,b) {
                    return (a.name > b.name) ? 1 : -1;
                })
            },
            countryChoice(){
                return this.selections.country ? this.item.find((f) => { return f.id === parseInt(this.selections.country)}).name : null;
            },
            regionChoice(){
                return this.selections.region ? this.regionList.find((f) => { return f.id === parseInt(this.selections.region)}).name : null;
            },
            areaChoice(){
                return this.selections.area ? this.areas.find((f) => { return f.id === parseInt(this.selections.area)}).name : null;
            },
            placeChoice(){
                return this.selections.place ? this.places.find((f) => { return f.id === parseInt(this.selections.place)}).name : null;
            },
            toPage(){
                return this.selections.place === null ? 2 : 3;
            },
            hasAreas(){
                return this.areas.length === 0 ? 4 : 2;
            },
            hasPlace(){
                return this.places.length == 0 ? 4 : 3;
            },
        },
        mounted(){
            this.getGigLocations().then((resp) =>{
                this.item = JSON.parse(JSON.stringify(resp));
            })
        },
        methods: {
            ...apiMethods, 
            save(){

                let payload = {};

                if (this.selections.area === null){
                    payload = {
                        preview: JSON.parse(JSON.stringify(this.regionChoice)),
                        id: JSON.parse(JSON.stringify(this.selections.region))
                    }
                } else {
                    payload = {
                        preview: this.selections.place == null ? JSON.parse(JSON.stringify(this.areaChoice)) : JSON.parse(JSON.stringify(this.placeChoice)),
                        id: this.selections.place == null ? JSON.parse(JSON.stringify(this.selections.area)) : JSON.parse(JSON.stringify(this.selections.place))
                    }
                }

                this.$emit('saveLocation', payload);
                this.$emit('updateCountry', this.selections.country);
                this.toggleModal();
            },
            toggleModal() {
                this.modal_open = !this.modal_open;
                this.setCountry = null;
                this.setRegion = null;
                this.setArea = null;
                this.show_modal = 0;
            },
        }
    }

</script>