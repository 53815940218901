<template>
	<div 
		class="text-center items-center px-1 sm:px-3 py-0.5 rounded-lg sm:rounded-full text-xs sm:text-sm font-medium" 
		:class="status.class"
	>
		{{ displayText }}
	</div>
</template>

<script>
export default {
	name: "StatusBadge",
	props:{
		status: Object,
    text: {
      type: String,
      default: null
    }
	},
  computed: {
    displayText() {
      return this.text !== null ? this.text : this.status.name
    }
  }
}
</script>