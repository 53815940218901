<template>
  <PrivateVenuesOptInModal
          :show-modal="showPrivateVenuesModal"
          @showPrivateVenuesModal="value => showPrivateVenuesModal = value"
  />
</template>

<script>
import PrivateVenuesOptInModal from "@/components/PrivateVenueOptIn/PrivateVenuesOptInModal.vue";
export default {
    components: {
        PrivateVenuesOptInModal
    },
    data() {
        return {
            showPrivateVenuesModal: false
        }
    }

}
</script>