import client from "../../utils/client"
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    dictionaryArray: []
  },
  mutations: {
    SET_DICTIONARY(state, data) {
      console.log('Dictionary set to ', data);
      state.dictionaryArray = data;
    }
  },
  actions: {
    fetchDictionary({ commit }) {
      client.get('dictionary')
        .then((response) => {
          commit('SET_DICTIONARY', response.data.data);
        })
        .catch(err => {
          console.log('Error fetching dictionary:', err); // Add this line
        });
    }
  }
});

export default store;
