<template>

      <dl class="mt-6 space-y-6 divide-y divide-gray-200 col-span-full">
        <div class="">
          <dt class="text-lg  rounded-lg " :class="bg_class ? bg_class : 'bg-indigo-50'">
            <!-- Expand/collapse question button -->
            <button @click="toggle_in = !toggle_in" type="button" class="px-4 py-3 text-left w-full flex justify-between items-start text-gray-400" aria-controls="faq-0" aria-expanded="false">
              <span class="font-medium text-gray-900">
                {{heading}}
              </span>
              <span class="ml-6 h-7 flex items-center">
                <!--
                  Expand/collapse icon, toggle classes based on question open state.

                  Heroicon name: outline/chevron-down

                  Open: "-rotate-180", Closed: "rotate-0"
                -->
                <svg :class="toggle_in ? 'rotate-0' : '-rotate-90' " class=" h-6 w-6 transform" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </span>
            </button>
          </dt>
          <dd :class="open() + ' ' + (compact ? '' : 'grid-cols-full')"  class="mt-2  grid-cols-1 gap-y-2 grid-flow-row lg:gap-x-8 accordian" >              
                <slot name="contents">

                </slot>
          </dd>
        </div>
 
      </dl>

</template>


<script>
export default {
    name: "Accordian",
    props:{
        heading : String,
        toggle: Boolean,
        bg_class: String,
        compact: Boolean
    },
    data() {
        return {
            toggle_in: this.toggle
        }
    },
    methods:{
        
        open(){
            
            if (this.toggle_in){
                return "grid";
            }else{
                return "hidden";
            }
            
        }
    }
}
</script>