import client from "../../utils/client"



export const state = {
  artists: null,
}

export const mutations = {
  SET_ARTISTS(state, artists) {
    state.artists = artists
  },
}

export const getters = {
	artists: state => {
    return state.artists;
  },
}

export const actions = {
	storeArtists({ commit }, artists){
		commit('SET_ARTISTS', artists);
	},
  getArtists({ getters, dispatch }) {
		return new Promise((resolve) => {
			const artists = getters.artists
			if(artists){
				resolve(getters.artists)
			}else{
				dispatch('loadArtists')
				.then(() => {
					resolve(getters.artists);
				}).catch(err => {
					console.log(err)
				});
			}
		})
  },
	loadArtists({ getters, dispatch }){
		return new Promise((resolve) => {
			client
					.get(`/artists`)
					.then((response) => {
						dispatch('storeArtists', response.data.data)
						resolve(getters.artists)
					})
					.catch(err => {
						console.log(err)
					});
		})
	}
} 