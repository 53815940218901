<template> 
<div class="text-left mt-6 lg:rounded-lg lg:bg-gray-200 overflow-hidden shadow divide-y lg:divide-gray-200 grid grid-cols-1 lg:grid-cols-2 gap-px">
  
  <DashboardStep URL="/venues"
  :IsComplete="progress && progress.has_venue" BackgroundClass="bg-green-50" TextColorClass="text-green-700" 
  Title="Add your first venue">

    <template v-slot:icon>
        <OfficeBuildingIcon/>
    </template>
    
    <template v-slot:description>
        You can create as many venues as you like, which will also be displayed on our venues listings.
    </template>

  </DashboardStep>

  <DashboardStep URL="/gigs"  
  :IsComplete="progress && progress.has_gigs" BackgroundClass="bg-purple-50" TextColorClass="text-purple-700" 
  Title="Create your first gig">

    <template v-slot:icon>
           <TicketIcon/>
    </template>
    
    <template v-slot:description>
        You can create as many gigs as you'd like.  
        Once you're ready to publish, you will be able to set up your subscription based on the number of gigs you create per month. 
    </template>

  </DashboardStep>
  
  <DashboardStep URL="/gigtemplates" 
  :IsComplete="progress && progress.has_templates" BackgroundClass="bg-blue-50" TextColorClass="text-blue-700" 
  Title="Set up your templates">

    <template v-slot:icon>
         <TemplateIcon/> 
    </template>
    
    <template v-slot:description>
        Create your re-usable artist instructions to easily provide artists with what they need to perform.
    </template>

  </DashboardStep>
 
 
  <DashboardStep URL="/artist-directory"  
  :IsComplete="progress && progress.has_artists" BackgroundClass="bg-yellow-50" TextColorClass="text-yellow-700" 
  Title="Choose your artists">

    <template v-slot:icon>
          <MusicNoteIcon/>
    </template>
    
    <template v-slot:description>
      Once a gig is published, GigPig will source your artists automatically based on the type of gig, and their location.  
      Your selected artists will bid for the gig and you can choose based on your preferences.
    </template>

  </DashboardStep>
 
   
</div>

</template>

<script>
import DashboardStep from "./DashboardStep.vue";  
import { TemplateIcon, OfficeBuildingIcon, TicketIcon,
 MusicNoteIcon  } from "@vue-hero-icons/outline"   

export default {
    name: "CompleteSteps",
    components: { DashboardStep, OfficeBuildingIcon, TemplateIcon, MusicNoteIcon, TicketIcon },
    props:{
      progress: Object,
      complete: Boolean
    }
}
</script>