import AllInvoices from '../views/Invoices/Index.vue';
import MonthlyInvoices from '../views/MonthlyInvoices/Index.vue';
import AllArtistInvoices from '../views/ArtistProfile/Invoices/Index.vue';

export default (store) => [
	{
		path: "/invoices",
		name: "AllInvoices",
		meta: { authRequired: true },
		props: true,
		beforeEnter: (_, _2, next) => {
			next();
			// make sure userData is present
				store.dispatch('user/getUsersRoles')
					.then(() => {
						// check permission
						if(store.getters['user/hasBillingInvoicesPermission']){
							// do route stuff
							next();
						}else{
							next(false);
						}
					})
		},
		component: AllInvoices
	},  
	{
		path: "/monthly-invoices",
		name: "MonthlyInvoices",
		meta: { authRequired: true },  
		props: true,
		beforeEnter: (_, _2, next) => {
				// make sure userData is present
				store.dispatch('user/getUsersRoles')
					.then(() => {
						// check permission
						if(store.getters['user/hasBillingInvoicesPermission']){
							// do route stuff
							next();  
						}else{
							next(false);
						}
					})
		},
		component: MonthlyInvoices
	},
	{
		path: "/artist/monthly-invoices",
		name: "MonthlyInvoices",
		meta: { authRequired: true },
		props: true,
		beforeEnter: (_, _2, next) => {
			// make sure userData is present
			store.dispatch('user/getUsersRoles')
				.then(() => {
					// check permission
					if(store.getters['user/hasBillingInvoicesPermission']){
						// do route stuff
						next();
					}else{
						next(false);
					}
				})
		},
		component: MonthlyInvoices
	},
	{
		path: "/artist/invoices",
		name: "AllArtistInvoices",
		meta: { authRequired: true },
		props: true,
		component: AllArtistInvoices
	}, 
]
