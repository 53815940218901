<template>
    <div class="">
        <div class="flex space-x-2 items-center">
            <CalendarIcon class="text-gp_pink-default-500"/>
            <span class="font-black">Filter by date</span>
        </div>
        <div class="flex-row lg:flex lg:space-x-9 space-y-3 lg:space-y-0 mt-4">
            <div>
            <div class="col-span-3 lg:col-span-1">
                <input ref="start" placeholder="Start date" type="date" v-model="dateStart" @change="changeDate" class="datepick cursor-pointer h-full w-80 rounded-lg border-gray-300"/>
            </div>
            <p class="text-xs font-thin ml-1 mt-2">The start date of the gigs to display</p>
            </div>
            <div class="hidden lg:block">
            <ArrowCircleRightIcon class="text-gp_pink-default-400 mt-2" />
            </div>
            <div>
            <div class="col-span-3 lg:col-span-1">
                <input ref="end" type="date" v-model="dateEnd" @change="changeDate" class="datepick h-full w-80 cursor-pointer rounded-lg border-gray-300"/>
            </div>
            <p class="text-xs font-thin ml-1 mt-2">The end date of the gigs to display</p>
            </div>
        </div>
    </div>
</template>

<script>

import { DateTime } from 'luxon';
import { CalendarIcon, ArrowCircleRightIcon  } from "@vue-hero-icons/outline";

    export default {
        name: 'DateFilter',
        data() {
            return {
                dateStart: '',
                dateEnd: '',
                loaded: false,
            }
        },
        components: { CalendarIcon, ArrowCircleRightIcon },
        props: {
            "dateStartProp": {
                type: String,
                required: false,
                default: () => {
                    return DateTime.now().toISODate();
                }
            },
            "dateEndProp":{
                type: String,
                required: false,
                default: () => {
                    return DateTime.now().plus({years: 2}).toISODate();
                }
            },
        },
        mounted() {
            this.dateStart = this.dateStartProp;
            this.dateEnd = this.dateEndProp;
            this.loaded = true;
        },
        methods: {
            changeDate() {
                this.$emit('dateFilterChange', {dateFilterStart: this.dateStart, dateFilterEnd: this.dateEnd});
            }
        },
        watch: {
            dateStartProp: function(){
                this.dateStart = this.dateStartProp;
            },
            dateEndProp: function(){
                this.dateEnd = this.dateEndProp;
            }
        },
        emits: ['dateFilterChange']
    }
</script>