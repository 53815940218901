<template>
	<button 
		@click="$emit('accept')"
		class="inline-flex items-center border text-base font-medium rounded-md shadow-sm justify-center text-center transform-all ease-in duration-100"
		:class="classes"
		:disabled="disabled"
		:aria-disabled="disabled"
	>
		<span v-if="loading" class="flex justify-center items-center pr-2">
			<Spinner />
		</span>
		<span v-else-if="$slots.prefix" class="flex justify-center items-center pr-2">
			<slot name="prefix"></slot>
		</span>

		<slot name="default"></slot>

		<span v-if="$slots.suffix" class="flex justify-center items-center pr-2">
			<slot name="suffix"></slot>
		</span>
	</button>
</template>

<script>

import Spinner from '../spinner/Spinner.vue';

export default {
	components: {
		Spinner,
	},
	props:{
		loading:{
			type:Boolean,
			require:false,
			default:false
		},
		disabled:{
			type:Boolean,
			require:false,
			default:false
		},
		color:{
			type:String,
			require:false,
			default:"pink"
		},
		size:{
			type:String,
			require:false,
			default:"md"
		},
		height:{
			type:String,
			require:false,
			default:"auto"
		},
	},
	watch:{
		color(updatedColor){
			this.parseColour(updatedColor);
		},
		size(updatedSize){
			this.parseSize(updatedSize);
		}
	},
	data(){
		return {
			bgColour:"bg-gp_pink-default-500",
			bgColourDisabled:"bg-gp_pink-default-200",
			bgHover:"bg-pink-700",
			textColour:"text-white",
			textColourDisabled:"text-white",
			textHover:"text-white",
			paddingX:"4",
			paddingY:"1",
			borderColour:"transparent",
			borderColourHover:"transparent",
			borderColourDisabled:"transparent"
		}
	},
	computed:{
		classes() {
			return this.disabled || this.isLoading 
				? `px-${this.paddingX} py-${this.paddingY} ${this.bgColourDisabled} hover:${this.bgColourDisabled} ${this.textColourDisabled} hover:${this.textColourDisabled} border-${this.bgColourDisabled} cursor-not-allowed h-${this.height}`
				: `px-${this.paddingX} py-${this.paddingY} ${this.bgColour} hover:${this.bgHover} ${this.textColour} hover:${this.textHover} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${this.color} border-${this.borderColour} hover:border-${this.borderColourHover} h-${this.height}`;
		}
	},
	methods:{
		parseColour(color){
			// reset to solid colour
			this.borderColour = "transparent";
			this.borderColourHover = "transparent";
			this.borderColourDisabled = "transparent";
			switch(color.toLowerCase()){
				case "gray":
				case "grey":
					this.bgColour = "bg-gray-200";
					this.bgColourDisabled = "bg-gray-50";
					this.bgHover = "bg-gray-400";
					this.textColour = "text-black";
					this.textColourDisabled = "text-gray-600";
					this.textHover = "text-black";
					break;
				case "indigo":
					this.bgColour = "bg-indigo-600";
					this.bgColourDisabled = "bg-indigo-200";
					this.bgHover = "bg-indigo-700";
					this.textColour = "text-white";
					this.textColourDisabled = "text-white";
					this.textHover = "text-white";
					break;
				case "outline-gray":
				case "outline-grey":
					this.bgColour = "bg-transparent";
					this.bgColourDisabled = "bg-gray-50";
					this.bgHover = "bg-gray-100";
					this.textColour = "text-gray-600";
					this.textColourDisabled = "text-gray-300";
					this.textHover = "text-gray-700";
					//
					this.borderColour = "gray-300";
					this.borderColourHover = "gray-100";
					this.borderColourDisabled = "gray-100";
					break;
				case "pink":
				default:
					this.bgColour = "bg-gp_pink-default-500";
					this.bgColourDisabled = "bg-gp_pink-default-200";
					this.bgHover = "bg-pink-200";
					this.textColour = "text-white";
					this.textColourDisabled = "text-white";
					this.textHover = "text-white";
					break;
				case "black":
					this.bgColour = "bg-black";
					this.bgColourDisabled = "bg-indigo-200";
					this.bgHover = "bg-indigo-700";
					this.textColour = "text-white";
					this.textColourDisabled = "text-white";
					this.textHover = "text-white";	
					break;
			}
		},
		parseSize(size){
			switch(size.toLowerCase()){
				case "xs":
				case "extrasmall":
					this.paddingX = "2";
					this.paddingY = "0";
					break;
				case "sm":
				case "small":
					this.paddingX = "3";
					this.paddingY = "1";
					break;
				case "lg":
				case "large":
					this.paddingX = "4";
					this.paddingY = "3";
					break;
				case "xl":
				case "extralarge":
					this.paddingX = "4";
					this.paddingY = "4";
					break;
				case "md":
				case "medium":
				default:
					this.paddingX = "4";
					this.paddingY = "2";
					break;
			}
		}
		
	},
	emit: ['accept'],
	mounted(){
		this.parseColour(this.color);
		this.parseSize(this.size);
	}
}
</script>
 <!-- background-color: rgba(129, 140, 248, var(--tw-bg-opacity)); -->
<style lang="postcss" scoped>
	.pink:hover {
		@apply bg-indigo-400;
	}
	.gray:hover {
		@apply bg-gray-400;
	}
</style>