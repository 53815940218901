<template>

    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">

                    <!-- Country -->
                    <div v-if="show_modal == 0">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center">
                            <h3 class="block font-bold text-gray-900" id="modal-title">Select Country</h3>
                            <p v-show="sortedItem.length !== 0">Pick the country where you would like to perform</p>

                            <div v-show="sortedItem.length !== 0" class="grid w-full gap-4"> 
                                <t-rich-select
                                    :close-on-select="false" ref="countries" v-model="setCountry"
                                    :options="sortedItem" text-attribute="name" value-attribute="id" 
                                    placeholder="Countries"  class="w-full"
                                ></t-rich-select> 
                            </div>
                            <div v-show="sortedItem.length === 0" class=""> 
                                <p class="text-gray-700">You're all out of gig locations! Check back later for more updates.</p>
                            </div>

                        </div>
                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    <GPButton   @click.native="show_modal = 1;" 
												:color="'pink'"
												:disabled="!selections.country" 
												size="md"
											>Next</GPButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Region -->
                    <div v-if="show_modal == 1">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center">
                            <h3 class="block font-bold text-gray-900" id="modal-title"> Add locations - {{ countryChoice }}</h3> 
                            <p>Pick where in this country you would like to perform</p>
                            <div class="w-3/4 flex my-2">
                                <span class="mr-2">I want to gig nationwide</span>
                                <t-toggle v-model="setAllLocations" unchecked />
                            </div>
                            <div v-show="!setAllLocations" class="grid w-full gap-4"> 
                                <t-rich-select
                                    :close-on-select="false" ref="regions" v-model="setRegion"
                                    :options="sortedRegions" text-attribute="name" value-attribute="id" 
                                    placeholder="Regions"  class="w-full"
                                ></t-rich-select> 
                            </div>
                        </div>
                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    
                                    <div class="space-x-8">
                                        <GPButton 
                                                        @click.native="show_modal = 0;" 
                                                        :color="'indigo'"
                                                        size="md"
                                                    >Back</GPButton>
                                        <GPButton   v-show="setAllLocations"
                                                    @click.native="addAllLocations()" 
                                                    :disabled="this.regionList.length === 0" 
                                                    :color="'pink'"
                                                    size="md"
                                                >Confirm</GPButton>
                                        <GPButton   v-show="!setAllLocations"
                                                    @click.native="show_modal = hasAreas" 
                                                    :color="'pink'"
                                                    :disabled="!selections.region" 
                                                    size="md"
                                                >Next</GPButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Areas -->
                    <div v-if="show_modal == 2">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center ">
                        
                        <h3 class="block font-bold text-gray-900" id="modal-title"> 
                            Add locations - {{ regionChoice }}
                        </h3> 
                        <p>Select one or more areas in this region where you would like to perform</p>
                        <div class="w-3/4 flex my-2">
                            <span class="mr-2">Select all areas</span>
                            <t-toggle v-model="setAllAreas" unchecked />
                        </div>
                        <div class="grid w-full gap-4"> 
                                <t-rich-select
                                    multiple
                                    :close-on-select="false" ref="city" v-model="setAreas"
                                    :options="this.areas" text-attribute="name" value-attribute="id" 
                                    placeholder="Areas"  class="w-full"
                                ></t-rich-select> 
                            </div>
                        </div>
                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    <div class="space-x-8">
                                         <GPButton
												@click.native="show_modal = 1;" 
												:color="'indigo'"
												size="md"
											>Back</GPButton>
                                         <GPButton 
												@click.native="show_modal = hasPlaces" 
												:color="'pink'"
												:disabled="this.selections.areas.length === 0" 
												size="md"
											>Next</GPButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Places -->
                    <div v-if="show_modal == 3">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center ">
                        
                        <h3 class="block font-bold text-gray-900" id="modal-title"> 
                            Add locations - {{ regionChoice }} - Places
                        </h3> 
                        <p>Would you like to perform in these specific areas?</p>
                        <div class="w-3/4 flex my-2">
                            <span class="mr-2">Select all locations</span>
                            <t-toggle v-model="setAllPlaces" unchecked />
                        </div>
                        <div class="grid w-full gap-4"> 
                                <t-rich-select
                                    multiple
                                    :close-on-select="false" ref="further" v-model="selections.places"
                                    :options="this.places" text-attribute="name" value-attribute="id" 
                                    placeholder="Places"  class="w-full"
                                ></t-rich-select> 
                            </div>
                        </div>
                        <div class="text-left mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    <div class="space-x-8">
                                        <GPButton 
												@click.native="show_modal = 2;" 
												:color="'indigo'"
												size="md"
											>Back</GPButton>
                                         <GPButton 
												@click.native="preview()" 
												:color="'pink'"
                                                :disabled="this.selections.places.length === 0" 
												size="md"
											>Confirm</GPButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Finish -->
                    <div v-if="show_modal == 4">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center ">
                            <h3 class="block font-bold text-gray-900" id="modal-title"> 
                                Double-check the locations where you would like to receive gigs
                            </h3> 
                            <p>If you are happy with your choices, click 'Save' to continue.</p>
                        </div>
                        <div class="grid grid-cols-2 md:grid-cols-3 space-x-auto text-left my-8 gap-x-16 gap-y-8">

                            <div v-show="selections.country">
                                <p class="font-bold mb-2">Country</p>
                                <p class="text-sm">{{ countryChoice }}</p>
                            </div>

                            <div v-show="selections.region">
                                <p class="font-bold mb-2">Region</p>
                                <p class="text-sm">{{ regionChoice }}</p>
                            </div>

                            <div v-show="selections.areas.length > 0">
                                <p class="font-bold mb-2">Areas</p>
                                <div v-for="(area, index) in areas_preview"  :key="'areas' + index">
                                    <p class="text-sm">{{ area }}</p>
                                </div>
                            </div>
                        </div>
                        <p v-show="selections.places.length > 0" class="font-bold mb-2">Locations</p>
                        <div class="grid grid-cols-2 md:grid-cols-3 gap-x-16">
                            <div v-show="selections.places.length > 0" v-for="(place, index) in places_preview"  :key="'places' + index" class="">
                                <p class="text-sm">{{ place }}</p>
                            </div>
                        </div>
                    
                        <div class="text-left mt-8">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="toggleModal()" 
												:color="'grey'"
												size="md"
											>Close</GPButton>
                                    <div class="space-x-8">
                                        <GPButton 
												@click.native="show_modal = toPage" 
												:color="'indigo'"
												size="md"
											>Back</GPButton>
                                         <GPButton 
												@click.native="save()" 
												:color="'pink'"
												size="md"
											>Save</GPButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>        
        </div>
    </div>
</template>

<script>

import { apiComputed, apiMethods } from '@/state/helpers.js'
import { TRichSelect, TToggle } from 'vue-tailwind/dist/components';
import GPButton from '../../components/ui/buttons/Button.vue'

    export default
    {
        name: "ArtistGigLocations", 
        components:{
         TRichSelect,
         TToggle,
         GPButton
        },
        data(){
            return {
                isUpdate: false,
                itemCopy: [],
                item: [],
                regionList: [],
                areas: [],
                places: [],
                areas_preview: [],
                places_preview: [],
                show_modal: 1,
                modal_open: false,
                setCountry: null, 
                setRegion: null,
                setAllLocations: false,
                setAllAreas: false,
                setAllPlaces: false,
                setAreas: null,
                setPlaces: null,
                selections:{
                    country: '',
                    region: '',
                    areas: [],
                    places: [],
                    ids: []
                }
            }
        },
        watch: {
            setCountry(val){
                this.selections.country = val;
                this.setRegion = null;
                this.setAreas = [];
                this.setPlaces = [];

                if (val !== null) {
                    this.regionList = this.item.find((f) => f.id === val).locations;
                }
            },
          selections: {
            deep: true,
            handler(val) {
              this.setRegion = val.region
            }
          },
            setRegion(val){
                this.selections.region = val;
                this.setPlaces = [];
                if (val !== null) {
                  let regionList = this.regionList.find((f) => f.id == val);
                  if (regionList) {
                    this.areas = regionList.all_children
                  }
                }
            },
            setAllAreas(val){
                if (val === true) {
                    let toAdd = [];
                    this.areas.forEach((c) => { toAdd.push(c.id); })
                    this.setAreas = toAdd;
                } else {
                    this.setAreas = []; 
                }
            },
            setAreas(val){
                this.places.splice(0);
                this.areas_preview.splice(0);
                this.selections.areas = val;

                if (val !== null) {
                    this.selections.areas.forEach((city) => {
                        this.places = [...this.places, ...this.areas.find((f) => f.id == city).all_children]
                        this.areas_preview.push(this.areas.find((f) => {
                            return f.id == city;
                        }).name)
                    });

                    if (this.isUpdate && this.places){

                        let townUpdates = [];

                        this.places.forEach((t) => {
                            if(this.locationIDs.includes(t.id)){
                                townUpdates.push(t.id);
                            }
                        })
                        this.setPlaces = townUpdates;
                    }

                    /* Checks if the parent of a place (area) has been removed, if so then remove the 
                    selected places attached to that parent... Saves nulling the selected places 
                    obj and making the user select all their places again :) */

                    if(this.selections.places.length > 0){
                        this.areas.forEach((c) => {
                            if (!this.selections.areas.includes(c.id)) {
                                c.all_children.forEach((child) => {
                                    if (this.selections.places.includes(child.id)) {
                                        this.selections.places = this.selections.places.filter((f) => { return f !== child.id });
                                    }
                                })
                            }
                        })

                        this.setPlaces = this.selections.places;
                    } 
                }
            },
            setAllPlaces(val){
                if (val === true) {
                    let toAdd = [];
                    this.places.forEach((c) => { toAdd.push(c.id); })
                    this.setPlaces = toAdd;
                } else {
                    this.setPlaces = []; 
                }
            },
            setPlaces(val){
                this.selections.places = val;
            },
          modal_open(value) {
            document.body.style.overflow = value === true ? 'hidden' : 'auto';
          }
        },
        computed:{
            ...apiComputed, 
            sortedItem(){
                return this.item.slice().filter((f) => { return f.locations.length > 0})
            },
            sortedRegions(){
                return this.regionList.slice().sort(function(a,b) {
                    return (a.name > b.name) ? 1 : -1;
                })
            },
            countryChoice(){

              if (this.selections.country) {
                const country = this.item.find((f) => { return f.id === parseInt(this.selections.country)});
                if (country) {
                  return country.name;
                }
              }

              return null
            },
            regionChoice(){

              if (this.selections.region) {
                const region = this.regionList.find((f) => { return f.id === parseInt(this.selections.region)});
                if (region) {
                  return region.name;
                }
              }

              return null
            },
            toPage(){
              if (this.show_modal === 4) {
                if (!this.selections.places.length && !this.selections.areas.length) {
                  return 1;
                }
              }
                return this.selections.places.length == 0 ? 2 : 3;
            },
            hasAreas(){
                return this.areas.length === 0 ? 4 : 2;
            },
            hasPlaces(){
                return this.places.length === 0 ? 4 : 3;
            },
        },
        methods: {
            ...apiMethods, 
            addAllLocations(){
                
                this.regionList.forEach((i) => {

                    let areas = [];
                    let areas_names = [];
                    let places = [];
                    let places_names = [];

                    i.all_children.forEach((c) => {
                        areas.push(c.id);
                        areas_names.push(c.name);

                        c.all_children.forEach((y) => {
                            places.push(y.id);
                            places_names.push(y.name);
                        })
                    })

                    this.selections.ids.splice(0);
                    this.selections.ids = places.concat(areas);
                    this.selections.ids.unshift(i.id);

                    let payload = {
                        country_id: JSON.parse(JSON.stringify(this.selections.country)),
                        country_name: JSON.parse(JSON.stringify(this.countryChoice)),
                        region: JSON.parse(JSON.stringify(i.name)),
                        areas: JSON.parse(JSON.stringify(areas_names)),
                        places: JSON.parse(JSON.stringify(places_names)),
                        ids: JSON.parse(JSON.stringify(this.selections.ids))
                    }

                    this.isUpdate === true ? this.$emit('onUpdatedLocations', payload) : this.$emit('onPickedLocations', payload);
                })

                let index = this.item.findIndex((f) => f.id === this.selections.country);
                this.item[index].locations = [];
                this.toggleModal();
            },
            preview(){
             
                this.places_preview.splice(0);

                this.selections.places.forEach((town) => {
                    this.places_preview.push(this.places.find((f) => {
                        return f.id == town;
                    }).name)
                });

                this.areas_preview.sort();
                this.places_preview.sort();
                this.show_modal = 4;
            },
            save(){
                this.selections.ids.splice(0);
                this.selections.ids = this.selections.places.concat(this.selections.areas);
                this.selections.ids.unshift(this.selections.region);

                let payload = {
                    country_id: JSON.parse(JSON.stringify(this.selections.country)),
                    country_name: JSON.parse(JSON.stringify(this.countryChoice)),
                    region: JSON.parse(JSON.stringify(this.regionChoice)),
                    areas: JSON.parse(JSON.stringify(this.areas_preview)),
                    places: JSON.parse(JSON.stringify(this.places_preview)),
                    ids: JSON.parse(JSON.stringify(this.selections.ids))
                }

                this.isUpdate === true ? this.$emit('onUpdatedLocations', payload) : this.$emit('onPickedLocations', payload);
                this.spliceRegion(this.selections.region, this.selections.country);
                this.toggleModal();
            },
            addRegionBackToList($regionID, $countryID){
                let index = this.itemCopy.findIndex((f) => f.id === $countryID);

                const location = this.itemCopy[index].locations.find((f) => { return f.id === $regionID });

                if (this.item[index].locations.map(l => l.id).indexOf(location.id) === -1) {
                  this.item[index].locations.push(location);
                }
            },
            spliceRegion($regionID, $countryID){
                let index = this.item.findIndex((f) => f.id === $countryID);
                this.item[index].locations.splice(this.item[index].locations.findIndex((f) => f.id === $regionID), 1);
            },
            updateLocation($location, $countryID){
                this.isUpdate = true;
                this.locationIDs = JSON.parse(JSON.stringify($location));

                this.selections.country = $countryID;
                this.selections.region = this.locationIDs[0];

                let index = this.item.findIndex((f) => f.id === this.selections.country);
                this.areas = this.item[index].locations.find((f) => f.id === this.selections.region).all_children;
                let cityUpdates = [];

                this.areas.forEach((c) => {
                    if(this.locationIDs.includes(c.id)){
                       cityUpdates.push(c.id);
                    }
                })

                this.setAreas = cityUpdates;

                this.regionList = this.item.find((f) => f.id === $countryID).locations;

                this.show_modal = 1;
                this.modal_open = !this.modal_open; 
            },
            toggleModal() {
                this.modal_open = !this.modal_open;
                this.setCountry = null;
                this.setRegion = null;

                this.places_preview.splice(0);

                this.setAllLocations = false;
                this.setAllAreas = false;
                this.setAllPlaces = false;

                this.show_modal = 0;
                this.isUpdate = false;
            },
        }
    }

</script>