export default function formatStringLinebreaks(string) {

    if (!string)
    return [];

    let stringArr = string.split('\\r\\n');

    if (stringArr.length == 1)
        stringArr = string.split('\r\n');
    
    return stringArr;
}
