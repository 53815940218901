import client from "../../utils/client"

export const state = {
  regions: null,
}

export const mutations = {
  SET_REGIONS(state, regions) {
    state.regions = regions
  }
}

export const getters = {
	all: state => {
    return state.regions;
  },
}

export const actions = {
  getRegions({ getters, commit }) {
		return new Promise((resolve) => {
			if(getters.all){
				resolve(getters.all)
			}else{
				client
					.get('regions')
					.then((response) => {
						commit('SET_REGIONS', response.data.data);
						resolve(getters.all)
					})
					.catch(err => {
						console.log(err)
					});
			}
		})
  },
	requestRegions({ commit }) {
    return client.get('regions')
      .then((response) => {
        commit('SET_REGIONS', response.data.data);
      })
			.catch(err => {
				console.log(err)
			})
  }
} 