

import fullDate from  '@/utils/format-date-full.js'; 
import { DateTime } from 'luxon';

export default function formatDate(dateTimeString, format) {

    if (dateTimeString)
    {   
        let dateString;

        if (typeof(dateTimeString) == 'string')
        { 

            dateString = dateTimeString.replace(/-/g, '/').replace('T', ' ').replace('.000000Z', '.000Z');
            dateString = new DateTime(dateString).toUTC().toFormat(format);
        }
        else
        {
            dateString = DateTime.fromJSDate(dateTimeString); 
            return dateString.toUTC().toFormat(format);
        }


        try{ 
            return fullDate(dateTimeString);
        }
        catch($ex){ 
            return fullDate(dateTimeString);
        }
    }
}