<template>  
    <div v-if="viewModal" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
		<div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
			<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
			<transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">     
                <div class="inline-block align-bottom bg-white rounded-sm p-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-1">                      
                    <!-- Header -->
                    <div class="mt-4 px-6">
                        <div class="text-xl font-medium mb-2">Do you wish to delete the following gig template?</div>  
                    </div>
                    <!-- Body --> 
                    <div class="overflow-auto max-h-72 px-6">
                        <div class="space-y-4 mt-8 mb-8">
                            <p v-if="this.item && this.item.name"> Name: {{ this.item.name }} </p>
                            <p v-if="this.item && this.item.internal_desc"> Internal description: {{ this.item.internal_desc }}</p>
                        </div>
                    </div>
                    <!-- Footer -->
                    <footer class="flex flex-col sm:flex-row w-full justify-between bg-gray-50">
                        <div class="px-2 pt-3 pb-0 sm:pb-3 sm:px-3 flex flex-col sm:flex sm:flex-row-reverse space-x-4">
                            <Button @click.native="viewModal = false;" :disabled="btnDisabled" color="gray">Close</Button>
                        </div>
                        <div class="px-2 py-3 sm:px-3 flex space-x-0 sm:space-x-4 space-y-2 sm:space-y-0 flex-col sm:flex-row">
                            <Button v-if="this.item"
                                    @click.native="deleteTemplate()"
                                    :disabled="btnDisabled"
                                    :loading="btnDisabled"
                                    color="black"
                                    ><TrashIcon class="mr-2"/> Delete gig</Button>
                        </div>
                    </footer>
				</div>
            </transition>
		</div>
	</div>
</template>

<script> 

import { apiComputed, apiMethods } from '@/state/helpers';
import Button from '../../components/ui/buttons/Button.vue';
import { TrashIcon } from "@vue-hero-icons/outline";

export default {
    name: "DeleteGigTemplate",
    components: { Button, TrashIcon },
    data(){
        return {
            item: this.templateItem,
            viewModal: this.showModal,
            btn: false,
        }
    },
    props: {
        templateItem: Object,
        showModal: Boolean,
    },
    computed:{
        ...apiComputed,
        btnDisabled(){
            return this.btn;
        },
    },
    methods:{
        ...apiMethods,
        deleteTemplate(){
            this.btn = true;
            this.deleteGigTemplate(this.item)
                .then(() => {
                this.$notify("Gig template successfully deleted");
                this.btn = false;
                this.viewModal = false;
                this.$router.push('/gigtemplates');
            });
        }

    }
}
</script>
