<template>
    <div>         


        <InlineNotification Heading="Confirm your gig" class="ml-4" >
            <template slot="body">
            <p>These are the gigs that venues want to book you for. Just hit the ‘thumbs up’ to confirm you can still play the gig.</p>
            </template> 
        </InlineNotification>  
        <OfferByDate ref="offerByDate"
					noItemsText="No gigs to confirm for now" :unfilteredBids="filteredBids">
        </OfferByDate>
 
    </div>
</template>


<script type="text/javascript">
import NProgress from 'nprogress'
import OfferByDate from '../../../components/gigs/OfferByDate.vue'

import { canBidBeAccepted } from '@/state/models/bidStatus'
import InlineNotification from '../../../components/notifications/inline.vue';
import { 
} from 'vue-tailwind/dist/components' 
export default {
  name: 'ArtistBidIndex',
  components:
  {
    InlineNotification,
    OfferByDate
}, 
	data(){
		return{
			artistbids:null,
			filteredBids:[],
			isLoading:false,
		}
	},
	methods:{
		parseBids(){
			this.filteredBids = this.artistbids.filter((ab) => {
        return canBidBeAccepted(ab.status, ab.gig.status) && ab.date_bid && ab.notified_venue_ok_to_artist && ab.notified_artist_final_ok == null;
			})
		},
	},
	mounted(){
		this.isLoading = true;
		NProgress.start(); 
			// Artist Bids
			this.$store.dispatch('artistBids/getArtistBids')
			.then((response) => {
				this.artistbids = response;
				this.parseBids();
			})
		.finally(() => {
			this.isLoading = false;
			this.$refs.offerByDate.loadData();
			NProgress.done(); 
		});
	}
};

</script>