
<template>
  <div class="line-chart relative">
    <LineChart
        :chart-options="chartOptions"
        :chart-data="chartData"
    />
    <div ref="chartDetailsContainer" v-show="showChartDetails" class="absolute bg-white shadow rounded-2xl p-4" :style="chartDetailsDialogStyle" @click="closeChartDetails">
      <button class="absolute bg-pink-500 rounded-full p-3 text-white" style="top: -10px; right: -10px;line-height: 8px;" @click="closeChartDetails">&times;</button>
      <div ref="chartDetails" class="chart-details">
        <ActualDetails v-if="chartDetailsData.actual.show" :data="chartDetailsData.actual.data" :useFixedClasses="chartDetailsClasses.useFixedClassed" :customClasses="chartDetailsClasses.customClasses"></ActualDetails>
        <PotentialDetails v-if="chartDetailsData.potential.show" :data="chartDetailsData.potential.data" :useFixedClasses="chartDetailsClasses.useFixedClassed" :customClasses="chartDetailsClasses.customClasses"></PotentialDetails>
        <CombinedDetails v-if="chartDetailsData.combined.show" :data="chartDetailsData.combined.data" :useFixedClasses="chartDetailsClasses.useFixedClassed" :customClasses="chartDetailsClasses.customClasses"></CombinedDetails>
      </div>
    </div>

    <template v-show="0">
      <div ref="chartDetailsTooltip" class="chart-details">
        <ActualDetails v-if="chartDetailsData.actual.show" :data="chartDetailsData.actual.data" :useFixedClasses="chartDetailsClasses.useFixedClassed" :customClasses="chartDetailsClasses.customClasses"></ActualDetails>
        <PotentialDetails v-if="chartDetailsData.potential.show" :data="chartDetailsData.potential.data" :useFixedClasses="chartDetailsClasses.useFixedClassed" :customClasses="chartDetailsClasses.customClasses"></PotentialDetails>
        <CombinedDetails v-if="chartDetailsData.combined.show" :data="chartDetailsData.combined.data" :useFixedClasses="chartDetailsClasses.useFixedClassed" :customClasses="chartDetailsClasses.customClasses"></CombinedDetails>
      </div>
    </template>

  </div>
</template>

<script type="text/javascript">
import ActualDetails from "./ChartElements/actual-details.vue";
import PotentialDetails from "./ChartElements/potential-details.vue";
import CombinedDetails from "./ChartElements/combined-details.vue";
import { Line as LineChart } from 'vue-chartjs/legacy';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js';

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)

export default {
  name: 'AnalyticsLineChart',
  props: {
    lineData: {}
  },
  data: function(){
    return {
      showChartDetails: false,
      chartDetailsClasses: {
        useFixedClassed: false,
        customClasses: "flex items-center relative px-5 pb-1"
      },
      chartDetailsData: {
        actual: {
          show: false,
          data: null
        },
        potential: {
          show: false,
          data: null
        },
        combined: {
          show: false,
          data: null
        }
      },
      chartDetailsStyle: {
        minWidth: '30%',
        minHeight: '80px',
        top: '10%',
        left: '35%',
      },
      chartOptions: {
        elements: {
          point: {
            hoverRadius: 8
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
            external: this.externalTooltipHandler
          }
        },
        interaction:{
          mode: 'nearest'
        },
        parsing: {
          xAxisKey: 'axis.x',
          yAxisKey: 'axis.y'
        },
        onClick: this.handleChartClick,
      }
    }
  },
  components: {PotentialDetails, ActualDetails, CombinedDetails, LineChart},
  computed: {
    chartData: function(){
      let labels = this.lineData.labels;
      let data = this.lineData.data;
      return {
        labels: labels,
        datasets: this.transformData(data),
      };
    },
    chartDetailsDialogStyle: function(){
      return this.chartDetailsStyle;
    },
  },
  methods: {
    transformData: function(originalData){
      let formattedPotentialData = [];
      let formattedActualData = [];
      let formattedCombinedData = [];
      originalData.forEach((item) => {
        // Extract necessary values
        const potential = item.potential;
        const actual_spends = item.actual_spends;

        let actual_record = {axis: {x: item.label, y: null}, fees: {value: 0, label: "Artist Fees"},};
        let potential_record = {axis: {x: item.label, y: null}};
        let combined_record = {axis: {x: item.label, y: null}};
        // if is past (gig->start < now) then actual
        // if( item.isPast || item.isPassing){
          actual_record = {
            axis: {x: item.label, y: actual_spends},
            type: 'actual',
            fees: {value: actual_spends, label: "Artist Fees"},
            gigs: {value: item.total_gigs, label: "Total Number of Gigs"},
            label: item.label,
          };
        // }
        // else potential and combined
        if( !item.isPast || item.isPassing ){
          potential_record = {
            axis: {x: item.label, y: potential.average},
            type: 'potential',
            fees: {value: potential.average, label: "Potential Artist Fees"},
            potential: {
              min: {value: potential.min, label: 'Lowest Potential Fees'},
              max: {value: potential.max, label: 'Highest Potential Fees'}
            },
            gigs: {value: potential.total_gigs, label: "Total Number of Gigs"},
            label: item.label,
          };
          combined_record = {
            axis: {x: item.label, y: (item.total_combined_spends)},
            type: 'combined',
            fees: {value: (potential.average + actual_spends), label: "Actual And Potential Artist Fees"},
            combined: {
              min: {value: (actual_spends + potential_record.potential.min.value), label: "Min combined artist fees"},
              max: {value: (actual_spends + potential_record.potential.max.value), label: "Max combined artist fees"},
              // actual: actual_record, potential: potential_record
            },
            gigs: {value: (item.total_gigs + potential.total_gigs), label: "Total Number of Gigs"},
            label: item.label,
          };
        }

        formattedActualData.push(actual_record);
        formattedPotentialData.push(potential_record);
        formattedCombinedData.push(combined_record);
      });

      return [
        {
          label: 'Potential',
          backgroundColor: '#081331',
          data: formattedPotentialData,
          fill: false,
          borderColor: '#081331',
          tension: 0.3
        },
        {
          label: 'Actual',
          backgroundColor: '#C9A859',
          data: formattedActualData,
          fill: false,
          borderColor: '#C9A859',
          tension: 0.3,
        },
        {
          label: 'Combined',
          backgroundColor: '#4A82D7',
          data: formattedCombinedData,
          fill: false,
          borderColor: '#4A82D7',
          tension: 0.3,
        }
      ];
    },
    getOrCreateTooltip: function(chart){
      let tooltipEl = chart.canvas.parentNode.querySelector('div');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = '#ffffff';
        tooltipEl.style.borderRadius = '8px';
        tooltipEl.style.color = '#000000';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transition = 'all .1s ease';

        chart.canvas.parentNode.appendChild(tooltipEl);
      }
      return tooltipEl;
    },
    externalTooltipHandler: async function(context){
      // Tooltip Element
      const {chart, tooltip} = context;
      const tooltipEl = this.getOrCreateTooltip(chart);
      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltip.yAlign) {
        tooltipEl.classList.add(tooltip.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      // Set Text
      const activePoints = chart.getActiveElements();
      if( activePoints.length > 0){
        const dataSets = [];
        await activePoints.forEach((activePoint) => {
          const datasetIndex = activePoint.datasetIndex
          const activeIndex = activePoint.index;
          const _data = chart.data.datasets[datasetIndex];
          dataSets.push(_data.data[activeIndex]);
        });
        this.setChartDetailsDataAndStyle(dataSets, false);
        const gigDataContainer = this.$refs.chartDetailsTooltip;
        tooltipEl.append(gigDataContainer);
      }

      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

      let verticalPosition = {distance: positionY + tooltip.caretY, prop: 'top'};

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.minWidth = (window.innerWidth <= 940) ? '80%' : '30%';
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style[verticalPosition.prop] = verticalPosition.distance + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';

      const position = context.chart.canvas.getBoundingClientRect();
      // // Ensure the tooltip is inside the chart area
      const chartWidth = context.chart.width;
      const chartHeight = context.chart.height;

      const tooltipWidth = tooltipEl.offsetWidth;
      const tooltipHeight = tooltipEl.offsetHeight;

      if (tooltip.caretX + tooltipWidth > chartWidth) {
        tooltipEl.style.left = (positionX + tooltip.caretX - position.left ) + 'px';
      }
      if (tooltip.caretY + tooltipHeight > chartHeight) {
        tooltipEl.style.top = (verticalPosition.distance + tooltip.caretY - position.top) + 'px';
      }


      return tooltipEl;
    },
    handleChartClick: async function(event){
      let _chart = event.chart;
      const activePoints = _chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
      if( activePoints.length > 0){
        const dataSets = [];
        await activePoints.forEach((activePoint) => {
          const datasetIndex = activePoint.datasetIndex
          const activeIndex = activePoint.index;
          const _data = _chart.data.datasets[datasetIndex];
          dataSets.push(_data.data[activeIndex]);
        });
        this.setChartDetailsDataAndStyle(dataSets, true);
      }
    },
    setChartDetailsDataAndStyle: function(datasets, openDialog = true) {
      this.resetChartDetails();
      // let wrap = document.createElement('div');
      datasets.forEach((item) => {
        if (item.type === 'actual') {
          this.chartDetailsData.actual.show = true;
          this.chartDetailsData.actual.data = item;
        }
        else if (item.type === 'potential') {
          this.chartDetailsData.potential.show = true;
          this.chartDetailsData.potential.data = item;
        }
        else if (item.type === 'combined') {
          this.chartDetailsData.combined.show = true;
          this.chartDetailsData.combined.data = item;
        }
      });
      if( openDialog )
      this.openChartDetails((datasets.length > 0));
      // this.showChartDetails = (datasets.length > 0);
    },
    resetChartDetails: function(){
      this.showChartDetails = false;
      this.chartDetailsData = {
        actual: { show: false, data: null },
        potential: { show: false, data: null },
        combined: { show: false, data: null }
      };
    },
    openChartDetails: function(show = false){
      if(show){
        this.$nextTick(() => {
          this.chartDetailsStyle.minWidth = '30%';
          this.chartDetailsStyle.top = '10%';
          this.chartDetailsStyle.left = '35%';
          delete (this.chartDetailsStyle.paddingRight);
          delete (this.chartDetailsStyle.paddingLeft);
          if (window.innerWidth <= 940) {
            this.chartDetailsStyle.minWidth = '80%';
            this.chartDetailsStyle.paddingRight = '5px';
            this.chartDetailsStyle.paddingLeft = '5px';
            this.chartDetailsStyle.top = '5%';
            this.chartDetailsStyle.left = '10%';
            if (window.innerWidth <= 480) {
              this.chartDetailsStyle.minWidth = '100%';
              this.chartDetailsStyle.left = 0;
            }
          } else {
            let dialog = this.$refs.chartDetailsContainer;
            let parent = dialog.parentElement;
            let parent_half_width = parent.offsetWidth / 2;
            let dialog_half_width = dialog.offsetWidth / 2;
            let projected_left = parent_half_width - dialog_half_width;
            let potential_left = (projected_left / parent.offsetWidth) * 100;
            this.chartDetailsStyle.left = Math.floor(potential_left) + "%";
          }
        });
        this.showChartDetails = true;
      }
    },
    closeChartDetails: function(){
      this.resetChartDetails();
      this.showChartDetails = false;
    }
  },

};

</script>