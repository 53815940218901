<template>
  <div class="flex flex-col min-h-full h-full border rounded-lg mb-6 sm:mb-0 shadow-sm">
    <div class="bg-indigo-500 text-white font-bold p-4 rounded-t-lg">
      {{ product.name }}
    </div>
    
    <div class="p-3 flex-1 flex flex-col">
      <div class="flex-1">
        <p class="my-2" v-if="product.description">
          {{ product.description }}
        </p>

          <div v-if="showCurrentSubscription">
              <span
                      v-if="this.currentSubscription && this.currentSubscription.renews_at !== null && this.currentSubscription.product.id === this.product.id"
                      class="text-sm font-bold italic"
              >
          Renews on {{ format(new Date(currentSubscription.renews_at), 'dd/MM/Y') }}
        </span>
          </div>

      </div>

            <Button
                    v-if="showCurrentSubscription"
                    class="w-full mt-4"
                    :disabled="currentSubscription !== null && currentSubscription.product.id === product.id"
                    @click.native="$emit('onSwitchProduct', product)"
            >
                {{
                currentSubscription !== null &&
                currentSubscription.product.id === product.id
                    ? "Selected plan"
                    : "Select plan"
                }}
            </Button>

    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import Button from '../ui/buttons/Button.vue'

export default {
  components: {
    Button
  },
  methods: {
    format
  },
  props: {
    product: {
      type: Object,
      required: true,
      default() {
          return false
      }
    },
    currentSubscription: {
      type: Object,
      required: false,
        default() {
            return {};
        }
    }
  },
    computed: {
      showCurrentSubscription () {
          return Object.hasOwn(this.currentSubscription, 'renews_at') &&
                Object.hasOwn(this.currentSubscription, 'product')
        }
    }
}
</script>

<style>

</style>