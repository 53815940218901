<template>
    <div v-if="modalVisible" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <!-- Heroicon name: outline/exclamation -->
          <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
            Applying your template
          </h3>
          <div class=" modal-copy mt-2 text-sm text-gray-500">
            <p v-if="this.$route.query.new">
              <strong>Why am I seeing this?</strong>  This is your default template.  You can disable this by changing your default template under 
              <router-link to="/gigtemplates" class="text-gp_pink-default-500">gig templates</router-link>.
            </p>
            <p v-else>
                Choose what elements from "{{chosenTemplate.name}}" you wish to apply to this new gig. 
            </p>     

              <div class=" copy-toggles py-4">
                  
              <div class="col-span-full mt-8">
                <h2 class="font-bold text-base">Budget</h2>
              </div>
                <div>
                  <label>Artist Fees</label>
                  <t-toggle name="fees" v-model="copy.use_fees" :checked="true" />
                </div> 
              </div>

              <div class="mt-8">
                <h2 class="font-bold text-base">Choosing your artists</h2>
              </div>
              <div class=" copy-toggles py-4">
                   
                <div v-if="chosenTemplate.search_type == 200">
                  <label>Automated search preferences</label>
                  <t-toggle name="search_tags" v-model="copy.use_search_preferences" :checked="true" />
                </div> 
                <div v-else>
                  <label>Selected artists</label>
                  <t-toggle name="faves" v-model="copy.use_fave_artists" :checked="true" />
                </div> 
              </div>
              <div class="mt-8">
                <h2 class="font-bold text-base">Artist instructions</h2>
              </div>
              <div class=" copy-toggles py-4 mt-4">
                <div>
                  <label>Venue Equipment</label>
                  <t-toggle name="venue_equipment" v-model="copy.use_equipment_venue" :checked="true" />
                </div> 
  
                <div>
                  <label>Artist Equipment</label>
                  <t-toggle name="artist_equipment" v-model="copy.use_equipment_artist" :checked="true" />
                </div> 
  
                <div>
                  <label>Repertoire</label>
                  <t-toggle name="repertoire" v-model="copy.use_repertoire" :checked="true" />
                </div>  
                <div>
                  <label>Equipment details</label>
                  <t-toggle name="dress_code" v-model="copy.use_dress_code" :checked="true" />
                </div>  
                <div>
                  <label>Parking details</label>
                  <t-toggle name="parking" v-model="copy.use_parking_details" :checked="true" />
                </div> 
                <div>
                  <label>Load in times</label>
                  <t-toggle name="parking" v-model="copy.use_load_in_time" :checked="true" />
                </div> 
                <div>
                  <label>Sound check times</label>
                  <t-toggle name="soundcheck" v-model="copy.use_sound_check_time" :checked="true" />
                </div> 
                <div>
                  <label>Performance times</label>
                  <t-toggle name="performance" v-model="copy.use_performance_times" :checked="true" />
                </div> 
                <div>
                  <label>Briefs</label>
                  <t-toggle name="briefs" v-model="copy.use_briefs" :checked="true" />
                </div> 
                <div>
                  <label>Additional notes</label>
                  <t-toggle name="notes" v-model="copy.use_additional_notes" :checked="true" />
                </div> 
                <div>
                  <label>Invoicing</label>
                  <t-toggle name="invoicing" v-model="copy.use_invoicing" :checked="true" />
                </div> 
                <div>
                  <label>Important information</label>
                  <t-toggle name="invoicing" v-model="copy.use_important" :checked="true" />
                </div> 
                <div>
                  <label>Payment terms</label>
                  <t-toggle name="terms" v-model="copy.use_payment_terms" :checked="true" />
                </div> 
              </div>


            </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button type="button" @click="setTemplate" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
          Apply template to this gig
        </button>
        <button @click="modalVisible = false;" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  
import {
  TToggle 
} from 'vue-tailwind/dist/components'
export default {
    name: "template-copy",
    components:{  
      TToggle
    },
    data: function(){
      return { 
        chosenTemplate: {},
        modalVisible: false, 
        copy: {
          
            use_equipment_venue: true,
            use_equipment_artist: true,
            use_repertoire: true,
            use_dress_code: true,
            use_parking_details: true,
            use_load_in_time: true,
            use_sound_check_time: true,
            use_performance_times: true,
            use_additional_notes: true,
            use_invoicing: true,
            use_important: true,
            use_payment_terms: true,
            use_fees: true,
            use_search_preferences: true,
            use_fave_artists: true,
            use_briefs: true
        }
      }
    },
    methods: {
      updateTemplate(gigtemplate){
        this.chosenTemplate = gigtemplate;
      }, 
      toggleVisibility()
      {
        this.modalVisible = !this.modalVisible;
      },
      setTemplate(){
        this.$emit('templateSelection', this.copy);
        this.toggleVisibility();
        console.log("emmitted");
      }
    }, 
    mounted: function() {
        console.log("in modal gig template", this.chosenTemplate);
    },
}
</script>