<template>

<div class="col-span-full grid grid-flow-row gap-4 mt-4 input-group">
      <t-rich-select
          v-show="search_tiers && search_tiers.length && showTiers"
          class="col-span-4  t-rich"
        :multiple="single_tier" ref="tiers"
        :close-on-select="!single_tier" valueAttribute="id" textAttribute="name"
            :placeholder="placeholder" v-model="search_tiers" :options="tiers"
    ></t-rich-select>

  <t-rich-select  v-show="search_tiers && search_tiers.length > 0 && filteredMoreInfo.length > 0"
                  class="col-span-4 t-rich"
                  ref="genres"
                  valueAttribute="id"
                  textAttribute="name"
                  :options="filteredMoreInfo"
                  multiple
                  v-model="search_moreInfo"
                  :close-on-select="false"
                  placeholder="Specialism"
  ></t-rich-select>

  <t-rich-select  v-show="search_tiers && search_tiers.length > 0 && filteredCovers.length > 0"
                  class="col-span-4 t-rich"
                  ref="covers"  valueAttribute="id" textAttribute="name"  :options="filteredCovers"
                  multiple
                  v-model="search_covers"
                  :close-on-select="false"
                  placeholder="Covers/Original Artist"
  ></t-rich-select>


  <t-rich-select  v-show="search_tiers && search_tiers.length > 0 && filteredGenres.length > 0"
                  class="col-span-4 t-rich"
                  ref="genres"
                  valueAttribute="id"
                  textAttribute="name"
                  :options="filteredGenres"
                  multiple
                  v-model="searchGenresComputed"
                  :close-on-select="false"
                  placeholder="Genres"
                  :hideSearchBox="tagLimitReached"
                  :closeOnSelect="tagLimitReached"
    ></t-rich-select>

  <span v-if="tagLimitReached" class="has-error text-xs">Maximum of 5 genres</span>

</div>
</template>


<script>

import {
  TRichSelect
} from 'vue-tailwind/dist/components'

export default {
    name: "SearchByTag",
    components: {
        TRichSelect
    },
    props:{
        tiers: Array,
        covers: {
            type: Array,
            default: () => []
        },
        genres: {
          type: Array,
          default: () => []
        },
      moreInfo: {
        type: Array,
        default: () => []
      },
        single_tier: Boolean,
        placeholder: {
          type: String,
          default: 'Select type'
        },
      search_tiers: {
        type: Array,
        default: () => []
      },
      search_covers:{
        type: Array,
        default: () => []
      },
      search_genres :{
        type: Array,
        default: () => []
      },
      search_moreInfo :{
        type: Array,
        default: () => []
      },
      showTiers :{
        type: Boolean,
        default: () => true
      },
      tagLimitReached :{
        type: Boolean,
        default: () => true
      },
    },
  mounted() {
    this.$emit('searchByTagLoaded', true)
  },
		watch:{
        search_tiers() {
            this.$emit('tiersChange', this.search_tiers)
        },
        search_covers() {
          this.$emit('coversChange', this.search_covers)
        },
        search_genres() {
            // this.$emit('genresChange', this.search_genres)
        },
        search_moreInfo() {
          this.$emit('moreInfoChange', this.search_moreInfo)
        },
    },
  computed: {
    filteredCovers() {
      return this.covers.filter((cover) => {
        return cover.name !== 'N/A'
      })
    },
    filteredGenres() {
      return this.genres.filter((genre) => {
        return genre.name !== 'N/A'
      })
    },
    filteredMoreInfo() {
      return this.moreInfo.filter((moreInfo) => {
        return moreInfo.name !== 'N/A'
      })
    },
    searchGenresComputed: {
      get() {
        return this.search_genres
      },
      set(value) {
        this.$emit('genresChange', value)
      }
    },
    hasError() {
      return this.tagLimitReached
    }
  },
    methods:{},
    emits: ['tiersChange', 'coversChange', 'genresChange', 'moreInfoChange', 'searchByTagLoaded']
}

</script>

<style>
.has-error {
  color: red;
}
</style>