<template>

    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen max-w-sm" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-2 justify-between items-center">
                            <h3 class="block font-bold text-gp_pink-default-500" id="modal-title">Rate the artist - {{ artist.name }}</h3>
                            <p class="text-sm">Your feedback is highly appreciated and helps us to continually improve the user experience.</p>
                        </div>
                        <div class="px-26 mt-2">
                            <div class=" flex justify-center items-center rounded-xl space-x-3">
                                <div class="grid grid-cols-2 gap-6 p-5">
                                    <div>
                                        <img :src="artist.image ? (artist.image.conversions && artist.image.conversions.thumb ? artist.image.conversions.thumb : artist.image.url) : require('@/assets/images/placeholder.svg')" :alt="artist.name" class="w-24 h-24 object-cover object-center rounded-full border-2" />
                                    </div>
                                    <div class="mt-3">
                                        <strong class="text-md text-gp_pink-default-500">{{ artist.name }}</strong>
                                        <div>
                                            <span class="text-xs font-medium px-2.5 py-0.5 rounded-xl bg-[#0D1C43] text-white">{{artist.tier.name}}</span>
                                        </div>
                                        <!-- <StarRating class="mt-2" :rating="artist.rating"/> -->
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div class="px-0 md:px-0 mt-4 mb-4">
                                <span class="text-xs uppercase font-extralight text-gray-600/80">Choose a rating</span>
                            </div>
                            <div v-for="(rating) in ratings_questions.ratings" :key="rating.id" class="text-white mt-2 grid-cols-2 col-span-2 justify-between px-0 flex items-center">
                                <div class="font-light">
                                    <span class="text-sm text-black"> {{ rating.question }}</span>
                                </div>
                                <div>
                                    <InputStarRating class="mt-2 text-black" :rating="rating.rating" @updateRating="rating.rating = $event;"/>
                                </div>
                            </div>

                            <div v-for="(boolean) in ratings_questions.boolean" :key="boolean.id" class="text-white mt-6 grid-cols-2 col-span-2 justify-between px-0 flex items-center">
                                <div>
                                    <span class="text-sm text-black"> {{ boolean.question }} <span class="font-bold text-xs">(Optional)</span></span>
                                </div>
                                <div class="flex items-center justify-center text-black text-xs space-x-4">
                                    <span @click="boolean.answer = true;" :class="boolean.answer ? 'cursor-default bg-green-300 px-3 py-1 rounded-3xl text-white' : 'cursor-pointer hover:bg-gray-100 px-3 py-1 rounded-3xl'">Yes</span>
                                    <span @click="boolean.answer = false;" :class="!boolean.answer ? 'cursor-default bg-green-300 px-3 py-1 rounded-3xl text-white' : 'cursor-pointer hover:bg-gray-100 px-3 py-1 rounded-3xl'">No</span> 
                                </div>
                            </div>

                            <div v-for="(input) in ratings_questions.input" :key="input.id" class="text-white mt-4 px-0">
                                <span class="text-sm text-black"> {{ input.question }} <span class="font-bold text-xs">(Optional)</span></span>
                                <textarea 
                                    v-model="input.answer"
                                    id="message" 
                                    rows="4" 
                                    class="block mt-2 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                                    placeholder="Write your thoughts here..."
                                />
                            </div>
                        </div>

                        <div class="text-left mt-6">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <GPButton 
												@click.native="skipRating()" 
												:color="'grey'"
												size="md"
                                                class="px-14"
											>Skip</GPButton>
                                    <GPButton   @click.native="submitRating()" 
												:color="'pink'"
												size="md"
                                                class="px-14"
											>Submit</GPButton>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </transition>        
        </div>

        <NotificationSmall ref="notification" />
    </div>
</template>

<script>
// import StarRating from '../../components/rating/StarRating.vue';
import InputStarRating from '../../components/rating/InputStarRating.vue';
import client from '@/utils/client.js'
import GPButton from "../ui/buttons/Button.vue";
import { apiMethods } from '@/state/helpers'; 
import NotificationSmall from '../../components/notifications/small.vue';

export default
{
    name: "ArtistRatingModal", 
    components:{
        // StarRating,
        InputStarRating,
        GPButton,
        NotificationSmall
    },
    data(){
        return {
            modal_open: false,
            ratings_questions: [],
            ratings_questions_base: [],
        }
    },
    props: ['artist', 'rating', 'bidId', 'gigId'],
    mounted() {
        client
            .get('ratings-venue')
            .then((response) => {
                this.ratings_questions = JSON.parse(JSON.stringify(response.data));
                this.ratings_questions_base = JSON.parse(JSON.stringify(response.data));
            })
    },
    methods: {
        ...apiMethods,
        toggleModal() {
            this.ratings_questions = JSON.parse(JSON.stringify(this.ratings_questions_base));
            this.modal_open = !this.modal_open;
        },
        skipRating() {
            this.skipArtistRating({ 
                artistId: this.artist.id,
                gigId: this.gigId,
                bidId: this.bidId,
            });

            this.$emit('reload');
            this.toggleModal();
            this.$notify({text: 'Thank you for your feedback.',  duration: 5000});
        },
        submitRating() {
             this.submitArtistRating({ 
                data: this.ratings_questions,
                artistId: this.artist.id,
                gigId: this.gigId,
                bidId: this.bidId,
            });

            this.$notify({text: 'Rating submitted. Thank you for your feedback.',  duration: 5000});
            this.$emit('reload');
            this.toggleModal();
        }
    }
}

</script>