
export default {
  
  signup($auth, params){
    $auth.loginWithRedirect(params);
  },
  login($auth, params) {
    $auth.loginWithRedirect(params);
  },
  // Log the user out
  logout($auth) {
    $auth.logout({
      returnTo: window.location.origin,
    });
  },
  isArtistView()
  {
    return localStorage && localStorage.initialRoute == 'artist' || !localStorage;
  },
  switchRoute($profile)
  {

    if ($profile != 'artist' && $profile != 'venue'){
      $profile = 'venue';
    }

    localStorage.initialRoute = $profile;
  }
};
