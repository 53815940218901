import client from "../../utils/client"

export const state = {
  roles: null,
	rolesPermissionsSlugs:null,
	userData:null,
}

export const mutations = {
  SET_USERS_ROLES(state, roles) {
    state.roles = roles
  },
	SET_USERS_ROLES_PERMISSION_SLUGS(state, slugs) {
    state.rolesPermissionsSlugs = slugs
  },
	SET_USERS_DATA(state, userData) {
    state.userData = userData
  }
}

export const getters = {
	roles: state => {
    return state.roles;
  },
	userData: state => {
    return state.userData;
  },
	hasRoles: state => {
    return state.roles ? state.roles.length > 0 : false;
  },
	rolesPermissionsSlugs: state => {
    return state.rolesPermissionsSlugs;
  },
	hasManageGigsPermission: state => {
		return state.rolesPermissionsSlugs.includes('manage-gigs') || state.rolesPermissionsSlugs.includes('full-access') || state.roles.length === 0;
	},
	hasViewCalendarPermission: state => {
		return state.rolesPermissionsSlugs.includes('view-calendar') || state.rolesPermissionsSlugs.includes('full-access') || state.roles.length === 0;
	},
	hasBillingInvoicesPermission: state => {
		return state.rolesPermissionsSlugs.includes('billing-invoices') || state.rolesPermissionsSlugs.includes('full-access') || state.roles.length === 0;
	},
	hasManageTeamMembersPermission: state => {
		return state.rolesPermissionsSlugs.includes('manage-team-members') || state.rolesPermissionsSlugs.includes('full-access') || state.roles.length === 0;
	},
	hasAllUserDataLoaded: state => {
		if(!state.userData){
			return false;
		}
		if(!state.rolesPermissionsSlugs){
			return false;
		}
		return state.userData.id && state.rolesPermissionsSlugs ? true : false;
	},
	hasReferralCodeGenre: state => {
		return state.userData.referral_code ? true : false;
	},
	hasShowMonthlyInvoices: state => {
		if (state.userData.brand !== undefined && state.userData.brand !== null) {
			return !!state.userData.brand.feature_monthly_invoice;
		}
		return false
	}
}

export const actions = {
	getUsersData({ getters, commit }) {
		return new Promise((resolve) => {
			if(getters.userData){
				resolve(getters.userData)
			}else{
				client
					.get('user?append=brand')
					.then((response) => {
						commit('SET_USERS_DATA', response.data.data);
						resolve(getters.userData)
					})
					.catch(err => {
						console.log(err)
					});
			}
		})
  },
	getUsersRoles({getters, commit}) {
		if (state.roles === null) {
			return new Promise((resolve) => {
				client
					.get('user/roles')
					.then((response) => {
						const roles = response.data.data
						commit('SET_USERS_ROLES', roles);
						// parse rolesPermissionsSlugs
						const permissionSlugs = [];
						// loop all the roles in the response
						roles.map(role => {
							// for each role, loop the permissions
							role.permissions.map(permission => {
								// if the permissions isn't alreeady in the permissionSlugs array, add it
								if (!permissionSlugs.includes(permission.slug)) {
									permissionSlugs.push(permission.slug);
								}
							})
						});
						commit('SET_USERS_ROLES_PERMISSION_SLUGS', permissionSlugs)
						resolve(getters.roles)
					})
					.catch(err => {
						console.log(err)
					});
			})
		}
	},
} 