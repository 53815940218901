import { DateTime } from 'luxon';
import timeAgo from  '@/utils/format-date-timeago.js'  

export function timeUntilGig(start) {

    const diffInMinutes = DateTime.fromISO(start).diffNow().as('minutes');

    if(diffInMinutes < 0){
      
      const diffInHours = DateTime.fromISO(start).diffNow().as('hours');

      const minsDiff = Math.floor(Math.abs(diffInMinutes) % 60)
      const hoursDiff = -Math.ceil(diffInHours)

      const minutes = minsDiff === 1 ? 'minute' : 'minutes';
      const hours = Math.ceil(diffInHours) < -1 ? 'hours' : 'hour';

      if (hoursDiff > 0) {
        return `The gig started ${hoursDiff} ${hours} and ${minsDiff} ${minutes} ago`
      } else {
        return `The gig started ${minsDiff} ${minutes} ago`
      }
    } 

    return `${timeAgo(start)} until gig`
}