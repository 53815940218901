
<template>
	
	<ModalWrapper :is-open="isOpen">
		<template #header>
			<div class="text-xl"> 
				{{ modalTitle }}
			</div>
		</template>
		<template #body>
			
			<form class="mb-24 form input-group" ref="form" novalidate> 
				<div class="mt-6 sm:mt-5 col-span-full">

						<div class="mt-1 sm:mt-0">
							<InputText 
								required 
								id="email" 
								v-on:input="form.email = $event;" 
								v-bind:value="form.email" 
								label="Email address" 
								hint="The members email must be populated"
								error="The members email must be completed" 
								name="email"
								disabled
							/>
						</div>
				</div>

				<div class="mt-2 sm:mt-1 col-span-full">
					<div class="mt-1 sm:mt-0">
						<TSelect 
							class="col-span-2 force-mt-1-6" 
							@change.native="onSelectChange"
							:options="roleOptions"
							placeholder="Select a new role"
							:disabled="isLoading"
						/>
					</div>
				</div>
				

			</form>

		</template>
		<template #footer>

			<ModalFooterWrapper>
				<template #left>
					<Button @click.native="onClose" :disabled="isLoading" color="gray">Close</Button>
				</template>
				<template #right>

					<Button @click.native="onSave" :disabled="submitDisabled" :loading="isLoading">
						{{ submitButtonLabel }}
					</Button>
				</template>
			</ModalFooterWrapper>
					
	
		</template>
	</ModalWrapper>
	
</template>

<script>
import ModalWrapper from '../../ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '../../ui/modal/ModalFooterWrapper.vue'
import InputText from '../../form/InputText.vue';
import { TSelect } from 'vue-tailwind/dist/components';
import Button from '../../ui/buttons/Button.vue'
import client from '../../../utils/client';
export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		InputText,
		TSelect,
		Button,
	},
	props:{
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
		member:{
			type:Object,
			required: true
		},
		roles:{
			type:Array,
			required: true
		},
		venueId:{
			type:String,
			required: true,
		}
	},
	emits:["closed", "delete", "updated"],
	data(){
		return {
			form: {
				email: this.getMemberName(this.member),
				role: this.member.roles ? this.member.roles[0].id : null,
			},
			roleOptions: this.parseRoleOptions(this.roles),
			isLoading:false,
			// roles: ['Owner', 'Finance', 'Events team manager',]
		}
	},
	computed:{
		modalTitle() {
      return `Edit ${ this.getMemberName(this.member) }`
    },
		// accessIdEnabled() {
    //   return (id) => id === this.form.access;
    // },
		submitDisabled() {
      return !this.form.role || this.isLoading;
    },

		submitButtonLabel() {
      return this.isLoading ? 'Saving member' : 'Save';
    },
	},
	watch:{
		roles(updatedRoles){
			this.roleOptions = this.parseRoleOptions(updatedRoles)
		},
		member(updatedMember){
			this.form.email = this.getMemberName(updatedMember);
			this.form.role = updatedMember.roles ? updatedMember.roles[0].id : null;
		}
	},
	methods:{
		parseRoleOptions(r){
			const options = []
			if(r){
				r.map(role => {
					if(this.role){
						if(role.id !== this.role.id){
							// emit the selected role for the member
							options.push({
								text: role.name,
								value: role.id
							})
						}
					}else{
						// no role is found on the invite so include them all
						options.push({
							text: role.name,
							value: role.id
						})
					}
				})
			}
			return options
		},
		getMemberName(member){
			const display = member.name || this.member.name !== "" ? member.name : member.email || member.email !== "" ? member.email : "";
			return display === "" ? `No details for member ${member.id}` : display;
		},
		onSelectChange($e){
			this.form.role = $e.target.value === "" ? null : $e.target.value;
		},
		onClose(){
			this.$emit("closed");
		},
		onSave(){
			this.isLoading = true;
			client
				.put(`/venues/${this.venueId}/members/${this.member.id}`, {role:this.form.role})
				.then(() => {
					this.isLoading = false;
					this.$emit("updated");
				})
				.catch((err) => {
					console.log(err)
				})	
		},
		onRoleUpdated(role){
			this.form.role = role;
		},
	},
}
</script>