<template>
	<div 
		style="border-top-color:transparent"
		class="border-solid rounded-full animate-spin w-8 h-8 border-4 border-pink-500"
	>
	</div>
</template>

<script>
export default {
	// props:{
	// 	tailwindDiameter:{
	// 		type:String,
	// 		default:"4"
	// 	},
	// 	tailwindBorder:{
	// 		type:String,
	// 		default:"2"
	// 	},
	// 	tailwindColor:{
	// 		type:String,
	// 		default:"white"
	// 	}
	// },
	// computed:{
	// 	classes() {
	// 		return `w-${this.tailwindDiameter} h-${this.tailwindDiameter} border-${this.tailwindBorder} border-${this.tailwindColor}`
	// 	}
	// },
	// methods:{
	// 	parseColour(color){
	// 		switch(color.toLowerCase()){
	// 			case "gray":
	// 			case "grey":
	// 				this.bgColour = "bg-gray-200";
	// 				this.bgColourDisabled = "bg-gray-50";
	// 				this.bgHover = "bg-gray-300";
	// 				this.textColour = "text-black";
	// 				this.textColourDisabled = "text-gray-600";
	// 				this.textHover = "text-black";
	// 				break;
	// 			case "pink":
	// 			default:
	// 				this.bgColour = "bg-gp_pink-default-500";
	// 				this.bgColourDisabled = "bg-gp_pink-default-200";
	// 				this.bgHover = "bg-pink-700";
	// 				this.textColour = "text-white";
	// 				this.textColourDisabled = "text-white";
	// 				this.textHover = "text-white";
	// 				break;
	// 		}
	// 	}
		
	// },
}
</script>