<template>
<div transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal " ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-12">
                    

<!-- This example requires Tailwind CSS v2.0+ -->
<nav aria-label="Progress" class="mb-4">
  <ol role="list" class="space-y-4 md:flex md:space-y-0 md:space-x-8">
    <li class="md:flex-1">
      <!-- Completed Step -->
      <a @click="paymentMethodSelected = null;selectedPlan = null;"  class="border-indigo-600 group pl-4 py-2 flex flex-col border-l-4  hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
        <span class="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">Step 1</span>
        <span class="text-sm font-medium">Payment method</span>
      </a>
    </li>

    <li class="md:flex-1">
      <!-- Current Step -->
      <a @click="selectedPlan = null; "  v-bind:class="paymentMethodSelected != null ? 'border-indigo-600' : ''"  class="pl-4 py-2 flex flex-col border-l-4  md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
        <span class="text-xs text-indigo-600 font-semibold tracking-wide uppercase">Step 2</span>
        <span class="text-sm font-medium">Package</span>
      </a>
    </li>

    <li class="md:flex-1">
      <!-- Upcoming Step -->
      <a href="#" v-bind:class="selectedPlan != null && paymentMethodSelected != null ? 'border-indigo-600' : ''"  class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
        <span class="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">Step 3</span>
        <span class="text-sm font-medium">Subscribe</span>
      </a>
    </li>
  </ol>
</nav>


                    
            <div v-show="paymentMethodsLoadStatus == 2 
                && paymentMethods.length == 0"
                class="">
                    No payment method on file, please add a payment method.
            </div>
            <div v-show="!showAddCard && !paymentMethodSelected">
                
            <h3 class="font-bold col-span-full mb-4">Select a payment method</h3>   
                    <div v-if="intentToken === ''" class="text-xs uppercase">Contacting Stripe...</div>
                <div v-show="paymentMethodsLoadStatus == 2 
                        && paymentMethods.length > 0">


                    <div v-for="(method, key) in paymentMethods" 
                            v-bind:key="'method-'+key" 
                            v-on:click="paymentMethodSelected = method.id"
                            class="cursor-pointer "
                            >
                        <div class="border rounded row p-4" v-bind:class="{
                            'bg-indigo-500 text-white': paymentMethodSelected == method.id    
                        }">
                            <div class="p-1 uppercase font-bold text-xs">
                                {{ method.brand.charAt(0).toUpperCase() }}{{ method.brand.slice(1) }}
                            </div>
                            <div class="p-1 grid grid-cols-4 items-center justify-center">
                                <span class="text-xs font-bold">Ending In:</span> {{ method.last_four }} 
                                <span class="text-xs font-bold">Exp:</span> {{ method.exp_month }} / {{ method.exp_year }}
                            </div>
                        </div>
                            <div class="col-7 text-right bg-white text-black">
                                <span class="cursor-pointer uppercase text-xs" v-on:click.stop="removePayment( method.id )">Remove payment method</span>
                            </div>
                    </div>
                </div> 
                <div class="grid grid-cols-5 justify-end items-end my-4">
                    <a class="col-span-5 col-start-4 text-xs gp_cta  bg-gp_pink-default-500" @click="showAddCard = true;">Add a new payment method</a>
                </div>
            </div>
            <div class="form grid grid-flow-row gap-4 justify-center items-center" v-show="showAddCard">
                <h3 class="font-bold col-span-full">Add a new payment method</h3>                  
                <div class="col-span-full">
                    <label>Cardholder name</label>
                    <input id="card-holder-name" type="text" v-model="name" class="form-control mb-2" />
                </div>
                <div class="col-span-full">
                    <label>Card</label>
                    <div id="card-element">

                    </div>
                </div>                
                <button class="gp_cta col-span-4 text-center bg-gp_pink-default-500" id="add-card-button" v-on:click="submitPaymentMethod()">
                    Save payment method
                </button>

                <div v-if="addPaymentStatusError" class="has-error col-span-full">
                    {{addPaymentStatusError}}
                </div>
            </div>  

            

            <div v-if="addPaymentStatus == 2">
                Payment successful.
            </div>

            <div v-show="paymentMethodSelected != null">

                <h3 class="font-bold col-span-full mt-4">Select a plan</h3>             
            <div class="mt-3 row rounded border cursor-pointer p-4" 
        v-bind:class="{'bg-indigo-500 text-white': selectedPlan == 'price_1KC5AbIjgLpxg7G0MUTz8v2C'}" 
        v-on:click="selectedPlan = 'price_1KC5AbIjgLpxg7G0MUTz8v2C'">
                <div class="col-6">
                    <span class="text-xs font-bold mb-4">Standard subscription</span>
                    <div class="package">
                        <div>Up to 10 p/m <span>£25.00 p/m</span></div>
                        <div>Between 11 gigs - 25 gigs p/m  <span>£50.00 p/m</span></div>
                        <div>between 26 to 50 p/m <span>£75.00 p/m</span></div>
                        <div>Unlimited gigs <span>£100.00 p/m</span></div>
                    </div>
                </div>
            </div>
            <div class="uppercase text-xs my-4 text-right">
                Total due now: £25.00.  <br/>Billed monthly based on usage.
            </div> 
            <div class="grid grid-cols-8 justify-between items-center" v-show="selectedPlan">
                <a class="gp_cta btn-primary col-span-3 text-center items-center justify-center" id="add-card-button" v-on:click="updateSubs()">
                    Subscribe now
                </a>
                <img src="@/assets/images/powered_by_stripe_blurple.svg" class="col-span-2 col-start-7" alt="Powered by Stripe" />
            </div>
        </div>
            <div class="text-xs uppercase mt-4">
                <a @click="closeModal">Cancel</a>
            </div>
        </div>
    </div>
</div>
</template>

<script> 

import { apiMethods } from '@/state/helpers'; 
export default {
    name: "SubscriptionManagement",
    data(){
        return {
            stripeAPIToken: process.env.VUE_APP_STRIPE_API_TOKEN,
            stripe: '',
            elements: '',
            card: '',
            intentToken: '',
            name: '',
            addPaymentStatus: 0,
            addPaymentStatusError: '',
            paymentMethods: [],
            paymentMethodsLoadStatus: 0,
            paymentMethodSelected: null,
            selectedPlan: '',
            showAddCard: false
        }
    },
    methods: { 
        ...apiMethods,
        closeModal(){            
            this.$emit('close',
                false
            )
        },
        includeStripe( URL, callback ){
            let documentTag = document, tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = '//' + URL;
            if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        }, // for PCI compliance this must come from Stripe itself, not an npm library
        configureStripe(){
            // eslint-disable-next-line no-undef
            this.stripe = Stripe( this.stripeAPIToken );

            this.elements = this.stripe.elements();
            this.card = this.elements.create('card');

            this.card.mount('#card-element');
        },
        updateSubs(){
            this.updateSubscription({plan: this.selectedPlan, payMethod: this.paymentMethodSelected}).then(function(response){  
                console.log("subscription", response);
            });
        },
        submitPaymentMethod(){
            this.addPaymentStatus = 1;
            
            console.log("adding payment method");

            this.stripe.confirmCardSetup(
                this.intentToken.client_secret, {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: this.name
                        }
                    }
                }
            ).then(function(result) {

                let self = this;

                console.log("response to payment", result);
                if (result.error) {
                    
                    console.log("payment error", result);
                    this.addPaymentStatus = 3;
                    this.addPaymentStatusError = result.error.message;
                } else {
                    console.log("saved payment method",  result.setupIntent.payment_method);
                    
                    this.savePaymentMethod( result.setupIntent.payment_method ).then(function(){                
                        self.loadPayment();
                        self.addPaymentStatus = 2;
                        self.card.clear();
                        self.name = '';
                    });

                }
            }.bind(this));
        }, 
        loadPayment(){
            let self = this;
            this.paymentMethodsLoadStatus = 1;
            this.loadPaymentMethods().then(function(response){                
                self.paymentMethods = response.data;
                self.paymentMethodsLoadStatus = 2;
            });
        },
        removePayment(paymentID){
            let self = this;
            this.removePaymentMethod(paymentID).then( function(){
                self.loadPayment();
            });
        }
    },
    mounted(){
        this.includeStripe('js.stripe.com/v3/', function(){
            this.configureStripe();
        }.bind(this) ); 

        let self = this;
        this.loadStripeIntent().then(function(response){ 
            console.log("response", response.data);
            self.intentToken = response.data;
        }); // can be loaded later 

        
        this.loadPayment();
    },
}
</script>