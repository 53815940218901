
// GIG STATUS
const gigStatuses = [
	{ 
		code: 0, 
		name:'Uncaught', 
		type:'UNCAUGHT_STATUS', 
		bg:'rgb(255, 255, 255)', 
		color:'rgb(0, 0, 0)',
		class:'gig-uncaught',
	},
	{ 
		code: 25, 
		name:'Draft', 
		type:'UNPUBLISHED', 
		bg:'rgb(224, 231, 255)', 
		color:'rgb(55, 48, 163)',
		class:'gig-unpublished',
	},
	{ 
		code: 50, 
		name:'Cancelled', 
		type:'CANCELLED', 
		bg:'rgb(243, 244, 246)', 
		color:'rgb(31, 41, 55)',
		class:'gig-cancelled',
	},
	{ 
		code: 100, 
		name:'Looking for artists', 
		type:'NO_ARTISTS', 
		bg:'rgb(243, 244, 246)', 
		color:'rgb(31, 41, 55)',
		class:'gig-no-artists',
	},
	{ 
		code: 200, 
		name:'Pending response', 
		type:'NEEDS_RESPONSE', 
		bg:'rgb(220, 38, 38)', 
		color:'rgb(255, 255, 255)',
		class:'gig-needs-response',
	},
	{ 
		code: 300, 
		name:'Searching for Artists', 
		type:'PENDING', 
		bg:'rgb(254, 243, 199)', 
		color:'rgb(146, 64, 14)',
		class:'gig-pending',
	},
	{ 
		code: 400, 
		name:'Confirmed', 
		type:'CONFIRMED', 
		bg:'rgb(16, 185, 129)', 
		color:'rgb(255, 255, 255)',
		class:'gig-confirmed',
	},
	{ 
		code: 450, 
		name:'Needs sign-off', 
		type:'NEEDS_SIGNOFF', 
		bg:'rgb(180, 83, 9)', 
		color:'rgb(255, 255, 255)',
		class:'gig-not-signed-off',
	},
	{ 
		code: 500, 
		name:'Unpaid', 
		type:'UNPAID', 
		bg:'rgb(69, 216, 191)', 
		color:'rgb(153, 27, 27)',
		class:'gig-unpaid',
	},
	{ 
		code: 600, 
		name:'Paid', 
		type:'PAID', 
		bg:'rgb(209, 250, 229)',
		color:'rgb(6, 95, 70)',
		class:'gig-paid',
	},
	{ 
		code: 650, 
		name:'Signed-off', 
		type:'SIGNED_OFF', 
		bg:'rgb(209, 250, 229)', 
		color:'rgb(6, 95, 70)',
		class:'gig-signoff',
	},
	{
		code: 700,
		name:'Artist needs replacing',
		type:'CANCELLATION_REQUESTED_BY_ARTIST',
		bg:'rgb(251,121,0)',
		color:'rgb(255,255,255)',
		class:'cancelled-by-artist',
	},
];



const getStatusFromName = ( name ) => {
	if(typeof(name) !== String){
		throw 'Need to add a proper status name eg: Confirmed';
	}
	const filteredStatus = gigStatuses.filter(s => s.name === name)
	if(!filteredStatus.length){
		return gigStatuses[0]
	}
	return filteredStatus[0];
}

const getStatusFromType = ( type ) => {
	if(!type){
		return gigStatuses[0];
	}
	const filteredStatus = gigStatuses.filter(s => s.type === type)
	if(!filteredStatus.length){
		return gigStatuses[0]
	}
	return filteredStatus[0];
}

const getStatusesFromTypes = ( types ) => {
	if(!types){
		return gigStatuses;
	}
	const statuses = types.map(type => gigStatuses.filter(s => s.type === type)[0]);
	return statuses
}

const canGigBeBooked = (gigStatusType) => {
	if(gigStatusType === "UNCAUGHT_STATUS" ||
		gigStatusType === "CANCELLED" ||
		gigStatusType === "CONFIRMED" ||  
		gigStatusType === "NEEDS_SIGNOFF" ||
		gigStatusType === "UNPAID" ||
		gigStatusType === "PAID" ||
		gigStatusType === "SIGNED_OFF"
	){
		return false
	}else{
		return true
	}
}

const hasGigBeenBooked = (gigStatusType) => {
	if(gigStatusType === "UNCAUGHT_STATUS" ||
		gigStatusType === "CANCELLED" ||
		gigStatusType === "UNPUBLISHED" ||  
		gigStatusType === "NO_ARTISTS" ||
		gigStatusType === "NEEDS_RESPONSE" ||
		gigStatusType === "PENDING" ||
		gigStatusType === "CANCELLATION_REQUESTED_BY_ARTIST"
	){
		return false
	}else{
		return true
	}
}

export { 
	gigStatuses, 
	getStatusFromName, 
	getStatusFromType, 
	getStatusesFromTypes, 
	canGigBeBooked,
	hasGigBeenBooked
};
