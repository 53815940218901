
<template>
  <div>
    <h3 class="px-5 text-xs font-bold text-center border-t border-b my-1">Potential Spent({{data.label}})</h3>
    <AnalyticsTile :title="data.potential.min.label" :stat="'£' + data.potential.min.value" :icon="moneyIcon" :useFixedClasses="useFixedClasses" :customClasses="customClasses" />
    <AnalyticsTile :title="data.potential.max.label" :stat="'£' + data.potential.max.value" :icon="moneyIcon" :useFixedClasses="useFixedClasses" :customClasses="customClasses" />
    <AnalyticsTile :title="data.gigs.label" :stat="data.gigs.value" :icon="gigIcon" :useFixedClasses="useFixedClasses" :customClasses="customClasses" />
  </div>
</template>

<script type="text/javascript">

import AnalyticsTile from "../analytics-tile.vue";

export default {
  name: 'PotentialDetails',
  components: {AnalyticsTile},
  props: {
    data: {
      type: [Object, Array],
      default: null
    },
    useFixedClasses: {
      type: Boolean,
      default: true
    },
    customClasses: {
      type: [String, Object],
      default: null
    },
    moneyIcon: {
      type: String,
      default: '/img/icons/pound-circle-48x48.png'
    },
    gigIcon: {
      type: String,
      default: '/img/icons/group-circle-48x48.png'
    }
  }
};

</script>