import axios from 'axios'

axios.defaults.baseURL = getBaseUrl()

export const state = {
    currentUser: getSavedState('auth.currentUser'),
}

export const mutations = {
    SET_CURRENT_USER(state, currentUser) {
        state.currentUser = currentUser
        saveState('auth.currentUser', currentUser)
    },
}

export const getters = {
    currentUser(state) {
        return state.currentUser
    },
}

export const actions = {
    setCurrentUser({ commit }, currentUser) {
        commit('SET_CURRENT_USER', currentUser)
    },
}

export function getBaseUrl() {
    return process.env.VUE_APP_API
}
// ===
// Private helpers
// ===

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
