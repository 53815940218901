<template>
  <PageDetails>
    <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
      Edit Venue {{ isLoading ? "-" : "-" }}
      <span v-if="venue">{{venue.name}}</span>
    </h1>
    <div class="lg:max-w-5xl">
      <VenueTabs :venue-id="venueId" current-route="manage-subscription">
        <template v-slot:right-cta>
          <Button
            v-show="currentSubscription !== undefined && !currentSubscription.product.unlimited_gigs"
            class="gp_cta"
            @click.native="onBillingPortal"
            :disabled="billingPortalButtonDisabled"
            size="sm"
          >
            View Billing Portal
          </Button>
        </template>
      </VenueTabs>

      <template v-if="isLoading">
        <div class="p-8 flex justify-center items-start">
          <PageSpinner
            tailwind-diameter="8"
            tailwind-border="4"
            tailwind-color="pink-500"
          />
        </div>
      </template>
      <template v-else>
        <div>
          <div v-if="currentSubscription && currentSubscription !== undefined && currentSubscription.product.unlimited_gigs" class="flex justify-center p-12">
              <div>
                <NoDataMsgWrapper>{{venue.name}} is on the unlimited plan, and can add as many gigs as necessary.</NoDataMsgWrapper>
              </div>
          </div>
          <div v-else-if="products.length">
            <div class="grid grid-cols-1 sm:grid-cols-4 gap-4 my-6 w-full">
              <billing-plan
                v-for="product in products"
                :key="product.id"
                :product="product"
                :currentSubscription="currentSubscription"
                @onSwitchProduct="switchProduct"
              />
            </div>

            <div class="grid lg:grid-cols-12">
              <div class="lg:col-start-8 lg:col-span-5 pb-4 text-sm text-right">
                  Part of a large venue group and looking for unlimited gigs?  <button class="text-gp_pink-default-500" @click="openIntercom('I\'d like to discuss an unlimited gigs plan')">Get in touch with our team today.</button>
              </div>
            </div>

            <hr />

            <div v-if="monthsToReport && monthsToReport.length" class="grid grid-flow-col lg:grid-flow-row lg:grid-cols-12 mt-3 items-center">
              <span class="lg:col-span-2">Filter usage</span>
              <TSelect class="lg:col-span-10" :options="monthsToReport" textAttribute="text" valueAttribute="value" @change="filterUsages" v-model="selectedMonth"  />
              
            </div>
 
            <div class="text-sm text-gp_pink-default-500 text-right mt-2" v-if="filteredUsages">{{ filteredUsages.filter(f => f.type == 'USAGE').length }} gigs used on this month</div>
            <billing-table v-if="filteredUsages === null && monthsToReport.length" :usages="usages" />
            <billing-table v-else-if="monthsToReport.length" :usages="filteredUsages" />
          </div>
        </div>
      </template>
    </div>

    <NotificationSmall ref="notification" />
  </PageDetails>
</template>

<script>
import PageDetails from "@/components/ui/wrappers/PageDetails.vue";
import Button from '../../components/ui/buttons/Button.vue';
import PageSpinner from "../../components/ui/spinner/PageSpinner.vue";

import BillingTable from '../../components/billing/BillingTable.vue'

import VenueTabs from "../../components/venues/VenueTabs.vue";

import NotificationSmall from "../../components/notifications/small.vue";
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import client from "../../utils/client";
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth'
import formatISO from 'date-fns/formatISO';
import parseISO from "date-fns/parseISO";
import add from 'date-fns/add';
import lastDayOfMonth from 'date-fns/lastDayOfMonth'; 
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';

import BillingPlan from '../../components/billing/BillingPlan.vue'
import {
    TSelect
} from 'vue-tailwind/dist/components'; 

export default {
  components: {
    PageDetails,
    PageSpinner,
    VenueTabs,
    NotificationSmall,
    Button,
    BillingTable,
    BillingPlan,
    NoDataMsgWrapper,
    TSelect
  },
  props: ["venueId"],
  data() {
    return {
      products: [],
      isLoading: true,
      billingPortalButtonDisabled: false,
      usages: [],
      monthsToReport: [],
      filteredUsages: null,
      selectedMonth: null
    };
  },
  computed: {
    hasData() {
      return true;
    },
    venue() {
      return this.$store.getters['venue/currentVenue']
    },
    currentSubscription() {
      return this.venue !== null ? this.venue.active_subscription : undefined;
    },
  },
  methods: {
    openIntercom($message){
      window.Intercom('showNewMessage', $message);
    },
    onBillingPortal() {
      this.billingPortalButtonDisabled = true;
      client.post(`/venues/${this.venue.id}/billing-portal`).then((response) => {
        window.location.href = response.data.data.billing_portal_url;
      });
    },
    switchProduct(product) {
 
      let currentGigLimit = this.currentSubscription.product.limits.gigs == null ? 0 : this.currentSubscription.product.limits.gigs;
      let switchingGigLimit = product.limits.gigs == null ? 0 : product.limits.gigs;

      let isDowngrading =this.currentSubscription != null && switchingGigLimit < currentGigLimit; 
      if (isDowngrading)       
      {
        this.openIntercom("I'd like to downgrade to " + product.name); 
      }
      else

      

      client
        .post(`/venues/${this.venue.id}/subscriptions`, {
          product: product.id,
        })
        .then((response) => {
          const { data } = response.data;

          if (data.checkout_url !== undefined) {
            window.location.replace(data.checkout_url);

            return;
          }

          setTimeout(() => this.$store.dispatch('venue/getCurrentVenueWithUsagesAndInvoices', this.venueId), 3000)
        });
    },
    parseType($plan){

      let $output = "";
      switch($plan){
        case "PLAN":
          $output = "Subscription plan";
        break;
        case "INVOICED":
          $output = "Invoiced";
          break;
        case "PAY_AS_YOU_GO":
          $output = "PAYG";
          break;
      }

      return $output;
    },
    formatInvoiceUsages(invoices, usages) {
      invoices = invoices.map(invoice => ({
        type: 'INVOICE',
        label: invoice.type === 'PLAN' ? 'New billing month' : 'PAYG Top up',
        date: new Date(invoice.created_at),
        created_at: new Date(invoice.created_at),
        invoice,
      }))
 
      
      usages = usages.map(usage => ({
        type: 'USAGE',
        gig: usage.usageable,
        usageType: usage.type,
        created_at: new Date(usage.created_at),
        date: new Date(usage.date),
        label: this.parseType(usage.type)
      }))

      const formattedUsages = [...invoices, ...usages].map((usage, index) => ({...usage, id: index }))

      return reverse(sortBy(formattedUsages, 'date'))
    },
    filterUsages() {
      if (
        this.selectedMonth === null ||
        this.selectedMonth === '' 
      ) {        
        return
      } 

      let parsedDate = parseISO(this.selectedMonth);
      let filterFrom = startOfMonth(parsedDate)
      let filterTo = lastDayOfMonth(parsedDate);
 
      this.filteredUsages = this.usages
        .filter(usage => usage.date >= filterFrom)
        .filter(usage => usage.date <= filterTo)
    },
    format,
  },
  mounted() {
    this.isLoading = true;
    Promise.all([
      // Venue
      this.$store.dispatch("venue/getCurrentVenueWithUsagesAndInvoices", this.venueId),
      this.$store.dispatch("products/getProducts").then((response) => {
        this.products = response;
      }),
    ])
    .then(() => {
      this.usages = this.formatInvoiceUsages([...this.venue.invoices] || [], [...this.venue.usages] || []);


      let $usage = sortBy(this.usages, 'date');
 
      let $earliestDate = $usage.length ? $usage[0].date : false;
      let $latestDate = $usage.length ? lastDayOfMonth($usage[$usage.length - 1].date) : false;

      if ($earliestDate && $latestDate) {
          while ($earliestDate <=  $latestDate)
          {
              this.monthsToReport.push({
                  text: format($earliestDate, 'MMMM yyyy'),
                  value: formatISO(lastDayOfMonth($earliestDate))
              });

              $earliestDate = add($earliestDate, { months: 1 });

          }
      }


      reverse(this.monthsToReport);

    }).catch((error) => {
        console.log('ManageSubscriptionErrors', error)
    })
    .finally(() => {

      this.filterUsages();
      this.isLoading = false;
    });
  }
};
</script>
