import client from "../../utils/client"

export const state = {
  venueMembers: [],
}

export const mutations = {
  SET_VENUE_MEMBERS(state, { members, venueId }) {
		const index = state.venueMembers.findIndex(r => r.venueId === venueId)
    index > -1 
			? state.venueMembers[index].members = members
			: state.venueMembers.push({
				venueId: venueId,
				members
			})
  }
}

export const getters = {
	all: state => {
    return state.venueMembers.members;
  },
	venueMembers: state => venueId => {
		const i = state.venueMembers.findIndex(r => r.venueId === venueId)
    return i > -1 ? state.venueMembers[i].members : null;
  },

}

export const actions = {
  getVenueMembers({ getters, commit }, venueId) {
		return new Promise((resolve) => {
			// if(getters['venueMembers'](venueId)){
			// 	resolve(getters['venueMembers'](venueId))
			// }else{
				// return dispatch('requestVenueMembers', venueId);
				client.get(`/venues/${venueId}/members`)
					.then((response) => {
						commit('SET_VENUE_MEMBERS', {
							members: response.data.data, 
							venueId
						});
						resolve(getters['venueMembers'](venueId))
					})
					.catch(err => {
						console.log(err)
					})
			// }
		})
  },

	requestVenueMembers({ commit }, venueId) {
    return client.get(`/venues/${venueId}/members`)
      .then((response) => {
        commit('SET_VENUE_MEMBERS', {
					members: response.data.data, 
					venueId
				});
      })
			.catch(err => {
				console.log(err)
			})
  }
} 