export default function capitalise(data) {

    const capitalised = []
    data.split(' ').forEach(word => {
        capitalised.push(
            word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase()
        )
    })
    return capitalised.join(' ')
}