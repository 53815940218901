<template>
    
    <li class="relative pb-10">
      <div v-if="!isLast" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true"></div>

      <a href="#" class="relative flex items-center group" aria-current="step">
        <span class="h-9 flex items-center" aria-hidden="true">
          <span v-if="isCurrent" class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
            <span class="h-2.5 w-2.5 rounded-full group-hover:bg-gray-300 bg-indigo-600"></span>
          </span>

          <span v-else-if="isDone" class="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </span>

          <span v-else class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
            <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"></span>
          </span>

        </span>
        <span class="ml-4 min-w-0 flex flex-col ">
          <span class="text-xs font-semibold tracking-wide uppercase" :class="headerState">{{label}}</span>
          <span class="text-sm text-gray-500" v-if="description">{{description}}</span>
        </span>
      </a>
    </li>
</template>

<script>
export default {
    name: "progress-step",
    computed:
    {
        headerState() {
             if (this.isCurrent)
             {
                return "";
             }
             else if (this.isDone)
             {
                return "text-indigo-600";
             }
             else
             {
                 return "text-gray-500";
             }
        } 
    },
    props:{
        label: String,
        description: String,
        isCurrent: Boolean,
        isComplete: Boolean,
        isDone: Boolean,
        isLast: Boolean
    }
}
</script>