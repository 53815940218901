import axios from 'axios'
import branding from "@/global/branding";

let instance = axios.create({
  baseURL: branding.getBaseUrl()
});

instance.interceptors.request.use(function (config) {
  let token = sessionStorage.getItem('imp');

  if (token != null){ 
    config.headers['gp-imp'] =  token;
  }

  let opusToken = sessionStorage.getItem('opus');

  if (opusToken != null)
  {
    config.headers['opus-auth'] = opusToken;
  }
  
  return config;
});

export default instance;

