var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{ref:"form",staticClass:"form",attrs:{"novalidate":""}},[_c('div',{staticClass:"sm:col-span-8 col-span-full form-select lg:grid grid-flow-row"},[_c('label',{staticClass:"mb-4",attrs:{"for":"recurrence"}},[_vm._v(" Repeat options ")]),_c('t-select',{ref:"recurrence",staticClass:"w-full",attrs:{"name":"recurrence","placeholder":"Select an option","value-attribute":"value","text-attribute":"text","options":[
						{ value: 6, text: 'Select date(s)'},
						// { value: 1, text: 'Single date'},
						{ value: 2, text: 'Daily'},
						{ value: 3, text: 'Weekly'},
						{ value: 4, text: 'Fortnightly'},
						{ value: 5, text: 'Monthly'} 
					]},on:{"change":_vm.onUpdate},model:{value:(_vm.recurrence),callback:function ($$v) {_vm.recurrence=$$v},expression:"recurrence"}})],1),(_vm.recurrence)?[_c('div',{staticClass:"datepick sm:col-span-8 grid-cols-1 lg:grid-cols-2 lg:grid grid-flow-col gap-4"},[_c('div',{staticClass:"col-span-full lg:col-span-1 input-content"},[(!_vm.canSelectMultiple)?[_c('label',{attrs:{"for":"event-start"}},[_vm._v(" "+_vm._s(_vm.recurrence == 1 ? 'Date to generate' : 'Start from')+" ")]),_c('TDatePicker',{attrs:{"userFormat":"D d/m/Y","initialTime":"","required":"","multiple":_vm.canSelectMultiple,"timepicker":false},on:{"change":_vm.onStartDateSelected},model:{value:(_vm.startFrom),callback:function ($$v) {_vm.startFrom=$$v},expression:"startFrom"}})]:[_c('label',[_vm._v("Date(s)")]),_c('TDatePicker',{attrs:{"userFormat":"D d/m/y","multiple":_vm.canSelectMultiple,"timepicker":false,"required":"","placeholder":"Select date(s)"},model:{value:(_vm.repeats),callback:function ($$v) {_vm.repeats=$$v},expression:"repeats"}})]],2),(_vm.canRepeatFrequency)?_c('div',[_c('InputText',{ref:"repeatFrequency",attrs:{"required":"","id":"repeatFrequency","max":"52","label":"Recurrence length","hint":"The frequency must be completed.","error":"The frequency must be completed.","type":"number","placeholder":"0","name":"repeatFrequency","prefix":"for","suffix":_vm.frequencyLabel + '(s)'},on:{"input":[function($event){_vm.frequency = $event;},_vm.onUpdate]}})],1):_vm._e()])]:_vm._e()],2),_c('div',{staticClass:"action-bar"},[_c('GPButton',{attrs:{"disabled":_vm.getPreviewDisabled,"loading":_vm.isLoading,"size":"lg"},nativeOn:{"click":function($event){return _vm.generateRepeats.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.getPreviewLabel))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }