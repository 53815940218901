
<template>
    <div>
 
        <div v-if="modalOpen" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal rounded-3xl" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">

                        <div>
                            <div class="grid grid-cols-2 gap-4 place-content-end">
                                <div>
                                    <span class="block font-bold text-gray-900 text-xl" id="modal-title">Update gig tags</span> 
                                </div>
                                <div class="justify-self-end font-bold">
                                    <component :is="icons.XIcon" @click="toggleModal()" class="w-6 h-6 cursor-pointer" />
                                </div>
                            </div>

                            <hr class="mt-8 h-px border-0 bg-gray-100">

                            <div class="mt-6">
                                <span class="block font-bold text-gray-900 text-md" id="modal-title">{{ gig.name }}</span> 
                                <p>{{fullDate(gig.start)}}</p>
                            </div>

                            <div class="mt-6">
                                <span class="block font-bold text-gray-900 text-md" id="modal-title">Custom Tags</span> 
                                <div class="flex items-center space-x-3 mt-2">
                                    <div class="w-80">
                                        <TRichSelect 
                                            multiple
                                            v-model="custom_tags"
                                            ref="iconselect"  
                                            class="col-span-12 lg:col-span-5 cursor-pointer"
                                            :close-on-select="false" valueAttribute="id"  textAttribute="name"
                                            :options="user_custom_tags"
                                            placeholder="Filter by custom tag" 
                                        >  
                                            <template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">                                 
                                                <div class="flex gap-4 py-2">
                                                    <component :is="icons[option.raw.icon]" class="ml-3"/>  {{ option.raw.name }} 
                                                </div>
                                            </template>

                                        </TRichSelect> 
                                    </div>
                                    <div>
                                        <button @click="openCreateTagModal()" class="flex justify-center items-center bg-gp_pink-default-500 hover:bg-gp_pink-default-400 text-white text-sm font-bold py-1 px-4 rounded-3xl">
                                            <component :is="icons['PlusIcon']" class="w-5 h-5 mr-1"/>
                                            <span class="hidden md:block">New Tag</span>
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <footer class="mt-12">
                                <GPButton 
                                        @click.native="save()" 
                                        :disabled="isSubmitting"
                                        :loading="isSubmitting"
                                        :color="'pink'"
                                        size="md"
                                        class="w-full"
                                    >Save</GPButton>

                            </footer>
                        </div>
                    </div>
                </transition>        
            </div>
        </div>
        <NotificationSmall ref="notification" />
        <CreateCustomGigTag ref="createGigTag" v-on:addedTag="newCreatedTag($event)"/> 
        
    </div>
</template>

<script>
import NotificationSmall from '../../../components/notifications/small.vue';
import { TInput, TRichSelect } from 'vue-tailwind/dist/components';
import GPButton from '../../../components/ui/buttons/Button.vue';
import client from '@/utils/client.js'
import  * as icons from "@vue-hero-icons/outline" 
import fullDate from  '@/utils/format-date-full.js'  
import CreateCustomGigTag from '../../../components/modal/venues/CreateCustomGigTag.vue';

export default
{
    name: "AmendCustomGigTag", 
    components:{
        TInput,TRichSelect, GPButton, NotificationSmall, icons, CreateCustomGigTag
    },
    data(){
        return {
            modalOpen: false,
            gig: [],
            custom_tags: [],
            user_custom_tags: [],
            isSubmitting: false, 
            icons: icons,
            selectedIcon: null
        }
    },
    mounted() {
        client.get('custom-tags').then(response => (this.user_custom_tags = response.data.data));
    },
    methods: { 
        fullDate, 
        toggleModal()
        {
            this.modalOpen = !this.modalOpen;
        },
        updateGigTag(gig)
        {
            this.gig = JSON.parse(JSON.stringify(gig));
            this.custom_tags = this.gig.custom_tags.map(obj => obj.id);
            this.toggleModal();
        },
        openCreateTagModal() {
            this.$refs.createGigTag.modalOpen = true;
        },
        newCreatedTag(event) {
            this.user_custom_tags.push(event);
        },

        async save()
        {

            this.isSubmitting = true;

            // this.$emit('updatedTag', response.data.data);
            // Retrieve all tags attacged to the gig then update the rows at index

            let payload = { tags: this.custom_tags };

            client.post('gig-tags/' + this.gig.id, payload)
                .then(() => {
                    let emit = this.user_custom_tags.filter(obj => this.custom_tags.includes(obj.id));
                    this.$emit('updatedTag', emit);
                    this.$notify({text: 'Gig tag successfully updated.',  duration: 5000});
                    this.toggleModal();
                })
                .catch((error) => {
                    this.$notify({text: error.response.data.message,  duration: 5000});
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        }
    }
}
</script>