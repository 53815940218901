export default function changeAudio(data) {
    
    let url = "";

    try{
        url = new URL(data)
    }
    catch {      
        return "";
    }

    let amendedUrl = data.includes('soundcloud') ? 'https://w.soundcloud.com/player/?url=' + url : 'https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=' + url;
 
    if((url.host === 'soundcloud.com' || url.host === 'www.soundcloud.com' || url.host === 'mixcloud.com' || url.host === 'www.mixcloud.com')) {
        return amendedUrl;
    } else {
        return ''
    }
}