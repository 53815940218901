import store from "@/state/store";

export default {
    auth0Params(additionalParams) {
        let params = {...additionalParams}
        const brand = this.getBrand()

        params.brand = brand.name
        params.logo = brand.logo_url
        params.primaryColor = brand.primary_colour

        return params
    },
    getBrand() {
        const domainBrand = store.getters['api/domainBrand']

        const gigpig = {
            name: 'gigpig',
            logo_url: process.env.VUE_APP_LOGO_URL,
            logo_url_internal: process.env.VUE_APP_LOGO_URL_INTERNAL,
            primary_colour: process.env.VUE_APP_PRIMARY_COLOUR,
            css_file: null
        }

        if (domainBrand !== undefined) {
            return domainBrand !== null ?
             {
                name: domainBrand.name,
                logo_url: domainBrand.logo_url,
                logo_url_internal: domainBrand.logo_url_internal,
                primary_colour: domainBrand.primary_colour,
                css_file: domainBrand.css_file
            } : gigpig
        }
    },
    getLogoutUri(){
 
        const domainBrand = store.getters['api/domainBrand']

        if (domainBrand !== undefined && domainBrand !== null) {
            const prefix = process.env.NODE_ENV === 'development' ? 'http://' : 'https://'
            const domain = store.getters['api/domainBrand'].domain;

            if (domain && domain.length){
                return prefix + domain
            }
            else return process.env.VUE_APP_AUTH_CALLBACK_URL;
        } else {
            return process.env.VUE_APP_AUTH_CALLBACK_URL;
        }
    },
    getRedirectUri() {
        let redirectUrl = ''
        const domainBrand = store.getters['api/domainBrand']

        if (domainBrand !== undefined && domainBrand !== null) {
            const prefix = process.env.NODE_ENV === 'development' ? 'http://' : 'https://'
            redirectUrl = prefix + store.getters['api/domainBrand'].domain
        } else {
            redirectUrl = process.env.VUE_APP_AUTH_CALLBACK_URL
        }

        return redirectUrl + process.env.VUE_APP_AUTH_CALLBACK_PATH

    },
    getBaseUrl() {
        return process.env.VUE_APP_API
    },
}