import { canGigBeBooked } from './gigStatus'

// BID STATUS
const bidStatuses = [
	{ 
		code: 0, 
		name:'Uncaught', 
		type:'UNCAUGHT_STATUS', 
		bg:'rgb(255, 255, 255)', 
		color:'rgb(0, 0, 0)',
		class:'bid-uncaught',
	},
	{ 
		code: 50, 
		name:'Cancelled', 
		type:'CANCELLED', 
		bg:'rgb(243, 244, 246)', 
		color:'rgb(31, 41, 55)',
		class:'bid-cancelled',
	},
	{ 
		code: 200, 
		name:'Needs response', 
		type:'NEEDS_RESPONSE', 
		bg:'rgb(220, 38, 38)', 
		color:'#ffffff',
		class:'bid-needs-response',
	},
	{ 
		code: 300, 
		name:'Pending', 
		type:'PENDING', 
		bg:'rgb(254, 243, 199)', 
		color:'rgb(146, 64, 14)',
		class:'bid-pending',
	},
	{ 
		code: 400, 
		name:'Confirmed', 
		type:'CONFIRMED', 
		bg:'rgb(16, 185, 129)', 
		color:'rgb(255, 255, 255)',
		class:'bid-confirmed', 
	},
	{ 
		code: 450, 
		name:'Needs sign-off', 
		type:'AWAITING_SIGN_OFF', 
		bg:'rgb(180, 83, 9)', 
		color:'rgb(255, 255, 255)',
		class:'bid-awaiting-signoff',
	},
	{ 
		code: 500, 
		name:'Declined', 
		type:'DECLINED', 
		bg:'rgb(69, 216, 191)', 
		color:'rgb(153, 27, 27)',
		class:'bid-declined',
	},
	{ 
		code: 600, 
		name:'Signed-off', 
		type:'SIGNED_OFF', 
		bg:'rgb(209, 250, 229)', 
		color:'rgb(6, 95, 70)',
		class:'bid-signed-off',
	},
	{
		code: 700,
		name:'Artist needs replacing',
		type:'CANCELLATION_REQUESTED_BY_ARTIST',
		bg:'rgb(251,121,0)',
		color:'rgb(255,255,255)',
		class:'cancelled-by-artist',
	},
];


const bidStatusesArtistMappings = [
	{
		code: 700,
		name:'Artist cancelled gig',
		type:'CANCELLATION_REQUESTED_BY_ARTIST',
		bg:'rgb(251,121,0)',
		color:'rgb(255,255,255)',
		class:'cancelled-by-artist',
	},
];




const getStatusFromType = ( type ) => {
	if(!type){
		return bidStatuses[0];
	}

	const filteredStatus = bidStatuses.filter(s => s.type === type)

	const mappedStatuses = bidStatusesArtistMappings.filter(s => {
		return filteredStatus.map(fs => fs.type).indexOf(s.type) > -1
	})

	if(mappedStatuses.length){
		return mappedStatuses[0];
	}

	if(!filteredStatus.length){
		return bidStatuses[0]
	}
	return filteredStatus[0];
}

const getStatusesFromTypes = ( types ) => {
	if(!types){
		return bidStatuses;
	}
	const statuses = types.map(type => bidStatuses.filter(s => s.type === type)[0]);
	return statuses
}

const isBidWaitingToBeAcceptedByVenue = (bidStatusType, gigStatusType) => {
		if(bidStatusType === "UNCAUGHT_STATUS" ||
			bidStatusType === "AWAITING_SIGN_OFF" ||
			bidStatusType === "DECLINED" ||
			bidStatusType === "SIGNED_OFF" ||
			bidStatusType === "CANCELLATION_REQUESTED_BY_ARTIST"){
				console.log("bid no")
			return false
			
		}else if(bidStatusType == "NEEDS_RESPONSE"){
			return true;
		}
		else{
			// check gig!
			return canGigBeBooked(gigStatusType);
		}
}

const canBidBeAccepted = (bidStatusType, gigStatusType) => {
	if(bidStatusType === "UNCAUGHT_STATUS" ||
		bidStatusType === "CANCELLED" || 
		bidStatusType === "CONFIRMED" ||  
		bidStatusType === "AWAITING_SIGN_OFF" ||
		bidStatusType === "DECLINED" ||
		bidStatusType === "SIGNED_OFF" || 
		gigStatusType === "CONFIRMED" ||
		gigStatusType === "CANCELLED" ||
		gigStatusType === "CANCELLATION_REQUESTED_BY_ARTIST"
		// gigStatusType === "CANCELLED_BY_ARTIST"

	){
		return false
	}else{
		// check gig!
		return canGigBeBooked(gigStatusType);
	}
}

const isBidPending = (bidStatusType, gigStatusType) => {
	if(bidStatusType === "UNCAUGHT_STATUS" ||
		bidStatusType === "CANCELLED" || 
		bidStatusType === "CONFIRMED" ||  
		bidStatusType === "AWAITING_SIGN_OFF" ||
		bidStatusType === "DECLINED" ||
		bidStatusType === "SIGNED_OFF" || 
		gigStatusType === "CONFIRMED" ||
		gigStatusType === "CANCELLED"
	){
		return false
	}else{
		// check gig!
		return canGigBeBooked(gigStatusType);
	}
}

export { 
	bidStatuses, 
	getStatusFromType, 
	getStatusesFromTypes, 
	canBidBeAccepted,
	isBidWaitingToBeAcceptedByVenue,
	isBidPending,
};
