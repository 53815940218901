import {Interval} from 'luxon'; 
import pluralize from '@/utils/pluralize';

export default function timeAgo(date) {
 
    const now = new Date();
    let interval = null;
 
    if (new Date(date) < now){
        interval = Interval.fromDateTimes(new Date(date), now);
    }
    else{
        interval = Interval.fromDateTimes(now, new Date(date));
    }
 
    if (!interval.isValid)
        return "Just now";
     

    let days = Math.floor(interval.length('days'));
    let hours = Math.floor(interval.length('hours'));
    let minutes = Math.floor(interval.length('minutes'));
    let seconds = Math.ceil(interval.length('seconds'));

    let value = "";

    if (days != 0)
        value = pluralize(days, "day");
    else if (hours != 0)
        value = pluralize(hours, "hour");
    else if (minutes != 0)
        value = pluralize(minutes, "minute");
    else if (seconds != 0)
        value = pluralize(seconds, "second");

    //console.log("value", days, hours, minutes, seconds, value);

    return value;

}
 