<template>  
    <div v-if="viewModal" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
		<div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
			<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
			<transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">     
                <div class="inline-block align-bottom bg-white rounded-sm p-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-1">                      
                    <!-- Header -->
                    <div class="mt-4 px-6">
                        <div class="text-xl font-medium mb-2" v-if="item.name">{{item.name}}</div>  
                    </div>
                    <!-- Body --> 
                    <div class="overflow-auto max-h-72 px-6">
                        <div class="space-y-4 mt-8 mb-8">
                            <p v-if="item.venue.name"> Venue: {{item.venue.name}}</p>
                            <p v-if="item.start && item.end"> Date: {{ DateTime.fromISO(item.start).toUTC().toFormat('cccc DDD HH:mm')}} - {{ getDateHour(item.end)}}</p>
                            <div class="flex items-center space-x-4">
                                <p v-if="item.status"> Status: <gig-status :gig="getStatus" class="self-center" /></p>
                                <Button v-if="!isArtistView && hasManageGigsPermission && showDeleteButton"
                                    @click.native="deleteItem()"
                                    :disabled="btnDisabled"
                                    :loading="btnDisabled"
                                    size="sm"
                                    color="indigo"
                                    text="lg"
                                    ><TrashIcon class="mr-2 w-4 h-4s"/><span class="text-sm"> Delete gig</span></Button>
                            </div>

                            <div v-show="isGenre" class="flex-wrap md:flex w-full space-x-3 items-center">
                                <div v-if="!isArtistView && item.custom_tags.length > 0 " class="mb-6 space-x-3 flex items-center">
                                    <strong class="text-sm font-medium">Custom Tags:</strong>
                                    <div class="flex items-center justify-center px-3 py-1 rounded-3xl space-x-2 bg-black">
                                        <component :is="icons[firstCustomTagIconName(item.custom_tags[0])]" class="w-4 h-4" :style="{ color: firstCustomTagIconColour(item.custom_tags[0]) }"/>
                                        <span class="text-white text-sm">{{ firstCustomTagName(item.custom_tags[0]) }}</span>
                                    </div>
                                    <div>
                                        <span class="relative inline-flex flex-col items-center group" v-if="item.custom_tags.length > 0">
                                            <span v-if="item.custom_tags.length > 1"
                                            class="text-sm font-medium underline underline-offset-2 cursor-pointer"
                                            >+{{ item.custom_tags.length - 1 }} more</span>
                                            <div class="absolute z-10 bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
                                            <span class="p-4 bg-black rounded-sm shadow-lg w-48">
                                                <ul class="list-none">
                                                <li v-for="tag in item.custom_tags" :key="tag.id" class="text-white flex space-x-4 items-center space-y-1">
                                                    <component :is="icons[tag.icon]" class="w-5 h-5" :style="{ color: tag.icon_colour }"/>
                                                    <span>{{ tag.name }}</span>
                                                </li>
                                                </ul>
                                            </span>
                                            <div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="!isArtistView && hasTagAccess" class="mb-6 space-x-3 flex items-center">
                                    <Button 
                                        @click.native="openUpdateTagsModal(item)"
                                        size="sm"
                                        color="pink"
                                        text="lg"
                                    >
                                        <TagIcon class="mr-2 w-4 h-4s"/><span class="text-sm">Manage Tags</span>
                                    </Button>
                                </div>
                            </div>
                            
                        </div>
                        <template v-if="!isArtistView && item.bookings.length > 0">
                            <div class="relative flex py-5 items-center">
                                <div class="flex-grow border-t border-gray-400"></div>
                                    <MusicNoteIcon class="mx-6"/>
                                <div class="flex-grow border-t border-gray-400"></div>
                            </div>
                            <h2 v-show="displayStaged" class="font-bold">Pending Artists</h2>
                            <h2 v-show="!displayStaged" class="font-bold">Booked Artists</h2>
                            <div v-for="(booking, index) in item.bookings"  :key="'booking' + index">
                                <div v-if="booking.artist" class="grid grid-flow-col gap-4 justify-start items-start my-6">
                                    <img v-if="booking.artist.image" :src="parseImage(booking.artist)" class="w-20 h-20 object-cover object-center" /> 
                                    <img v-else :src="require('@/assets/images/placeholder.svg')" class="w-20 h-20 object-cover object-center" /> 
                                    <div class="text-left">
                                        <div class="text-sm"> 
                                            <div>
                                                <p v-if="booking.artist.name" class="my-1">
                                                    Name:  {{ booking.artist.name }} 
                                                </p>
                                                <p v-if="booking.artist.tier && booking.artist.tier.name" class="my-1">
                                                    Type:  {{ booking.artist.tier.name }} 
                                                </p>
                                                <span v-if="booking.fee" class="my-1">
                                                    Fee:   {{ item.currency.symbol }}{{ booking.fee }} <span v-if="booking.artist.vat_number">+ VAT</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="isArtistView && artistBid.accepted && artistBid.artist_final_accepted">
                            <div class="relative flex py-5 items-center">
                                <div class="flex-grow border-t border-gray-400"></div>
                                    <MusicNoteIcon class="mx-6"/>
                                <div class="flex-grow border-t border-gray-400"></div>
                            </div>
                            <div class="grid grid-flow-col gap-4 justify-start items-start my-6">
                                <img v-if="bookedArtist" :src="bookedArtist" class="w-20 h-20 object-cover object-center" /> 
                                <img v-else :src="require('@/assets/images/placeholder.svg')" class="w-20 h-20 object-cover object-center" /> 
                                <div class="text-left">
                                    <div class="text-sm"> 
                                        <div>
                                            <p v-if="artistBid.artist.name" class="my-1">
                                                Name:  {{ artistBid.artist.name }} 
                                            </p>
                                            <p v-if="artistBid.artist.tier.name" class="my-1">
                                                Type:  {{ artistBid.artist.tier.name }} 
                                            </p>
                                            <span v-if="artistBid.fee" class="my-1">
                                                Fee:   {{ artistBid.gig.currency.symbol }}{{ artistBid.fee }} <span v-if="artistBid.vat_number">+ VAT</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="this.item.bookings.length > 1">
                                <div class="relative flex py-5 items-center">
                                    <div class="flex-grow border-t border-gray-400"></div>
                                        <span class="px-4">Other Bookings</span>
                                    <div class="flex-grow border-t border-gray-400"></div>
                                </div>
                                
                                <div v-for="(booking, index) in item.bookings"  :key="'booking' + index" 
                                    v-show="booking.artist.id !== artistBid.artist.id" 
                                    class="grid grid-flow-col gap-4 justify-start items-start my-6"
                                    >
                                    <img v-if="booking.artist.image && booking.artist.image.url !== null" :src="parseImage(booking.artist)" class="w-20 h-20 object-cover object-center" /> 
                                    <img v-else :src="require('@/assets/images/placeholder.svg')" class="w-20 h-20 object-cover object-center" /> 
                                    <div class="text-left">
                                        <div class="text-sm"> 
                                            <div>
                                                <p v-if="booking.artist.name" class="my-1">
                                                    Name:  {{ booking.artist.name }} 
                                                </p>
                                                <p v-if="booking.artist.tier && booking.artist.tier.name" class="my-1">
                                                    Type:  {{ booking.artist.tier.name }} 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                    </div>
                    <!-- Footer -->
                    <footer class="flex flex-col sm:flex-row w-full justify-between bg-gray-50">
                        <div class="px-2 pt-3 pb-0 sm:pb-3 sm:px-3 flex flex-col sm:flex sm:flex-row-reverse space-x-4">
                            <Button @click.native="viewModal = false;" :disabled="btnDisabled" color="gray">Close</Button>
                        </div>
                        <div class="px-2 py-3 sm:px-3 flex space-x-0 sm:space-x-4 space-y-2 sm:space-y-0 flex-col sm:flex-row">
                            <router-link type="button" :to="isArtistView ? ('/artist/bookings/' + bidId) : ('gigs/' + item.id)" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto">
                                <ArrowCircleRightIcon class="mr-2"/>Go to gig
                            </router-link>
                        </div>
                    </footer>
				</div>
            </transition>
		</div>

        <AmendCustomGigTag ref="updateGigTags" v-on:updatedTag="updatedTags($event)"/> 
	</div>
</template>

<script> 
import GigStatus from '../iconsets/GigStatus.vue'
import { apiComputed, apiMethods } from '@/state/helpers';
import { getStatusFromType as getGigStatus } from '@/state/models/gigStatus'
import { getStatusFromType as getBidStatus } from '@/state/models/bidStatus'
import {DateTime} from 'luxon';
import { TrashIcon, ArrowCircleRightIcon, MusicNoteIcon, TagIcon } from "@vue-hero-icons/outline";
import Button from '../../components/ui/buttons/Button.vue';
import normalizeDate from '../../utils/normalizeDate.js';
import  * as icons from "@vue-hero-icons/outline" 
import AmendCustomGigTag from '../../components/modal/gigs/AmendCustomTags.vue';
import {mapState} from "vuex";

export default {
  components: { GigStatus, Button, TrashIcon, ArrowCircleRightIcon, MusicNoteIcon, TagIcon, AmendCustomGigTag },
    name: "CalendarPreview",
    data(){
        return {
            bookedArtist: this.theArtist,
            artistBid: this.gigBid,
            item: this.calendarItem,
            viewModal: this.showModal,
            user: null,
            DateTime,
            btn: false,
            displayStaged: false,
            icons: icons
        }
    },
    props:{
        theArtist: Object,
        gigBid: Object,
        calendarItem: Object,
        showModal: Boolean,
        viewType: String,
        bidId: Number,
				showAsBid:{
					type:Boolean,
					default:false
				}
    },
    created() {
        try {
            this.fetchUser().then((user) => {
                this.user = user;
            });
        } catch (error) {
            console.error(error);
        }
    },
    mounted() {
        if (this.item) {
            if (this.item.bookings.length == 0 && this.item.staged_artists.length > 0) {
                this.displayStaged = true
                this.item.bookings = this.item.staged_artists
            }
        }
    },
    computed:{
        ...apiComputed,
        ...mapState({
			userRoles: (state) => state.user.rolesPermissionsSlugs,
		}),
        hasTagAccess()
        {
            if (this.userRoles && this.userRoles.length > 0) {
                return this.userRoles[0] == 'full-access';
            } else {
                return null; 
            }
        },
        userData(){
			return this.$store.getters['user/userData']
		},
		isGenre() {
            return this.userData && this.userData.email.includes('genremusic')
		},
        showDeleteButton()
        {
           return this.item.status === 'UNPUBLISHED' || this.item.status === 'CANCELLED' || this.item.status === 'PENDING' || this.item.status === 'NEEDS_RESPONSE';
        },
        isArtistView() {
          if (this.user !== null) {
            return this.user.type === 'ARTIST'
          }

          return this.viewType === 'ARTIST'

        },
        hasManageGigsPermission(){
            return this.$store.getters['user/hasManageGigsPermission']
        },
        getStatus(){
            return { status: this.showAsBid  ? getBidStatus(this.item.status) : getGigStatus(this.item.status) }
        },
        btnDisabled(){
            return this.btn;
        },
    },
    methods:{
        ...apiMethods,
        openUpdateTagsModal(gig) {
            this.$refs.updateGigTags.updateGigTag(gig);
        },
        updatedTags(event) {
            this.item.custom_tags = event;
        },
        firstCustomTagName(val){
            return val.name
        },
        firstCustomTagIconName(val){
            return val.icon
        },
        firstCustomTagIconColour(val){
            return val.icon_colour
        },
        async fetchUser() {
            return await this.$store.getters['user/userData']
        },
        getDateHour($date){
            let normalised = normalizeDate($date);
            let datetime = DateTime.fromFormat(normalised, 'yyyy/MM/dd HH:mm');
            return datetime.toFormat('HH:mm');
        },
        parseImage($artist){ 
            if ($artist.image && $artist.image.conversions && $artist.image.conversions.thumb_xs) {
                return $artist.image.conversions.thumb_xs;
            }
            else if ($artist.image && $artist.image.conversions && $artist.image.conversions.thumb) {
                return $artist.image.conversions.thumb;
            }
            else return $artist.image.url;
        }, 
        deleteItem(){ 
            this.btn = true;
            let $self = this;
            this.deleteGig(this.item).finally(() => {
                $self.$emit("deletion", true);
                $self.$notify("Gig successfully deleted");
                this.viewModal = false;
                this.btn = false;
                this.$emit("refresh");
            });
        }
    }
}
</script>