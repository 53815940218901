<template>
  <Modal :show="showModal" @closeModal="close">
    <template #header><h3 class="text-xl font-bold leading-6 text-gray-900 block" id="modal-title">Cancel Booking</h3></template>
    <template #header2><p class="block mt-2">You are about to cancel your booking. The venue will be informed. Please select a reason for your cancellation and click Cancel Booking to continue.</p></template>
    <template #default>

      <div>
        <div
            class="mx-0 p-0 important py-0">
          <div class="text-left sm:mt-5">
            <div class="text-sm" style="color: #000 !important;">
              <TRichSelect
                  v-model="selectedReason"
                  class="col-span-12 lg:col-span-5 cursor-pointer"
                  :close-on-select="true"
                  :options="cancellationReasons"
                  :hideSearchBox="true"
                  valueAttribute="id"
                  textAttribute="reason"
                  placeholder="Select a reason for cancellation"
              >
              </TRichSelect>
            </div>
          </div>
          <TTextarea
              v-if="selectedReason && selectedReason === 6"
              v-model="other"
              class="col-span-12 lg:col-span-7 mt-6"
              placeholder="Other (please specify)"
              ></TTextarea>
          <p v-if="errorMessage" class="errors text-red-600 mt-3">{{ errorMessage }}</p>
        </div>

      </div>
    </template>

    <template #buttons>


      <div class="text-left sm:mt-5">
        <div class="text-sm">
          <form  class="form mb-36 lg:mb-24">
            <div class="action-bar px-6 w-full justify-between" style="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
              <GPButton
                  class="w-full lg:w-auto"
                  @click.native.prevent="close"
                  color="indigo">
                Close</GPButton>

              <GPButton
                  class="w-full lg:w-auto"
                  @click.native.prevent="confirmCancellation"
                  color="pink"
                  :disabled="acceptBtnDisabled"
              >
                Cancel Booking</GPButton>
            </div>
          </form>
        </div>
      </div>

    </template>
  </Modal>

</template>

<script>
import Modal from "@/components/Modal.vue";
import GPButton from "@/components/ui/buttons/Button.vue";
import {apiMethods} from "@/state/helpers";
import {TRichSelect, TTextarea} from "vue-tailwind/dist/components";
import NProgress from "nprogress";
export default {
  name: "ArtistCancellationModal",
  components: {
    GPButton,
    Modal,
    TRichSelect,
    TTextarea
  },
  data() {
    return {
      index: -1,
      declineBtnDisabled: false,
      other: '',
      selectedReason: null,
      errorMessage: 'Please give a reason for your cancellation',
    }
  },
  props: {
    showModal: {type: Boolean, default: false},
    bidId: {type: Number, default: null, required: false},
    cancellationReasons: { type: Array, default: () => []}
  },
  methods: {
    ...apiMethods,
    confirmCancellation() {
      if (this.selectedReason) {

        if (this.selectedReason === 6 && this.other === '') {
          this.errorMessage = 'Please give a reason for your cancellation'
          return
        }

        NProgress.start();

        this.$emit('booking-cancelled', {reason: this.selectedReason, other: this.other})

      } else {
        this.errorMessage = 'Please select a reason for your cancellation'
      }
    },
    close() {
      this.$emit('show-cancellation-modal', false)
      this.btnDisabled = false
    },
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        NProgress.done();
      }
    },
    selectedReason() {
      if (this.selectedReason) {
        if (this.selectedReason === 6 && this.other === '') {
          this.errorMessage = 'Please give a reason for your cancellation'
        } else {
          this.errorMessage = false
        }
      } else {
        this.errorMessage = 'Please select a reason for your cancellation'
      }
    },
    other() {
      if (this.other && this.other.length && this.selectedReason === 6) {
        this.errorMessage = false
      } else {
        this.errorMessage = 'Please select a reason for your cancellation'
      }
    }
  },
  computed: {
    acceptBtnDisabled() {
      return this.errorMessage !== false || !this.selectedReason
    }
  },
  emits: ['show-cancellation-modal', 'booking-cancelled']
}
</script>