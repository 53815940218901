<template>
  <div>
    <ModalDefault :venue_only="true" />
  </div>
</template>

<script> 
 
import ModalDefault from "../../components/modal/ModalDefault.vue"; 

export default {
  name: "VenueLogin",
  components: {
    ModalDefault, 
  },
  beforeRouteUpdate(){
 

  }
};
</script>
