<template>
    
    <div class="gigtemplate"> 
        <div class="details-view mb-72">
            <h3>Edit gig templates</h3>

            <div>
                <div class=" lg:max-w-5xl"> 

                    <form class="form" ref="form" novalidate>
                        
            <div class="sm:col-span-3 grid grid-flow-col ">
                    <input-text  required id="name" ref="name" v-on:input="form.name = $event;" v-bind:value="form.name" label="Gig template name" hint="The template name must be populated e.g. Thursdays at Corkies"
                error="The template name must be completed" placeholder="e.g. Thursdays at Corkies" name="name"/>
                </div>



                <div class="sm:col-span-6 grid grid-flow-col ">
                    <input-text-area :rows="rowHeight" type="textarea"  required id="internal_desc" v-on:input="form.internal_desc = $event" v-bind:value="form.internal_desc" label="Internal description (not visible to artists)" hint="The internal description must be populated e.g. Use this for gigs involving the lightning seeds.  This field will only be shown to your team."
                        error="The internal description must be completed" placeholder="e.g. Use this template for any gig taking place at Thursday's with Joan" name="internal_desc"/>
                </div>   
                
                
        <Accordian heading="Artist Instructions" :toggle="accordians.open_artist_instructions">
            <template #contents>
              
           
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="load_in_time" label="Load in times" name="load_in_time" v-on:input="form.load_in_time = $event" v-bind:value="form.load_in_time" placeholder="e.g. You can gain access to the room any time during the day. If you are planning on setting up earlier then please check with the venue first" />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="sound_check_time" label="Sound check times" name="sound_check_time" v-on:input="form.sound_check_time = $event" v-bind:value="form.sound_check_time" placeholder="e.g. Must be checked by 7pm"  />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="performance_times" label="Performance times" name="performance_times" 
                            placeholder="e.g. 7:30pm – 8:30pm, 9pm - 10pm "  v-on:input="form.performance_times = $event" v-bind:value="form.performance_times"  />
                        </div>
                        <h2 class="col-span-full pt-4">Equipment Details</h2>
                        <div class="sm:col-span-6 grid grid-flow-col  ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="equipment_venue"  v-on:input="form.equipment_venue = $event" v-bind:value="form.equipment_venue" placeholder="e.g. Venue will only supply sound system" label="Equipment provided by venue" name="equipment_venue"/>
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="equipment_artist" v-on:input="form.equipment_artist = $event" v-bind:value="form.equipment_artist" placeholder="e.g. Must provide own stereo jack cables and mic cables." label="Equipment provided by artist" name="equipment_artist"/>
                        </div>
                        <h2>Set info</h2>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="repertoire" label="Repertoire" name="repertoire"  v-on:input="form.repertoire = $event" v-bind:value="form.repertoire" placeholder="e.g. A good mix of old classics and current tunes is essential. Keep your sets as upbeat as possible, even if the venue doesn’t seem that busy."/>
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="dress_code" label="Dress code" name="dress_code" placeholder="e.g. Smart Casual" v-on:input="form.dress_code = $event" v-bind:value="form.dress_code" />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="parking_details" label="Parking details" name="parking_details"  v-on:input="form.parking_details = $event" v-bind:value="form.parking_details" placeholder="e.g. Loading available outside of venue. Free public parking close to venue" />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area  :rows="rowHeight"  type="textarea"  id="additional_notes" v-on:input="form.additional_notes = $event" v-bind:value="form.additional_notes" label="Additional notes" name="additional_notes"  placeholder="e.g. Musician to ask for the manager on duty upon arrival"/>
                        </div>
                        <div class="sm:col-span-6  grid-flow-col">
                            <div class="mt-1 input-group">
                                <label for="briefs" class="block text-sm font-medium text-gray-700"> Briefs </label>
                                <div class="input-content min-h-[138px] tiptap-content">
                                    <tiptap @input="setBriefs" :id="'briefs'" :value="form.briefs" tabindex="0" class="tiptap-container"/>
                                    <div class="input-error"></div>
                                </div>
                            </div>
                        </div>
                        <h2>Commercial</h2>
                        <div class="sm:col-span-6 grid-flow-col hidden">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="invoicing" v-on:input="form.invoicing = $event" v-bind:value="form.invoicing" label="Invoicing" name="invoicing" placeholder="e.g. If you are completing work on a regular weekly basis we ask that you submit your weekly invoice as promptly as possible, (ideally on a Sunday)." />
                        </div>
                        <div class="sm:col-span-6 grid-flow-col hidden">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="important" v-on:input="form.important = $event" v-bind:value="form.important"  label="IMPORTANT INFORMATION" name="important" placeholder="e.g. Your Invoice must be made out to: ABC Music Ltd, 10 Samson Close" />
                        </div>
                        <div class="sm:col-span-6 grid grid-flow-col ">
                            <input-text-area :rows="rowHeight"  type="textarea"  id="payment_terms" v-on:input="form.payment_terms = $event" v-bind:value="form.payment_terms"  label="Payment terms" name="payment_terms" placeholder="e.g. We settle our invoices on the first Monday that falls at least 21 days from the receipt of your invoice." />
                        </div>

          </template>
        </Accordian> 
 

        <Accordian heading="Fees" :toggle="accordians.open_fees">
            <template #contents>
                            <Fees 
                                ref="fees" 
                                :gig-tiers="gigTiers"
                                :is-template="true"
                                @updated="getFees"
                                :prefix="prefix"
                            ></Fees>
                    <!-- <Fees ref="fees" :tiers="tiers" v-on:changeTiers="getFees($event)" :isTemplate="true">

                    </Fees> -->
                            
          </template>
        </Accordian> 
        <Accordian heading="Settings"  :toggle="accordians.open_settings">
            <template #contents>
                        <div class="hidden sm:col-span-1 grid grid-flow-col items-center">
                            <label for="enabled" class="mb-0">
                                Gig template enabled
                            </label>
                            <t-toggle name="enabled" v-model="is_enabled" id="enabled" ref="enabled" class="justify-self-end" />
                            
                        </div>
                        <div class="hidden sm:col-span-1  grid-flow-col items-center">
                            <label for="enabled" class="mb-0">
                                Make this template the default for new Gigs
                            </label>
                            <t-toggle name="default" id="default"  ref="default" v-model="is_default" class="justify-self-end" />
                            
                        </div>

                        <div class="col-span-full py-4">
                            <h2>How do you want to book artists - use artists you already have as favourites, or let GigPig find them for you?</h2>
                        </div>

                        <div class="sm:col-span-1 grid grid-flow-col items-center">
                            <label for="enabled">
                                Use my favourites lists
                            </label>
                            <t-toggle name="usefaves" id="usefaves"  ref="usefaves" v-model="use_faves" @click="switch_filter()" class="justify-self-end" />
                            
                        </div>


                        <div class="sm:col-span-1 grid grid-flow-col items-center">
                            <label for="enabled">
                                Let GigPig find artists for me
                            </label>
                            <t-toggle name="usefaves" id="usefaves"  ref="usesearch" v-model="use_search" @click="switch_filter()" class="justify-self-end" />
                            
                        </div>                 
                        
                        <div v-if="showSharedToggle" class="sm:col-span-1 grid grid-flow-col items-center">
                            <label for="enabled">
                                Enable Brand Visibility
                            </label>
                            <t-toggle name="allow_brand_visibility" id="allow_brand_visibility" v-model="allowBrandVisiblity" class="justify-self-end" />
                            
                        </div>    
            </template> 
        </Accordian>
        
        <Accordian heading="Configure Favourites" v-show="use_faves" :toggle="accordians.open_faves">
            <template #contents>


               <SearchByFave   ref="SearchByFave" 
               :passed_artistdirectory="passed_artistdirectory"
               :passed_tiers="tiers"  
               :passed_faveslists="passed_faveslists"  
               :genres="genres"
                :defaultFees="form.gigtemplatetiers" 
                :is_fetching_faves="loading_faves"
                :is_fetching_directory="loading_directory"
               
               />

            </template>
        </Accordian>
        <Accordian heading="Configure Search" v-show="use_search" :toggle="true" bg_class="bg-gp_pink-default-300">
            <template #contents>

              <span class="mb-2 mt-2">Pick a type of artist you'd like to search for</span>
              <t-rich-select

                  :close-on-select="true" v-model="search_tiers[0]"
                  :options="tiers"
                  :name="'tiers'"
                  text-attribute="name"
                  value-attribute="id"
                  placeholder="Type" class="w-full"  ref="tiers" @change="tiersChange"
              ></t-rich-select>

              <SearchByTag
                ref="SearchByTag" class="pb-48"
                 @tiersChange="tiersChange"
                 @coversChange="coversChange"
                 @genresChange="genresChange"
                 @moreInfoChange="moreInfoChange"
                :tagLimitReached="tagLimitReached"
                 :search_tiers="search_tiers"
                 :search_genres="search_genres"
                 :search_covers="search_covers"
                 :search_moreInfo="search_moreInfo"
                :showTiers="false"
                 :single_tier="true"
                 :tiers="tiers"
                 :covers="covers"
                 :genres="genres"
                 :moreInfo="moreInfo"
              ></SearchByTag>

            </template>
        </Accordian>

                    </form>

                <div class="action-bar justify-between">  
                    <router-link to="/gigtemplates" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 items-center bg-green-500 text-base font-medium text-white hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3  sm:w-auto">
                    <ArrowCircleLeftIcon class="mr-2"/>Back</router-link>              
                 
                    <div class="flex space-x-2 flex-col space-y-2 lg:flex-row items-end w-full lg:w-1/2"> 
                        <GPButton @click.native="save()" :disabled="buttonDisabled" class="w-full sm:w-4/12" color="indigo"><SaveIcon class="mr-2"/>Save template</GPButton>
                        <GPButton @click.native="deleteTemplate()" class="w-full sm:w-4/12" color="black"><TrashIcon class="mr-2"/>Delete template</GPButton>
                    </div>
                 </div>
                </div>
            </div>
        </div>
        <NotificationSmall ref="notification" />


<nav aria-label="Progress" class="hidden xl:fixed right-12 top-24 bg-white p-4 rounded-lg max-h-90vh overflow-y-auto">
  <ol role="list">
    <ProgressStep label="Template name" :isDone="form.name != ''"  /> 
    <ProgressStep label="Interal description " :isDone="form.internal_desc != ''" /> 
    <ProgressStep label="Venue equipment " :isDone="form.equipment_venue != ''" /> 
    <ProgressStep label="Artist equipment" :isDone="form.equipment_artist != ''" /> 
    <ProgressStep label="Repertoire" :isDone="form.repertoire != ''" /> 
    <ProgressStep label="Dress code" :isDone="form.dress_code != ''" /> 
    <ProgressStep label="Parking details" :isDone="form.parking_details != ''" /> 
    <ProgressStep label="Load in times" :isDone="form.load_in_time != ''" /> 
    <ProgressStep label="Sound check times" :isDone="form.sound_check_time != ''" /> 
    <ProgressStep label="Performance times" :isDone="form.performance_times != ''" /> 
    <ProgressStep label="Additional notes" :isDone="form.additional_notes != ''" /> 
    <ProgressStep label="Invoicing" :isCurrent="false" :isDone="form.invoicing != ''" /> 
    <ProgressStep label="Important information" :isDone="form.important != ''" /> 
    <ProgressStep label="Payment terms" :isDone="form.payment_terms != ''" :isLast="true" /> 
  </ol>
</nav>


    <DeleteGigTemplate ref="deleteGigTemplateModal" />

    </div>
    

</template>


<script> 
import Pristine from '../../utils/pristine.js'
import { apiComputed, apiMethods } from '@/state/helpers'; 
import {
  TToggle,
    TRichSelect
} from 'vue-tailwind/dist/components'
import InputText from '../../components/form/InputText.vue';
import NotificationSmall from '../../components/notifications/small.vue';
import InputTextArea from '../../components/form/InputTextArea.vue';
import ProgressStep from '../../components/progress/progress-step.vue';
import Accordian from '../../components/accordian/Accordian.vue';
import Fees from '../../components/gigs/Fees.vue';
import SearchByFave from '../../components/gigs/SearchByFave.vue';
import SearchByTag from '../../components/gigs/SearchByTag.vue';
import { SaveIcon, TrashIcon, ArrowCircleLeftIcon } from "@vue-hero-icons/outline";
import { TAG_TYPES } from '../../state/models/tagType.js';
import NProgress from 'nprogress';
import GPButton from '../../components/ui/buttons/Button.vue';
import DeleteGigTemplate from '@/components/modal/DeleteGigTemplate.vue';
import Tiptap from "@/components/form/Tiptap.vue";

export default {
    name: "GigTemplateDetails",
    components:{
    TToggle, 
    InputText, 
    InputTextArea,
    ProgressStep,
    SaveIcon,
    TrashIcon,
    ArrowCircleLeftIcon,
    NotificationSmall,
    Accordian,
    Fees,
    SearchByFave,
    SearchByTag,
    GPButton,
    DeleteGigTemplate,
    Tiptap,
    TRichSelect
},  
    data () {
 
       return {
            loading_faves: false,
            loading_directory: false,
            item:null,
            rowHeight: 5,
            is_enabled: true,
            is_default: false, 
            use_faves: true,
            use_search: false,
            allowBrandVisiblity: false,
            selectedFave: null,
            selectedArtist: null,
            search_tiers: [],
            search_genres: [],
            search_covers: [],
            search_moreInfo: [],
            faveslist: null,
            addedArtists: [],
            fees: [], 
            gigTiers:[],
            genres: [],
            moreInfo: [],
            covers: [],
            directory: [],
            passed_faveslists: [],
            passed_artistdirectory: [],
            accordians: {
                open_settings: true,
                open_fees: true,
                open_search: true,
                open_faves: true,
                open_artist_instructions: true                
            },
            pristine: null,
            pristineConfig: {
                classTo: 'input-group',
                errorClass: 'has-error',
                successClass: 'has-success',
                errorTextParent: 'input-error',
                errorTextTag: 'span',
                errorTextClass: 'text-help'
            }, 
            form: {
                name: '',
                internal_desc: '',
                equipment_artist: '',
                equipment_venue: '',
                repertoire: '',
                dress_code: '',
                parking_details: '',
                load_in_time: '',
                sound_check_time: '',
                performance_times: '',
                additional_notes: '',
                invoicing: '',
                important: '',
                payment_terms: '',
                briefs: '',
                allow_brand_visibility: false
            },
         buttonDisabled: false,
         prefix: '£',
         user: null,
         showSharedToggle: false,
         tiersWithGenres: null,
         selectionLimit: 5,
         tagLimitReached: false,
       }
    }, 
    async created(){
        await this.fetchTiers()
        this.getGigTemplates().then((resp) => {
            this.item = resp.find(item => item.id === Number(this.$attrs.id));
        }).finally(() => {
            // this.loadTemplate();
        })

        this.user = this.$store.getters['user/userData'];
    },
        mounted(){
            this.createGigTiers();
        },
    computed: {
        ...apiComputed,
    },
  watch: {
    item() {
      this.prefix = this.item && this.item.currency ? this.item.currency.symbol : '£';
      this.loadTemplate();
    },
    user() {
      if (this.user.brand) {
        this.showSharedToggle = 
        (this.user.brand.id === 8 || this.user.brand.id === 31)
      }
    }
  },
    methods:{
      fetchTiers() {
        return this.getTiers().then((resp) => {
          this.tiersWithGenres = resp;
        });
      },
        createGigTiers(){
            const tiers = this.$store.getters['api/tiers'];
            this.gigTiers = tiers.map(t => {
                const existingFee = this.fees.filter(ef => ef.tier_id === t.id)[0]
                return {
                    tier: t,
                    requested_fee: existingFee ? existingFee.requested_fee : "0",
                    tier_id: t.id,
                    id: t.id
                }
            })
        },
        setBriefs(value) {
            this.form.briefs = value;
        },
        loadTemplate(){

            this.loading_faves = true;

            if (!this.passed_faveslists || this.passed_faveslists.length == 0)
            {
                this.getFavesLists().then((resp) => {
                    this.passed_faveslists = resp;
                }).finally(() => {
                    this.loading_faves = false;
                });
            } else{
                this.loading_faves = false;                
            }

            this.loading_directory = true;

            this.getArtistDirectorySimple().then((resp) => {
                this.directory = resp;
                this.passed_artistdirectory = resp; 
            }).finally(() => {
                this.loading_directory = false;
            });

            this.pristine = new Pristine(this.$refs.form, this.pristineConfig); 

            this.genres = this.artisttags.filter(tag => tag.tag_type == TAG_TYPES.GENRE); 
            this.covers = this.artisttags.filter(tag => tag.tag_type == TAG_TYPES.COVERS);
            this.moreInfo = this.artisttags.filter(tag => tag.tag_type == TAG_TYPES.MORE_INFO);

            if (this.item)
            {
                let deepCopy = JSON.parse(JSON.stringify(this.item));            
                this.form = deepCopy;
                
                this.$refs.SearchByFave.setStaged(this.form.staged_artists);

                if (!this.form.tiers)
                {
                    this.form.tiers = [];
                }
                this.is_default = this.item.is_default == 1;
                this.is_enabled = this.item.is_enabled == 1;

                this.use_faves = this.item.search_type == 100;
                this.use_search = this.item.search_type == 200;

                this.allowBrandVisiblity = Boolean(this.item.allow_brand_visibility);
                
                this.$refs.SearchByFave.setStaged(this.item.staged_artists);

                this.search_tiers = this.item.gig_template_search_tiers.map((tag) => {return tag.tier_id});

                // this.$refs.fees.setFees(this.form.gigtemplatetiers);
                // this.$refs.fees.setTiers(this.item.gigtemplatetiers);
                this.fees = this.item.gig_template_tiers;
                this.createGigTiers()
            }
        },
        getFees()
        {
          const tempFees = this.gigTiers
              .filter(gt => gt.requested_fee !== "0")
              .map(f => {
                  return {
                      requested_fee: f.requested_fee,
                      tier_id: f.id,
                      id: f.id
                  }
              })

              this.fees = tempFees//$fees;
              this.$refs.SearchByFave.setDefaultFees(tempFees);
        },
        ...apiMethods,
        parseEnabled(){
            return  this.is_enabled ? 1 : 0;
        },
        parseDefault(){
            return  this.is_default ? 1 : 0;
        },
        switch_filter(){
            this.use_faves = !this.use_faves;
            this.use_search = !this.use_search;
        },
        save(){
            this.buttonDisabled = true

            var valid = this.pristine.validate(); 
            
            //TODO: validate if fave list has artists, or search criteria filled

            if (this.fees.filter(f => !f.requested_fee).length > 0) {
              this.$refs.notification.createNotification('You must enter a value for all fees. Enter 0 if no fee is required', true);
              valid = false;
              this.buttonDisabled = false;
              return;
            }


            if(!this.form.payment_terms){
                this.$refs.notification.createNotification('You must complete the payment terms', true);
                valid = false;
                this.buttonDisabled = false;
                return;
            }

            NProgress.start();

            if (valid)
            {
              let genres = [];

                if (this.search_genres != undefined)
                {
                  genres = this.search_genres;
                }



              let covers = [];

              if (this.search_covers != undefined)
              {
                covers = this.search_covers;
              }

              let moreInfo = [];

              if (this.search_moreInfo != undefined)
              {
                moreInfo = this.search_moreInfo;
              }

              let tags = [...genres, ...covers, ...moreInfo];

                let payload = this.form;
 
                payload.is_enabled = this.parseEnabled();
                payload.is_default = this.parseDefault();
                payload.search_type = this.use_faves ? 100 : 200;
                payload.add_artists = this.$refs.SearchByFave.addedArtists;
                payload.tags = tags; 
                payload.fees = this.fees; 
                payload.tiers = this.$refs.SearchByTag.search_tiers;
                payload.allow_brand_visibility = this.allowBrandVisiblity;
                
                const self = this; 
                if (payload.id == null)
                {
                    this.createGigTemplate(payload)
                        .then(() => {
                            this.$refs.notification.createNotification("Gig Template created");
                            self.$router.push({ path: `/gigtemplates` });
                        }).catch((error) => {
                            alert(error);
                            this.buttonDisabled = false;
                        })
                }
                else
                { 
                    this.updateGigTemplate(payload)
                        .then(() => {
                            this.$refs.notification.createNotification("Gig Template updated");
                            self.$router.push({ path: `/gigtemplates` });
                        }).catch((error) => {
                            alert(error);
                            this.buttonDisabled = false;
                    })
                }
                NProgress.done();
            }
        },
        deleteTemplate(){
            let modal = this.$refs.deleteGigTemplateModal;
            modal.item = this.item;
            modal.viewModal = true;
        },
      tiersChange(e) {

        this.selectedTiers = e;

        if (this.item) {
          this.search_genres = this.item.artist_tags.filter(tag => tag.tag_type == TAG_TYPES.GENRE).map((tag) => tag.id).map(t => t);
          this.search_moreInfo = this.item.artist_tags.filter(tag => tag.tag_type == TAG_TYPES.MORE_INFO).map((tag) => {return tag.id });
          this.search_covers = this.item.artist_tags.filter(tag => tag.tag_type == TAG_TYPES.COVERS).map((tag) => {return tag.id });
        }


        let tiers = [e];
        let genres = [];
        let covers = [];
        let moreInfo = [];
        let toSplice = [];

        // get all the genres associated to the picked tiers
        tiers.forEach((t) => {
          this.gigTiers.filter((f) => {
            if (t === f.id) {
              f.tier.genres.forEach((x) => {
                genres.push(x)
              })
              f.tier.covers.forEach((x) => {
                covers.push(x)
              })
              f.tier.more_info.forEach((x) => {
                moreInfo.push(x)
              })
            }
          })
        })

        // remove the duplicate objs from the array
        this.genres = genres.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === genres.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          });
        })

        this.covers = covers.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === covers.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          });
        })

        this.moreInfo = moreInfo.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === moreInfo.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          });
        })

        // splice genres that dont belong to the tier
        let genreChoices = Object.values(JSON.parse(JSON.stringify(this.search_genres)));

        genreChoices.forEach((c) => {
          let target = genres.filter((f) => { return f.id === c ? f : null });

          if (target.length === 0) {
            toSplice.push(c);
          }
        })

        toSplice.forEach((c) => {
          let index = genreChoices.indexOf(c);
              if (index !== -1) {
                genreChoices.splice(index, 1);
              }
        })

        this.search_genres = Object.assign([], genreChoices);


        let coversChoices = Object.values(JSON.parse(JSON.stringify(this.search_covers)));

        coversChoices.forEach((c) => {
          let target = covers.filter((f) => { return f.id === c ? f : null });

          if (target.length === 0) {
            toSplice.push(c);
          }
        })

        toSplice.forEach((c) => {
          let index = coversChoices.indexOf(c);
              if (index !== -1) {
                coversChoices.splice(index, 1);
              }
        })

        this.search_covers = Object.assign([], coversChoices);



        let moreInfoChoices = Object.values(JSON.parse(JSON.stringify(this.search_moreInfo)));

        moreInfoChoices.forEach((c) => {
          let target = moreInfo.filter((f) => { return f.id === c ? f : null });

          if (target.length === 0) {
            toSplice.push(c);
          }
        })

        toSplice.forEach((c) => {
          let index = moreInfoChoices.indexOf(c);
              if (index !== -1) {
                moreInfoChoices.splice(index, 1);
              }
        })

        this.search_moreInfo = Object.assign([], moreInfoChoices);
      },
      coversChange() {
        this.changesMade = true;

        this.coversChanged = true;
      },
      genresChange(selectedGenres) {

        if (selectedGenres.length > this.selectionLimit) {
          this.search_genres = selectedGenres.slice(0, this.selectionLimit);
        }

        this.tagLimitReached = selectedGenres.length === this.selectionLimit

        if (this.genresChanged)
          this.changesMade = true;

        this.genresChanged = true;
      },
      moreInfoChange() {
        if (this.moreInfoChanged)
          this.changesMade = true;

        this.moreInfoChanged = true;
      },
    }
}
</script>