import client from "../../utils/client"

export const state = {
  permissions: null,
}

export const mutations = {
  SET_ROLE_PERMISSIONS(state, permissions) {
    state.permissions = permissions
  }
}

export const getters = {
	all: state => {
    return state.permissions;
  },
}

export const actions = {
  getRolePermissions({ getters, commit }) {
		return new Promise((resolve) => {
			if(getters.all){
				resolve(getters.all)
			}else{
				client
					.get('permissions')
					.then((response) => {
						commit('SET_ROLE_PERMISSIONS', response.data.data);
						resolve(getters.all)
					})
					.catch(err => {
						console.log(err)
					});
			}
		})
  },
	requestRolePermissions({ commit }) {
    return client.get('permissions')
      .then((response) => {
        commit('SET_ROLE_PERMISSIONS', response.data.data);
      })
			.catch(err => {
				console.log(err)
			})
  }
} 