import client from "./client";

export const getUploadParameters = ({ name, type }) => {
  return client
    .post("/uploads", {
      file_name: name,
      mime_type: type,
    })
    .then((response) => {
      return {
        method: "put",
        url: response.data.data.url,
        headers: {
          "x-amz-acl": "private",
          "Content-Type": type,
        },
        fields: { id: response.data.data.id },
      };
    });
};

export const confirmUpload = (file) => {
  return client.post(`/uploads/${file.meta.id}/confirmations`);
};
