<template>
    <div>
        <h1>Dev</h1>
        <button ref="testgeo" @click="query">
            Test geo
        </button>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "dev",
    methods:
    {
        query()
        {
             axios
            .get(`/dev/query`)
            .then(response => {
                const responseGig = response.data.data 
                console.log("response gig", response); 
                return responseGig;
            })
        }
    }
}
</script>