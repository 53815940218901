<template>

    <div>  
           <VueHtml2pdf 
            :show-layout="false"
            :float-layout="true"
            :enable-download="enable_download"
            :preview-modal= "preview_modal"
            :paginate-elements-by-height="1400"
            filename="gig_pig_invoice"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            
image="{ type: 'jpg', quality: 0.95 }"
    
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content" id="pdf_content">
                <!-- PDF Content Here -->
                <InvoiceItem v-for="(page, index) in invoiceItems" :key="index" :invoiceItem="page" class="html2pdf__page-break" />
            </section>
        </VueHtml2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import InvoiceItem from './contents.vue'
export default{
    created() {
        window.addEventListener("resize", this.setDownloadPreviewParams);
    },
    mounted() {
        this.setDownloadPreviewParams();
    },
    name: "InvoiceGeneration",
    props:{
        invoiceItems: Array
    },
    data(){
        return{
            enable_download: false,
            preview_modal: false
        }
    },
    components:{
        InvoiceItem, VueHtml2pdf
    },
    methods:{
        setDownloadPreviewParams() {
            const matches = window.matchMedia("(max-width:768px)").matches;

            this.enable_download = matches
            this.preview_modal = !matches
        },
        generate(){
            this.$refs.html2Pdf.generatePdf()
        },     
        onProgress(loading){
            console.log(loading);
        },
        hasGenerated(loading){
            console.log(loading);
        },
        hasStartedGeneration(loading){
            console.log(loading);
        },
    }
}

</script>