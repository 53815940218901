<template>

<div v-if="modal_open && tier" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        
        
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
               
            </div>
    
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div  v-if="show_modal == 0">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center ">
                            <h3 class="block font-bold text-gray-900" id="modal-title"> 
                                Your speciality - {{tier.name}}
                            </h3> 
                            <p>Let us know what type of {{tier.name}} entertainer you are (Pick one, you can add more later).</p>
                            <div class="grid w-full gap-4"> 
                                <t-rich-select
                                    :close-on-select="false" ref="moreinfo" v-model="selections.more_info"
                                    :options="tier.more_info" text-attribute="name" value-attribute="id" 
                                    :placeholder="tier.more_info[0].name"  class="w-full"
                                ></t-rich-select> 
                            </div>
                        </div>
                        <div class="text-left sm:mt-5">
                        <div class="text-sm">  
                            <div class="flex justify-between">
                                <a class="gp_cta bg-gp_navy-default-500" @click="toggleModal()">Cancel</a>
                                <GPButton   @click.native="showCovers()" 
												:color="'pink'"
												:disabled="!selections.more_info" 
												size="md"
											>Next</GPButton>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div v-else-if="show_modal == 1">
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center "> 
                            <h3 class="block font-bold text-gray-900" id="modal-title"> 
                                Your speciality - {{tier.name}}
                            </h3> 
                            <p>Are you a cover singer, perform originals, or do both?</p>
                            <div class="grid grid-cols-2 gap-4">
                                <div class="grid grid-flow-col gap-4 justify-start items-center" v-for="(cover, index) in tier.covers" :key="'cover_' + index">
                                    <label :for="'cover_' + cover.id">{{cover.name}}</label>
                                    <input :id="'cover_' + cover.id" type="checkbox" v-model="selections.covers" :value="cover.id" /> 
                                </div>
                            </div>
                        </div>
                        <div class="text-left sm:mt-5">
                        <div class="text-sm">  
                            <div class="flex justify-between">
                                <a class="gp_cta bg-gp_navy-default-500" @click="showMoreInfo(true)">Back</a>

                                <GPButton   @click.native="showVocals()" 
												:color="'pink'"
												:disabled="!selections.covers.length" 
												size="md"
											>Next</GPButton>
                            </div>
                        </div>
                        </div>                        
                    </div>
                    <div class="grid grid-cols-2row gap-4" v-else-if="show_modal == 2">
                        
                        <h3 class="block font-bold text-gray-900" id="modal-title"> 
                            Your speciality - {{tier.name}}
                        </h3> 
                        <p>Do you sing? If so tick the boxes below.</p>

                        <div class="grid grid-flow-col gap-4 justify-start items-center" v-for="(cover, index) in tier.vocals" :key="'cover_' + index">
                            <label :for="'cover_' + cover.id">{{cover.name}}</label>
                            <input :id="'cover_' + cover.id" type="checkbox" v-model="selections.vocals" :value="cover.id" /> 
                        </div>  
                        <div class="text-left sm:mt-5">
                            <div class="text-sm">  
                                <div class="flex justify-between">
                                    <a class="gp_cta bg-gp_navy-default-500" @click="showCovers(true)">Back</a>
                                    
                                    <GPButton   @click.native="showGenres()" 
												:color="'pink'"
												size="md"
											>Next</GPButton>
                                </div> 
                            </div>                        
                        </div>   
                    </div>                 
                    <div v-else>
                        <div class="mx-auto grid grid-flow-row gap-4 justify-between items-center ">
                        
                        <h3 class=" font-bold leading-6text-gray-900" id="modal-title"> 
                            Your speciality - {{tier.name}}
                        </h3> 
                        <p>Venues search for artists based on specific genres. Select ONLY the genres you can perform professionally.</p>

                        <div class="grid w-full gap-4"> 
                            <t-rich-select
                                multiple
                                :close-on-select="false" ref="genres" v-model="selections.genres"
                                :options="tier.genres" text-attribute="name" value-attribute="id" 
                                :placeholder="tier.genres[0].name"   class="w-full"
                            ></t-rich-select> 
                        </div>
                        </div>
                        <div class="text-left sm:mt-5">
                        <div class="text-sm">  
                            <div class="flex justify-between">
                                <a class="gp_cta bg-gp_navy-default-500" @click="showVocals(true)">Back</a>
                                <GPButton   @click.native="finish()" 
												:color="'pink'"
												:disabled="!selections.genres.length" 
												size="md"
											>Finish</GPButton>
                            </div>
                        </div>
                        </div>                        
                    </div> 
                </div>
            </transition>        
        
        </div>
</div>

</template>

<script>

import { apiComputed, apiMethods } from '@/state/helpers.js'
import { TRichSelect } from 'vue-tailwind/dist/components';
import GPButton from '../../components/ui/buttons/Button.vue'

    export default
    {
        name: "ArtistSpeciality", 
        components:{
         TRichSelect,
         GPButton
        },
        data(){
            return {
                tier: {}, 
                defaults: {
                    more_info: '',
                    covers: [],  
                    vocals: [],
                    genres: []
                },
                selections: {
                    more_info: '',
                    covers: [],  
                    vocals: [],
                    genres: []
                },  
                show_modal: 0,
                modal_open: false, 
                isUpdate: false
            }
        },
        computed:{
            ...apiComputed, 
        },

        mounted(){ 
        },
        methods: {
            ...apiMethods, 
            toggleModal() {
                this.modal_open = !this.modal_open;
                
                if (this.modal_open){
                    Object.assign(this.selections, this.defaults);
                    this.selections.genres = [];
                    this.isUpdate = false;
                    
                    this.showMoreInfo();
                }
            },
            showMoreInfo(back){
                
                if (this.tier.more_info && this.tier.more_info.length > 1){
                    this.show_modal = 0;
                } else if (back) {
                    this.toggleModal();
                }
                else{
                    this.showCovers();
                }
            },
            showCovers(isBack){
 
                if (this.tier.covers != null && this.tier.covers.length > 1){
                    this.show_modal = 1; 
                }
                else if (isBack){
                    this.showMoreInfo(isBack);
                }
                else{
                    this.showVocals();
                }
            },
            showVocals(isBack){
 
                if (this.tier.vocals && this.tier.vocals.length > 1){ 
                    this.show_modal = 2;
                }
                else if (isBack){
                    this.showCovers(true);
                }
                else{
                    this.showGenres();
                }
            },
            showGenres(isBack)                    
            {   
                if (this.tier.genres && this.tier.genres.length > 1){ 
                    this.show_modal = 3;
                } 
                else if (isBack){
                    this.showVocals(true);                
                }                                
                else {
                    this.finish();
                }
            },
            finish(){
                // emit object

                let completedSelection = {};

                if (this.selections.more_info == ''){
                    this.selections.more_info = [];
                }
                else{                    
                    this.selections.more_info = [this.selections.more_info];
                }

                Object.assign(completedSelection, this.selections);
                
                if (this.isUpdate) {
                    this.$emit('onUpdatedSelections', completedSelection);
                } else {
                    this.$emit('onPickedSelections', completedSelection);
                }
                this.toggleModal();
            },
            updateSpecialities($specials)
            {
                this.isUpdate = true;

                let copy = JSON.parse(JSON.stringify($specials));
                
                this.selections = {
                    more_info: copy.more_info[0],
                    covers: copy.covers,  
                    vocals: copy.vocals,
                    genres: copy.genres
                }

                this.show_modal = 0;
                this.modal_open = !this.modal_open;
            }
        }
    }

</script>