<template>
	
	<ModalWrapper 
		ref="repeatConfirmModal" 
		:is-open="isOpen"
	>
		<template #header>
			<div class="text-xl font-semibold">{{ modalTitle }}</div>
			<p class="mt-2 text-md">This will amend fee <span class="font-semibold">{{ getArtistsName }} </span>for:</p>
		</template>

		<template #body>

			<form class="mb-24 form input-group" ref="form" novalidate> 
				<div class="mt-4 col-span-full">
						<div class="mt-1 sm:mt-0">
							{{ getGigName }}
						</div>
						<div class="mt-1 sm:mt-0">
							{{ getVenueName }}
						</div>
						<div class="mt-1 sm:mt-0">
							{{ getGigStartDate }}
						</div>
						<div class="grid grid-cols-2 sm:grid-cols-4 gap-4 items-center mt-4">
							<div class="col-span-1">
								Current fee:
							</div>
							<div>
								{{ getCurrentBidFee }} {{ getVAT }}
							</div>
						</div>

						<div class="grid grid-cols-2 sm:grid-cols-4 gap-4 items-center mt-1">
							<div class="col-span-1">
								New fee:
							</div>

							<input 
								type="number" name="fee" id="fee" 
								v-model.trim="fee" 
								:disabled="isLoading"
								@blur="onInputFeeBlur"
								class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" 
								placeholder="0.00"
								min="0"
								:prefix="getCurrencySymbol"
							/> {{ getVAT }}
						
						</div>
						<p v-if="inputValidity === 'invalid'" class="mt-2 text-sm text-red-600" id="name-error">
							{{ getFeeInvalidMessage}}
						</p>
						<p v-else class="mt-2 text-sm">&nbsp;</p>
				</div>



			</form>
		</template>

		<template #footer>

			<ModalFooterWrapper>
				<template #left>
					<Button 
						@click.native="onClose" 
						:disabled="isLoading" 
						color="gray"
					>Cancel</Button>
				</template>
				<template #right>
					<Button 
						@click.native="onAmend" 
						:disabled="submitDisabled" 
						:loading="isLoading"
					>
						{{ submitButtonLabel }}
					</Button>
				</template>
			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>
	
</template>

<script>
import ModalWrapper from '../../ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '../../ui/modal/ModalFooterWrapper.vue'
import Button from '../../ui/buttons/Button.vue'
import client from '../../../utils/client';
// import { DateTime } from 'luxon';
import fullDate from  '@/utils/format-date-full.js'  
export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		Button,
	},
	props:{
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
		bid:{
			type:Object,
			required: true,
		},
	},
	emits:["closed", "updated", "error"],
	data(){
		return {
			isLoading:false,
			error:false,
			fee:null,
			inputValidity:"",
		}
	},
	watch:{
		bid(){
			this.fee = null
		}
	},
	computed:{
		modalTitle() {
      return `Fee amendment`
    },
		getVAT(){
			return this.bid && this.bid.artist.vat_number != null ? "+ VAT" : "";
		},
		getGigName(){
			return this.bid && this.bid.gig.name ? this.bid.gig.name : "No gig details";
		},
    getCurrencySymbol(){
      return this.bid && this.bid.gig.currency ? this.bid.gig.currency.symbol : "";
    },
		getVenueName(){
			return this.bid && this.bid.gig ? this.bid.gig.venue ? this.bid.gig.venue.name : "" : "No venue details";
		},
		getArtistsName(){
			return this.bid && this.bid.artist ? this.bid.artist.name : "no artists details";
			
		},
		getGigStartDate(){
			// return DateTime ? DateTime.fromISO(this.bid.gig.start).toUTC().toFormat('cccc DDD HH:mm') : ''
			return this.bid && this.bid.gig ? fullDate(this.bid.gig.start) : ''
		},
		getCurrentBidFee(){
			return this.bid && this.bid.fee ? `${this.getCurrencySymbol}${ this.bid.fee }` : "no bid fee";
		},
		getFeeInvalidMessage(){
			let msg = "A new fee (in £'s) must be entered."
			if(Number(this.fee) < 0){
				msg = `${msg} The fee must no be lower than £0`
			}
			return msg
		},
		submitButtonLabel() {
      return this.isLoading ? 'Amending fee' : 'Amend fee'
    },
		submitDisabled() {
      return this.isLoading || !this.fee;
    },
		
	},
	methods:{
		onClose(){
			this.error = false;
			this.$emit("closed");
		},
		onInputFeeBlur(){
			if(this.inputValidity !== ''){
				if(this.checkFeeValid()){
					this.inputValidity = "invalid"
				}else{
					this.inputValidity = "valid"
				}
			}
		},
		formValid(){
			let valid = true;
			if(this.checkFeeValid()){
				valid = false;
				this.inputValidity = "invalid"
			} else {
				this.inputValidity = "valid"
			}
			return valid;
		},
		checkFeeValid(){
			return !(this.fee !== '' && Number(this.fee) && Number(this.fee) >= 0)
		},
		onAmend(){
			if(this.formValid()){
				this.isLoading = true;
				this.error = false;
				//
				const payload = {
					fee: this.fee,
				}

				client
				.put(`gigs/${this.bid.gig.id}/bids/${this.bid.id}/fees`, payload)
					.then((response) => {
						this.isLoading = false;
						// setTimeout(() => {
						// 	this.isLoading = false;
						// },3000)
						this.$emit("updated", response.data.data);
					})
					.catch(err => {
						console.log(err)
						this.isLoading = false;
						this.error = true;
						this.$emit("error", payload);
					})
			}
		},
	},
}
</script>

