<template>
	
	<ModalWrapper 
		ref="deleteRoleModal" 
		:is-open="isOpen"
	>
		<template #header>
			<div class="text-xl">{{ modalTitle }}</div>
			<p class="mt-2 text-sm">{{ introText }}</p>
		</template>

		<template #body>
			
			<template v-if="role.users.length">
				<form class="mb-24 form input-group" ref="form" novalidate> 
					<div class="mt-6 sm:mt-5 col-span-full">

						<div 
							class="grid justify-center items-center grid-flow-row-dense grid-cols-10 gap-px space-y-4 mb-4" 
							v-for="(value, key) in form.users"
							:key="key"
						>
							<span class="col-span-10 sm:col-span-3">
								<span class="text-sm font-medium text-gray-900">
									{{ getMemberInfo(String(key)) }}
								</span>
							</span>
							<TSelect 
								:id="String(key)"
								class="col-span-9 sm:col-span-6 force-mt-0" 
								@change.native="onSelectChange"
								:options="roleOptions"
								placeholder="Select a new role"
								:disabled="isLoading"
							/>
							<div class="col-span-1 flex justify-center items-center force-mt-0 text-sm font-medium">

								<span v-if="value === 'success'" class="text-green-600">
									<CheckIcon />
								</span>
								<span v-else-if="value === 'error'" class="text-red-600">
									<XIcon />
								</span>
								<span v-else-if="isLoading">
									<div 
										style="border-top-color:transparent"
										class="w-4 h-4 border-2 border-gray-300 border-solid rounded-full animate-spin"
									></div>
								</span>
								<span v-else>
									&nbsp;
								</span>
							</div>
						</div>
			
					</div>
				</form>
			</template>
			<template v-if="!role.users.length">
				<p>Are you sure you want to delete this role?</p>
			</template>

		</template>

		<template #footer>

			<ModalFooterWrapper>
				<template #left>
					<Button @click.native="onClose" :disabled="isLoading" color="gray">Close</Button>
				</template>
				<template #right>

					<Button @click.native="onDeleted" :disabled="submitDisabled" :loading="isLoading">{{ submitButtonLabel }}</Button>
				</template>
			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>
	
</template>

<script>
import ModalWrapper from '../../ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '../../ui/modal/ModalFooterWrapper.vue'
import { TSelect } from 'vue-tailwind/dist/components';
import Button from '../../ui/buttons/Button.vue'

import { CheckIcon } from "@vue-hero-icons/outline"
import { XIcon } from "@vue-hero-icons/outline"


import client from '../../../utils/client';
export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		TSelect,
		Button,
		CheckIcon,
		XIcon,
	},
	props:{
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
		role:{
			type:Object,
			required: true
		},
		roles:{
			type:Array,
			required: true
		},
		venueId:{
			type:String,
			required: true,
		},
		// members:{
		// 	type:Array,
		// 	required:true
		// }
	},
	emits:["closed", "deleted"],
	data(){
		return {
			roleOptions: this.parseRoleOptions(this.roles),
			isLoading:false,
			form:{
				users: this.parseRoleUsersToForm(this.role),
			}
		}
	},
	computed:{
		introText(){
			let msg = `You currently have ${ this.role.users.length } member${ this.role.users.length !== 1 ? 's' : ''} in this role.`;
			if(this.role.users.length > 0){
				msg = `${msg} Please choose a new role for ${ this.role.users.length === 1 ? 'this member.' : 'these members.' }`
			}
			return msg;
		},
		modalTitle() {
      return `Delete ${ this.role.name } role`
    },
		submitButtonLabel() {
      return this.isLoading ? 'Deleting role' : 'Confirm';
    },
		submitDisabled() {
      return Object.values(this.form.users).some(x => x === null || x === '') || this.isLoading;
    },
		getMemberInfo(){
			return (userId) => {
				const member = this.role.users.find(m => m.id == userId);
				if(member){
					const display = 
						member.name || member.name !== "" ? member.name
						: member.email || member.email !== "" ? member.email 
						: "";
					return display === "" ? `No details for member ${member.id}` : display;
				}else{
					return "Member not found!"
				}
			}
		}
	},
	watch:{
		roles(updatedRoles){
			this.roleOptions = this.parseRoleOptions(updatedRoles)
		},
		role(updatedRole){
			this.form.users = this.parseRoleUsersToForm(updatedRole)
		},
	},
	methods:{
		parseRoleOptions(r){
			const options = []
			if(r){
				r.map(role => {
					if(role.id !== this.role.id){
						options.push({
							text: role.name,
							value: role.id
						})
					}
				})
			}
			return options
		},
		parseRoleUsersToForm(r){
			const users = {}
			if(r.users){
				r.users.map(user => {
					users[user.id] = null;
				})
			}
			return users
		},
		onSelectChange($e){
			this.form.users[$e.target.id] = $e.target.value === "" ? null : $e.target.value;
		},
		onClose(){
			this.$emit("closed");
		},
		onDeleted(){
			this.isLoading = true;
			// const deletePayload = JSON.stringify({users: this.form.users})
			let hasErr = false;
			if(this.role.users.length){
				const usersPromises = [];
				// loop through each rule user and edit their role
				this.role.users.map(u => {
					usersPromises.push(
						client
							.put(`/venues/${this.venueId}/members/${u.id}`, {role:this.form.users[u.id]})
							.then(() => {
								this.form.users[u.id] = "success"
							})
							.catch(() => {
								hasErr = true;
								this.form.users[u.id] = "error"
							})
						)		
				})
				Promise.all(usersPromises)
					.then(() => { 
						if(!hasErr){
							this.deleteRole(); 
						}else{
							this.isLoading = false;
						}
					})
					.catch(() => {
            // handle errors here
					});
			}else{
				this.deleteRole();
			}
			
		},
		deleteRole(){
			client
				.delete(`/venues/${this.venueId}/roles/${this.role.id}`)
				.then(() => {
					this.$store
						.dispatch('venueRoles/deleteVenueRole', {
							venueId:this.venueId, 
							role:this.role
						})
						.then((response) => {
							this.isLoading = false;
							this.$emit("deleted", response);
						}, err => {
							console.log(err)
						});
				})
				.catch(err => {
					console.log(err)
				})
		},
	},
}
</script>

<style lang="postcss" scoped>
	.force-mt-0{
    margin-top: 0!important;
	}
</style>