<template>
    <div @click="$router.push(to)" class="rounded-md cursor-pointer p-4 relative" :class="[colors, customClass]" >
    <div class="flex">
        <div class="flex-shrink-0">
                <slot name="icon">

                </slot> 
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
        <p class="text-sm" :class="'text-' + color_class + '700'">

            <DictionaryContent :hookId="10" :dynamicUrl="$router.history ? $router.history.current.path : ''">
                {{text}}
            </DictionaryContent> 
        </p>
        <p class=" text-sm items-start justify-start lg:ml-8">
            <a :class="'text-' + color_class + '700 hover:text-' + color_class + '600'" class="whitespace-nowrap font-medium">
                Details  <span aria-hidden="true">&rarr;</span>
            </a>
        </p>
        </div>
    </div>
 
    </div>
</template>

<script type="text/javascript">

    import DictionaryContent from '../../components/dictionary/DictionaryContent.vue';
    export default {
      name: "AlertWithLink",
      components: {DictionaryContent},
      props: {
        text: String,
        to: String,
        color_class: String,
        customClass: {type: String, required: false, default: null}
      },
      computed: {
        colors() {
          return this.color_class ? 'bg-' + this.color_class + '-50 ' + 'hover:bg-' + this.color_class + '-100' : ''
        }
      }
    }

</script>