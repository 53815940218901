<template>	
	<ModalWrapper :is-open="isOpen">
		<template #header>
			<div class="text-xl"> 
				{{ modalTitle }}
			</div>
		</template>
		<template #body>
			<p class="mt-2 p-6 text-sm">
				Are you sure you want to remove {{ membersName }} as a team member? 
			</p>
		</template>
		<template #footer>
			<ModalFooterWrapper>
				<template #left>
					<Button @click.native="onClose" :disabled="isLoading" color="gray">Close</Button>
				</template>
				<template #right>

					<Button color="indigo" @click.native="onRemove" :disabled="submitDisabled" :loading="isLoading">
						{{ submitButtonLabel }}
					</Button>
				</template>
			</ModalFooterWrapper>
		</template>
	</ModalWrapper>
</template>

<script>
import ModalWrapper from '../../ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '../../ui/modal/ModalFooterWrapper.vue'
import Button from '../../ui/buttons/Button.vue'
import client from '../../../utils/client';
export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		Button,
	},
	props:{
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
		member:{
			type:Object,
			required: true
		},
		roles:{
			type:Array,
			required: true
		},
		venueId:{
			type:String,
			required: true,
		}
	},
	emits:["closed", "delete", "updated"],
	data(){
		return {
			form: {
				email: this.getMemberName(this.member),
				role: this.member.roles ? this.member.roles[0].id : null,
			},
			isLoading:false,
		}
	},
	computed:{
	modalTitle() {
      return `Remove ${ this.getMemberName(this.member) } from the team`
    },
	membersName() {
		return this.getMemberName(this.member);
	},
	submitDisabled() {
      return this.isLoading;
    },
	submitButtonLabel() {
      return this.isLoading ? 'Removing member' : 'Remove';
    },
	},
	watch:{
		member(updatedMember){
			this.form.email = this.getMemberName(updatedMember);
			this.form.role = updatedMember.roles ? updatedMember.roles[0].id : null;
		}
	},
	methods:{
		getMemberName(member){
			const display = member.name || this.member.name !== "" ? member.name : member.email || member.email !== "" ? member.email : "";
			return display === "" ? `No details for member ${member.id}` : display;
		},
		onClose(){
			this.$emit("closed");
		},
		onRemove(){
			this.isLoading = true;
			client
				.delete(`/venues/${this.venueId}/members/${this.member.id}`, {role:this.form.role})
				.then(() => {
					this.isLoading = false;
					this.$emit("delete");
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>