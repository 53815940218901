
 
export const Session = () => {
    const win = window.top || window;
    let storage = (win.name ? JSON.parse(win.name): {});
    window.addEventListener("unload", () => {
        win.name = JSON.stringify(storage);
    }, false);
    return {
        set(key, value) {
            storage[key] = value;
        },

        get(key) {
            return storage[key] || null;
        },

        delete(key) {
            delete storage[key];
        },

        clear() {
            storage = {};
        },

        dump() {
            return JSON.stringify(storage);
        }
    }
};