<template>  
    <div v-if="isOpen" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
		<div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
			<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
			<transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">     
                <div class="inline-block align-bottom bg-white rounded-sm p-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-1">                      
                    <!-- Header -->
                    <div class="mt-4 px-6">
                        <div class="text-xl">Do you wish to accept all of the following gig offers?</div>
                        <p v-if="selectedOffer.length > 1" class="mt-2 text-base text-red-500">
                            Confirming will notify the venue(s) of your availability for the gigs listed below.
                        </p>  
                        <p v-else class="mt-2 text-base text-red-500">
                            Confirming will notify the venue of your availability for the gig listed below.
                        </p>    
                    </div>
                    <!-- Body -->
                    <div class="overflow-auto max-h-72 my-4 px-6">
                        <div v-for="(date, index) in groupedDatesbyArray" :key="'datepick' + index">
                            <div class="relative flex py-5 items-center">
                                <div class="flex-grow border-t border-gray-400"></div>
                                    <span class="flex-shrink mx-4 my-1">{{date[0].start}}</span>
                                <div class="flex-grow border-t border-gray-400"></div>
                            </div>
                            <div v-for="(bid, index) in date" :key="'key' + index">
                                <div class="my-1 font-bold">
                                    <p>
                                        Event: {{ bid.gig.name }}
                                    </p>
                                </div>
                                <p>
                                    Date: {{ fullDate(bid.gig.start) }} - {{ fullDate(bid.gig.end) }}   
                                </p>
                                <p>
                                    Venue: {{ bid.gig.venue.name }}
                                </p>
                                <p>
                                    Artist(s): {{ bid.artist.name }}
                                </p>
                                <p>
                                    Fee: £{{ bid.fee }}
                                </p>
                                <br>
                            </div>
                        </div>
                    </div>
                    <!-- Footer -->
                    <footer class="flex flex-col sm:flex-row w-full justify-between bg-gray-50">
                        <div class="px-2 pt-3 pb-0 sm:pb-3 sm:px-3 flex flex-col sm:flex sm:flex-row-reverse space-x-4">
                            <Button @click.native="closeModal" :disabled="btnDisabled" color="gray">Close</Button>
                        </div>
                        <div class="px-2 py-3 sm:px-3 flex space-x-0 sm:space-x-4 space-y-2 sm:space-y-0 flex-col sm:flex-row">
                            <Button
                                    @click.native="acceptAll(selectedOffer)"
                                    :disabled="btnDisabled"
                                    :loading="btnDisabled"
                                    color="pink"
                                    >Confirm</Button>
                        </div>
                    </footer>
				</div>
            </transition>
		</div>
	</div>
</template>
 
<script>
import { DateTime } from 'luxon';
import lodash from 'lodash';
import Button from '../../components/ui/buttons/Button.vue'
import { apiComputed, apiMethods } from '@/state/helpers.js'
import fullDate from  '@/utils/format-date-full.js' 

export default {
    name: "AcceptAllOffers", 
    components: {
        Button
    },
    data() {
        return {
            allOffers: [],
            groupedDatesbyArray: [],
            selectedOffer: [],
            isOpen: false,
            btn: false,
        }
    },
    computed:{
        ...apiComputed,
        btnDisabled(){
            return this.btn;
        },
    },
    methods: {
        ...apiMethods,
        fullDate,
        closeModal() {
            this.isOpen = false;
        },
        show($selectedOffers){

            this.allOffers = $selectedOffers.sort((a, b) => a.gig.start_unix - b.gig.start_unix);

            let groupedDates = lodash.groupBy(this.allOffers, bid => {
              return DateTime.fromISO(bid.gig.start).toFormat('dd/MM/yyyy');
            });

            let keys = Object.keys(groupedDates);
            this.groupedDatesbyArray = [];

            keys.forEach(date => 
            {    
                this.groupedDatesbyArray.push(this.allOffers.filter(b =>
                {                 
                    return DateTime.fromISO(b.gig.start).toFormat('dd/MM/yyyy') == date
                }));

                this.groupedDatesbyArray.sort(function(a, b){
                return a[0].gig.start_unix - b[0].gig.start_unix; 
                })
            });
           
            this.selectedOffer = $selectedOffers;            
            this.isOpen = true;
        },
        acceptAll($offers){
            
            this.btn = true;
            let bidsID = $offers.map(bid => bid.id);

            this.multiInitialArtistAcceptance(bidsID)
                .then(() => {
                    this.$notify("Gig availability successfully accepted");
                    this.btn = false;
                    this.$emit('reset');
                    this.isOpen = false;
                })
                .catch((error) => {
                    this.btn = false;
                    alert(error);
                });
        },
    }
}
</script>