<script>

export default {
    name: "NotFound"
}
</script>

<template>
        <div class="flex justify-center items-center p-20">
            <div class="text-3xl md:text-5xl lg:text-6xl uppercase text-gp_pink-default-500">
                Page not found
            </div>
        </div>
</template>
