
<template>
	<div class="flex items-center">
		<button 
			@click="onToggle" 
			type="button" 
			class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none"
			:class="buttonClasses"
			role="switch" 
			aria-checked="false"
			:aria-labelledby="id"
			:disabled="disabled"
		>
			<span class="sr-only"></span>

			<span 
				class="pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
				:class="circleClasses"
			>

				<span 
					class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
					:class="notEnabledStateClasses"
					:aria-hidden="enabled"
				>
					<svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
						<path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</span>

				<span 
					class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
					:class="enabledStateClasses"
					:aria-hidden="!enabled"
				>
					<svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
						<path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
					</svg>
				</span>
			</span>
		</button>
		<span class="ml-3" :id="id">
			<span 
				@click="onToggle" 
				class="text-sm font-medium text-gray-900"
			>{{ label }}</span>
		</span>
	</div>
	
</template>

<script>
export default
{
	name: "SwitchToggle",
	props:{
		id:String,
		label:String,
		enabled:Boolean,
		disabled:{
			type:Boolean,
			default:false
		}
	},
	emits:["updateEnable"],
	// data(){
	// 	return {
	// 		enabled: false
	// 	}
	// },
	computed:{
		buttonClasses() {
			let classes = this.enabled ? 'bg-indigo-600' : 'bg-gray-200'
      classes += this.disabled ? ' cursor-not-allowed opacity-70' : ' cursor-pointer opacity-100';
			return classes
    },
		// buttonDisabledClasses() {
    //   return this.disabled ? 'cursor-not-allowed opacity-80' : 'cursor-pointer opacity-100';
    // },
		circleClasses() {
      return this.enabled ? 'translate-x-5' : 'translate-x-0';
    },
		notEnabledStateClasses() {
      return this.enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200';
    },
		enabledStateClasses() {
      return this.enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100';
    },
	},
	methods:{
		onToggle(){
			if(!this.disabled){
				// this.enabled = !this.enabled;
				this.$emit("updateEnable", this.id)
			}
		}
	},
}
</script>
