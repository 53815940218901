<template>
    <div>
        <div class="fixed bg-white w-full min-h-screen h-full top-0 left-0">
            <div class="w-full min-h-screen h-full flex items-center justify-center">
                <div v-if="loading">
                    Loading Invoice
                </div>
                <div v-else-if="!invoice">
                    Invoice not found
                </div> 
                <div v-else >
                    Your download will begin shortly
                </div>

                <div class="absolute top-[-9999px] left[-9999px]">
                    <InvoiceGeneration ref="invoice" :invoiceItems="this.invoice" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import InvoiceGeneration from '../../../components/invoice/wrapper.vue';
    export default
    {
        name: "AppInvoices",
        components: {
            InvoiceGeneration 
        },
        data() {
            return {
                loading: false,
                invoiceBids: [],
                invoice: null
            }
        },
        mounted(){
            this.loading = true;
            this.$store.dispatch("api/getArtistInvoices").then((response) => {                
                this.invoiceBids = response.data.data;
            }).finally(() => {
                this.loading = false;     
                this.invoice = this.invoiceBids.filter(f => f.invoice.id == this.$route.params.id);            
                this.$refs.invoice.generate();
            }); 
        }

    }

</script>