import client from "../../utils/client";

export const state = {
  bankHolidays: []
};

export const mutations = {
  SET_BANK_HOLIDAYS(state, bankHolidays) {
    state.bankHolidays = bankHolidays
  }
}

export const getters = {
  bankHolidays(state) {
    return state.bankHolidays
  }
}

export const actions = {
  getBankHolidays({ commit }) {
    return client.get('/bank-holidays')
      .then((response) => {
        commit('SET_BANK_HOLIDAYS', response.data.data);
      })
  }
}
