<template>
    <div>
                        
            <span v-for="item in tags" :key="item.value" class="justify-items-start justify-start inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gp_pink-default-100 text-gp_pink-default-700">
                <span class="block">{{item.text}}</span>
                <button @click="removeTagById(item.value)" type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gp_pink-default-400 hover:bg-gp_pink-default-200 hover:gp_pink-default-500 focus:outline-none focus:bg-gp_pink-default-500 focus:text-white">
                    <span class="sr-only">Remove {{item.text}} option</span>
                    <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                </button>
            </span>
            
    </div>
</template>

<script>
export default {
    name: "tags",
    methods:
    {
        removeTagById(id){
 

            let index = this.tags.findIndex(t => {
                console.log(t, id);
                return t.value === id
            });

            console.log(index);

            if (index !== -1){
                this.tags.splice(index, 1);
            }
        },
        addTag($tagobject){

            let findTag =this.tags.find(tag => {
                return tag.value == $tagobject.value
            });

            if (!findTag){
                this.tags.push({
                        text: $tagobject.text,
                        value : $tagobject.value
                });
            }
        }
    },
    data() {
        return{
             tags: []  ,
             forRemoval: {}      
        }
    },
}
</script>