<template>
  <div  transition="fadein" class="brandedLogin fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <link v-if="cssFile" rel="stylesheet" :href="cssFile" >
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 transition-opacity" aria-hidden="true"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div :class="artist_only || venue_only ? 'sm:max-w-md': 'sm:max-w-2xl'" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6">
        <div>
          <div class="mx-auto flex gap-4 flex-row items-center justify-center brandedImage">
            <img :src="logoUrl" alt="Genre." class="w-33" />
          </div>
 
        </div>

        <ul role="list" class="grid gap-6 grid-flow-row lg:grid-flow-col mt-2">

          <li v-if="!artist_only" :class="allowVenues ? '' : 'deter_nav'"  class=" col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8  ">
              <h3 class="mt-6 text-gray-900 text-sm font-bold uppercase">Venue Management</h3>
 

              <dl class="mt-4 flex-grow flex flex-col justify-between">
                <dt class="sr-only">Login</dt>
                <dd class=" ">
                  <a rel="nofollow" @click="loginEvents($auth);" class="mt-2 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Log in</a>
                </dd>
              </dl>
            </div>
          </li>


          <li v-if="artist_only" class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
            <div class="flex-1 flex flex-col p-8">
              <h3 class="mt-6 text-gray-900 text-sm uppercase font-bold">Artist area</h3>

              <dl class=" flex-grow flex flex-col justify-between">
                <dt class="sr-only">Login</dt>
                <dd class=" ">
                  <a
                      rel="nofollow"
                      @click="loginArtists($auth);"
                      class="mt-2 block w-full border rounded-md py-2 text-sm font-semibold text-white text-center brandedButton"
                  >Log in</a>
                </dd>
              </dl>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import branding from "@/global/branding";

export default {
  name: "BrandedLogin",
  props:{
    artist_only: Boolean,
    venue_only: Boolean,
    allowVenues: {type: Boolean, default: true},
    alignment: {type: String, default: 'justify-center'},
    logoUrl: String,
    name: String,
    cssFile: String
  },
  methods: {
    signupArtists($auth){
      this.$global.switchRoute('artist');
      this.$global.signup($auth, branding.auth0Params({screen_hint: 'signup', allowLogin: false}));
    },
    loginArtists($auth){
      this.$global.switchRoute('artist');
      this.$global.login($auth, branding.auth0Params({allowSignUp: false}));
    },
    signupEvents($auth){

      this.$global.switchRoute('event');
      this.$global.signup($auth, branding.auth0Params({screen_hint: 'signup', allowLogin: false}));
    },
    loginEvents($auth){

      this.$global.switchRoute('event');
      this.$global.login($auth, branding.auth0Params({allowSignUp: false}));
    }
  }
}
</script>

<style scoped>
.external .router-link-active {

  @apply bg-transparent;

}
.fadeIn-transition {
  transition: all .3s ease;
  opacity:0;
}
.expand-enter, .expand-leave {
  opacity: 1;
}
</style>