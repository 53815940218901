<template>
	
	<ModalWrapper ref="editRoleModal" :is-open="isOpen">
		<template #header>
			<div class="text-xl"> 
				Invite new team members
			</div>
			<p class="mt-2 text-sm">Enter your team’s email address below and they will be invited by email to join.  If they are not already a GigPig member, they will be asked to register upon clicking the invite.</p>
			<p v-if="userHasFullVenuePerms" class="mt-2 text-sm">Alternatively, click the add button to add the user without them having to go through the invitation process.</p>
		</template>

		<template #body>

			<form class="mb-24 form input-group" ref="form" novalidate> 
				<div class="mt-6 sm:mt-5 col-span-full">

					<div 
						v-for="member in members"
						:key="member.key"
					>
						<div class="grid justify-center items-center grid-flow-row-dense grid-cols-4 gap-1 sm:gap-4 space-y-5 sm:space-y-4" >

							<div class="col-span-3 sm:col-span-1">
								<label for="email" class="block text-sm font-medium text-gray-700">Email</label>
								<div class="mt-2">
									<input 
										type="text" name="email" id="email" 
										v-model.trim="member.email" 
										:disabled="isLoading"
										@blur="onInputNameBlur(member)"
										class="shadow-sm focus:ring-indigo-300 focus:border-indigo-300 block w-full sm:text-sm border-gray-300 rounded-md text-black" 
									/>
								</div>
							</div>

							<TSelect 
								v-model="member.role"
								class="col-span-3 sm:col-span-2 force-mt-1-6" 
								placeholder="Select a new role"
								:options="roleOptions" 
								:disabled="isLoading"
							/>
							<div v-if="member.key !== members[0].key" class="col-span-1 sm:col-span-1 text-sm text-red-600">
								<button 
								class="mt-[9px]"
								@click.prevent="removeItem(member.key)">Remove</button>
							</div>
						</div>

						<p v-if="member.inputValidity === 'notEmail'" class="mt-2 text-sm text-red-600" id="email-error">The members email must be a valid email address.</p>
						<p v-else-if="member.inputValidity === 'invalid'" class="mt-2 text-sm text-red-600" id="email-error">The members email must be completed.</p>
						<p v-else class="mt-2 text-sm">&nbsp;</p>
						

					</div>

					<div v-if="validationErrors.length">
							<p 
								v-for="error in validationErrors"
								:key="error.key"
								class="mt-2 text-sm text-red-600" id="user-email-error">{{ error.message }}</p>
						</div>

					

				</div>
			</form>
			<div class="flex justify-center mb-4">

				<Button @click.native="addMember" color="indigo" :disabled="submitDisabled">
					Add more
					<template #suffix>
						<PlusIcon class="text-sm" />
					</template>
				</Button>

			</div>
		</template>

		<template #footer>

			<ModalFooterWrapper>
				<template #left>
					<Button @click.native="onClose" :disabled="isLoading" color="gray">Close</Button>
				</template>
				<template #right>
					<Button v-if="userHasFullVenuePerms" @click.native="add" :disabled="submitDisabled" :loading="isLoading">Add</Button>
					<Button @click.native="onInvite" :disabled="submitDisabled" :loading="isLoading">{{ submitButtonLabel }}</Button>
				</template>
			</ModalFooterWrapper>
					
		</template>
	</ModalWrapper>
	
</template>

<script>
import ModalWrapper from '../../ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '../../ui/modal/ModalFooterWrapper.vue'
import Button from '../../ui/buttons/Button.vue'
import { TSelect } from 'vue-tailwind/dist/components';
import { PlusIcon } from "@vue-hero-icons/outline"

import client from '../../../utils/client';

export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		TSelect,
		PlusIcon,
		Button
	},
	props:{
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
		venueId:{
			type:String,
			required: true,
		},
		roles:{
			type:Array,
			required: true
		},
		userHasFullVenuePerms:{
			type:Boolean,
			required: true
		}
	},
	emits:["closed","invited"],
	data(){
		return {
			members: [{
				key: new Date().valueOf(),
				role: null,
				email: "",
				inputValidity:"",
				dirty:false,
			}],
			roleOptions: this.parseRoleOptions(this.roles),
			isLoading:false,
			validationErrors:[]
		}
	},
	computed:{
		submitButtonLabel() {
      return this.isLoading ? 'Inviting' : 'Invite';
    },
		submitDisabled() {
			const latestMember = this.members[this.members.length - 1]
			if(latestMember){
				return latestMember.inputValidity === 'valid' && latestMember.role ?  this.isLoading : true;
			}else{
				return true
			}
    },
	},
	watch:{
		roles(updatedRoles){
			this.roleOptions = this.parseRoleOptions(updatedRoles)
		}
	},
	methods:{
		parseRoleOptions(r){

			if(r){
				return r.map(role => {
					return {
						text: role.name,
						value: role.id
					};
				})
			}else{
				[]
			}
		},
		isValidEmail(email){
			return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		},
		onInputNameBlur(member){
			if(member.email === ''){
				member.inputValidity = "invalid"
			}else if(!this.isValidEmail(member.email)){
				member.inputValidity = "notEmail"
			}else{
				member.inputValidity = "valid"
			}
		},

		onClose(){
			this.validationErrors = [];
			this.$emit("closed");
		},
		add() {
			this.validationErrors = [];
			this.isLoading = true;
		
		const payload = this.members.map(member => {
			return {
				role:member.role,
				email:member.email
			}
		})
		
		client
			.post(`venues/user_skip-invite/${this.venueId}`, payload)
			.then(response => {

				this.isLoading = false;
						// reset form
						this.members = [{
							key: new Date().valueOf(),
							role: null,
							email: "",
							inputValidity:"",
							dirty:false,
						}],
						this.$emit("added", response);
				
			})
			.catch(err => {
				if (err.response.status === 422)
					this.addErrors(err)
			})
		},
		onInvite(){
			this.validationErrors = [];
			this.isLoading = true;
		
			const payload = this.members.map(member => {
				return {
					role:member.role,
					email:member.email
				}
			})
			
			client
				.post(`venues/${this.venueId}/invites`, payload)
				.then(response => {

					this.$store
						.dispatch('venueInvites/addVenueInvites',{
							venueId:this.venueId, 
							invites:response.data.data
						})
						.then((response) => {
							this.isLoading = false;
							// reset form
							this.members = [{
								key: new Date().valueOf(),
								role: null,
								email: "",
								inputValidity:"",
								dirty:false,
							}],
							this.$emit("invited", response);
						}, err => {
							console.log(err)
						})
					
				})
				.catch(err => {
					if (err.response.status === 422)
						this.addErrors(err)
				})
			
		},

		addMember(){
			this.validationErrors = [];
			this.members.push({
				key: new Date().valueOf(),
				role: null,
				email: "",
				inputValidity:"",
				dirty:false,
			})
		},
		addErrors(err){
			for (const [key, value] of Object.entries(err.response.data.error)) {
						this.validationErrors.push({
							key: key,
							message: value[0],
						});
					}
					this.isLoading = false;
		},
		removeItem(key){
			if (this.members.length > 1) {
				this.members = this.members.filter(member => member.key !== key)
			}
		}
	},
}
</script>


<!-- margin-top: 1.6rem!important; -->
<style lang="postcss" scoped>
	.force-mt-1-6{
    margin-top: 0important;
	}
	@media (min-width: 640px){
		.force-mt-1-6{
			margin-top: 1.6rem!important;
		}
	}
	.force-mt-0{
    margin-top: 0!important;
	}
</style>