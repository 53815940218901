<template>
		<GigPageWrapper
			:gig-id="String($attrs.id)" 
			current-route="invoices"
			title="Invoices"
		>
    <InlineNotification Heading="Gig Invoices" class="p-0 m-0" DismissId="ArtistInvoices">
        <template slot="body">
            <p>Once the gig has been completed and signed off, the invoices will appear below. You can view and download then here.</p>
        </template> 
    </InlineNotification> 
						<template v-if="this.isLoaded && item">
                <div class=" flex flex-col mt-4 " v-if="item.is_past && item.bookings.length > 0">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                        class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                    >
                        <div
                        class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                        >
                        <table class="min-w-full divide-y divide-gray-200 text-left">
                            <thead class="bg-gray-50">
                            <tr>
                                <th
                                scope="col"
                                class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                Artist
                                </th> 
                                <th
                                scope="col"
                                class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                Fee
                                </th> 
                                <th
                                scope="col"
                                class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                ></th><th scope="col" class="relative  text-xs px-6 py-3">
                                <span class="sr-only">Action</span>
                                </th>
                                <th scope="col" class="relative  text-xs px-6 py-3">
                                <span class="sr-only">Action</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200  text-sm">
                            <tr v-for="(booking, index) in item.bookings" :key="index" :row="booking">
                                <td v-if="booking.artist.is_active" class="text-left mx-2 p-6">
                                    {{ booking.artist.name }}
                                </td>
                                <td v-else class="text-left mx-2 p-6">
                                    {{booking.artist.name}}
                                    <span v-show="booking.artist.deleted_at" class="text-xs">
                                        [Artist deleted]
                                    </span>
                                </td>


                                <td class="p-6">
                                    <div v-if="booking.artist">
                                        <span>{{booking.currency.symbol}}{{booking.fee}}
                                            <span v-if="booking.artist.vat_number"> + VAT</span>
                                        </span>
                                        <a class="text-gp_pink-default-500 pl-2" v-if="!booking.signed_off_at"
                                            @click="editBidFee(booking)"
                                        >Amend</a>
                                    </div>
                                    <div v-else>-</div>
                                </td>
                                <!-- <td class="p-6 items-center">
                                    <a v-if="booking.artist.is_active" class="text-gp_pink-default-500">Message artist</a>
                                    <span v-else class="text-gray-400">Message artist</span>
                                </td> -->
                                <td colspan="2" class="p-6 large:space-y-8 " v-if="!booking.cancelled && booking.invoice">
                                    <a class="text-gp_pink-default-500" @click="generateInvoice(booking)">
                                    <span v-if="booking.paid">Download</span>
                                    <span v-else>Preview</span> 
                                    invoice</a> 
                                </td>
                                <td v-else>
                                    <span>Invoice not generated</span>
                                </td>
                                <td>
                                        <span v-if="booking.signed_off_at">Signed off on {{signoffFormat(booking.signed_off_at)}}</span>
                                        <a @click="signOff(booking)" v-else>Sign off invoice</a>
                                </td> 
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="mt-10 space-y-4" v-else>
                    <p>There are no invoices to display.</p>
                    <p>Invoices will be shown once the gig date has passed and the gig has booked artists.</p>
                </div>
						</template>
                        <NoDataMsgWrapper v-else>Loading invoices page. Please wait.</NoDataMsgWrapper>
                <InvoiceGeneration ref="invoice" :gig="item" :invoiceItems="[invoiceItem]" />

				<template v-if="selectedBid">
					<AmendBidFee 
						:is-open="showAmendBidFeeModal"
						:bid="selectedBid"
						@closed="cancelModal"
						@updated="onBidFeeUpdated"
					/>
				</template>

                <ArtistRatingModal ref="ratingmodal" @reload="reloadData()" />

			</GigPageWrapper>
</template>

<script>

import InlineNotification from '../../../components/notifications/inline.vue';
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue';
import InvoiceGeneration from '../../../components/invoice/wrapper.vue';  
import { apiComputed, apiMethods } from '@/state/helpers'; 
import formatDate from  '@/utils/format-date.js' ;  
import NProgress from 'nprogress';
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import ArtistRatingModal from '../../../components/modal/ArtistRating.vue';

const { DateTime } = require("luxon");
import AmendBidFee from '@/components/modal/gigs/AmendBidFee.vue'
export default {
    name: "InvoiceIndex",
    data() {
        return {
            isLoaded: false,
            invoiceItem: null,
            item: null,
                    showAmendBidFeeModal: false,
                    selectedBid:null,
        }
    },
    methods: {
        formatDate,
        ...apiMethods,
        signoffFormat(date){
            return DateTime.fromFormat(date, "yyyy-MM-dd HH:mm:ss").toFormat("dd/MM/yyyy")
        },
        markAsPaid(booking)
        {
            this.markInvoiceAsPaid({ bidId: booking.id});

            this.reloadData(); // refresh state after click


        },
        reloadData(){
            NProgress.start();
                this.getGigWithOffers({id: this.$attrs.id, useTrashedArtists: true}).then((resp) => {
                this.item = resp;
                this.$store.dispatch('gig/storeGig', this.item); 
                NProgress.done();
                this.isLoaded = true;
            })
        },
        signOff(booking)
        {
            this.signoffArtists([booking.id]);
            this.$refs.ratingmodal.artist = booking.artist;
            this.$refs.ratingmodal.bidId = booking.id;
            this.$refs.ratingmodal.gigId = this.item.id;
            this.$refs.ratingmodal.toggleModal();
        },
        generateInvoice (booking) {

            this.invoiceItem = booking;

            this.$refs.invoice.generate();

        },
        editBidFee($bid){
            this.selectedBid = $bid;
            this.showAmendBidFeeModal = true;
        },
        cancelModal(){
            this.showAmendBidFeeModal = false;
        },
        onBidFeeUpdated($bid){
            this.selectedBid = $bid;
            this.cancelModal();
            this.reloadData();
        },
    },
    created(){
        this.reloadData();
    },
    computed: {
        form: function() {
            let deepCopy = JSON.parse(JSON.stringify(this.item));            
            return deepCopy;  
        },
        ...apiComputed,
            // titleOutput(){
            // 		if(!this.item){
            // 			return "";
            // 		}
            // 		if(!this.item.start){
            // 			return "";
            // 		}
            // 		return ': ' + this.item.name + ', '  + formatDate(this.item.start, 'dd/MM/yy HH:mm').replaceAll(',', '');
            // 	},
    }, 
    components: { 
        InlineNotification,
        NoDataMsgWrapper,
        GigPageWrapper, 
        InvoiceGeneration,
        AmendBidFee,
        ArtistRatingModal
    }
}
</script>