<template>
    <li class="relative " :class="IsLast ? '' : 'pr-8'">
        <!-- Completed Step -->
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div v-if="!IsLast" class="h-0.5 w-full " :class="IsComplete ? 'bg-gp_pink-default-600' : 'bg-gray-500'"></div>
        </div>
        <span v-if="IsComplete"  class="relative w-8 h-8 flex items-center justify-center bg-gp_pink-default-600 rounded-full">
            <!-- Heroicon name: solid/check -->
            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            <span class="sr-only">{{Step}}</span>
        </span>
        <span v-else-if="IsNextStep" class="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-black rounded-full" aria-current="step">
            <span class="h-2.5 w-2.5 bg-gp_pink-default-600 rounded-full" aria-hidden="true"></span>
            <span class="sr-only">{{Step}}</span>
        </span>
        <span v-else  class="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
            <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" aria-hidden="true"></span>
            <span class="sr-only">{{Step}}</span>
        </span>
    </li>
</template>

<script>
export default {
    name: "ProgressBarItem",
    props:
    {
        IsComplete: Boolean,
        IsNextStep: Boolean,
        IsEmpty: Boolean,
        Step: String,
        IsLast: Boolean
    }
}
</script>