export const state = {
    artistProfilePageLoaded: false,
    userRolesLoaded: false,
    permissionSlugsLoaded: false,
};

export const mutations = {
    SET_ARTIST_PROFILE_PAGE_LOADED(state, bool) {
        state.artistProfilePageLoaded = bool
    },
    SET_USER_ROLES_LOADED(state, bool) {
        state.userRolesLoaded = bool
    },
    SET_PERMISSION_SLUGS_LOADED(state, bool) {
        state.permissionSlugsLoaded = bool
    },
};

export const actions = {
    setArtistProfilePageLoaded({commit}) {
        commit('SET_ARTIST_PROFILE_PAGE_LOADED', true)
    },
    setUserRolesLoaded({commit}) {
        commit('SET_USER_ROLES_LOADED', true)
    },
    setPermissionSlugsLoaded({commit}) {
        commit('SET_PERMISSION_SLUGS_LOADED', true)
    },
};

export const getters = {
    artistProfilePageLoaded(state) {
        return new Promise((resolve) => {
            resolve(state.artistProfilePageLoaded);
        });
    },
    userRolesLoaded(state) {
        return state.userRolesLoaded
    },
    permissionSlugsLoaded(state) {
        return state.permissionSlugsLoaded
    },
};