<template>
    <Modal :show="showModal" @closeModal="close">
        <template #header>Update Sage code</template>
        <template #default>
            <div>
                <div class="mx-0 p-0 important py-0">
                    <div class="text-left sm:mt-[25px]">
                        <div class="text-sm">
                            <form  class="lg:mb-[20px]">
                                <TInput
                                :maxlength="25"
                                :minlength="1"
                                :placeholder="'Sage code for ' + selectedArtist.name"
                                v-model="code"
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #header2>
            <h4 class="block text-md mb-[5px]">
                <strong>Update the Sage code for {{selectedArtist.name}}</strong>
            </h4>
            <hr>
        </template>
        
        <template #buttons>
            <GPButton
                :disabled="buttonDisabled"
                :loading="buttonDisabled"
                class="bg-pink-500 w-full"
                @click.native="updateCode"
                color="indigo">
                Update Sage Code</GPButton>
        </template>
    </Modal>

</template>

<script>
import Modal from "@/components/Modal.vue";
import GPButton from "@/components/ui/buttons/Button.vue";
import { apiMethods } from "@/state/helpers";
import { TInput } from "vue-tailwind/dist/components";

export default {
    name: "SageAccountCodeModal",
    components: {
        GPButton,
        Modal,
        TInput
    },
    data() {
        return {
            code: '',
            buttonDisabled: false
        }
    },
    methods: {
        ...apiMethods,
        updateCode() {
            this.buttonDisabled = true
            this.updateArtistAccountCode({brandId: this.venueBrandId, artistId: this.selectedArtist.id, accountCode: this.code}).then(() => {
                this.$emit('artistAccountCodeUpdated', false)
                this.$emit('showAccountCodeModal', false)
                this.buttonDisabled = false
            })
        },
        close() {
            this.$emit('showAccountCodeModal', false)
        },
    },
    props: {
        showModal: {type: Boolean, default: false},
        selectedArtist: {type: Object, default: null, required: false},
        venueBrandId: {type: Number, default: null, required: false},
        codeProp: {type: String, default: null, required: false},
    },
    watch: {
        codeProp: function(newVal) {
            this.code = newVal
        }
    },
}
</script>