<template>  
    <div v-if="isOpen" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
		<div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
			<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
			<transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">     
                <div class="inline-block align-bottom bg-white rounded-sm p-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-1">                      
                    <!-- Header -->
                    <div class="mt-4 px-6">
                        <div class="text-xl">Do you wish to delete the following gig ?</div>
                        <p class="mt-2 text-base text-red-500">
                            Confirming will permanently remove the gig from your account.
                        </p>  
                        
                    </div>
                    <!-- Body -->
                     <div class="overflow-auto max-h-72 px-6">
                        <div class="space-y-4 mt-8 mb-8 text-sm">
                            <p v-if="item.venue && item.venue.name"> Venue: {{item.venue.name}}</p>
                            <p> Date: {{ DateTime.fromISO(item.start).toUTC().toFormat('cccc DDD HH:mm')}} until {{ DateTime.fromISO(item.end).toUTC().toFormat('cccc DDD HH:mm')}}</p>
                            <p> Status: <gig-status :gig="getStatus" class="self-center" /></p>
                        </div>
                    </div>
                    <!-- Footer -->
                    <footer class="flex flex-col sm:flex-row w-full justify-between bg-gray-50">
                        <div class="px-2 pt-3 pb-0 sm:pb-3 sm:px-3 flex flex-col sm:flex sm:flex-row-reverse space-x-4">
                            <Button @click.native="closeModal" :disabled="btnDisabled" color="gray">Close</Button>
                        </div>
                        <div class="px-2 py-3 sm:px-3 flex space-x-0 sm:space-x-4 space-y-2 sm:space-y-0 flex-col sm:flex-row">
                            <Button
                                    @click.native="deleteItem()"
                                    :disabled="btnDisabled"
                                    :loading="btnDisabled"
                                    color="indigo"
                                    >Confirm</Button>
                        </div>
                    </footer>
				</div>
            </transition>
		</div>
	</div>
</template>
 
<script>
import GigStatus from '../iconsets/GigStatus.vue'
import Button from '../../components/ui/buttons/Button.vue'
import { apiComputed, apiMethods } from '@/state/helpers.js'
import {DateTime} from 'luxon';
import { getStatusFromType as getGigStatus } from '@/state/models/gigStatus'
import { getStatusFromType as getBidStatus } from '@/state/models/bidStatus'
import fullDate from  '@/utils/format-date-full.js' 

export default {
    name: "DeleteGigConfrimation", 
    components: {
        Button,
        GigStatus
    },
    data() {
        return {
            allOffers: [],
            groupedDatesbyArray: [],
            selectedOffer: [],
            DateTime,
            isOpen: false,
            item: null,
            btn: false,
        }
    },
    computed:{
        ...apiComputed,
        btnDisabled(){
            return this.btn;
        },
        getStatus(){
            return { status: this.showAsBid  ? getBidStatus(this.item.status) : getGigStatus(this.item.status) }
        },
    },
    methods: {
        ...apiMethods,
        fullDate,
        closeModal() {
            this.isOpen = false;
        },
        show($gig){
            console.log($gig);
            this.item = $gig;
            this.isOpen = true;
        },
        deleteItem(){
            this.btn = true;
            this.deleteGig(this.item).finally(() => {
                this.isOpen = false;
                this.btn = false;
                this.$router.push("/calendar");
                this.$notify("Gig successfully deleted");
            });
        },
    }
}
</script>