<template>
    <Modal :show="showModal" @closeModal="close">
        <template #header>Private Artist Invitations</template>
        <template #default>
            <InlineNotification class="mx-0 p-0 important py-0">
                <template #body>
                    <p>You have been sent an 'Exclusive artist invite'. As an exclusive artist to the venues below, you will only receive gig offers from these venues. No other venues will be able to offer you gigs. You won't appear in any artist directory searches by any other venues.
                        You can change these settings at any time by visiting your artist profile and clicking 'choose venues' on the 'Private Venues' section of your profile.</p>
                </template>
            </InlineNotification>

            <div v-if="venueRequests.length">
                <div
                    class="mx-0 p-0 important py-0"
                    v-for="(request, i) in venueRequests"
                    :key="i">
                    <div class="text-left sm:mt-5">
                        <div class="text-sm">
                            <form  class="mb-2 lg:mb-4">
                                <div class="px-6 w-full justify-between grid grid-cols-12 space-x-4">
                                    <GPButton
                                        class="col-span-2"
                                        @click.native.prevent="decline(request.id);index=i"
                                        color="indigo"
                                        :disabled="declineBtnDisabled && i==index"
                                        :loading="declineBtnDisabled && i==index"
                                    ><span class="text-xs">No Thanks</span></GPButton>
                                    <GPButton
                                        style="text-align: left !important;"
                                        class="col-span-9"
                                        @click.native.prevent="accept(request.id);index=i"
                                        :disabled="acceptBtnDisabled && i==index"
                                        :loading="acceptBtnDisabled && i==index"
                                        color="pink"><span class="text-xs">I would like the artist profile "{{ request.artist.name }}" to be a private artist for the venue "{{ request.venue.name }}".</span>
                                    </GPButton>

                                    <span v-if="request.status === 'accepted'" class="text-green-500 col-span-1 flex items-center justify-center"><ThumbUpIcon/></span>
                                    <span v-else-if="request.status === 'declined'" class="text-red-500 col-span-1 flex items-center justify-center"><ThumbDownIcon/></span>
                                    <span v-else class="col-span-1 flex items-center justify-center"><QuestionMarkCircleIcon/></span>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <div v-else>
                <p>You have no private venue requests</p>
            </div>
        </template>
        
        <template #buttons>


            <div class="text-left sm:mt-5">
                            <div class="text-sm">
                                <form  class="form mb-36 lg:mb-24">
                                    <div class="action-bar px-6 w-full justify-between">
                                        <GPButton
                                            class="w-full lg:w-auto"
                                            @click.native="close"
                                            color="indigo">
                                            Close</GPButton>
                                    </div>
                                </form>
                            </div>
                        </div>
            
        </template>
    </Modal>

</template>

<script>
import Modal from "@/components/Modal.vue";
import GPButton from "@/components/ui/buttons/Button.vue";
import {apiMethods} from "@/state/helpers";

import { createNamespacedHelpers} from "vuex";
const { mapState } = createNamespacedHelpers('user')
import InlineNotification from "@/components/notifications/inline.vue";
import auth from "@/global/auth";
import {ThumbUpIcon, ThumbDownIcon} from "@vue-hero-icons/outline";
import {QuestionMarkCircleIcon} from "@vue-hero-icons/outline";

export default {
    name: "PrivateVenuesOptInModal",
    components: {
        ThumbUpIcon,
        ThumbDownIcon,
        QuestionMarkCircleIcon,
        InlineNotification,
        GPButton,
        Modal
    },
    data() {
        return {
            index: -1,
            acceptBtnDisabled: false,
            declineBtnDisabled: false,
            venueRequests: []
        }
    },
    methods: {
        ...apiMethods,
        close() {
            this.$emit('showPrivateVenuesModal', false)
            this.btnDisabled = false
        },
        decline(id) {
            this.declineBtnDisabled = true
            this.venueRequests.find(request => {
                return request.id === id
            }).status = 'declined'

            this.saveVenuePrivateArtist({id: id, status: 'declined'}).then(() => {
                this.declineBtnDisabled = false
            })
        },
        accept(id) {
            this.acceptBtnDisabled = true

            this.venueRequests.find(request => {
                return request.id === id
            }).status = 'accepted'

            this.saveVenuePrivateArtist({id: id, status: 'accepted'}).then(() => {
                this.acceptBtnDisabled = false
            })
        },
    },
    props: {
        showModal: {type: Boolean, default: false},
        venueRequestsProp: {type: Array, default: null, required: false}
    },
    computed: {
        ...mapState({
            user: (state) => state.userData
        }),
    },
    watch: {
        venueRequestsProp() {
            if (this.venueRequestsProp !== null) {
                this.venueRequests = this.venueRequestsProp
            }
        },
        user() {
            if (auth.isArtistView() && localStorage.getItem('display_login_components')) {

                localStorage.removeItem('display_login_components')

                this.getPrivateVenueRequests(this.user.id).then((response) => {
                    this.venueRequests = response.data.data
                    if (this.venueRequests.filter(request => { return request.status === 'pending' }).length > 0) {
                        this.$emit('showPrivateVenuesModal', true)
                    }
                })

            }

        },
    },
    emits: ['showPrivateVenuesModal']
}
</script>