<template>

  <div  transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-4 px-2 sm:px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div :class="artist_only || venue_only ? 'sm:max-w-md': 'sm:max-w-2xl'" 
      class="inline-block w-full mx-4 align-bottom bg-gp_navy-default-500 rounded-lg px-6 sm:px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6">
    <div>
      <div class="mx-auto flex gap-4 flex-col justify-between items-center ">

        <img src="@/assets/images/icons/single_logo.svg" alt="GigPig" class="w-24" />
        <h3 class="font-bold text-2xl  text-white" id="modal-title">
          GigPig
        </h3>
      </div>
      <div class="text-left sm:mt-5" v-if="!venue_only && !artist_only">
        <div class="mt-8 text-sm">
          <p class="mb-4" >
            Are you an artist?
          </p>
          <p class="mb-4">
            Pick an option below to login or register.
          </p>
        </div>
      </div> 
    </div>

    <ul role="list" class="grid gap-6 grid-flow-row lg:grid-flow-col">
      <li v-if="!artist_only" :class="allowVenues ? '' : 'deter_nav'"  class=" col-span-1 flex flex-col text-center bg-gp_navy-default-500 rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col  ">
           <h3 class="mt-8 text-white text-sm font-bold uppercase">Venue Management</h3>



          <dl class="mt-6 flex-grow flex flex-col justify-between">
            <dt class="sr-only">New Venue? Sign up</dt>
            <dd class=" ">
              <a rel="nofollow" @click="signupEvents($auth);" 
              class="mt-2 block w-full bg-white border border-gray-800 rounded-md py-2 
              text-sm font-semibold text-gp_navy-default-500 text-center hover:bg-gp_pink-default-500 
              hover:text-white">
                New Venue? Sign up
              </a>
            </dd>
          </dl>

          <dl class="mt-4 flex-grow flex flex-col justify-between">
            <dt class="sr-only">Login</dt>
            <dd class=" ">
              <a rel="nofollow" @click="loginEvents($auth);" 
              class="mt-2 block w-full bg-white border border-gray-800 rounded-md py-2 
              text-sm font-semibold text-gp_navy-default-500 text-center  
              hover:text-white hover:bg-gp_pink-default-500">
                Log in to an existing venue
              </a>
            </dd>
          </dl>


          <dl class="mt-4 external">
            <router-link class="text-white text-xs" to="/">Click here to switch to artist login</router-link>
          </dl>
        </div>
      </li>
      <li v-if="!venue_only" class="col-span-1 flex flex-col text-center bg-gp_navy-default-500 rounded-lg shadow divide-y divide-gray-200">
        <div class="flex-1 flex flex-col">
 
          <h3 class="mt-8 text-white text-sm font-bold uppercase">Artist Area</h3>

          <dl class="flex-grow flex flex-col justify-between">
            <dt class="sr-only">Sign up</dt>
            <dd >
              <a href="#"  rel="nofollow" @click="signupArtists($auth);" class="mt-8 block w-full bg-white border border-gray-800 rounded-md py-2 text-sm font-semibold text-gp_navy-default-500 text-center hover:bg-gp_pink-default-500 hover:text-white">New artist? Sign up</a>
            </dd>
          </dl>
          <dl>
            <p class="mt-2 uppercase font-semibold text-sm text-white">Or</p>
          </dl>

          <dl class=" flex-grow flex flex-col justify-between">
            <dt class="sr-only">Login</dt>
            <dd class=" ">
              <a  rel="nofollow"  @click="loginArtists($auth);" class="mt-2 block w-full bg-white border border-gray-800 rounded-md py-2 text-sm font-semibold text-gp_navy-default-500 text-center hover:bg-gp_pink-default-500 hover:text-white">Log in to an existing account</a>
            </dd>
          </dl>

          <dl class="mt-8 flex justify-center">
            <router-link class="text-white text-xs" to="/venues/login">Click here to switch to venue login</router-link>
          </dl>
        </div>
      </li>
    </ul>

  </div>

    </div>
  </div>


</template>

<script>
import branding from '@/global/branding'
export default {
  name: "GigPig",
  props:{
    artist_only: Boolean,
    venue_only: Boolean,
    allowVenues: {type: Boolean, default: true},
    alignment: {type: String, default: 'justify-center'}
  },
  methods: {
    signupArtists($auth){

      this.$global.switchRoute('artist');
      this.$global.signup($auth, branding.auth0Params({screen_hint: 'signup', allowLogin: false}));
    },
    loginArtists($auth){
      this.$global.switchRoute('artist');
      this.$global.login($auth, branding.auth0Params({allowSignUp: false}));
    },
    signupEvents($auth){

      this.$global.switchRoute('event');
      this.$global.signup($auth, branding.auth0Params({screen_hint: 'signup', allowLogin: false}));
    },
    loginEvents($auth){

      this.$global.switchRoute('event');
      this.$global.login($auth, branding.auth0Params({allowSignUp: false}));
    }
  }
}
</script>

<style scoped>

.external .router-link-active {

  @apply bg-transparent;

}
.fadeIn-transition {
  transition: all .3s ease;
  opacity:0;
}
.expand-enter, .expand-leave {
  opacity: 1;
}
</style>