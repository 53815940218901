<template>
<div>
<ModalWrapper ref="editVenueIdentityModal" :is-open="isOpen">
		<template #header>
			<div class="text-xl"> 
				Profile
			</div>
			<p class="mt-2 text-sm">
				This information will be shown on your venue profile page to potential artists.
			</p>
		</template>

		<template #body>

			<form class="mb-24 form mt-0 gap-y-4 input-group" ref="form" novalidate> 
				<div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5 col-span-full">
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="venue-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Venue name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="venue-name"
                  id="venue-name"
                  
                  
                  v-model="form.name"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
                <span v-show="!form.name" class="mt-2 text-xs text-red-500">Venue name field must be completed.</span>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="about"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                About
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  id="about"
                  name="about"
                  rows="3"
                  v-model="form.about"
                  class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                ></textarea>
                <p class="mt-2 text-sm text-gray-500">
                  Write a few sentences about your venue.
                </p>
                <span v-show="!form.about" class="mt-2 text-xs text-red-500">About field must be completed.</span>
              </div>
            </div>
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="country"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Tags
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="country"
                  name="country"
                  v-model="form.venueTags"
                  autocomplete="country"
                  multiple
                  class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option :value=null selected disabled>-- Select one or more tags --</option>
                  <option
                    v-for="tag in venueTags"
                    :key="tag.id"
                    :value="tag.id"
                  >
                    {{ tag.name }}
                  </option>
                </select>
                <span v-show="!form.venueTags" class="mt-3 text-xs text-red-500">Venue tag(s) must be selected.</span>
              </div>
            </div>

             <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Location
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2 text-center flex">
                  <Button v-show="!location"
                          @click.native="openLocationsModal()" 
                          type="button" 
                          class="sm:mt-2">Add venue location</Button>
                  <input
                    v-show="location"
                    type="text"
                    name="venue-location"
                    id="venue-location"
                    
                    disabled
                    v-model="location"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  <div v-show="location" class="block ml-4 sm:mt-px sm:pt-2">
                    <TrashIcon @click="deleteLocation()" class="cursor-pointer text-gp_pink-default-500" />
                  </div>
              </div>
              <span v-show="!location" class="mt-2 text-xs text-red-500">Venue location must be completed.</span>
            </div>


						<div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Venue Logo
              </label>
							<profile-picture-upload 
								:image="form.icon" 
								@onImageChange="profilePictureUploaded" 
								profile-type="VenueProfilePicture"
							/>
						</div>
            <div class="mt-6">
              <span v-show="!form.icon" class="mt-6 text-md text-red-500">Venue logo must be uploaded.</span>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="cover-photo"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Gallery (Optional)
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
								<upload-gallery 
									:images="form.gallery"
									gallery-type="VenueGallery"
									@onGalleryChange="onGalleryChanged" 
								/>
              </div>
            </div>
          </div>

			</form>

		</template>

		<template #footer>

			<ModalFooterWrapper>

				<template #left>
					<GPButton @click.native="onClose" :disabled="isFormLoading" color="gray">Cancel</GPButton>
				</template>

				<template #right>
					<GPButton @click.native="onSave" :disabled="formDisabled" :loading="isFormLoading" color="pink">{{ submitButtonLabel }}</GPButton>
				</template>

			</ModalFooterWrapper>

		</template>
	</ModalWrapper>
  <VenueLocationModal ref="locationModal" v-on:saveLocation="addLocation($event)"/>

</div>
</template>
<script>
import ModalWrapper from '@/components/ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '@/components/ui/modal/ModalFooterWrapper.vue'
import Button from '@/components/ui/buttons/Button.vue'
import ProfilePictureUpload from '@/components/uploads/profile-picture-upload.vue'
import UploadGallery from '@/components/uploads/upload-gallery.vue'
import { TrashIcon } from '@vue-hero-icons/outline'
import VenueLocationModal from '../../components/modal/VenueLocation.vue'; 
import { apiMethods } from "@/state/helpers";
import GPButton from '@/components/ui/buttons/Button.vue'

export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
    Button,
		GPButton,
		ProfilePictureUpload,
		UploadGallery,
    TrashIcon,
    VenueLocationModal
  },
  props: {
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
    venue: {
      type: Object,
      required: true,
    },
  },
	emits:["closed", "updated"],
  data() {
    return {
			venueTags:null,
			isLoading:false,
      location: '',
      form: {
        name: '',
        about: '',
        venueTags: [],
        icon: '',
				gallery:[],
        location:{
          id:'',
        },
      },
    }
  },
  computed: {
		hasData(){
			if(this.venueTags){
				return true;
			}
			return false;
		},
		formDisabled() {
      return !this.form.name || !this.form.about || this.form.venueTags === [] || !this.form.icon || this.form.location.id === '' ? true : false
    },
		isFormLoading(){
			return false
		},
		submitButtonLabel(){
			return this.isFormLoading ? 'Applying' : 'Apply'
		}
  },

  mounted() {

		this.isLoading = true;

		Promise.all([
			//  Tags
			this.$store.dispatch('venue/getTags')
			.then((response) => {
				this.venueTags = response;
			})
		]).finally(() => {
			this.form.name = this.venue.name;
			this.form.venueTags = this.venue.venue_tags.map((tag) => tag.id);
			this.form.about = this.venue.about;
			this.form.icon = this.venue.icon;
			this.form.gallery = this.venue.gallery;
      this.location = this.venue.location ? this.venue.location.name : null;
      this.form.location.id = this.venue.location ? this.venue.location.id : null;
			//
			this.isLoading = false;
		});
  },
  methods: {
    ...apiMethods,
    addLocation($location){
      this.location = $location.preview;
      this.form.location.id = $location.id;
    },
    deleteLocation(){
      this.location = '';
      this.form.location.id = '';
    },
    openLocationsModal(){
      this.$refs.locationModal.toggleModal();
    },
		profilePictureUploaded(image) {
			this.form.icon = image
		},
    onGalleryChanged(images) {
      this.form.gallery = images;
    },
		onClose(){
			this.$emit('closed')
		},
		onSave(){
			if(this.form.name == "" || this.form.location.length === 0 || this.form.about == "" || this.form.venueTags.length == 0 || this.form.icon == null)
			{
				return;
			} else {
				this.$emit('updated', this.form);
			}
		}
  }
}
</script>
