import Vue from 'vue'
import App from './App.vue'
import './assets/css/base.css'
import 'animate.css';
import '@uppy/core/dist/style.css'
import '@uppy/file-input/dist/style.css'
import router from './router'
import store from './state/store'
import Meta from "vue-meta";
import vClickOutside from 'v-click-outside';
import { Auth0Plugin } from "./auth"; 
import { domain, clientId } from "../auth_config.json"

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import Embed from 'v-video-embed'
 
Vue.use(Embed);
import Notifications from 'vue-notification'
Vue.use(Notifications)
 
import VueNativeNotification from 'vue-native-notification'

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})

import VueTailwind from 'vue-tailwind'
const components = {
  //...
}

Vue.use(VueTailwind, components) 

import global from './global/auth.js' 
import './registerServiceWorker'

import GmapVue from 'gmap-vue';

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyDVHsyWlJY9jmaiiqoZuDPOePvDGj665DE',
    libraries: 'places',
    v: '3.26',
    customCallback: 'MyCustomCallback',
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})


import VueLuxon from "vue-luxon";
Vue.use(VueLuxon);

import VueGtm from '@gtm-support/vue2-gtm';


Vue.use(VueGtm, {
  id: 'GTM-T8CGDXT', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

Vue.use(Meta);
Vue.use(vClickOutside); 

Vue.mixin({
  methods: {
  },
})

//import Echo from 'laravel-echo' 

//window.Pusher = require('pusher-js');

window.PristineConfig = {
    classTo: 'input-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'input-error',
    errorTextTag: 'span',
    errorTextClass: 'text-help'
};

if (process.env.NODE_ENV == 'production')
{

  Sentry.init({
    Vue,
    dsn: "https://d2126e14f4cf4068ae2d2890d0ad8e5c@o1318371.ingest.sentry.io/6572762",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["bookings.genremusic.co.uk", /^\//],
        release: "gigpig_production@" + process.env.npm_package_version,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

}

/*

var echo_instance = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    wsHost: "api.gigpig.local", 
    wsPort: 6001,
    forceTLS: false,
    disableStats: true
})

*/

const plugin = {
  install () { 
    Vue.global = global 
    Vue.prototype.$global = global
 //   Vue.prototype.$echo = echo_instance
    Vue.prototype.debug = process.env.NODE_ENV == 'development'
  }
}
//console.log(echo_instance); 

Vue.filter('bookings', function (bid) {
  return bid.artist_final_accepted;
})


Vue.use(plugin)

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  // redirectUri: "https://bookings.genremusic.co.uk/auth/callback",
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
 
let $is_opus = false;
let $app_title = "GigPig";
let $favicon_ico = 'favicon.ico';
let $favicon_svg = 'favicon.svg';
if (window.location.pathname == '/opus-booking' || window.sessionStorage.getItem('opus')){ 
  document.title = 'OpusApeiro';
  document.querySelectorAll("[href*='favicon']").forEach(function($rel){
    $rel.setAttribute("href", "https://gigpigcdn.ams3.cdn.digitaloceanspaces.com/branding/opus_favicon.svg");
  });
  $is_opus = true;
  $app_title = 'OpusApeiro';
  $favicon_ico = '';
  $favicon_svg = '';
}

Vue.config.productionTip = false

new Vue({
  router,
  store: store,
  render: h => h(App, {
    props: {
      'isOpus': $is_opus,
      'app_title': $app_title,
      'favicon_ico': $favicon_ico,
      'favicon_svg': $favicon_svg
    }
  })
}).$mount('#app')
