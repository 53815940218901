<template>
    
    <transition
      appear
      enter-active-class="animate__animated animate__fadeInRight"
      leave-active-class="animate__animated animate__zoomOut"
    >
      <div class="fixed top-12 right-0 p-12 z-50" v-if="show_notification">
        <div class="rounded-md  p-4" :class="isError ? 'bg-red-50' : 'bg-green-50'">
          <div class="flex">
            <div class="flex-shrink-0">

<svg v-if="isError" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
</svg>


              <!-- Heroicon name: solid/check-circle -->
              <svg v-if="isError == false"
                class="h-5 w-5 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium " :class="isError ? 'text-red-800' : 'text-green-800'">
                {{notification_message}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>
export default {
    name: "notificationsmall",
    data()
    {
      return {
        notification_message: '',
        show_notification: false,
        fade_delay_ms: 7000,
        isError: false
      }
    },    
    computed: 
    {
        errorClass(){
          return this.isError ? 'focus:ring-offset-red-50 focus:ring-red-600 text-red-500 hover:bg-red-100' : 'focus:ring-offset-green-50 focus:ring-green-600 text-green-500 hover:bg-green-100'
        }
    },
    methods:
    {
      closeNotification()
      {         
          this.show_notification = false;
      },
      createNotification(message, isError = false){
         this.notification_message = message;
         this.show_notification = true;
         this.isError = isError
         
          setTimeout(this.closeNotification, this.fade_delay_ms);
       }
    }
}
</script>