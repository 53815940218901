<template>
  <div>
    <div class="mb-4">
      <file-input :uppy="uppy" />

      <image-gallery
        :images="images"
        v-on:onGalleryChange="replaceImages"
        v-on:onGalleryImageDeleted="deleteImage"
      />
    </div>

    <div v-if="restrictionErrors.length > 0">
      <ul>
        <li
          v-for="(restrictionError, index) in restrictionErrors"
          :key="index"
          class="text-red-500 text-sm"
        >
          {{ restrictionError }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AWSS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import ms from "ms";
import { getUploadParameters, confirmUpload } from "../../utils/uppy";
import FileInput from "@uppy/vue/src/file-input";
import ImageGallery from "./image-gallery.vue";

export default {
  components: {
    FileInput,
    ImageGallery,
  },
	props: {
    images: {
      type: Array,
    },
		galleryType:{
			type: String,
			default:"ArtistGallery"
		}
  },
  computed: {
    uppy() {
      const uppy = new Uppy({
        id: this.galleryType,
        autoProceed: true,
        restrictions: {
          maxFileSize: 4194304,
          allowedFileTypes: [".jpg", ".jpeg", ".png"],
        },
        locale: {
          strings: {
            chooseFiles: "Choose images",
          },
        },
      })
        .use(ThumbnailGenerator, {
          thumbnailWidth: 200,
        })
        .use(AWSS3, {
          limit: 2,
          timeout: ms("1 minute"),
          getUploadParameters,
        })
        .on("restriction-failed", (file, error) => {
          this.restrictionErrors = [error.message];
        })
        .on("thumbnail:generated", (file, preview) => {
          const { id, name, progress } = file;
          const { uploadComplete } = progress;

          this.addImage({ id, name, preview, uploading: !uploadComplete, uppyId: id });
        })
        .on("upload-success", (file) => {
          confirmUpload(file).then(() => {
            const images = [...this.images];
            const { id, meta } = file;
            const imageIndex = images.findIndex((image) => image.id === id);
            images.splice(imageIndex, 1, {
              ...images[imageIndex],
              uploading: false,
              id: meta.id,
            });

            this.$emit("onGalleryChange", images);
          });
        });

      return uppy;
    },
  },
  data() {
    return {
      restrictionErrors: [],
    };
  },
  beforeDestroy() {
    this.uppy.close({ reason: "unmount" });
  },
  methods: {
    closeUploadModal() {
      this.$emit("hideUploadModal");
    },
    addImage(image) {
      this.$emit("onGalleryChange", [...this.images, image]);
    },
    deleteImage(deletedImage) {
      if (deletedImage.uppyId) {
        this.uppy.removeFile(deletedImage.uppyId);
      }

      const images = this.images.filter((image) => image.id !== deletedImage.id);
      this.$emit("onGalleryChange", images);
    },
    replaceImages(images) {
      this.$emit("onGalleryChange", images);
    },
  },
};
</script>
