<template>

<div>  

  <AcceptAllOffers ref="acceptalloffers" @reset="selectedReset()"/>
  <DeclineAllOffers ref="declinealloffers" @reset="selectedReset()"/>

  <div class="p-4 grid grid-cols-1 gap-4 sm:grid-cols-1 bidblock max-w-6xl">
    <div v-for="(date, index) in groupedDatesbyArray"  :key="'datepick' + index">
      <div  :class="index == selectedIndex ? 'selected' : ''" @click="selectedIndex = index" class="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
        <div class="flex-1 min-w-0 ">
          <a class="focus:outline-none grid gap-4 lg:grid-flow-row grid-flow-col">  
            <div class="grid grid-flow-col justify-between space-x-3">
              <div class="grid grid-flow-col lg:grid-cols-2 gap-4">
                <p class="text-sm font-medium text-gray-900">{{date[0].start}}</p>
                <p class="text-sm text-gray-500 truncate  hidden lg:block" >{{pluralize(date.length, 'offer')}}</p>
              </div>

              <div class="items-center justify-center flex-none md:flex space-x-none md:space-x-8 lg:space-x-24 w-46 md:w-76 lg:96">
                <span v-if="index == selectedIndex" class="uppercase text-xs">Selected</span>
                <span v-else class="uppercase text-xs">Click to view offers</span>
                <div v-show="showAcceptDeclineAll" class="flex space-x-6 mt-2 md:mt-0 items-center justify-center md:justify-none">
                  <span @click="$refs.acceptalloffers.show(date);" title="Available" class="text-green-500 hover:text-green-800"><ThumbUpIcon /></span> 
                  <span @click="$refs.declinealloffers.show(date);" class="hover:text-black text-red-500" title="Not Available"><ThumbDownIcon /></span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      
      <div class=" flex flex-col " v-show="index == selectedIndex"> 
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="max-w-6xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full bg-white w-max divide-y relative divide-gray-200 text-left">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="relative px-6 py-3" :colspan="hasBrandedVenue(date) ? 2 : 1">
                      <span class="sr-only">Edit</span>
                    </th>
                    <div v-if="mobile"> 
                      <th scope="col" v-show="showSelects" class="relative px-6 py-3 max-w-200px"> 
                        Availability
                      </th> 
                      <th scope="col" v-show="!showSelects" class="relative px-6 py-3 max-w-200px"> 
                        Status
                      </th>   
                    </div>
                    <th scope="col" class="relative px-6 max-w-200px">
                      Date
                    </th> 
                    <th scope="col" class="relative px-6 max-w-200px"> 
                      Gig
                    </th>
                    <th scope="col" class="relative px-6 py-3 max-w-200px"> 
                        Artist
                    </th>
                    <th scope="col" class="relative px-6 py-3 max-w-200px"> 
                        Fee
                    </th> 
                    <div v-if="!mobile"> 
                      <th scope="col" v-show="showSelects" class="relative px-6 py-3 max-w-200px"> 
                        Availability
                      </th> 
                      <th scope="col" v-show="!showSelects" class="relative px-6 py-3 max-w-200px"> 
                        Status
                      </th>  
                    </div>
                    <th scope="col" class="relative px-6 py-3 max-w-200px" v-show="showSelects"> 
                      <input ref="selectall" type="checkbox" @change="selectAll($event.target.checked, index)"/>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr class="relative" v-for="(bid, innerIndex) in date" :key="'key' + innerIndex" :style="{backgroundColor: bidBackgroundColour(bid)}">
                    <td class="p-6">          
                      <router-link class="text-gp_pink-default-500" :to="'/artist/offers/' + bid.id" >View offer</router-link>
                    </td>
                      <td v-if="hasBrandedVenue(date)">
                          <img v-if="bid.gig.venue.brand !== null && bid.gig.venue.brand.logo_url !== null" class="max-w-[100px]" :src="bid.gig.venue.brand.logo_url" :alt="bid.gig.venue.brand.name">
                      </td>
                    <td v-if="mobile" class="p-6">                         
                      <span v-if="bid.gig.cancelled">
                        This gig has been cancelled
                      </span>   
                      <span v-else-if="bid.gig && !bid.accepted && bid.gig.status == 'CONFIRMED'" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        Another artist is booked on this gig
                      </span>                     
                      <span v-else-if="bid.declined" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                        You declined this offer
                      </span>                   
                      <span v-else-if="!bid.date_bid && !bid.declined && !bid.accepted" class="inline-flex items-center space-x-8">                                         
                          <a @click="initial_accept(bid.id)" title="Available" class="text-green-500 hover:text-green-800"><ThumbUpIcon/></a> 
                          <a @click="decline(bid.id)" class="hover:text-black text-red-500" title="Not Available"><ThumbDownIcon/></a>  
                      </span>                   
                      <span v-else-if="bid.date_bid && !bid.declined && !bid.accepted" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                        Awaiting venues confirmation
                      </span>
                      <span v-else-if="bid.date_bid && bid.cancelled" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                        Removed from lineup
                      </span>       
                      <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800" v-else-if="bid.accepted && !bid.artist_final_accepted">
                        Needs your final acceptance
                      </span>                                                      
                    </td>
                    <td class="p-6 max-w-200px" v-if="bid.gig">   
                        <div> {{ formatDate(bid.gig.start) }} </div>
                        <div> {{ getDateHour(bid.gig.start) }} - {{ getDateHour(bid.gig.end) }} </div>
                    </td>
                    <td class="p-6 max-w-200px" v-if="bid.gig && bid.gig.venue && bid.gig.venue.address">
                      {{bid.gig.name}}<br/>
                      {{bid.gig.venue.name}}<br/>
                      {{bid.gig.venue.address.town}}<br/>
                    </td>
                    <td class="p-6 max-w-200px" v-if="bid.artist">                             
                        {{  bid.artist.name }}                               
                    </td> 
                    <td class="p-6 max-w-200px">                             
                        {{bid.gig.currency.symbol}}{{ bid.fee }} {{ bid.artist.vat_number ? '+ VAT' : '' }}
                    </td>
                    <td v-if="!mobile" class="p-6">         
                      <span v-if="bid.gig.cancelled" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        This gig has been cancelled
                      </span>   
                      <span v-else-if="bid.gig && !bid.accepted && bid.gig.status == 'CONFIRMED'" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        Another artist is booked on this gig
                      </span>                     
                      <span v-else-if="bid.declined" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                        You declined this offer
                      </span>                   
                      <span v-else-if="!bid.date_bid && !bid.declined && !bid.accepted" class="inline-flex items-center space-x-8">                                         
                          <a @click="initial_accept(bid.id)" title="Available" class="text-green-500 hover:text-green-800"><ThumbUpIcon/></a> 
                          <a @click="decline(bid.id)" class="hover:text-black text-red-500" title="Not Available"><ThumbDownIcon/></a>  
                      </span>                  
                      <span v-else-if="bid.date_bid && !bid.declined && !bid.accepted" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                        Awaiting venues confirmation
                      </span>     
                      <span v-else-if="bid.date_bid && bid.cancelled" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                        Removed from lineup
                      </span>   
                      <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800" v-else-if="bid.accepted && !bid.artist_final_accepted">
                        Needs your final acceptance
                      </span>               
                    </td>
                    <th v-if="!bid.date_bid && !bid.accepted && !bid.declined" scope="col" class="relative px-6 py-3 max-w-200px"> 
                      <input ref="check" type="checkbox" v-bind:id="bid.id" :selectedIndex="index" @change="selectOne($event.target.checked, bid)"/>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="unfilteredBids && groupedDatesbyArray.length == 0">
      <NoDataMsgWrapper>{{noItemsText}}</NoDataMsgWrapper>
    </div> 
    <div  v-show="unfilteredBids == null">
      <NoDataMsgWrapper>Fetching your enquiries...</NoDataMsgWrapper>    
    </div>
    <!-- More people... -->
  </div>
  <div v-show="this.selectedOffers.length > 0" class="action-bar justify-between">  
    <p v-if="selectedOffers.length == 1" class="uppercase text-gp_pink-default-500 font-gp_pink-default-500 font-light px-8">Offer Selected</p>
    <p v-else class="uppercase text-gp_pink-default-500 font-gp_pink-default-500 font-light px-8">Offers Selected</p>
    <div class="flex space-x-2 flex-col space-y-2 lg:flex-row items-end w-full lg:w-1/2"> 
      <button @click="openAcceptModal()" class="cta icon bg-indigo-600"><ThumbUpIcon class="mr-2" /> Confirm Availability ({{ selectedOffers.length }})</button>
      <button @click="openDeclineModal()" class="cta icon bg-black text-white"><ThumbDownIcon class="mr-2" /> Decline Availability ({{ selectedOffers.length }})</button>
    </div>
  </div>
</div>

</template>

<script type="text/javascript">

import { DateTime } from 'luxon';
import fullDate from  '@/utils/format-date-full.js'; 
import sortBy from  '@/utils/sortby.js'; 
import lodash from 'lodash';
import pluralize from  '@/utils/pluralize'
import NProgress from 'nprogress'
import { apiComputed, apiMethods } from '@/state/helpers.js';
import { ThumbUpIcon, ThumbDownIcon} from "@vue-hero-icons/outline";
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import AcceptAllOffers from '../../components/modal/AcceptAllOffers.vue';
import DeclineAllOffers from '../../components/modal/DeclineAllOffers.vue';
import normalizeDate from '../../utils/normalizeDate.js';


    export default {
        name: "OfferByDate", 
        data(){
          return {
            mobile: false,
            items: [], 
            filteredBids: [],
            selectedOffers: [],
            selectedIndex: -1, 
            groupedDatesbyArray: null,
            openFirstByDefault: false,
            loading: false
          }
        },
        props:{
          unfilteredBids: Array,          
          noItemsText: String,
          showSelects: Boolean,
        },
        components: {
          AcceptAllOffers,
          DeclineAllOffers,
          NoDataMsgWrapper,
          ThumbUpIcon, 
          ThumbDownIcon,
        },
        created(){
          
          this.loading = true;
        },
        mounted(){
          this.loadData();
          this.loading = false;
        },
        beforeUpdate() {
          //NProgress.start();
        },
        updated(){
          //NProgress.done();
        },
        computed:{
          ...apiComputed,
          showAcceptDeclineAll(){
            return this.$route.name === 'ArtistBidIndex' || this.$route.name === 'ArtistCounterBidIndex'
          }
        },    
				watch:{
					unfilteredBids(){
						this.loadData()
					},
          selectedOffers: function(value){
            if(value.length == 0){
              this.$refs.selectall[0].checked = false;
            }
          }
				},
        methods:
        {   
          ...apiMethods,
            bidBackgroundColour(bid) {
              if (bid && bid.gig && bid.gig.venue && bid.gig.venue.brand && bid.gig.venue.brand.logo_bg) {
                return bid.gig.venue.brand.logo_bg;
              } else {
                return false;
              }
            },
            hasBrandedVenue(date) {
              return date.filter(item => {
                  return item.gig.venue.brand !== null && item.gig.venue.brand !== undefined
              }).length > 0
            },
          loadData(){
            // Query device

            console.time('loadOfferByDate');

            var query = window.matchMedia("(max-width:768px)");

            if (query.matches){
                this.mobile = true;
            } else {
                this.mobile = false;
            }


            let groupedDates = lodash.groupBy(this.unfilteredBids, bid => {
              return DateTime.fromISO(bid.gig.start).toFormat('dd/MM/yyyy');
            });
 
            let keys = Object.keys(groupedDates);
            this.groupedDatesbyArray = [];

            keys.forEach(date => {

            this.groupedDatesbyArray.push(this.unfilteredBids.filter(b =>
            {
                return DateTime.fromISO(b.gig.start).toFormat('dd/MM/yyyy') == date
            }));

            this.groupedDatesbyArray.sort(function(a, b){
              return a[0].gig.start_unix - b[0].gig.start_unix; 
            })});


            
            console.timeEnd('loadOfferByDate');


            if (this.openFirstByDefault) {
              this.filter(0);
            }

          },    
          getDateHour($date){
            let normalised = normalizeDate($date);
            let datetime = DateTime.fromFormat(normalised, 'yyyy/MM/dd HH:mm');
            return datetime.toFormat('HH:mm');
          },
          formatDate($date) {
            const dateUTC = new Date($date);
            let date = dateUTC.toUTCString();
            return date.slice(0,-13);
          },
          initial_accept($bidID){
            
            NProgress.start();
            this.getArtistBid($bidID).then((resp) => { 
                this.initialArtistAcceptance(resp)
                    .then(response => {
                        console.log("update response", response);
                        NProgress.done();
                        this.$notify("Offer sent to venue to accept/decline");
                        this.selectedIndex = null;
                        this.$emit('reloadData');
                     }).catch((error) => {
                        alert(error);
                })  
            });

          },
          decline($bidID){
            
            NProgress.start();
            this.getArtistBid($bidID).then((resp) => {
                resp.declined = true;
                this.updateArtistBid(resp)
                .then(() => {
                    NProgress.done();
                    this.$notify('Bid declined');
                    this.selectedIndex = null;
                    this.$emit('reloadData');
                });
            });

          },
          selectAll($checked, $index){
            this.$refs.check.forEach((c) => {
              if(c.getAttribute('selectedIndex') == $index){
                c.checked = $checked;
                let $offer = this.groupedDatesbyArray[$index].find(bid=>bid.id == c.id);
                this.selectOne($checked, $offer)
              }
            })      
          },
          selectOne: function(isChecked, $bid){
            if (isChecked && this.selectedOffers.includes($bid) == false){
              this.selectedOffers.push($bid);
            } else if (!isChecked && this.selectedOffers.includes($bid) == true){ 
              let deselect = this.selectedOffers.indexOf($bid);
              if (deselect !== -1){
                this.selectedOffers.splice(deselect, 1);
              }
            } 
          },
          openAcceptModal(){
           this.$refs.acceptalloffers.show(this.selectedOffers);
          },
          openDeclineModal(){
            this.$refs.declinealloffers.show(this.selectedOffers);
          },
          selectedReset($boolean){
            this.selectAll($boolean);
            this.selectedOffers = [];
            this.$emit('reloadData');
            var clist = document.getElementsByTagName("input");
            for (var i = 0; i < clist.length; ++i) { 
              clist[i].checked = false; 
            }
          },
          pluralize,
          fullDate,
          sortBy,        
        }

    }

</script>