
<template>
	
	<ModalWrapper :is-open="isOpen">
		<template #header>
			<div class="text-xl"> 
				{{ modalTitle }}
			</div>
		</template>
		<template #body>
			
			<form class="mb-24 form input-group" ref="form" novalidate> 
				<div class="mt-6 sm:mt-5 col-span-full">

						<div class="mt-1 sm:mt-0">
							<div>
								<label for="name" class="block text-sm font-medium text-gray-700">Role</label>
								<div class="mt-1">
									<input 
										type="text" name="name" id="name" 
										v-model.trim="form.name" 
										:disabled="isLoading"
										@blur="onInputNameBlur"
										class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" 
										placeholder="Name of the new role"
									/>
									
								</div>
								<p v-if="inputValidity === 'invalid'" class="mt-2 text-sm text-red-600" id="name-error">The role name must be included.</p>
								<p v-else class="mt-2 text-sm">&nbsp;</p>
							</div>
						</div>

				</div>

				<div class="mt-4 sm:mt-3 col-span-full">
					<div class="flex items-center mb-4" v-for="permission in permissions" :key="permission.slug">
						<SwitchToggle 
							:enabled="accessIdEnabled(permission.slug)"
							:disabled="isLoading"
							@updateEnable="onAccessUpdated"
							:id="permission.slug" 
							:label="permission.name"
						/>
					</div>
				</div>

			</form>

			<FormFooterMessage :show="!accessValid">
				<p class="text-sm text-red-600">
					You must select at least 1 permission.
				</p>
			</FormFooterMessage>

		</template>
		<template #footer>
		
			<ModalFooterWrapper>
				<template #left>
					<Button @click.native="onClose" :disabled="isLoading" color="gray">Close</Button>
				</template>
				<template #right>

					<Button @click.native="onSave" :disabled="formDisabled" :loading="isLoading">{{ submitButtonLabel }}</Button>
				</template>
			</ModalFooterWrapper>
					
			
		</template>
	</ModalWrapper>
	
</template>

<script>
import ModalWrapper from '../../ui/modal/ModalWrapper.vue'
import ModalFooterWrapper from '../../ui/modal/ModalFooterWrapper.vue'
import SwitchToggle from '../../ui/form/SwitchToggle.vue';
import FormFooterMessage from '../../ui/form/FooterMessage.vue'
import Button from '../../ui/buttons/Button.vue'
import client from '../../../utils/client';
const fullAccessId = "full-access";
export default {
	components: {
		ModalWrapper,
		ModalFooterWrapper,
		SwitchToggle,
		FormFooterMessage,
		Button,
	},
	props:{
		identity:{
			type:String,
		},
		isOpen:{
			type:Boolean,
			required: true,
			default:false
		},
		role:{
			type:Object,
			required: true
		},
		permissions:{
			type:Array,
			required: true,
		},
		venueId:{
			type:String,
			required: true,
		}
	},
	emits:["closed", "updated", "delete"],
	data(){
		return {
			form: {
				name: this.role.name,
				access: this.role.permissions.map(p => p.slug)
			},
			inputValidity:"",
			accessValid:true,
			isLoading:false,
		}
	},
	computed:{
		modalTitle() {
      return `Edit ${ this.role.name } role`
    },
		accessIdEnabled() {
      return (id) => this.form.access.includes(fullAccessId) && this.form.access.length === 1 ? true : this.form.access.includes(id);
    },
		formDisabled() {
      return this.form.access.length <= 0 || this.isLoading ? true : false;
    },
		submitButtonLabel() {
      return this.isLoading ? 'Saving role' : 'Save';
    },
	},
	watch:{
		role(updatedRole){
			this.form.name = updatedRole.name;
			this.form.access = updatedRole.permissions.map(p => p.slug)
		}
	},
	methods:{
		onClose(){
			this.$emit("closed");
		},
		onDelete(){
			this.$emit("delete");
		},
		onInputNameBlur(){
			if(this.inputValidity !== ''){
				if(this.form.name === ''){
					this.inputValidity = "invalid"
				}else{
					this.inputValidity = "valid"
				}
			}
		},
		formValid(){
			let valid = true;
			if(this.form.name === ''){
				valid = false;
				this.inputValidity = "invalid"
			}else{
				this.inputValidity = "valid"
			}
			return valid;
		},
		onSave(){
			if(this.formValid()){
				const payload =  {
					name: this.form.name,
					permissions: this.form.access
				}
				this.isLoading = true;
				client
					.put(`venues/${this.venueId}/roles/${this.role.id}`, payload)
					.then(response => {
						const role = response.data.data;
						// CHECK, bug in the api that returned the role without the permissions obj, so add them here if it is missing.
						if(!role.permissions){
							role.permissions = payload.permissions.map(p => {
								const ap = this.permissions.find(pp => pp.slug === p)
								return {
									...ap
								}
							})
						}
						// CHECK, that returned role without the users obj, so add them here if it is missing.
						if(!role.users){
							role.users = []
						}
						this.$store
							.dispatch('venueRoles/updateVenueRole', {
								venueId:this.venueId, 
								role
							})
							.then((response) => {
								this.isLoading = false;
								this.$emit("updated", response);
								}, err => {
									console.log(err)
								});
						
						
					})
					.catch(err => {
						console.log(err)
					})
			}
		},
		onAccessUpdated(id){
			if(this.form.access.includes(id)){
				// if the id is already included, assume to take it out
				this.form.access = this.form.access.filter(n => n !== id);
			}else{
				if(id === fullAccessId){
					// if full access dismiss everything else
					this.form.access = [ id ];
				}else{
					// check if not coming from full-access
					if(this.form.access.includes(fullAccessId) && this.form.access.length === 1){
						// if we are coming from full access then need to deselect full-access, deselect the selected id (because it is forced on) and add all the other permissions except selected id
						const updated = []
						this.permissions.map(p => {
							if(p.slug !== fullAccessId && p.slug !== id){
								updated.push(p.slug);
							}
						});
						this.form.access = updated;
					}else{
						// if it doesn't match the above logic, just add it
						this.form.access.push(id);
					}
					
				}
			}
			this.accessValid = this.form.access.length > 0;
		}
	},
}
</script>